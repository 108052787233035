<template>
    <div :class="mode!=='LK'?'component_padding q-my-md':'q-my-xs'">

        <q-tabs
              v-model="tab"
              class="text-accent"
              active-color="primary"
              align="left"
            >
            <q-tab v-if="!hideTabs" name="list_programs" :label="message[lang].list_programs" />
            <!-- <q-tab v-if="!hideTabs" name="list_schedules" :label="message[lang].list_schedules"/> -->
            <!-- <q-tab v-if="!hideTabs" name="list_user_schedules" :label="message[lang].results"/> -->

        </q-tabs>
        
        <q-tab-panels
              v-model="tab"
              class="bg-dark text-accent q-pr-md"
            >
            <!-- Программы в каталоге -->
            <q-tab-panel v-if="mode==='cat'" name="list_programs" style="height: 750px; padding:0px; margin:0px;">
                
                <div class="row items-center">

                    {{message[lang].the_programs}}
                
                    <q-btn flat round color="positive" :icon="isListViewProg?'dashboard':'list'" @click="isListViewProg=!isListViewProg">
                        <q-tooltip v-if="isListViewProg" class="bg-primary h-tt">{{message[lang].view_cards}}</q-tooltip>
                        <q-tooltip v-else class="bg-primary h-tt">{{message[lang].view_list}}</q-tooltip>
                    </q-btn>
                </div>
                    
                <!-- Список -->
                <div v-if="isListViewProg">
                    <q-list separator>
                        <q-item clickable v-ripple v-for="(item,index) in listPrograms" :key="item.ID">
                            <q-item-section avatar v-if="item?.PROPERTY_VALUES?.flags?.logo">
                                <q-avatar rounded>
                                    <q-img
                                    class="rounded-borders"
                                    :src="item.PROPERTY_VALUES.flags.logo"
                                    spinner-size="2rem"
                                    spinner-color="positive"
                                    />
                                </q-avatar>
                            </q-item-section>

                            <q-item-section @click="viewProgram(item, index)">
                                <q-item-label class="text-positive h5">
                                    <span v-if="parent.showSysInfo=='Y' && (parent.isModer || parent.isAdmin)">{{item.ID}}</span>
                                </q-item-label>
                                <q-item-label class="text-positive h4" lines="2">
                                    {{parent.restoreQuotes(item.NAME)}}
                                </q-item-label>
                            </q-item-section>
                        </q-item>
                    </q-list>

                </div>
                <!-- Карточки -->
                <div v-else class="q-pa-md row items-start q-gutter-md">
                    <q-card  class="cours-card" v-for="(item,index) in listPrograms" :key="item.ID">
                        <q-card-section>
                            <div class="row justify-between items-center">
                                <div class="col-8 q-gutter-x-md">
                                    <q-btn unelevated color="primary" @click="viewProgram(item,index)">
                                    {{message[lang].one_program}}
                                    <span v-if="parent.showSysInfo=='Y' && (parent.isModer || parent.isAdmin)">_{{item.ID}}</span>
                                    </q-btn>
                                    <q-img v-if="item.ACTIVE === 'Y'" style="cursor: pointer; height: 26px; width: 26px; margin-left: 10px"  src="img/card/cours/secondary/doc/published.svg"><q-tooltip class="bg-primary h-tt">{{message[lang].published}}</q-tooltip></q-img>
                                    <q-img v-else style="cursor: pointer; height: 26px; width: 26px; margin-left: 10px"  src="img/card/cours/secondary/doc/draft.svg"><q-tooltip class="bg-primary h-tt">{{message[lang].not_published}}</q-tooltip></q-img>
                                </div>
                            </div>
                        </q-card-section>

                        <q-card-section class="cursor-pointer" horizontal @click="viewProgram(item,index)">
                            <q-card-section avatar v-if="item?.PROPERTY_VALUES?.flags?.logo" class="col-5 flex flex-center">
                                <q-img
                                    :src="item.PROPERTY_VALUES.flags.logo"
                                    spinner-size="2rem"
                                    spinner-color="positive"
                                    style="border: #dddddd 1px dashed; border-radius: 15px"
                                />
                            </q-card-section>

                            <q-card-section class="q-pa-lg text-accent h4">
                                {{parent.restoreQuotes(item.NAME)}}
                            </q-card-section>

                        </q-card-section>
                    </q-card>
                </div>
            </q-tab-panel>
            <!-- Программы у модератора -->
            <q-tab-panel v-else name="list_programs" style="height: 750px; padding:0px; margin:0px;">
                <div class="row items-center justify-between q-mb-md" style="margin-top: 0px">
                  <div class="col-11 h5 text-positive">
                    <q-expansion-item
                        icon="help_outline"
                        :label="message[lang].program_info.substr(0,100)+'...'"
                      >
                        <div v-html="message[lang].program_info"/>
                    </q-expansion-item>
                  </div>
                  <div class="col-1 text-right q-gutter-md" style="padding-right: 10px">
                      <q-btn :disable="loading" padding="xs" size="lg" flat round color="secondary" icon="add_circle_outline" @click="addProgram(null,null)">
                          <q-tooltip class="bg-primary h-tt">{{message[lang].add_program}}</q-tooltip>
                      </q-btn>
                  </div>
                </div>
                
                <div class="row justify-between q-pa-md">
                    <div class="col-3 h4 text-positive text-left">
                        <q-toggle
                            v-model="isArchive"
                            :label="message[lang].is_archive"
                            color="accent"
                            false-value="N"
                            true-value="Y"
                            @update:model-value="loadMorePrograms(true)"
                        />
                    </div>
                    
                    <!-- Фильтр по шаблонам -->
                    <div class="col-2 text-right">
                        <q-btn-dropdown color="positive" :label="message[lang].prog_filter" dropdown-icon="tune" no-caps rounded flat>
                        <q-list>
                            <q-item clickable v-close-popup @click="loadMorePrograms(true)">
                            <q-item-section>
                                <q-item-label>{{message[lang].prog_filter_all}}</q-item-label>
                            </q-item-section>
                            </q-item>

                            <q-item clickable v-close-popup @click="loadMorePrograms(true, 'grp', 500)">
                            <q-item-section>
                                <q-item-label>{{message[lang].prog_filter_tgrp}}</q-item-label>
                            </q-item-section>
                            </q-item>

                            <q-item clickable v-close-popup @click="loadMorePrograms(true, 'user', 10)">
                            <q-item-section>
                                <q-item-label>{{message[lang].prog_filter_tuser}}</q-item-label>
                            </q-item-section>
                            </q-item>
                        </q-list>
                        </q-btn-dropdown>
                    </div>
                </div>
                <div class="g-mr-md">
                    <q-card class="cat-card q-py-sm">
                        <q-card-section>
                            <q-list separator v-if="listPrograms && listPrograms.length">
                                <q-item v-for="(item,index) in listPrograms"
                                    :key="index"
                                    clickable
                                    v-ripple
                                    >
                                    <q-item-section avatar>
                                        <q-img v-if="item?.PROPERTY_VALUES?.flags?.logo"
                                            :src="item.PROPERTY_VALUES.flags.logo"
                                            spinner-size="2rem"
                                            spinner-color="positive"
                                            style="border: #dddddd 1px dashed; border-radius: 15px"
                                        />
                                    </q-item-section>
                                    <q-item-section @click="addProgram(index, item)">
                                        <span :class="item.ACTIVE==='Y' ? 'text-primary' : 'text-positive'">
                                            <q-icon size="sm"  v-if="item.PREVIEW_TEXT==='template_type:user'"
                                                name="person" :color="item.ACTIVE==='Y' ? 'primary':'positive'"/>
                                            <q-icon size="sm"  v-else-if="item.PREVIEW_TEXT==='template_type:grp'"
                                                name="groups" :color="item.ACTIVE==='Y' ? 'primary':'positive'"/>    

                                            {{parent.restoreQuotes(item.NAME)}}
                                        </span>
                                    </q-item-section>
                                    <q-item-section side>
                                    <q-btn flat round icon="file_copy" color="positive" @click="copyProgram(index, item)">
                                        <q-tooltip class="bg-primary h-tt">{{message[lang].copy_program}}</q-tooltip>
                                    </q-btn>
                                    <q-btn :disable="item.ACTIVE==='Y'" flat round icon="clear" color="positive" @click="delProgram(index, item, 'programs')">
                                        <q-tooltip class="bg-primary h-tt">{{message[lang].clear_program}}</q-tooltip>
                                    </q-btn>
                                    </q-item-section>
                                </q-item>
                            </q-list>
                            <div class="text-center" v-if="isMoreProgram">
                            <q-btn @click="loadMorePrograms()" round flat color="positive" icon="keyboard_arrow_down" />
                            </div>
                        </q-card-section>
                    </q-card>
                </div>
            </q-tab-panel>

            <!-- Назначения -->
            <q-tab-panel name="list_schedules" style="height: 750px; padding:0px; margin:0px">
                <div class="row items-center justify-between q-mb-md" style="margin-top: 0px">
                <div class="col-3 h4 text-positive text-left">
                        <q-toggle
                            v-model="isArchive"
                            :label="message[lang].is_archive"
                            color="accent"
                            false-value="N"
                            true-value="Y"
                            @update:model-value="loadSchedules(true)"
                        />
                    </div>
                    <div class="col-3 text-right q-gutter-md" style="padding-right: 10px">
                        <q-btn :disable="loading" padding="xs" size="lg" flat round color="secondary" icon="add_circle_outline" @click="addSchedule(null,null)">
                            <q-tooltip class="bg-primary h-tt">{{message[lang].add_schedule}}</q-tooltip>
                        </q-btn>
                    </div>
                </div>

                <div class="g-mr-md">
                    <q-card class="cat-card">
                        <q-list separator v-if="listSchedules && listSchedules.length">
                            <q-item v-for="(item,index) in listSchedules"
                                :key="index"
                                clickable
                                v-ripple
                                >
                                <q-item-section @click="addSchedule(index, item)">
                                    <span :class="item.ACTIVE==='Y' ? 'text-primary' : 'text-accent'">
                                        {{item.ID}} {{parent.restoreQuotes(item.program_name)}}
                                        [{{ parent.customFormatter(item.DATE_ACTIVE_FROM) }} - {{ parent.customFormatter(item.DATE_ACTIVE_TO) }}]

                                    </span>
                                    <div class="row q-gutter-xs text-positive h6" v-if="item.DETAIL_TEXT && item.DETAIL_TEXT.students && item.DETAIL_TEXT.students.length" >
                                        <div class="q-my-none" v-for="(student, st_index) in item.DETAIL_TEXT.students" :key="st_index">{{student.name}}, </div>
                                    </div>
                                </q-item-section>
                                <q-item-section side>
                                    <q-btn :disable="item.ACTIVE==='Y'" flat round icon="clear" color="positive" @click="delProgram(index, item, 'progscheds')">
                                        <q-tooltip class="bg-primary h-tt">{{message[lang].clear_schedule}}</q-tooltip>
                                    </q-btn>
                                </q-item-section>
                            </q-item>
                        </q-list>
                        <!--   -->
                        <div class="text-center" v-if="isMore">
                          <q-btn @click="loadSchedules()" round flat color="positive" icon="keyboard_arrow_down" />
                        </div>
                    </q-card>
                </div>
            </q-tab-panel>

            <!-- Результаты назначений -->
            <q-tab-panel name="list_user_schedules" style="height: 750px; padding:0px; margin:0px">
                <div v-if="mode!=='LK'" class="row justify-between q-px-md" style="margin-top: 56px">
                    <div class="col-3 h4 text-positive text-left">
                        <q-toggle
                            v-model="isArchive"
                            :label="message[lang].is_archive"
                            color="accent"
                            false-value="N"
                            true-value="Y"
                            @update:model-value="loadSchedules(true)"
                        />
                    </div>
                    <div class="col-4 text-right q-gutter-x-sm">
                        <q-btn icon="done" color="positive" no-caps rounded flat 
                            :label="isChecked(listSchedules)?message[lang].uncheck_all_uscheds:message[lang].check_all_uscheds" 
                            @click="checkAll(listSchedules)" />

                        <q-btn :disable="!isChecked(listSchedules)" icon="send" :loading="loading_send"
                            color="primary" flat no-caps rounded :label="isArchive==='Y'?message[lang].from_archive:message[lang].to_archive" @click="toggleArchive(listSchedules)" >
                            <!-- <q-tooltip class="bg-primary h-tt">{{isArchive?message[lang].from_archive:message[lang].to_archive}}</q-tooltip> -->
                        </q-btn>
                    </div>
                </div>
                <div class="g-mr-md" >
                    <!-- ЛК мои программы -->
                    <q-card v-if="mode==='LK'" class="cat-card">
                        <q-list separator>
                            <q-item v-for="(item, index) in listUserSchedules" :key="index">
                                <q-item-section avatar>
                                    <q-img v-if="item?.program_flags?.logo"
                                        :src="item.program_flags.logo"
                                        spinner-size="2rem"
                                        spinner-color="positive"
                                        style="border: #dddddd 1px dashed; border-radius: 15px"
                                    />
                                </q-item-section>
                                <q-item-section>
                                    <q-item-label class="text-accent h4">
                                        {{ parent.restoreQuotes(item.program_name) }}
                                    </q-item-label>
                                    <q-item-label caption>
                                        {{ parent.customFormatter(item.DATE_ACTIVE_FROM) }} - {{ parent.customFormatter(item.DATE_ACTIVE_TO) }}
                                    </q-item-label>
                                    <div class="q-pt-sm q-pl-md">
                                        <div class="q-pa-none q-ma-none" v-for="(moduleRow, index_module_row) in item.DETAIL_TEXT" :key="index_module_row" style="margin-left: 20px; border-bottom: 1px dotted #cccccc">
                                          <div v-if="moduleRow && moduleRow.length > 1">
                                            <!-- На уровне несколько модулей свернем их -->
                                            <q-expansion-item
                                              clickable
                                              :label="`${message[lang].module_level} ${index_module_row+1} `"
                                              :icon="isModulesLevelPassed(moduleRow)?'done':'remove'"
                                              :header-class="isModulesLevelPassed(moduleRow)?'text-secondary':'text-accent'"
                                            >
                                              <div class="q-pa-none q-ma-none" v-for="(module, index_module) in moduleRow" :key="index_module">
                                                  <div class="row q-pa-xs myselect">
                                                      <div class="col-10 q-pr-md text-positive">
                                                          {{ showTypeModule(module.mtype) }}
                                                          <span v-if="isShowModule(item.DETAIL_TEXT, index_module, index_module_row, item.method)"
                                                              class="text-primary cursor-pointer" @click="goModule(module, item.ID)">
                                                              {{ module.mname }}
                                                          </span>
                                                          <span v-else>{{ module.mname }}</span>
                                                      </div>
                                                      <div class="col-2" :class="'text-'+(showModulePassed(module.passed).color)">
                                                          <q-icon :name="showModulePassed(module.passed).icon"/>
                                                          {{ showModulePassed(module.passed).label }}
                                                      </div>

                                                  </div>
                                              </div>
                                            </q-expansion-item>
                                          </div>
                                          <div v-else>
                                           <!-- На уровне 1 модуль -->
                                              <div class="q-pa-none q-ma-none" v-for="(module, index_module) in moduleRow" :key="index_module">
                                                  <div class="row q-pa-xs myselect">
                                                      <div class="col-10 q-pr-md text-positive">
                                                          {{index_module_row+1}} {{ showTypeModule(module.mtype) }}
                                                          <span v-if="isShowModule(item.DETAIL_TEXT, index_module, index_module_row, item.method)"
                                                              class="text-primary cursor-pointer" @click="goModule(module, item.ID)">
                                                              {{ module.mname }}
                                                          </span>
                                                          <span v-else>{{ module.mname }}</span>
                                                      </div>
                                                      <div class="col-2" :class="'text-'+(showModulePassed(module.passed).color)">
                                                          <q-icon :name="showModulePassed(module.passed).icon"/>
                                                          {{ showModulePassed(module.passed).label }}
                                                      </div>

                                                  </div>
                                              </div>


                                          </div>

                                        </div>
                                    </div>
                                </q-item-section>
                            </q-item>
                        </q-list>

                    </q-card>
                    <!-- Назначения У модератора -->
                    <q-card v-else class="cat-card">
                        <q-list separator v-if="listSchedules && listSchedules.length">
                            <q-expansion-item
                                group="somegroup"
                                clickable
                                v-for="(item,index) in listSchedules"  :key="index"
                                @click="loadUserSchedules(item.ID)"
                                >
                                <template v-slot:header>
                                    <q-item-section avatar>
                                        <q-checkbox  v-model="item.check" />
                                    </q-item-section>

                                    <q-item-section class="text-accent">
                                        {{parent.restoreQuotes(item.program_name)}}
                                    </q-item-section>

                                    <q-item-section side class="text-accent">
                                        {{parent.customFormatter(item.DATE_ACTIVE_FROM)}}
                                        {{parent.customFormatter(item.DATE_ACTIVE_TO)}}
                                    </q-item-section>
                                </template>

                                <q-item-section v-if="listUserSchedules && listUserSchedules.length">
                                    <q-item-section class="q-px-xl q-my-sm h5 text-positive" >
                                        <div>
                                            {{message[lang].type_open}}: {{showProgramType(item.program_type)}}"
                                        </div>
                                    </q-item-section>
                                        <q-expansion-item group="usergroup" class="q-px-xl" v-for="(sched, ind_sched) in listUserSchedules" :key="ind_sched">
                                          <template v-slot:header>
                                            <q-item-section class="text-accent" v-if="listUsers && Object.keys(listUsers).length && listUsers[sched.NAME]">
                                                <q-separator />
                                                <div><q-icon
                                                    :name="showModulePassed(isAllModulesPassed(sched)).icon"
                                                    :color="showModulePassed(isAllModulesPassed(sched)).color"
                                                  />
                                                  <span class="text-primary">{{listUsers[sched.NAME].name}}</span>
                                                  <span v-if="!listUsers[sched.NAME].active" class="text-warning q-px-xl">{{message[lang].not_in_sched}}</span>

                                                </div>
                                            </q-item-section>
                                          </template>
                                        <!-- Список модулей одного юзера -->
                                        <template v-slot:default>
                                          <q-item-section v-if="sched.DETAIL_TEXT">
                                              <div v-for="(modRow,index_mod_row) in sched.DETAIL_TEXT" :key="index_mod_row" style="margin-left: 20px; border-bottom: 1px dotted #cccccc">
                                                  <div>-{{ index_mod_row+1 }}-</div>
                                                  <div v-for="(mod,index_mod) in modRow" :key="index_mod">
                                                      <div class="row text-positive h6 q-my-xs">
                                                          <div class="col-1">
                                                              {{ showTypeModule(mod.mtype) }}
                                                          </div>
                                                          <div class="col-8">{{mod.mname}} </div>
                                                          <div class="col-3">
                                                              <q-select
                                                                  dense
                                                                  option-label="label"
                                                                  option-value="value"
                                                                  map-options
                                                                  emit-value
                                                                  v-model="mod.passed"
                                                                  :options="message[lang].mod_passed"
                                                                  @update:model-value="setModulePassed(sched, mod)"
                                                                  color="positive"
                                                                  label-color="positive"
                                                                  class="text-positive"
                                                              >
                                                                  <template v-slot:prepend>
                                                                      <q-icon
                                                                          :name="showModulePassed(mod.passed).icon"
                                                                          :color="showModulePassed(mod.passed).color"
                                                                      />
                                                                  </template>
                                                                  <q-tooltip v-if="mod.who" class="bg-primary h-tt">
                                                                      {{listUsers[mod.who].name}},
                                                                      {{ message[lang].mod_action[mod.action] }},
                                                                      {{ mod.date }}
                                                                  </q-tooltip>
                                                              </q-select>
                                                          </div>
                                                      </div>
                                                  </div>
                                              </div>
                                          </q-item-section>
                                        </template>
                                      </q-expansion-item>
                                    <!--  -->
                                </q-item-section>
                            </q-expansion-item>
                        </q-list>
                        <div class="text-center" v-if="isMore">
                          <q-btn @click="loadSchedules()" round flat color="positive" icon="keyboard_arrow_down" />
                        </div>
                    </q-card>
                </div>
            </q-tab-panel>

        </q-tab-panels>

        <q-inner-loading :showing="loading">
          <q-spinner-oval size="5em" color="positive" />
        </q-inner-loading>

        <!-- Dialogues -->
        <q-dialog v-model="program_modal" persistent full-width full-height>
            <q-card class="bg-dark">
                <q-card-section class="q-pt-xl">
                <program-view
                    @toSchedProgram="toSchedProgram"
                    @closeViewProgram="closeViewProgram"
                    v-bind:parent="parent"
                    v-bind:lang="lang"
                    v-bind:back="parent.currentScreen"
                    v-bind:itemProgram="itemProgram"
                    />
                </q-card-section>
            </q-card>
        </q-dialog>

        <q-dialog v-model="course_modal" persistent full-width full-height>
            <q-card class="bg-dark">
                <q-card-section class="q-pt-xl">
                    <course-view
                        @lessonViewById="parent.lessonViewById"
                        @testViewById="parent.testViewById"
                        @goback="courseClose"
                        @courseReturn="courseReturn"
                            v-bind:parent="parent"
                            v-bind:lang="lang"
                            v-bind:courseItem="moduleItem"
                            v-bind:user_current="parent.user_current"
                            v-bind:back="parent.currentScreen"
                        ></course-view>
                </q-card-section>
            </q-card>
        </q-dialog>
        <q-dialog v-model="test_modal" persistent full-width full-height>
            <q-card class="bg-dark">
                <q-card-section class="q-pt-xl">
                    <test-view
                        @testViewById="parent.testViewById"
                        @lessonViewById="parent.lessonViewById"
                        @goback="courseClose"
                        @courseReturn="courseReturn"
                       v-bind:parent="parent"
                       v-bind:back="parent.currentScreen"
                       v-bind:lang="lang"
                       v-bind:testItem="moduleItem"
                       v-bind:user_current="parent.user_current"
            ></test-view>
                </q-card-section>
            </q-card>
        </q-dialog>
        <q-dialog v-model="lesson_modal" persistent full-width full-height>
            <q-card class="bg-dark">
                <q-card-section class="q-pt-xl">
                    <lesson-view
                        @lessonViewById="parent.lessonViewById"
                        @testViewById="parent.testViewById"
                        @goback="courseClose"
                        @courseReturn="courseReturn"
                         v-bind:parent="parent"
                         v-bind:back="parent.currentScreen"
                         v-bind:lang="lang"
                         v-bind:lessonItem="moduleItem"
                        ></lesson-view>
                </q-card-section>
            </q-card>
        </q-dialog>
        <q-dialog v-model="events_modal" persistent full-width full-height>
            <q-card class="bg-dark">
              <q-card-section class="row items-center q-pb-none">
                <q-btn rounded no-caps
                  @click="courseReturn({...moduleItem,passed:true})"
                  color="secondary"
                  :label="message[lang]['module_complete']"/>
                  <q-space />
                  <q-btn icon="close" flat round dense v-close-popup color="accent"/>
                </q-card-section>
                <q-card-section class="q-pt-xl">
                  <events-list @addNewEvent="addNewEvent" @editEvent="editEvent" @goback="goback"
                          v-bind:parent="parent"
                          v-bind:lang="lang"
                          v-bind:back="backScreen"
                          v-bind:mode="'programs'"
                          v-bind:eventItem="moduleItem"
                  ></events-list>
                </q-card-section>
            </q-card>
        </q-dialog>

    </div>

</template>

<script>
import {messageObj} from "src/helpers/message";
import dayjs from "dayjs";
import courseView from './CourseView.vue';
import testView from './TestView.vue';
import lessonView from './LessonView.vue';
import listOfEvents from './ListOfEvents.vue';
import programView from "src/ProgramView";


    export default {
        name: "ListOfPrograms",
        props:{
            parent:Object,
            lang:String,
            back:String,
            mntTab:String,
            hideTabs:Boolean,
            mode:String,
            search:String

        },
        // emits: ['emitgoback'],
        data() {
            return {
                isListViewProg:false,
                program_modal:false,   
                programItem:null,
                loading_send:false,
                isArchive:'N',
                loading:false,
                last_id:null,
                total:0,
                count:10,
                listPrograms:[],
                listSchedules:[],
                listUserSchedules:[],
                tab:'list_programs',
                listUsers:{},
                moduleItem:{},
                course_modal:false,
                test_modal:false,
                lesson_modal:false,
                events_modal:false,
                program_chedule_id:0,


                message: messageObj
            }
        },
        computed: {
          isMore(){
            // console.log('isMore',this.listSchedules, this.total, !this.loading, Object.keys(this.listSchedules).length >= this.count)
            return this.listSchedules && this.total && !this.loading && Object.keys(this.listSchedules).length >= this.count;
          },
          isMoreProgram(){
            // console.log('isMore',this.listSchedules, this.total, !this.loading, Object.keys(this.listSchedules).length >= this.count)
            return this.listPrograms && this.total && !this.loading && Object.keys(this.listPrograms).length >= this.count;
          },

        },
        watch: {
          tab(val){
            if(val=='list_user_schedules' || val=='list_schedules'){
              this.loadSchedules(true);
            }else{
              this.loadMorePrograms(true);
            }
          },
          search(val){
            console.log(':: search=',val)
            let filter = null;
            let table='programs';
            if(this.tab=='list_user_schedules'){
              table='uprogscheds'
            }else if(this.tab=='list_schedules'){
              table='progscheds'
              filter = {
                    '1': {
                    LOGIC: "AND",
                    '%DETAIL_TEXT': '%' +val + '%'
                    }
                };
            }else{
              filter = {
                    '1': {
                    LOGIC: "AND",
                    '%NAME': '%' +val + '%'
                    }
                };
            }

            if(this.mode==='cat'){
                filter['1'].ACTIVE='Y';
            }
            this.loading=true;
            this.parent.loadPrograms(table, this.count, this.last_id, null, null, null, null,filter).then(res=>{
                this.total = false;
                this.loading=false;
                if(res && res.length>0){
                  this.listPrograms = res;
                }else{
                  this.listPrograms = [];
                }
              });
          }
        },
        mounted() {
          this.tab = this.mntTab;
            // this.loading=true;
            if(this.mode==='cat'){
                this.parent.userOption('get','isListViewProg').then(res=>{
                    this.isListViewProg = res!==false && res!=='false';
                    console.log(':: mounted isListViewProg =',this.isListViewProg)
                });  
            }
            this.loadMorePrograms(true).then(res=>{
                // this.listPrograms = res;
                console.log(':: mounted listPrograms ',this.listPrograms)
                // this.loading=false;

                if(this.tab=='list_schedules' || this.tab=='list_user_schedules'){
                    if(this.mode==='LK'){
                        this.loadMySchedules();
                    } else{
                        this.loadSchedules(true);
                    }
                }

            });

        },
        watch: {
          isListViewProg:function(arg){
            console.log(':: isListViewProg ',arg)
            this.parent.userOption('set','isListViewProg', arg);
          },  
        },
        methods: {
            toSchedProgram(item){
                console.log('::toSchedProgram ', item)
                this.program_modal=false;
                this.parent.goToMy('programs')
            },
            closeViewProgram(){
                this.program_modal=false;
            },
            viewProgram(item,index){
                console.log('::viewProgram ', item)
                this.itemProgram = item;
                this.program_modal=true;

            },
            checkAll(list){
                let check = !this.isChecked(list);
                list.forEach(item => item.check = check);

            },
            toggleArchive(list){
                let $this = this;
                console.log(':: toggleArchive list=',list)
                let checked = this.getChecked(list);
                console.log(':: toggleArchive checked=',checked)
                let arBatch={};
                for(let item of checked){
                    let params = {
                        'ENTITY': 'uni_uprogscheds',
                        'ID': item.ID,
                        'ACTIVE': this.isArchive === 'Y' ? 'N' : 'A',
                    };
                     arBatch["uprogscheds_" + item.ID] = ['entityItemUpdate', params];
                }
                // console.log("::toggleArchive arBatch=", arBatch);
                if(arBatch && Object.keys(arBatch).length>0){   
                    this.loading_send = true;
                    this.parent.callBatch(arBatch, (res) => {
                        // console.log('::callBatch res=', res);
                        $this.loading_send = false;
                        $this.loadSchedules(true);
                    });
                }
            },
            getChecked(list){
                let ret = list ? list.filter(item =>  item.check):[];
                return ret;
            },
            isChecked(list){
                // console.log(':: toggleArchive list=',list)
                return this.getChecked(list).length > 0;
            },
            showProgramType(type){
                const ret = this.message[this.lang].type_open_opt.find(el=>{return el.value==type});
                return ret?ret.label:type;
            },
            setModulePassed(user_sched, module, isAuto){
                //TODO: сохранять id модератора, который сделал ручное изменеие
                // будет еще параметр self_passed, который будет проставляться автоматически при
                // завершении модуля студентом (ищем в назначениях студента этот модуль и ставим self_passed=Y|N)
                //
                module.who = this.parent.user_current.ID; //Кто изменил
                module.date = dayjs().format('YYYY-MM-DD HH:mm'); //Дата изменения
                module.action = isAuto?'auto':'hand'; //Способ изменения - ручками
                console.log(':: setModulePassed ',user_sched);
                if(this.isAllModulesPassed(user_sched)==='Y'){
                    this.savePpointsForProgram(module, user_sched);
                }
                let params = {
                    'ENTITY': 'uni_uprogscheds',
                    'ID': user_sched.ID,
                    'DETAIL_TEXT': JSON.stringify(user_sched.DETAIL_TEXT),
                };
                let url =  this.parent.bx24Init({ AJAX_ACTION: 'entityItemUpdate', params:params });
                this.parent.b24POST(url, this.parent.bx24params, function (res) {
                    if (res) {
                        console.log(':: setModulePassed saved:',res);
                    }
                }, "json");

            },
            showTypeModule(type){
                const item = this.message[this.lang].module_type_opt.find(i=>i.value==type);
                return item?item.label:'';
            },
            loadMorePrograms(isReload, tmpl_type, limit){
                let $this = this;
                this.loading=true;
                if(isReload){
                    this.last_id = null;
                    this.listPrograms = [];
                }
                let count = this.count;
                let filter = null;
                let active = null;
                if(this.mode==='cat'){
                    filter = { '1': { 'LOGIC': "AND", 'ACTIVE': 'Y' } };
                }else if(tmpl_type){
                    filter = {
                        '1': {
                        'LOGIC': "AND",
                        'PREVIEW_TEXT': 'template_type:'+tmpl_type
                        }
                    };
                    if(this.isArchive==='N'){
                        filter['1']['!ACTIVE']= 'A';
                    }else if(this.isArchive==='Y'){
                        filter['1']['ACTIVE']= 'A';
                    }
                    count = limit;
                } else if(this.isArchive){
                    if(this.isArchive=='N'){
                        filter = { '1': { 'LOGIC': "AND", '!ACTIVE': 'A' } };
                    }else if(this.isArchive=='Y'){
                        filter = { '1': { 'LOGIC': "AND", 'ACTIVE': 'A' } };
                    }
                }
                // console.log(':: loadMorePrograms',isReload, tmpl_type, limit, filter)
                return new Promise((resolve, reject) => {

                    $this.parent.loadPrograms('programs', count, $this.last_id, active, null, null, null, filter).then(res=>{
                        $this.total = false;
                        $this.loading=false;
                        if(res && res.length>0){
                            for(let item of res){
                                item.PROPERTY_VALUES.flags = $this.parent.isJSON(item.PROPERTY_VALUES.flags) ?
                                    JSON.parse(item.PROPERTY_VALUES.flags) : item.PROPERTY_VALUES.flags;
                            }
                        $this.total = true;
                        $this.last_id = res[res.length-1].ID;
                        $this.listPrograms = [...$this.listPrograms, ...res];
                        }
                        console.log('::loadMorePrograms ',$this.listPrograms)
                        resolve();
                    });
                    
                })

            },
            loadSchedules(isReload){
                let active = null;//this.tab=='list_user_schedules' ? 'Y' : false; //В результатах показываем только активные назначения
                this.loading=true;
                if(isReload){
                    this.last_id = null;
                }
                let filter = null;
                 if(this.isArchive){
                    if(this.isArchive=='N'){
                        filter = { '1': { 'LOGIC': "AND", '!ACTIVE': 'A' } };
                    }else if(this.isArchive=='Y'){
                        filter = { '1': { 'LOGIC': "AND", 'ACTIVE': 'A' } };
                    }
                }

                console.log('::loadSchedules this.listPrograms=',this.listPrograms)

                this.parent.loadPrograms('progscheds', this.count, this.last_id, active, null, null, null, filter).then(res=>{
                    console.log(':: loadSchedules res=',res)
                    this.total = false;
                    if(isReload){
                        this.listSchedules = [];
                    }
                    this.listSchedules = this.listSchedules?this.listSchedules:[]
                    if(res && res.length>0){
                      this.total = true;

                      for(let item of res){
                          let program = this.listPrograms.find(p=>p.ID==item.NAME);
                        //   console.log(':: loadSchedules program=',program)
                          item.program_id  = program?program.ID:0;
                          item.program_name = program?program.NAME:'---';
                          item.program_type = program&&program.PROPERTY_VALUES.type?program.PROPERTY_VALUES.type:'P';
                          item.DETAIL_TEXT = this.parent.isJSON(item.DETAIL_TEXT)?JSON.parse(item.DETAIL_TEXT):item.DETAIL_TEXT;
                          item.STUFF = this.parent.isJSON(item.STUFF)?JSON.parse(item.STUFF):item.STUFF;
                          this.last_id = item.ID;
                          if(program?.PROPERTY_VALUES?.flags){
                            item.program_flags = this.parent.isJSON(program.PROPERTY_VALUES.flags) ? JSON.parse(program.PROPERTY_VALUES.flags) : program.PROPERTY_VALUES.flags;
                          }
                          if(this.tab=='list_user_schedules'){
                            item.check = false;
                          }
                        //   console.log(':: loadSchedules item=',item)
                          
                          this.listSchedules.push(item)
                      }
                    }
                    // console.log('::listSchedules ',this.listSchedules)

                    this.loading=false;
                });
            },
            /**
             *  Для ЛК
             */

            /**
             * функция findElement итерирует по каждому подмассиву
             * в массиве array и использует метод find для поиска элемента
             * с указанным mid. Если элемент найден, функция возвращает его,
             * иначе возвращает null.
             */
             findElement(array, mid){
                for (const subArray of array) {
                    const element = subArray.find(item => {return item.mid == mid});
                    if (element) {
                        return element;
                    }
                }
                return null;
            },
             courseClose(arg) {
                console.log('::courseClose ', arg);
                this.course_modal = false;
                this.test_modal = false;
                this.lesson_modal = false;
            },
            courseReturn(arg) {
                console.log('::courseReturn ', arg);
                arg = this.parent.isJSON(arg)?JSON.parse(arg):arg;
                let user_sched = this.listUserSchedules.find(s=>{ return s.ID==arg.program_chedule_id});
                console.log('::courseReturn user_sched=', user_sched);
                if(user_sched){
                  // for(let itemRow of user_sched.DETAIL_TEXT){
                    let module = this.findElement(user_sched.DETAIL_TEXT, arg.module_id);
                    // user_sched.DETAIL_TEXT.find(m=>{return m.mid == arg.module_id});
                    console.log('::courseReturn module=', module);
                    if(module){
                        module.passed = arg.passed?'Y':'N';
                        if(arg.pt){
                            module.pt = arg.pt;
                        }
                        if(arg.type2){
                            module.type2 = arg.type2;
                        }
                        this.setModulePassed(user_sched, module, true);
                    }
                  // }
                }
                this.course_modal = false;
                this.test_modal = false;
                this.lesson_modal = false;
                this.events_modal = false;
            },
            loadMySchedules(){
                console.log('::loadMySchedules this.listPrograms=',this.listPrograms)
                this.loading=true;
                this.parent.loadPrograms('uprogscheds', 5000, null, null, null, this.parent.user_current.ID).then(res=>{
                    console.log(':: loadMySchedules res=',this.parent.user_current.ID, res)
                    this.listUserSchedules = res?res:[];
                    for(let item of this.listUserSchedules){
                        item.DETAIL_TEXT = this.parent.getProgramModules(item.DETAIL_TEXT);
                        item.STUFF = this.parent.isJSON(item.STUFF)?JSON.parse(item.STUFF):item.STUFF;
                        let program = this.listPrograms.find(p=>p.ID==item.STUFF);
                        item.program_id = program?program.ID:0;
                        item.program_name = program?program.NAME:'---';
                        item.method = program?program.PROPERTY_VALUES.type:'S'; //P
                        // item.PROPERTY_VALUES.flags = this.parent.isJSON(item.PROPERTY_VALUES.flags) ? JSON.parse(item.PROPERTY_VALUES.flags) : item.PROPERTY_VALUES.flags;
                        if(program?.PROPERTY_VALUES?.flags){
                            item.program_flags = this.parent.isJSON(program.PROPERTY_VALUES.flags) ? JSON.parse(program.PROPERTY_VALUES.flags) : program.PROPERTY_VALUES.flags;
                        }
                    }
                    console.log(':: listUserSchedules ',this.listUserSchedules)
                    this.loading=false;

                });

            },
            goModule(mod, usched_id){
                console.log(':: goModule ',mod, usched_id);
                this.program_chedule_id = usched_id;
                this.moduleItem = {ID: mod.mid,  go: true, program_chedule_id: usched_id, module_id: mod.mid};
                if(mod.mtype==1){
                    this.course_modal = true;
                }else if(mod.mtype==2){
                    this.test_modal = true;
                }else if(mod.mtype==3){
                    this.lesson_modal = true;
                }else if(mod.mtype==4){
                    this.events_modal = true;
                }

            },
            showModuleLink(mod, usched_id){
                let mod_type = mod.mtype==1?'course':(mod.mtype==2?'test':'lesson');
                const link = `${this.parent.appURL}?${mod_type}=${mod.mid}&program=${usched_id}`;
                return link;
            },
            showModulePassed(passed){
                let label = this.message[this.lang].mod_passed.find(i=>{return i.value==passed});
                label = label?label.label:'-?-';
                const color = passed=='Y'?'secondary':(passed=='N'?'warning':'positive');
                const icon = passed=='Y'?'check':(passed=='N'?'close':'remove');
                return {label:label, color:color, icon:icon};
            },
            isModulesLevelPassed(modules){
              let ret=true;
              for(let mod of modules){
                if(mod.passed!=='Y'){
                  ret=false;
                  break;
                }
              }
              return ret;
            },
            isShowModule(list, index_module, index_module_row, method){
                //console.log(':: isShowModule ',list, index_module, index_module_row, method);
                if(method=='P' || index_module_row == 0){
                    //Первый уровень всегда открыт
                    return true;
                }else {
                    /**
                     * method=='H': Открывать последовательно. Если модуль не пройден, не открывать последующий
                     * method=='S': Открывать последовательно. Если модуль не пройден, все равно открывать следующий

                     * Если в программе задано последовательное открытие модулей,
                     * то модули на одном уровне доступны все сразу,
                     * при условии что весь прошлый уровень закрыт.
                        •⁠  ⁠m1√
                        •⁠  ⁠⁠m2√,m3√,m4√
                        •⁠  ⁠⁠m5,m6 <-на этом уровне доступны оба модуля сразу
                        •⁠  ⁠⁠m7 <-этот уровень не доступен
                     */
                    for(let i in list[index_module_row-1]){
                        let item = list[index_module_row-1][i];
                        // console.log(':: ? ',item.passed, item );
                        if(item.passed!=='Y'&& method=='H' || item.passed==0 && method=='S'){
                            //console.log('-------')
                            return false;
                        }
                    }
                    // console.log('+++')
                    return true;
                }
                return false;
            },
            isAllModulesPassed(sched){
                
                let list =sched.DETAIL_TEXT;
                for(let itemRow of list){
                    for(let item of itemRow){
                        if(item.passed=='N'){
                            return 'N';
                        }else if(item.passed!=='Y'){
                            return null;
                        }
                    }
                }
                return 'Y';
            },
            savePpointsForProgram(module, user_sched){
                console.log('::savePpointsForProgram module=',module)
                console.log('::savePpointsForProgram user_sched=',user_sched)
                if(user_sched?.program_flags?.for_prog?.active){
                    //Баллы заданные в программе
                    let for_prog=user_sched.program_flags.for_prog;
                    if(for_prog && for_prog.active && for_prog.mode=='hand' && parseInt(for_prog.value)){
                        console.log('::Баллы заданные вручную for_prog=',for_prog.value)
                        this.parent.savePoints('D', user_sched.program_id, parseInt(for_prog.value));
                    }else if(for_prog && for_prog.active && for_prog.mode=='sum'){
                        console.log('::Баллы определяются суммированием баллов за модули=',for_prog.mode);
                        let sum_value = 0;
                        for(let itemRow of user_sched.DETAIL_TEXT){
                            for(let mod of itemRow){
                                mod.pt = module.pt || mod.pt;
                                if(mod.pt){
                                    console.log('::Баллы записаны в модуле mod=',mod)
                                    sum_value += parseInt(mod.pt);
                                }else{
                                    let type = '';
                                    if(mod.mtype==1){
                                        type = 'C';
                                    }else if(mod.mtype==2){
                                        type = module.type2 === 'final' ? 'I' : 'T'
                                    }else if(mod.mtype==3){
                                        type='L';
                                    }else if(mod.mtype==4){
                                        type='E';
                                    }
                                    console.log('::Взять баллы из настроек type, mod=', type, mod)

                                    if(type){
                                        sum_value += this.parent.getPointsForModType(type);
                                    }
                                }
                            }
                        }
                        console.log('::savePpointsForProgram sum_value=',sum_value)
                        if(sum_value){
                            this.parent.savePoints('D', user_sched.program_id, sum_value);    
                        }
                    }
                }else{

                }   

            },
            loadUserSchedules(sched_id){
                this.loading=true;
                let schedule = this.listSchedules.find(el=>{return el.ID==sched_id});
                console.log(':: loadUserSchedules schedule=',schedule)

                this.parent.loadPrograms('uprogscheds', 5000, null, null, sched_id).then(res=>{
                    console.log(':: loadUserSchedules res=',sched_id,res)
                    this.listUserSchedules = res?[...res]:[];
                    let usersIds=[];
                    for(let item of this.listUserSchedules){
                        item.DETAIL_TEXT = this.parent.isJSON(item.DETAIL_TEXT)?JSON.parse(item.DETAIL_TEXT):item.DETAIL_TEXT;
                        item.DETAIL_TEXT.modules = this.parent.getProgramModules(item.DETAIL_TEXT.modules);
                        usersIds.push(parseInt(item.NAME));
                        //достанем все who
                        const whoArray = item.DETAIL_TEXT.reduce((acc, obj) => {
                            if(obj.who) acc.add(parseInt(obj.who));
                            return acc;
                        }, new Set()).values();
                        usersIds = [...usersIds, ...whoArray];
                    }
                    //Оставим только уникальные id
                    usersIds = Array.from(new Set(usersIds));

                    console.log('://1//: loadUserSchedules usersIds=',usersIds)
                    this.parent.getUsers(true, '', usersIds).then(users => {
                        console.log('://2//: loadUserSchedules users=',users)
                        let items = [];
                        for (let i in users) {
                            let user = users[i];
                            let user_id = parseInt(user.id);
                            let index_in_sched = schedule.DETAIL_TEXT.students?schedule.DETAIL_TEXT.students.findIndex(el=>el.id==user_id):-1;
                            let itemUser = user;// {id: user.ID, name: user.NAME + ' ' + user.LAST_NAME, dep: user.UF_DEPARTMENT, type:user.USER_TYPE, active:index_in_sched>=0};
                            itemUser.active = index_in_sched>=0;
                            this.listUsers[user_id] = itemUser;
                        }
                        console.log(':: loadUserSchedules listUsers=',this.listUsers)
                        let list = [];
                        for(let i in this.listUsers){
                          list.push(this.listUsers[i]);
                        }
                        // this.parent.getSubAccounts(list).then(res=>{
                        //   for(let i in res){
                        //     this.listUsers[parseInt(res[i].id)] = res[i];
                        //   }
                        //   console.log(':: this.listUsers=',this.listUsers);
                        // });
                    });

                    this.loading=false;
                });
            },
            // loadRecursiveUserSchedules(programs){
            //     let program = programs.shift();
            //     if(program){
            //         this.parent.loadPrograms('uprogscheds', 5000, null, program.ID).then(res=>{
            //             this.listUserSchedules = [...this.listUserSchedules, ...res];
            //             this.loadRecursiveUserSchedules(programs);
            //         });
            //     }

            // },
            addSchedule(index, item){
                this.$emit('goback',{scr:'scheduleAdd', itemSchedule:item});
            },
            addProgram(index, item){
                this.$emit('goback',{scr:'programAdd', itemProgram:item});
            },
            copyProgram(index, item){
              item.ID=null;
              item.ACTIVE='N';
              this.$emit('goback',{scr:'programAdd', itemProgram:item});
            },


            delProgram(index, item, table){
                console.log('::delProgram',index, item);
                let $this = this;
                $this.parent.do_confirm = ()=>{
                    let params = {
                        'ENTITY': `uni_${table}`,
                        'ID': item.ID
                    };
                    let url =  $this.parent.bx24Init({ AJAX_ACTION: 'entityItemDel', params:params });
                    $this.parent.b24POST(url, $this.parent.bx24params, function (res) {
                        if(table=='progscheds'){
                            $this.listSchedules.splice(index,1);
                        }else{
                            $this.listPrograms.splice(index,1);
                        }
                    });

                    $this.parent.confirm=false;
                };
                $this.parent.title_confirm = this.message[this.lang].del_program;
                $this.parent.message_confirm = this.message[this.lang].del_program_msg + ' "'+item.NAME+'"?';
                $this.parent.modal_ok = null;
                $this.parent.modal_cancel = null;

                $this.parent.confirm=true;
            }

        },
        components: {
            programView,
            courseView,
            testView,
            lessonView,
            'eventsList': listOfEvents,
        }
    }
</script>

<style scoped>
.myselect:hover {
    filter: brightness(70%);
}


</style>
