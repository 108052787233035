export const messageObj = {
  ru: {
    sign_up_btn_ok:"Создать",
    sign_up_confirm:"Будет создан новый портал. Вы станете главным админом и сможете пригласить других пользователей.",
    is_reg_info:"Такой email отсутствует. С ним Вы можете зарегистрировать новый портал и пригласить в него пользователей",
    for_prog_dop_lb:"Баллы за курсы/уроки/тесты внутри",
    passed_prog:"Пройденная программа",

    the_collapsed:"Суммарно старше 3 мес",
    the_collapsed_info:"Записи в детализации старше трех месяцев сворачиваются в одну. Баллы, при этом суммируются",
    the_test:"Тест",
    the_lesson:"Урок",
    passed_course:"Пройденный курс",
    points_reset:"Обнулить все баллы",
    clear_points_msg:"Баллы будут обнулены и можно будет снова назначить баллы. Уже начисленные баллы не изменятся.",
    duration_info:"Длительность программы в днях. Рассчитывается суммированием длительности всех модулей. Используется при определении сроков назначений и задач.",
    the_programs:"Программы",
    program_view_btn:"Не выбран руководитель программы",
    the_program_info:"Нажимая кнопку перейти, Вы назначите себе программу для ее прохождения. Найти назначение Вы сможете в разделе 'Мои программы'",
    go_to_program:"Перейти",
    the_program:"Программа",
    clear_program:"Удаление программы. Удалить можно только не опубликованные программы.",
    uncheck_all_uscheds:"Снять отметки",
    check_all_uscheds:"Отметить все",
    is_archive:"Архив",
    from_archive:"Вернуть из архива",
    to_archive:"Перенести в архив",
    program_archived:"Перенесено в архив",
    program_published:"Программа опубликована",
    add_auditors:"Ответственные за модуль",
    modules_auditors:"Редактирование наблюдателей и сроки модулей",
    add_main_auditor_info:"Руководитель программы - это наблюдатель в задаче. Он будет ставиться по каждому модулю, если в модулях не выбран иной наблюдатель.",
    dates_schedule_info:"Даты - являются информационными и используются в установке сроков задач",
    clear_schedule:"Удаление назначения. Удалить можно только не опубликованные назначения.",
    program_updated:"Программа записана",
    program_added:"Программа добавлена",
    program_scheds_done:"Назначения сделаны",
    such_module_exist:"Модуль уже выбран",
    such_user_exist:"Сотрудник уже выбран",
    duration_hint:"Рассчитывется автоматически",
    degree_add_block_info:"Выберите из списка блок компетенций и нажмите на +. Его компетенции будут добавлены к Оценке 360",
    add_block_label:"Выбрать блок компетенций",
    add_esteem:"Самооценка",
    add_esteem_info:"По умолчанию самооценка отключена. При активации всем оцениваемым будем поставлена задача оценить самих себя. В результатах оценки будут представлены результаты с учётом самооценки и без самооценки.",
    thematics:"Тематика",
    categories:"Раздел каталога",
    slaves_add:"Автоматическое добавление сотрудников подразделения",
    slaves_add_byhands:"Ручное добавление",
    chief_add:"Автоматическое добавление руководителей подразделений сотрудника",
    chief_add_byhands:"Ручное добавление",
    prog_logo:"Логотип",
    logo_added:"Нажмите кнопку-облачко 'загрузить на сервер'",
    thematics_placeholder:"Тематика",
    thematics_hint: "Для использования слова в качестве тэга поставьте перед ним знак #",
    duration:"Длительность",
    target_placeholder:"Цель",
    del_subpassw_title:"Удаление пароля",
    del_subpassw_message:"Пароль будет очищен, пользователь при входе должен будет ввести его заново",
    degree_msg_sended:"Уведомления отправлены",
    degree_checkall_btn:"Отметить все",
    degree_clear_btn:"Удалить ответы",
    degree_clear_msg:"Будут удалены оценки(ответы) выбранных оценивающих. Им нужно будет произвести оценки заново.",
    degree_status_student:{1:"Нет оценок экспертов",2:"Не назначены эксперты"},
    degree_status_expert:{0:"Все оценки даны",2:"Нет оценок по одной или более компетенций",3:"Не все оценки даны"},
    degree_status_btn:"Отправить уведомление",
    degree_status_msg:"Вы участвуете в оценке сотрудников в качестве оцениваемого. Сформируйте список оценивающих, выберите согласующих данный список в личном кабинете приложения «Университет» в разделе Мои оценки.",
    degree_uagree_msg:"Вы назначены согласующим в оценке 360. Перейдите в личный кабинет приложения «Университет» в разделе Мои оценки.",
    degree_fill_msg:"Вы участвуете в оценке сотрудников в качестве оценивающего. Проставьте оценку сотрудникам в личном кабинете приложения «Университет» в разделе Мои оценки.",
    degree_status:{0:"Согласовано", 1:"Нет назначенных экспертов", 2:"Не назначен согласующий", 3:"Не согласовано"},
    Degree360StatusList:"Статусы формирования",
    Degree360StatusFill:"Статусы заполнения",
    change_password:"Сменить пароль",
    change_password_m:"Смена паролей сотрудникам",
    change_password_hint:"Введите новый пароль и подтверждение. После смены пароля войдите в систему.",
    change_password_hint_m:"Укажите сотрудника, пароль будет сформирован автоматически.",
    change_password_done:"Паорль изменен, используйте его при следующем входе.",
    change_password_fail:"Произошла ошибка, пароль не изменен. Попробуйте еще раз или обратитесь к администратору.",
    change_password_done_m:"Паорль изменен, скопируйте и передайте его сотруднику.",
    clear_profile:"Удалить профиль",
    clear_profile_msg:"Профиль будет удален, но email и пароль для входа будут сохранены. Для создания нового профиля нужно будет войти в систему. Вы уверены?",
    clear_profile_done_m:"Профиль удален",
    add_head:"Руководитель",
    add_users:"Сотрудник",
    struct_reset_msg:"Вы действительно хотите сбросить структуру?",
    struct_reset:"Сбросить структуру",
    department_add:"Подразделение",
    struct_company:"/Компания",
    struct_department:"Отдел",
    company_structure:"Компания",
    last_name_need:"Обязательно заполните имя и фамилию!",
    invitations: "Приглашения",
    link_invite: "Ссылка для самостоятельной регистрации",
    reminders: "Напоминания",
    notifications: "Уведомления",
    sign_up_for_an_account:"Войдите или зарегистрируйте свой аккаунт",
    auth_email:"Email",
    auth_password:"Пароль",
    auth_password2:"Повторите пароль",
    sign_up:"Зарегистрировать",
    sign_in:"Войти",
    email_invalid:"Не корректный email",
    password_invalid:"Пароль не верный",
    email_exist:"Email найден",
    email_ok:"Email свободен",
    auth_password_match:"Подходящий пароль",
    auth_password_not_match:"Пароли не совпадают",
    subac_validate_info:"Для имени субаккаунта используйте только латинские буквы и цифры.",
    promo_1:"Расширьте возможности вашего приложения. Приобретайте подписку на тему, диск или субаккаунты в разделе Настройки:Мой тариф.",
    prog_filter:"Фильтр",
    prog_filter_all:"Все программы",
    prog_filter_tgrp:"Шаблоны для групп",
    prog_filter_tuser:"Для новых сотрудников",
    prog_is_template:"Это шаблон для назначения",
    prog_is_template_newuser:"новым сотрудникам",
    prog_is_template_grp:"для групп",
    prog_info_grp:"Постоянно действующе назначение. При добавлении в группу сотрудника ему автоматически будет осуществлено назначение.",
    prog_info_newusers:"При добавлении нового сотрудника на портал Битрикс24 ему автоматически будет осуществлено назначение.",
    im_agreemer:"Я - оценивающий",
    agree_degree360_mess:"Согласовать список оценивающих для Оценки 360?",
    decline_degree360_mess:"Вернуть на доаботку список оценивающих для Оценки 360?",
    degree360_all_agreed:"Списки оценивающих согласованы для оценки 360",
    degree_valid_1:"Не задано название Оценки 360",
    degree_valid_2:"Не заданы даты",
    degree_valid_3:"Не заданы Оценивающие у одного или нескольких Оцениваемых",
    degree_valid_4:"Не заданы компетенции",
    degree_valid_5:"Не задана шкала Оценки 360",
    degree_valid_6:"Не согласованы Оценивающие при самостоятельном выборе",
    pdf_download:"Скачать файл",
    pdf_build:"Построить PDF-файл",
    scale_value:"Баллы",
    scale_name:"Название",
    scale_desc:"Описание",
    degree360_self_message:"Вам назначено задание на формирование списка оценивающих для Оценки 360",
    clear_agreeID:"Удалить согласующего",
    stidents_clear:"Будут удалены все Оцениваемые вместе со своими Оценивающими",
    add_degree360_info:"Оценка 360 полностью формируется модератором",
    add_task_degree360_info:"Формирование списка оценивающих возможно двумя способами: модератором; оцениваемыми. При активации формирование списка будет осуществляться оцениваемыми. После публикации оценки у оцениваемых в личном кабинете в разделе Мои оценки появится задача по созданию списка и выбора согласующих список. Оценка будет запущена когда все списки будут сформированы.",
    listDegree360:"Оценки 360",
    eve_add_bid_moder:"Заявка на участие в мероприятии от сотрудника",
    profiles_lb:"Профили",
    profiles_plc:"Профиль содержит несколько компетенций",
    add_from_comps:"Выбрать компетенцию",
    incomplete_data:"Не полная компетенция, отсутсвуют индикаторы",
    experts_clear:"Будут удалены все оценивающие сотрудники",
    degree_tasks:[{value:0, label:"⁠ставить задачи индивидуально"},{value:1, label:"ставить одну общую задачу"},{value:2, label:" ⁠⁠не ставить задачи"}],
    old_assessments:"Старый формат оценок",
    degree_task_name:"Введите название задания",
    degree_task_description:"Введите описание задания",
    the_task_360:"Задание",
    tasks_360:"Задания",
    add_task_degree360:"Формирование списка оценивающих оцениваемыми",
    add_degree360:"Добавить оценку 360",
    stud_id:"ID Оцениваемого",
    its_my_degree:"Я - оцениваемый",
    degree_readonly_info:"По Оценке 360 уже сделаны назначения, некоторые действия с Оцекой не могут быть выполнены. Например, удаление оцениваемых, оценивающих, удаление и добавление компетенций, смена дат, включение самооценки, изменение способа постановки задач.",
    degree360_alert:"Заявка уже согласована, изменить ее нельзя.",
    degree360_agreeID:"Согласование",
    degree360_agreed:"Согласовано",
    degree360_wait_agreed:"Ожидает согласования",
    degree360_self_title:"Заявки на Оценку 360",
    degree360_self:"Заявка на Оценку 360",
    degree_self:"Формирование списка оценивающих",
    degree_self_info:"Что бы подать заявку, выберите для себя Оценивающих и Ответственного за согласование Оценки 360",
    degree_start_finish_info:"Даты используются информационно и не влияют на отображение  Оценки360.",
    agree_not_valid:"Не все данные запоолнены. Пожалуйста, проверьте название, даты, Оцениваемых, Оценивающих.",
    degree_add_scale_info:"Выберите шкалу оценки из списка, что бы добавить ее к Оценке 360",
    degree_add_comp_info:"Выберите из списка компетенцию и нажмите кнопку <+>, чтобы добавить компетенцию к Оценке 360",
    degree_add_prof_info:"Выберите из списка профиль и нажмите на +. Его компетенции будут добавлены к Оценке 360",
    degree_add_experts_info:"Добавьте Оценивающих используя кнопки ниже",
    degree_add_students_info:"Добавьте Оцениваемых сотрудников используя кнопки ниже",
    degree_agree_degree360_info:"В левом положении флажка, можно сохранять рабочую оценку. Правое положение - будет сделано назначение при сохранении.",
    degree_agree_user_info:"Для назначения Оценки необходимо согласование. Выберите согласующего сотрудника.",
    degree_hide_fio_info:"По умолчанию оцениваемый будет видеть данные оценивающих. При активации данные оценивающих будут скрыты.",
    degree_pub_lk_info:"Публикация результатов оценки в личном кабинете оцениваемого после того, как все оценивающие выставят оценки.",
    degree_task_info:"Задача в Битриксе произвести оценку. Ставится оценивающим.",
    show_options_block:"Настройки Оценки 360",
    show_users_block:"Списки оцениваемых и оценивающих",
    show_comps_block:"Компетенции, индикаторы, шкала оценки",
    degree360_aver_title:"Результаты оценок (средние значения)",
    degree360_stat_title:"Количество респондентов, принимавших участие в оценке",
    degree360_aver_col_:"Практики",
    degree360_stat_ret0:"Кол-во человек",
    degree360_stat_col_:"Группы респондентов",
    degree360_stat_col0:"Самооценка",
    degree360_stat_col1:"Руководитель",
    degree360_stat_col2:"Коллеги",
    degree360_stat_col3:"Подчиненные",
    degree360_stat_col4:"Все респонденты<br>(без самооценки)",
    degree360_stat_col5:"Все респонденты",
    degree360_description:"Вам необходимо выполнить Оценку 360",
    degree360_message:"Вам назначена Оценка 360. Перейдите в приложение 'Университет/Мои оценки'",
    you_agree_360:"Вы назначениы согласующим в Оценке 360.  Перейдите в приложение Университет для согласования.",
    del_student:"Вы хотите удалить оцениваемого",
    do_schedules:"Оценка 360  будет назначена оценивающим",
    no_experts:"Не заданы оценивающие",
    agree_user:"Согласующий",
    agree_degree360:"Согласовать",
    decline_degree360:"Вернуть",
    decline_degree360_info:"Если Вы изменили статус заявки, сохраните ее",
    publish_degree360:"Публикация оценки 360",
    del_degree360:"Удаление оценки 360",
    del_degree360_msg:"Вы действительно хотите удалить оценку 360",
    degree_name:"Введите название оценки",
    add_prof_label:"Выбрать профиль",
    scale_plc:"Выбрать шкалу",
    add_prof:"Добавить к компетенциям",
    save_comp:"Сохранить",
    close_comp:"Закрыть",
    program_readonly:"По этой программе сделаны назначения, поэтому изменить ее нельзя. Можно сделать копию программы в списке программ для её изменения и новых назначений.",
    program_info:"Модуль 'Программы' предназначен для формирования комплексных программ развития. Например, таких как Адаптация новичков, Обучение кадрового резерва и др."+
      "<br>Программы можно формировать из следующих элементов: курсы, тесты, уроки (например, добавить в урок PDF-файл, регламент, презентацию, ссылки, материалы, лонгрид и т.д.), мероприятия (например, создать мероприятие Встреча с Руководителем)."+
      "<br>Программы формируются из элементов параллельно и последовательно. Последовательные элементы формируют уровни (это может быть День,Этап обучения). В одном уровне может находиться любое количество элементов, это значит, что на этом уровне все их необходимо пройти."+
      "<br>После создания программы ее можно назначать сотрудникам, группам (при добавлении сотрудника в группу, ему автоматически будет сделано назначение программы). Список сотрудников можно менять: при добавлении - сотруднику будет назначена программа, при удалении - он будет продолжать присутствовать в отчетах."+
      "<br>Назначенную программу нельзя менять.",
    copy_program:"Копировать программу",
    module_level:"Уровень",
    is_answered:"Есть ответы",
    check_all:"Отметить все оценки",
    check_group:"Отметить группу",
    check_one:"Отметить строку",
    rep_date:"Дата",
    rep_type:"За что",
    the_open:"Открытый вопрос",
    the_event:"Мероприятие",
    the_360:"Оценка 360",
    the_aos:"АОС",
    the_rate:"Оценка курса/теста",
    the_comment:"Комментарий",
    the_ipr:"ИПР",
    the_Rc:"Оценка курса",
    the_Rt:"Оценка теста",
    the_course:"Курс",
    write_off:"Списание",
    module_level:"Уровень",
    not_in_sched:"Отсутствует в назначении программы",
    sub_alert:"Данный аккаунт содержит субаккаунты, но их лимит исчерпан. Обратитесь к модератору приложения",
    sub_limit_exceeded:"Лимит субаккаунтов исчерпан. Перейдите в настройки/Мой тариф для увеличения лимита",
    sub_login_already:"Такой субаккаунт уже существует",
    sub_accounts:"Субаккаунты",
    native_add:"Добавить аккаунт.",
    sub_add:"Добавить субаккаунт",
    del_subac_title:"Удаление субаккаунта",
    del_subac_message:"Субаккаунт будет удален из списка. Вы уверены?",
    del_sub_title:"Удаление аккаунта",
    del_sub_message:"Аккаунт будет удален из списка вместе со своими субаккаунтами. Вы уверены?",
    self_sign_up:"Самостоятельная регистрация",
    sub_acc_title:"Авторизация",
    sub_acc_login:"Логин",
    sub_acc_password:"Пароль",
    sub_acc_password2:"Повторите пароль",
    sub_acc_recv:"Ваши данные",
    sub_acc_first_name:"Имя",
    sub_acc_last_name:"Фамилия",
    sub_acc_middle_name:"Отчество",
    sub_acc_position:"Должность",
    sub_acc_login_not_found:"Логин не найден, требуется регистрация",
    sub_acc_save:"Сохранить",
    sub_acc_ok:"Войти",
    sub_acc_passwords_not_match:"Пароли не совпадают",
    sub_acc_password_fail:"Неверный пароль",
    sub_acc_valid_recv:"Заполните реквизиты",
    sub_acc_recv:"Изменить данные",
    sub_important_info:"Важная информация",
    sub_info:"<ol>"+
      "<li><b>⁠Добавить аккаунт.</b><br>"+
      "Важные условия:</li>"+
      "<ul><li>Это аккаунт Битрикс24, к которому будут привязаны субаккаунты Университета.</li>"+
      "<li>Создавать аккаунт может только администратор портала Битрикс24. Не модератор приложения.</li>"+
      "<li>Аккаунт не может быть Администратором Битрикс24 или модератором приложения Университет.</li></ul>"+
      "<li><b>⁠Выбрать способ регистрации суббакаунтами в приложении:</b></li>"+
      "<ul><li><b>Самостоятельно.</b> В этом случае администратор портала должен ввести только логины и сообщить эти логины суббакаунтам. При входе субаккаунты введут свой логин, затем приложение предложит заполнить данные (ФИО и должность) и придумать пароль.</li>"+
      "<li><b>Администратором.</b> В этом случае администратор вводит логин и заполняет все данные (ФИО и должность), передаёт субаккаунтам логины. При входе суббакаунту будет предложено создать пароль. Остальные данные (ФИО и должность) уже будут заполнены Администратором.</li></ul>"+
      "<li><b>⁠При каждом входе в приложение субаккаунт должен будет ввести свой логин и пароль.</b></li>"+
      "</ol>",

    cert_format:"Формат страницы",
    cert_name:"Название сертификата",
    cert_save:"Сохранить сертификат",
    cert_new:"Добавить сертификат",
    cert_list:"Список сертификатов",
    clear_form:"Новый сертификат",
    cert_remove:"Удалить из списка",
    cert_default:"По умолчанию",
    cert_scale:"Масштаб",
    cert_opts_fio:"Имя пользователя",
    cert_opts_pos:"Должность",
    cert_opts_name:"Название теста/курса",
    cert_opts_score:"Проходной балл",
    cert_opts_date:"Дата прохождения",
    cert_opts_text:"Дополнительный текст",
    cert_dark_mode:"Темный фон",
    cert_orient_a:"Альбомная",
    cert_orient_p:"Портретная",
    certs:"Сертификаты",
    cer_tificate:"Сертификат",
    cert_img:"Изображение",
    save_png:"Скачать",
    cert_del_title:"Внимание!",
    cert_del_message:"Сертификат будет удален из списка. Также будет удален файл с диска.",
    cert_reload_title:"Внимание!",
    cert_reload_message:"Для применения изменений потребуется выйти и зайти снова в настройки сертификата.",
    cert_font_size:"Размер шрифта",
    cert_font:"Шрифт",
    cert_font_color:"Цвет шрифта",

    is_program_tasks:[{value:0, label:"⁠ставить задачи для каждого модуля"},{value:1, label:"ставить одну общую задачу"},{value:2, label:" ⁠⁠не ставить задачи"}],
    program_tasks:"Задачи",
    program_task_description:"Вам необходимо выполнить программу",
    course_passed:"Курс пройден",

    module_complete:"Завершить модуль",
    done_success:"Успешно!",
    save_program:"Сохранить",
    add_module_s:"Добавить последовательный модуль",
    add_module_p:"Добавить паралелльный модуль",
    module_added:"Модуль добавлен",
    program_message:"Вам назначена программа обучения. Перейдите в приложение 'Университет/Мои программы'",
    my_programs:"Мои программы",
    publish_schedule_tooltip:"Не выбрана программа или не заданы даты назначения, руководитель, сотрудники",
    mod_description:"В плане выполнения программы, Вам необходимо выполнить модуль",
    list_schedules:"Назначения",
    add_schedule:"Добавить назначение",
    one_schedule:"Назначение",
    publish_schedule:"Сделать назначение",
    dates_schedule:"Даты назначения",
    add_students:"Сотрудники",
    dep_rem_title:"Удалить из назначения?",
    dep_rem_mess:"Удалить всех с этой группой (подразделением)",
    dep_rem_mess1:"Удалить этого участника",
    start_finish:"Начало/окончание",
    selectusers_label:"Сотрудники",
    selectgroups_label:"Группы",
    selectdeps_label:"Отделы",
    selecttests_label:"курсы/тесты",
    selectgroup_title:"Добавить группу",
    selectgroup_message:"Выберите группу",
    courses_tests:"Курсы-тесты",
    students:"Обучающиеся",
    del_schedule_title:"Удаление",
    del_schedule_message:"Подтвердите удаление назначения",
    del_schedules_message:"Подтвердите удаление назначений",
    date_to_short:"окончания",
    students_comment:"Если сотрудник был добавлен через отдел или группу, то удаление такого сотрудника повлечет за собой удаление и других сотрудников этого отдела/группы",
    add_department:"Отдел",
    add_group:"Группа",
    add_main_auditor:"Руководитель",
    
    mod_duration:"Длительность, дн.",
    mod_action:{'hand':"ручное", 'auto':"авто"},
    add_program:"Добавить программу",
    list_programs:"Список программ",
    one_program:"Программа",
    progrm_modules:"Модули",
    requaried_field:"Обязательное поле",
    program_name:"Название программы",
    program_description:"Описание программы",
    module_type:"Тип модуля",
    module_type_opt:[{value:1, label:"Курс"},{value:2, label:"Тест"},{value:3, label:"Урок"},{value:4, label:"Мероприятие"}],
    select_module:"Выберите модуль",
    not_found:"Ничего не найдено",
    type_open:"Способ открытия модулей",
    type_open_opt:[{value:'P', label:"Открывать все сразу"},{value:'S', label:"Открывать последовательно. Если модуль не пройден, все равно открывать следующий"},{value:'H', label:"Открывать последовательно. Если модуль не пройден, не открывать последующий"}],
    publish_program:"Опубликовать программу",
    del_program:"Удалить",
    del_program_msg:"Вы уверены, что хотите удалить",
    mod_passed:[{value:'0', label:"нет статуса"}, {value:'Y', label:"Пройден"},{value:'N', label:"Не пройден"}],

    points:"Баллы",
    points_plc:"поставьте 0 если баллы начисляются как задано в настройках приложения",
    for_course_active:"Пройденный курс",
    for_course_mode_lb:"Способ подсчета",
    for_course_mode:[
      {value:'sum', label:"Рассчитывать суммированием баллов за модули"},
      {value:'hand', label:"Ввести вручную"},
    ],
    for_course_dop_lb:"Баллы за уроки/тесты внутри курса",
    for_course_dop:[
      {value:'Y', label:"Начислять "},
      {value:'N', label:"Не начислять"},
    ],
    separ_add:"Добавить разделитель",
    sep:"Разделитель",

    bid_del_message:"Удалить заявку",
    em_count_limit:"Превышеено максимальное количество участников",
    em_count:"Количество участников. 0-не ограничено",
    eve_attende:"Участник",
    eve_bid:"Заявка",
    not_published:"не опубликовано",
    my_events:"Мои мероприятия",
    bid_exist:"Заявка подана",
    bid_decl:"Заявка отклонена",

    ipr_comp:"Компетенция",
    ipr_ind:"Индикаторы",
    eve_bid_done:"Ваша заявка добавлена. После рассмотрения Вы станете участником.",

    eve_new_bid_add:"Нужного мероприятия нет в списке? Предложите заявку. После проверки мероприятие будет добавлено в список и Вы станете участником.",
    eve_new_bid_title:"Заявка на новое мероприятие",
    eve_new_bid_done:"Ваша заявка добавлена. После рассмотрения мероприятие будет добавлено в список и Вы станете участником.",
    alert_understood:"Понятно",
    eve_new_bid_moder:"Поступила заявка  на мероприятие от сотрудника",
    em_details:"Детали",

    bid_restore_title:"Восстановить заявку",
    bid_restore_message:"Укажите причину восстановления заявки",
    bid_accepted:"Заявка принята",
    bid_declined:"Заявка отклонена, восстановить.",
    bid_accept:"Принять заявку",
    bid_decline_title:"Отклонить заявку",
    bid_decline_message:"Укажите причину отклонения заявки",
    eve_bids_all:"Добавить всех",
    eve_calend:"В календаре",
    eve_detail:"Подробнее о мероприятии",
    eve_edit:"Изменить",
    em_bids:"Заявки",

    eve_bid_lbl:"Оставьте Ваш комментарий",
    eve_title:"Подать заявку на участие в мероприятии",
    eve_bid_add:"Буду участником",
    eve_nts:"Мероприятия",
    text_block:"Текстовый блок",
    text_block_name:"Название поля",
    text_block_value:"Содержимое поля",
    r_o:"Только для чтения",

    set_files:"Файлы",
    integral_chart:"Интегральная диаграмма",
    view_cards:"Вид: карточки",
    view_list:"Вид: список",
    node_disabled:"Сделать неактивным",
    node_enabled:"Сделать активным",
    download:"Скачать",
    no_valid_publish:"Не заданы важные данные, например, сотрудник, руководитель, наставник, компетенции, индикаторы или нет прав для редактирования",
    hide_fio:"Скрывать данные оценивающих",
    such_element_exist:"Такой элемент уже существует",
    program_course:"/Программа курса",
    lessons:"Уроки",
    tests:"Тесты",
    no_lessons:"Нет уроков для добавления в курс",
    no_tests:"Нет тестов для добавления в курс",
    drophere:"Перетащите сюда уроки и тесты",
    reset_tree:"Сбросить навигацию",
    reset_tree_msg:"Удалить все уроки и тесты из курса?",

    ipr:"ИПР",
    export_all:"Экспортировать все",
    imgFiles:"Файлы к ответу",
    upload_title:"Загрузить",
    upload_err:"Ошибка загрузки",
    upload_hint:"Выберите файл на диске или перетащите его сюда, затем нажмите на кнопку «Загрузить»",
    max_file_size:"Размер файла не должен превышать ${mb} Мб",
    delete_image:"Удалить файл",
    ipr_note:"Вам назначен 'Индивидуальный план развития'. Перейдите в личный кабинет приложения Университет.",
    add_evt_title:"Мероприятие добавлено",
    add_evt_msg:"Закрыть форму добавления мероприятий и перейти к списку?",
    add_evt_ok:"Закрыть",
    add_evt_canc:"Добавить еще",
    res360_published:"Результаты оценки 360 опубликованы в Личном кабинете оцениваемого",
    rm_rm:"Развивающее мероприятие",
    rm_dln:"Просрочено",
    rm_deadline:"Крайний срок",
    go_course:"Перейти",
    no_results:"Нет данных",
    enter_course_name:"Наберите часть названия для поиска",
    course_name:"Добавить курс",
    course_statuses:"Статус курсов",
    ipr_courses:"РМ курсы",
    ipr_export:"Экспорт",
    iprset_create_label:"Кто может создавать",
    iprset_edit_label:"Кто может редактировать",
    iprset_read_label:"Кто может видеть",
    iprset_status_label:"Кто может менять статус",
    iprset_role_opt:[{value:'M', label:"Модератор"},{value:'U', label:"Сотрудник"},{value:'N', label:"Наставник"}],
    add_rm:"РМ",
    add_ind:"Индикатор",
    ipr_title:"Индивидуальный план развития",
    ipr_name:"Название ИПР",
    ipr_close:"Закрыть",
    ipr_open:"Открыть",
    select_person:"Сотрудник",
    select_manager:"Руководитель",
    select_mentor:"Наставник",
    rm_label:"РМ",
    rm_options:[
        "Ассесмент",
        "Аудио контент",
        "Вебинар",
        "Видео контент",
        "Викторина",
        "Воркшоп",
        "Встреча",
        "Дистанционный курс",
        "Доклад",
        "Игра",
        "Исследование",
        "Кейс",
        "Командообразование",
        "Конкурс",
        "Конференция",
        "Курс",
        "Лекция",
        "Мастер класс",
        "Митап",
        "Наставничество",
        "Оценка",
        "Подкаст",
        "Проект",
        "Семинар",
        "Сессия",
        "Симуляция",
        "Стажировка",
        "Тестирование",
        "Тренинг",
        "Универсиада",
        "Фасилитация",
        "Хакатон",
        "-свой вариант-"
    ],
    rm_compleeted:"Выполнено",
    rm_started:"В процессе",
    rm_new:"Не начато",
    rm_status:"Статус",
    hand_made:"Ввести вручную",
    comp_name:"Название компетенции",
    ind_name:"Название индикатора",
    del_ind:"Удалить индикатор",

    selectcomps_label:"Компетенции",
    selectcomps_title:"Добавить компетенции",
    selectcomp_message:"Выберите компетенцию",
    selectcomps_message:"Выберите одну или несколько компетенций",
    selectcomps_add:"Добавить",
    selectcomps_finish:"Закрыть",
    selectscale:"Шкала оценки",
    target_sc:"Целевой уровень",
    current_sc:"Текущий уровень",

    del_certs:"Сертификат был удален в Профиле, удалите его здесь",
    no_certs:"У Вас нет ни одного загруженного сертификата. Добавьте их в Профиль",
    aos_filled:"заполнена",
    anketa_add:"Добавить анкету",
    for_test:"Для тестов",
    for_course:"Для курсов",
    for_event:"Для мероприятий",
    aos_copy:"(Копия)",
    new_aos_name:"Измените имя что бы сохранить",
    first_aos_name:"Анкета по умолчанию",
    aos_edit:"Редактирование анкеты",
    aos_look:"Посмотреть анкету",
    aos_refresh_title:"Загрузить по умолчанию",
    aos_refresh_message:"Ваша анкета будет заменена на анкету по умолчанию",
    answ_aos:"Ответы",
    answer:"Текст",
    score:"Вес",
    quest_aos:"Вопросы",
    add_answ:"Добавить ответ",
    other_answ:"Другое",
    a_o_s:"АОС",
    add_aos:"Добавить вопрос",
    aos:"Анкета обратной связи",
    aos_del:"Удалить анкету",

    cert_del:"Удалить сертификат из мероприятия?",
    cert_add:"Добавить сертификат",
    event_certs:"Выберите сертификаты",
    em_del:"Удалить мероприятие (из календаря тоже)",
    em_save:"Записать",
    em_clear:"Очистить",
    em_meeting_notify:"Отправлять приглашения",
    em_description:"Описание события",
    em_meeting_text:"Текст приглашения",
    em_color:"Цвет события",
    em_text_color:"Цвет текста",
    em_attendees:"Участники",
    em_host:'Ответственный',
    em_org:"Организатор",
    em_title:"Внешние и очные мероприятия",
    em_eventName:"Название мероприятия",
    em_common_list:"Общий список",
    em_personas: 'Оцениваемые',
    em_close:"выбрать",
    em_location:"Тип",
    em_location_opt:['Внутреннее','Внешнее'],
    em_vid:"Вид",
    em_vid_opt:[
        'Ассесмент',
        'Аудио контент',
        'Вебинар',
        'Видео контент',
        'Викторина',
        'Воркшоп',
        'Дистанционный курс',
        'Доклад',
        'Игра',
        'Исследование',
        'Кейс',
        'Командообразование',
        'Конкурс',
        'Конференция',
        'Курс',
        'Лекция',
        'Мастер класс',
        'Митап',
        'Наставничество',
        'Оценка',
        'Подкаст',
        'Проект',
        'Семинар',
        'Сессия',
        'Симуляция',
        'Стажировка',
        'Тестирование',
        'Тренинг',
        'Универсиада',
        'Фасилитация',
        'Хакатон',
    ],
    common_list:"Общий список",
    personas: 'Оцениваемые',
    exp_comments:"Комментарии",
    no_events:"Нет предстоящих мероприятий",
    em_from_to:"Начало и конец мероприятия",
    acc1:"Показывать результаты в ЛК",
    add_section:"Добавить секцию",
    comps_added:"Добавленные компетенции",
    rep_competence:"Компетенция/Индикаторы",
    rep_ind:"Компетенция/Индикаторы",
    rep_indicators:"Эксперты",
    rep_scores:"Оценки",
    comps_add:"Добавить компетенцию",
    attention:"Внимание",
    del_competence:"Удалить компетенцию",
    competencies: 'Компетенции',
    comment:"Комментарий оцениваемому",
    acc:"Опубликовать в личном кабинете оцениваемого",
    all:"Все",
    you_ass_360:"Вам назначена оценка 360. Список всех оценок смотрите в личном кабинете.",
    del_many_title:"Удаление нескольких оценок",
    del_many_mess:"Вы хотите удалить несколько отмеченных оценок",
    add_coworkers: 'Добавить коллег', //новые слова
    coworkers: 'Коллеги',
    add_slaves: 'Добавить подчиненных',
    slaves: 'Подчиненные',
    add_chief: 'Добавить руководителя',
    chief: 'Руководитель',
    new: 'Сотрудник',
    average_: 'Среднее без участия оцениваемого',
    rows_per_page: 'Строк на странице',
    all_report: 'Общий отчет',
    start_finish: 'Начало/окончание',
    departments: 'Подразделения',
    add_departments: 'Добавить подразделения',
    selectdepartments_title: 'Добавить подразделение',
    selectdepartments_message: 'Выберите подразделение',
    selectdepartments_add: 'Добавить',
    selectdepartments_finish: 'Закрыть',

    selectusers_title: 'Добавить сотрудника',
    selectuser_message: 'Выберите пользователя',
    selectusers_message: 'Выберите одного или несколько пользователей',
    selectusers_add: 'Добавить',
    selectusers_finish: 'Закрыть',
    title: 'Оценка 360/180',
    goback: 'Вернуться',
    loading: 'Загрузка',
    remove: 'Удалить',
    assessment: 'Оценка',
    competences: 'Компетенции',
    name: 'Название',
    date_start: 'Дата начала',
    date_finish: 'Дата окончания',
    description: 'Описание',
    persona: 'Оцениваемый',
    experts: 'Оценивающие',
    clear: 'Очистить',
    del: 'Удалить',
    add: 'Добавить',
    add_assessment: 'Создать оценку',
    add_persona: 'Добавить оцениваемого',
    add_experts: 'Добавить оценивающих',
    ok: 'Записать',
    reset: 'Отменить',
    scores: 'Оценки',
    publish: 'Опубликовать',
    alert_message: 'Не заполнены данные',
    alert_persona: 'Оцениваемый',
    alert_experts: 'Оценивающие',
    alert_dates: 'Даты',
    alert_competences: 'Компетенции',
    alert_scores: 'Оценки',
    del_ass_title: 'Удаление оценки',
    del_ass_mess: 'Удалить оценку',
    deleteSome: 'Удалить',
    export: 'Экспортировать',
    report: 'Отчет',
    del_some_title: 'Удаление',
    del_some_mess: 'Вы хотите удалить оценку',
    more: 'Еще',
    chart: 'Результаты',
    chart_title: 'Диаграмма оценки 180°/360° для сотрудника',
    refresh: 'Обновить список',
    user: 'Сотрудник',
    clear_scores: 'Удалить ответы',
    clrscores_title: 'Очистка',
    clrscores_message: 'Ответы будут удалены из этой Оценки для',
    legend: 'Полные названия компетенций',
    results: 'Результаты',
    average: 'Среднее',
    average1: 'Среднее*',
    close_report: 'Закрыть отчет',
    assessment_name: 'Название оценки',
    action: 'Действие',
    list: 'Вернуться в список оценок',
    status: 'Статус',
    published: 'Опубликовано',
    draft: 'Черновик',
  },
  en: {
    sign_up_btn_ok: "Create",
    sign_up_confirm: "A new portal will be created. You will become the main admin and can invite other users.",
    is_reg_info: "This email is not found. You can register a new portal with it and invite users to it.",
    for_prog_dop_lb: "Points for courses/lessons/tests within",
    passed_prog: "Completed program",
    the_collapsed: "Total older than 3 months",
    the_collapsed_info: "Entries in the details older than three months are collapsed into one. Points are summed up in the process",
    the_test: "Test",
    the_lesson: "Lesson",
    passed_course: "Completed course",
    points_reset: "Reset all points",
    duration_info: "Program duration in days. Calculated by summing the duration of all modules. Used in determining deadlines for assignments and tasks.",
    the_programs: "Programs",
    program_view_btn: "Program manager not selected",
    the_program_info: "By clicking the 'Go to' button, you will assign the program to yourself for completion. You can find the assignment in the 'My Programs' section.",
    go_to_program: "Go to",
    the_program: "Program",
    clear_program: "Deleting program. Only unpublished programs can be deleted.",
    uncheck_all_uscheds: "Uncheck all",
    check_all_uscheds: "Check all",
    is_archive: "Archive",
    from_archive: "Restore from archive",
    to_archive: "Move to archive",
    program_archived: "Moved to archive",
    program_published: "Program published",
    add_auditors: "Module supervisors",
    modules_auditors: "Editing observers and module deadlines",
    add_main_auditor_info: "The program manager is an observer in the task. They will be assigned to each module unless another observer is selected in the modules.",
    dates_schedule_info: "Dates are informational and used for setting task deadlines",
    clear_schedule: "Deleting assignment. Only unpublished assignments can be deleted.",
    program_updated: "Program saved",
    program_added: "Program added",
    program_scheds_done: "Assignments made",
    such_module_exist: "Module already selected",
    such_user_exist: "Employee already selected",
    duration_hint: "Calculated automatically",
    degree_add_block_info: "Select a competence block from the list and click +. Its competencies will be added to the 360 Assessment.",
    add_block_label: "Select competence block",
    slaves_add: "Automatic addition of department employees",
    slaves_add_byhands: "Manual addition",
    chief_add: "Automatic addition of employee's department heads",
    chief_add_byhands: "Manual addition",
    del_subpassw_title: "Password Deletion",
    del_subpassw_message: "The password will be cleared, the user will need to enter it again upon login",
    degree_msg_sended: "Notifications sent",
    degree_checkall_btn: "Check all",
    degree_clear_btn: "Delete responses",
    degree_clear_msg: "The evaluations (responses) of the selected evaluators will be deleted. They will need to perform the evaluations again.",
    degree_status_student: {
     1: "No expert evaluations",
     2: "No experts assigned"
    },
    degree_status_expert: {
     0: "All evaluations given",
     2: "No evaluations for one or more competencies",
     3: "Not all evaluations given"
    },
    degree_status_btn: "Send notification",
    degree_status_msg: "You are participating in employee evaluation as an evaluatee. Create a list of evaluators and select approvers for this list in the 'University' app's personal account section 'My Evaluations'.",
    degree_fill_msg: "You are participating in employee evaluation as an evaluator. Rate the employees in the 'University' app's personal account section 'My Evaluations'.",
    degree_uagree_msg: "You have been appointed as an approver in the 360 evaluation. Go to the 'University' app's personal account section 'My Evaluations'.",
    degree_status: {
     0: "Approved",
     1: "No assigned experts",
     2: "No approver assigned",
     3: "Not approved"
    },
    Degree360StatusList: "Formation statuses",
    Degree360StatusFill: "Filling statuses",
    change_password: "Change password",
    change_password_m: "Change employees' passwords",
    change_password_hint: "Enter the new password and confirmation. After changing the password, log in to the system.",
    change_password_hint_m: "Specify the employee, the password will be generated automatically.",
    change_password_done: "Password changed, use it for the next login.",
    change_password_fail: "An error occurred, the password was not changed. Try again or contact the administrator.",
    change_password_done_m: "Password changed, copy and pass it to the employee.",
    clear_profile: "Delete profile",
    clear_profile_msg: "The profile will be deleted, but the email and password for login will be saved. To create a new profile, you will need to log in to the system. Are you sure?",
    clear_profile_done_m: "Profile deleted",
    add_head: "Manager",
    add_users: "Employee",
    struct_reset_msg: "Do you really want to reset the structure?",
    struct_reset: "Reset structure",
    department_add: "Department",
    struct_company: "/Company",
    struct_department: "Department",
    company_structure: "Company",
    last_name_need: "First and last name must be filled in!",

    degree_checkall_btn: "Check all",
    sign_up_for_an_account: "Sign in or sign up for an account",
    auth_email: "Email",
    auth_password: "Password",
    auth_password2: "Repeat password",
    sign_up: "Sign up",
    sign_in: "Sign in",
    email_invalid: "Invalid email",
    password_invalid: "Invalid password",
    email_exist: "Email found",
    email_ok: "Email available",
    auth_password_match: "Password match",
    auth_password_not_match: "Passwords do not match",
    invitations: "Invitations",
    link_invite: "Self-registration link",
    reminders: "Reminders",
    notifications: "Notifications",
    subac_validate_info: "Use only Latin letters and numbers for the subaccount name.",
    promo_1: "Expand the capabilities of your application. Purchase a subscription for a theme, disk, or subaccounts in the Settings:My Plan section.",
    prog_filter:"Filter",
    prog_filter_all:"All programs",
    prog_filter_tgrp:"Templates for groups",
    prog_filter_tuser:"For new employees",
    prog_is_template:"This is the template for the assignment",
    prog_is_template_newuser: "new employees",
    prog_is_template_grp:"for groups",
    prog_info_grp:"Permanent assignment. When an employee is added to the group, he will be automatically assigned.",
    prog_info_newusers:"When a new employee is added to the Bitrix24 portal, he will be automatically assigned.",
    im_agreemer:"I am the evaluator",
    agree_degree360_mess:"Agree on a list of evaluators for a 360 Grade?",
    decline_degree360_mess: "Should I return the list of evaluators for 360 Assessment for revision?",
    degree360_all_agreed:"The lists of evaluators have been agreed for the 360 grade",
    degree_valid_1:"The name of the 360 Grade has not been set",
    degree_valid_2:"Dates have not been set",
    degree_valid_3:"Evaluators are not set for one or more Evaluators",
    degree_valid_4:"Competencies are not set",
    degree_valid_5:"The 360 Grade scale is not set",
    degree_valid_6:"Evaluators are not agreed upon when making their own choice",
    pdf_download:"Download file",
    pdf_build: "Build a PDF file",
    scale_value:"Value",
    scale_name:"Name",
    scale_desc:"Description",
    degree360_all_agreed:"The lists of evaluators are agreed for the 360 grade",
    degree_valid_1:"The name of the 360 Grade is not set",
    degree_valid_2:"Dates are not set",
    degree_valid_3:"Evaluators are not set for one or more Evaluators",
    degree_valid_4:"Competencies are not set",
    degree_valid_5:"The 360 Grade scale is not set",
    degree_valid_6:"The Evaluators are not agreed upon when making their own choice",
    degree360_self_message:"You have been assigned a task to create a list of evaluators for the 360 Grade",
    clear_agreed:"Remove the concordant",
    students_clear:"All Graders will be deleted along with their Graders",
    add_degree360_info:"The 360 rating is completely generated by the moderator",
    add_task_degree360_info: "The list of evaluators can be formed in two ways: by the moderator; by the evaluated. When activated, the list will be formed by the evaluated. After the evaluation is published, the evaluated will have a task in their personal account in the My Evaluations section to create a list and select approvers. The evaluation will start when all lists are formed.",
    listDegree360:"360 ratings",
    eve_and_bad_moder:"Application for participation in the event from an employee",
    profiles_lb:"Profiles",
    profiles_plc:"The profile contains several competencies",
    add_from_comps: "Select a competence",
    incomplete_data:"Incomplete competence, missing indicators",
    experts_clear:"All evaluating employees will be deleted",
    degree_tasks:[{value:0, label:"set tasks individually"},{value:1, label:"set one common task"},{value:2, label:"do not set tasks"}],
    old_assessments:"Old rating format",
    degree_task_name:"Enter the name of the task",
    degree_task_description:"Enter a description of the task",
    the_task_360: "Task",
    tasks_360: "Tasks",
    add_task_degree360:"Forming a list of evaluators to be evaluated",
    add_degree360:"Add a score of 360",
    stud_id:"ID of the Person being evaluated",
    its_my_degree:"I am Being Evaluated",
    egree_readonly_info: "Assignments have already been made for the 360 Assessment, some actions with the Assessment cannot be performed. For example, deleting evaluated persons, evaluators, deleting and adding competencies, changing dates, enabling self-assessment, changing the way tasks are set.",
    degree360_alert:"The application has already been approved, it cannot be changed.",
    degree360_agreeID:"Approval",
    degree360_agreed:"Agreed",
    degree360_wait_agreed:"Awaiting approval",
    degree360_self_title:"360 Grade Applications",
    degree360_self:"360 Grade Application",
    degree_self:"Forming a list of evaluators",
    degree_self_info:"To apply, choose for yourself the Evaluators and the Person responsible for approving the 360 Grade",
    degree_start_finish_info:"Dates are used informatively and do not affect the display of the 360 Grade.",
    agree_not_valid:"Not all data is filled in. Please check the title, dates, Graded, Grading.",
    degree_add_scale_info:"Select a rating scale from the list to add it to the 360 Rating",
    degree_add_comp_info:"Select a competence from the list and click the <+> button to add the competence to the 360 Assessment",
    degree_adb_prof_info:"Select a profile from the list and click on +. His competencies will be added to the 360 Assessment",
    degree_add_experts_info: "Add Evaluators using the buttons below",
    degree_add_students_info:"Add Evaluated employees using the buttons below",
    degree_agree_degree360_info:"In the left position of the checkbox, you can save the working estimate. Right position - the assignment will be made when saving.",
    degree_agree_user_info:"Approval is required to assign an Assessment. Select a matching employee.",
    degree_hide_fio_info:"By default, the evaluator will see the evaluators' data. When activated, the evaluators' data will be hidden.",
    degree_pub_lk_info:"Publication of the evaluation results in the personal account of the evaluator after all evaluators have given grades.",
    degree_task_info:"The task in Bitrix is to make an assessment. Set by the evaluator.",
    show_options_block:"360 Rating Settings",
    show_users_block:"Lists of evaluators and evaluators",
    show_comps_block:"Competencies, indicators, assessment scale",
    degree360_aver_title:"Evaluation results (average values)",
    degree360_stat_title:"The number of respondents who participated in the assessment",
    degree360_aver_col_:"Practices",
    degree360_stat_ret0:"Number of people",
    degree360_stat_col_:"Respondent groups",
    degree360_stat_col0:"Self-assessment",
    degree360_stat_col1:"Supervisor",
    degree360_stat_col2:"Colleagues",
    degree360_stat_col3:"Subordinates",
    degree360_stat_col4:"All respondents<br>(without self-assessment)",
    degree360_stat_col5:"All respondents",
    degree360_description:"You need to perform a 360 Grade",
    degree360_message:"You have been assigned a Score of 360. Go to the 'University' app/My grades'",
    you_agree_360:"You are appointed as a concordant in the 360 Assessment. Go to the University application for approval.",
    del_student:"Do you want to delete the evaluated one",
    do_schedules:"The 360 grade will be assigned to the evaluator",
    no_experts:"Evaluators are not specified",
    agree_user:"The concordant",
    agree_degree360:"Agree",
    decline_degree360:"Return",
    decline_degree360_info:"If you have changed the status of the application, save it",
    publish_degree360:"Publishing a 360 grade",
    del_degree360:"Deleting a 360 grade",
    del_degree360_msg:"Do you really want to delete the 360 grade",
    degree_name:"Enter the name of the assessment",
    add_prof_label:"Select profile",
    scale_plc: "Select a scale",
    add_prof: "Add to competencies",
    save_comp:"Save",
    close_comp:"Close",
        program_readonly:"Appointments have been made for this program, so it cannot be changed. You can make a copy of the program in the list of programs for its modification and new assignments.",
        program_info:"The 'Programs' module is designed to form comprehensive development programs. For example, such as the Adaptation of beginners, Training of the personnel reserve, etc. "+
          "<br>Programs can be formed from the following elements: courses, tests, lessons (for example, add a PDF file to the lesson, regulations, presentation, links, materials, longrid, etc.), events (for example, create an event Meeting with the Head)."+
          "<br>Programs are formed from elements in parallel and sequentially. Sequential elements form levels (it can be a Day, a learning stage). Any number of elements can be in one level, which means that all of them must be completed at this level. "+
          "<br>After creating a program, it can be assigned to employees, groups (when adding an employee to a group, the program will be automatically assigned to him). The list of employees can be changed: when added, the employee will be assigned a program, when deleted, he will continue to be present in reports."+
          "<br>The assigned program cannot be changed.",
        copy_program:"Copy the program",
        module_level:"Level",
        eve_and_bad_moder:"Application for participation in the event from an employee",
        is_answered:"There are answers",
        check_all:"Mark all grades",
        check_group:"Mark group",
        check_one:"Mark the line",
        rep_date:"Date",
        rep_type:"For what",
        the_open:"Open question",
        the_event:"Event",
        the_360:"Score 360",
        the_aos:"AOS",
        the_rate:"Course/Test score",
        the_comment:"Comment",
        the_ipr:"IPR",
        the_Rc:"Course assessment",
        the_Rt:"Test score",
        the_course:"Course",
        write_off:"Write-off",
        not_in_sched:"Missing in the program assignment",
        sub_alert:"This account contains subaccounts, but their limit has been reached. Contact the moderator of the application",
        sub_limit_exceeded:"The subaccount limit has been reached. Go to Settings/My tariff for increasing the limit",
        sub_login_already:"Such a subaccount already exists",
        sub_accounts:"Subaccounts",
        native_add:"Add an account.",
        sub_add: "Add a subaccount",
        del_subac_title:"Deleting a subaccount",
        del_subac_message:"The subaccount will be removed from the list. Are you sure?",
        del_sub_title:"Account deletion",
        del_sub_message:"The account will be deleted from the list along with its subaccounts. Are you sure?",
        self_sign_up:"Self-registration",
        sub_acc_title:"Authorization",
        sub_acc_login:"Login",
        sub_acc_password:"Password",
        sub_acc_password2: "Repeat password",
        sub_acc_recv:"Your data",
        sub_acc_first_name:"Name",
        sub_acc_last_name:"Last name",
        sub_acc_middle_name:"Patronymic",
        sub_acc_position:"Position",
        sub_acc_login_not_found:"Login not found, registration required",
        sub_acc_save:"Save",
        sub_acc_ok:"Log in",
        sub_acc_passwords_not_match:"Passwords don't match",
        sub_acc_password_fail:"Invalid password",
        sub_acc_valid_recv:"Fill in the details",
        sub_acc_recv:"Change data",
        sub_important_info: "Important Information",
    sub_info: "<ol>"+
     "<li><b>Add an account.</b><br>"+
     "Important conditions:</li>"+
     "<ul><li>This is a Bitrix24 account to which University subaccounts will be linked.</li>"+
     "<li>Only the Bitrix24 portal administrator can create an account. Not the application moderator.</li>"+
     "<li>The account cannot be a Bitrix24 Administrator or a University application moderator.</li></ul>"+
     "<li><b>Select the method of subaccount registration in the application:</b></li>"+
     "<ul><li><b>Independently.</b> In this case, the portal administrator only needs to enter the logins and inform these logins to the subaccounts. When logging in, subaccounts will enter their login, then the application will prompt them to fill in the data (full name and position) and create a password.</li>"+
     "<li><b>By the administrator.</b> In this case, the administrator enters the login and fills in all the data (full name and position), and then provides the logins to the subaccounts. When logging in, the subaccount will be prompted to create a password. The other data (full name and position) will already be filled in by the Administrator.</li></ul>"+
     "<li><b>Each time they log into the application, the subaccount will need to enter their login and password.</b></li>"+
     "</ol>",
     cert_format:"Page format",
    cert_name:"Certificate name",
    cert_save:"Save the certificate",
    cert_new:"Add certificate",
    cert_list:"List of certificates",
    clear_form:"New certificate",
    cert_remove:"Remove from the list",
    cert_default:"By default",
    cert_scale:"Scale",
    cert_opts_fio:"User name",
    cert_opts_pos:"Position",
cert_opts_name:"Name of the test/course",
    cert_opts_score:"Passing score",
    cert_opts_date:"Date of completion",
    cert_opts_text:"Additional text",
    cert_dark_mode:"Dark background",
    cert_orient_a:"Landscape",
cert_orient_p:"Portrait",
certs:"Certificates",
    cer_tificate:"Certificate",
    cert_img:"Image",
    save_png:"Download",
cert_del_title:"Attention!",
cert_del_message:"The certificate will be removed from the list. The file will also be deleted from the disk.",
cert_reload_title:"Attention!",
cert_reload_message:"To apply the changes, you will need to log out and go back to the certificate settings.",
cert_font_size:"Font size",
    cert_font:"Font",
    cert_font_color:"Font color",
    is_program_tasks:[{value:0, label:"set tasks for each module"},{value:1, label:"set one common task"},{value:2, label:"do not set tasks"}],
    program_tasks:"Tasks",
    program_task_description:"You need to run the program",
    course_passed:"The course has been completed",
    module_complete:"Complete the module",
    done_success:"Successful!",
    save_program:"Save",
    add_module_s:"Add serial module",
    add_module_p:"Add a parallel module",
    program_message:"You have been assigned a training program. Go to the 'University' app/My programs'",
    my_programs:"My programs",
    publish_schedule_tooltip:"No program selected or appointment dates set, supervisor, employees",
    mod_description:"In the program execution plan, you need to execute the module",
    list_schedules:"Assignments",
    add_schedule:"Add an appointment",
    one_schedule:"Assignment",
    publish_schedule:"Make an appointment",
    dates_schedule:"Appointment dates",
    add_students:"Employees",
    dep_rem_title:"Remove from assignment?",
    dep_rem_mess:"Delete everyone with this group (division)",
    dep_rem_mess1:"Delete this member",
    start_finish:"Start/End",
    selectusers_label:"Employees",
    selectgroups_label:"Groups",
    selectdeps_label:"Departments",
    selecttests_label:"courses/tests",
    selectgroup_title:"Add a group",
    selectgroup_message:"Select the group",
    courses_tests:"Test courses",
    students:"Students",
    del_schedule_title:"Delete",
    del_schedule_message:"Confirm the deletion of the destination",
    del_schedules_message: "Confirm deletion of assignments",
    date_to_short:"endings",
    students_comment:"If an employee was added through a department or group, then deleting such an employee will entail deleting other employees of this department/group",
    add_department:"Department",
    add_group:"Group",
    add_main_auditor:"Supervisor",
    mod_duration:"Duration, days",
    mod_action:{'hand':"manual", 'auto':"auto"},
    add_program:"Add a program",
    list_programs:"List of programs",
    one_program:"Program",
    progrm_modules:"Modules",
    requaried_field:"Required field",
    program_name:"Program name",
    program_description:"Program description",
    module_type:"Module type",
    module_type_opt:[{value:1, label:"Course"},{value:2, label:"Test"},{value:3, label:"Lesson"}],
    select_module:"Select a module",
    not_found:"Nothing found",
    type_open:"The way to open modules",
    type_open_opt:[{value:'P', label:"Open all at once"},{value:'S', label:"Open sequentially. If the module is not passed, open the next one anyway"},{value:'H', label:"Open sequentially. If the module is not passed, do not open the next one"}],
    publish_program: "Publish the program",
    del_program:"Delete",
    del_program_msg:"Are you sure you want to delete",
    mod_passed:[{value:'0', label: "no status"}, {value:'Y', label:"Passed"},{value:'N', label:"Not passed"}],
    mod_passed_label:"Status",
    points:"Scores",
    points_plc:"Set 0 if points are awarded as specified in the application settings",
    for_course_active:"Completed course",
    for_course_mod_lb:"Counting method",
    for_course_mode:[
      {value:'sum', label:"Calculate by summing points for modules"},
      {value:'hand', label:"Enter manually"},
    ],
    for_course_dop_lb: "Points for lessons/tests within the course",
    for_course_dop:[
      {value:'top', label:"Accrue "},
      {value:'node', label:"Do not charge"},
    ],
    separ_add:"Add separator",
    sep:"Separator",
    bid_del_message: "Delete bid",
    em_count_limit: "Maximum number of participants exceeded",
    em_count:"Number of participants. 0-unlimited",
    eve_attende:"Participant",
    eve_bid:"Bid",
    not_published:"Not published",
    my_events:"My events",
    ipr_comp:"Competence",
    ipr_ind:"Indicators",
    eve_bid_done:"Your application has been added. After consideration, the event will be added to the list and you will become a participant.",
    eve_new_big_add:"The desired event is not in the list? Offer a request. After verification, the event will be added to the list and you will become a participant.",
    eve_new_bid_title:"Application for a new event",
    eve_new_bid_done:"Your application has been added. After consideration, the event will be added to the list and you will become a participant.",
    alert_understood:"Understood",
    eve_new_bid_moder:"An application for the event has been received from an employee",
    em_details:"Details",

    bid_restore_title:"Restore the application",
    bid_restore_message:"Specify the reason for restoring the application",
    bid_accepted:"The application has been accepted",
    bid_declined:"Application rejected, restore.",
    bind_accept:"Accept the application",
    bid_decline_title:"Reject the application",
    bid_decline_message:"Specify the reason for rejecting the application",
    eve_bids_all:"Add all",
    eve_calend:"In the calendar",
    eve_detail:"More information about the event",
    eve_edit:"Edit",
    em_bids:"Applications",
    eve_bid_lbl:"Apply to participate in the event",
    eve_title:"Apply to participate in the event",
    eve_bin_add:"I will be a participant",
    eve_nts:"Events",
    text_block:"The text block",
    text_block_name:"Field name",
    text_block_value:"Field contents",
    r_o:"Read-only",
    set_files:"Files",
    integral_chart:"Integral chart",
    view_cards:"Вид: карточки",
    view_list:"Вид: список",
    node_disabled:"Make inactive",
    node_enabled:"Make active",
    download: "Download",
    no_valid_publish:"Important data is not set, for example, employee, supervisor, mentor, competencies, indicators, or there are no editing rights",
    hide_fio: "Hide evaluators' data",
    such_element_exist:"Such an element already exists",
    program_course:"/The course program",
    lessons:"Lessons",
    tests:"Tests",
    no_lessons:"No lessons to add to the course",
    no_tests:"No tests to add to the course",
    drop_here:"Drag lessons and tests here",
    reset_tree:"Reset navigation",
    reset_true_msg:"Delete all lessons and tests from the course?",
    export_all:"Export All",
    imgFiles:"Files to reply",
    upload_title:"Upload",
    upload_err:"Upload failed",
    upload_hint:"Select a file on disk or drag and drop it here",
    max_file_size:"File size must not exceed {mb} Mb",
    delete_image:"Delete file",

    ipr_note:"You have been assigned an 'Individual Development Plan'. Go to your University app.",
    add_evt_title:"Event added",
    add_evt_msg:"Close the form for adding events and go to the list?",
    add_evt_ok:"Close",
    add_evt_canc:"Add more",
    res360_published:"The results of the 360 assessment are published in the Personal Account of the evaluated",
    rm_rm:"Developing event",
    rm_dln:"Expired",
    rm_deadline:"Deadline",
    go_course:"Go",
    no_results:"No data",
    enter_course_name:"Type part of the name to search for",
    course_name:"Add course",
    course_statuses:"Course status",
    ipr_courses:"RM courses",
    ipr_export:"Export",
    iprset_create_label:"Who can create",
    iprset_edit_label:"Who can edit",
    iprset_read_label:"Who can see",
    iprset_status_label:"Who can change the status",
    iprset_role_opt:[{value:'M', label:"Moderator"},{value:'U', label:"Employee"},{value:'N', label:"Mentor"}],
    add_rm:"RM",
    add_ind:"Indicator",
    ipr_title:"Individual development plan",
    ipr_name:"IPR name",
    ipr_close:"Close",
    ipr_open:"Open",
    select_person:"Employee",
    select_manager:"Supervisor",
    select_mentor:"Mentor",
    rm_label:"RM",
    rm_options:[
      "Assessment",
      "Audio content",
      "Case study",
      "Competition",
      "Conference",
      "Course",
      "Distance course",
      "Evaluation",
      "Facilitation",
      "Game",
      "Hackathon",
      "Internship",
      "Lecture",
      "Master class",
      "Meeting",
      "Meetup",
      "Mentoring",
      "Podcast",
      "Project",
      "Quiz",
      "Report",
      "Research",
      "Seminar",
      "Session",
      "Simulation",
      "Team building",
      "Testing",
      "Training",
      "Universiade",
      "Video content",
      "Webinar",
      "Workshop",
      "-your own version-"
    ],
    rm_compleeted:"Completed",
    rm_started:"In progress",
    rm_new:"Not started",
    rm_status:"Status",
    hand_made:"Enter manually",
    comp_name:"Competence name",
    ind_name:"Indicator name",
    del_ind:"Delete indicator",

    selectcomps_label:"Competencies",
    selectcomps_title:"Add competencies",
    selectcomp_message:"Choose a competence",
    selectcomps_message:"Choose one or more competencies",
    selectcomps_add:"Add",
    selectcomps_finish:"Close",
    selectscale:"Rating scale",
    target_sc:"Target level",
    current_sc: "Current level",

    del_certs:"The certificate was deleted in the Education section, delete it here",
    no_certs:"You don't have any downloaded certificates. Add them in the 'Education' section",
    aos_filled:"filled in",
    anketa_add:"Add profile",
    for_test:"For tests",
    for_course:"For courses",
    for_event:"For events",
    aos_copy:"(Copy)",
    new_aos_name:"Change the name to save",
    first_aos_name:"Default questionnaire",
    aos_edit:"Editing the questionnaire",
    aos_look:"View the questionnaire",
    aos_refresh_title:"Load by default",
    aos_refresh_message:"Your profile will be replaced with the default profile",
    answ_aos:"Answers",
    answer:"Text",
    score:"Weight",
    quest_aos:"Questions",
    add_answ:"Add response",
    other_answ:"Other",
    a_o_s:"AOS",
    add_aos:"Add a question",
    aos:"Feedback questionnaire",
    aos_del:"Delete profile",

    cert_del:"Remove the certificate from the event?",
    cert_add:"Add certificate",
    event_certs:"Select certificates",
    em_del: "Delete an event (from the calendar too)",
    em_save:"Write",
    em_clear:"Clear",
    em_meeting_notify:"Send invitations",
    em_description:"Event description",
    em_meeting_text:"Invitation text",
    em_color:"Event color",
    em_text_color:"Text color",
    em_attendees:"Members",
    em_host:'Responsible',
    em_org:"Organizer",
    em_title:"External and face-to-face events",
    em_eventName:"Event name",
    em_common_list:"General list",
    em_personas: 'Evaluated',
    em_close:"select",
    em_location:"Type",
    em_location_opt:['Internal','External'],
    em_vid:"View",
    em_vid_opt:[
      'Assessment',
      'Audio content',
      'Case',
      'Competition',
      'Conference',
      'Course',
      'Distance learning course',
      'Evaluation',
      'Facilitation',
      'Game',
      'Hackathon',
      'Internship',
      'Lecture',
      'Master class',
      'Meetup',
      'Mentoring',
      'Podcast',
      'Project',
      'Quiz',
      'Report',
      'Research',
      'Seminar',
      'Session',
      'Simulation',
      'Team building',
      'Testing',
      'Training',
      'Universiade',
      'Video content',
      'Webinar',
      'Workshop',
    ],
    common_list:"General list",
    personas: 'Evaluated',
    exp_comments:"Comments",
    no_events:"No upcoming events",
    em_from_to:"Start and end of the event",
    acc1: "Show results in personal account",
    add_section:"Add a section",
    comps_added:"Added competencies",
    comps_add:"Add competence",
    attention:"Attention",
    del_competence:"Delete competence",
    competencies: 'Competencies',
    all: "All",
    add_coworkers: 'Add colleagues', //new words
    coworkers: 'Colleagues',
    add_slaves: 'Add subordinates',
    slaves: 'Subordinates',
    add_chief: 'Add a supervisor',
    chief: 'Manager',
    new: 'Employee',
    average_: 'Average without the participation of the evaluated',
    departments: 'Divisions',
    add_departments: 'Add divisions',
    selectdepartments_title: 'Add a division',
    selectdepartments_message: 'Select a division',
    selectdepartments_add: 'Add',
    selectdepartments_finish: 'Close',

    rep_competence:"Competence/Indicators",
    rep_indicators:"Experts",
    rep_scores:"Ratings",
    comment:"Comment to the evaluated",
    acc:"Publish in the personal account of the evaluated",
    you_ass_360:"You have been assigned a score of 360. See the list of all ratings in your personal account.",
    del_many_title:"Deleting multiple ratings",
    del_many_mess:"Do you want to delete several marked ratings",
    rows_per_page: 'Records per page',
    all_report: 'General report',
    start_finish: 'Start/end',
    competence: 'Competence',
    selectusers_title: 'Add employee',
    selectuser_message: 'Select user',
    selectusers_message: 'Select one or more users',
    selectusers_add: 'Add',
    selectusers_finish: 'Close',
    title: '360/180 feedback',
    goback: 'Back',
    loading: 'Loading',
    remove: 'Remove',
    assessment: 'feedback',
    competences: 'Competencies',
    name: 'Name',
    date_start: 'Start date',
    date_finish: 'Finish date',
    description: 'Description',
    persona: 'Under feedback',
    experts: 'Members',
    clear: 'Clear',
    del: 'Delete',
    add: 'Add',
    add_assessment: 'Create a feedback',
    add_persona: 'Add a person under feedback',
    add_experts: 'Add members',
    ok: 'OK',
    reset: 'Cancel',
    scores: 'Feedbacks',
    publish: 'Publish',
    alert_message: 'Data not filled',
    alert_persona: 'Under feedback',
    alert_experts: 'Members',
    alert_dates: 'Dates',
    alert_competences: 'Competencies',
    alert_scores: 'Feedbacks',
    del_ass_title: 'Delete rating',
    del_ass_mess: 'Delete rating',
    deleteSome: 'Delete',
    export: 'Export',
    report: 'Report',
    del_some_title: 'Delete',
    del_some_mess: 'You want to delete the rating',
    more: 'More',
    chart: 'Results',
    chart_title: '180°/360° Rating chart for employee',
    refresh: 'Update list',
    user: 'Employee',
    clear_scores: 'Clear rating',
    clrscores_title: 'Clear',
    clrscores_message: 'All scores will be deleted from this Rating. Continue?',
    legend: 'Full competency names',
    results: 'Results',
    average: 'Average',
    average1: 'Average*',
    close_report: 'Close report',
    assessment_name: 'Rating name',
    action: 'Action',
    list: 'Back to scores list',
    status: 'Status',
    published: 'Published',
    draft: 'Draft',
  },
  ua: {
    sign_up_btn_ok: "Створити",
    sign_up_confirm: "Буде створено новий портал. Ви станете головним адміністратором і зможете запросити інших користувачів.",
    is_reg_info: "Такий email відсутній. З ним Ви можете зареєструвати новий портал і запросити в нього користувачів.",
    for_prog_dop_lb: "Бали за курси/уроки/тести всередині",
    passed_prog: "Пройдена програма",
    the_collapsed: "Сумарно старше 3 місяців",
    the_collapsed_info: "Записи в деталізації старше трьох місяців згортаються в одну. Бали при цьому сумуються",
    the_test: "Тест",
    the_lesson: "Урок",
    passed_course: "Пройдений курс",
    points_reset: "Обнулити всі бали",
    duration_info: "Тривалість програми в днях. Розраховується шляхом підсумовування тривалості всіх модулів. Використовується при визначенні термінів призначень і завдань.",
    the_programs: "Програми",
    program_view_btn: "Не обрано керівника програми",
    the_program_info: "Натискаючи кнопку 'Перейти', ви призначите собі програму для її проходження. Знайти призначення ви зможете в розділі 'Мої програми'.",
    go_to_program: "Перейти",
    the_program: "Програма",
    clear_program: "Видалення програми. Видалити можна лише неопубліковані програми.",
    uncheck_all_uscheds: "Зняти позначки",
    check_all_uscheds: "Позначити все",
    is_archive: "Архів",
    from_archive: "Повернути з архіву",
    to_archive: "Перенести в архів",
    program_archived: "Перенесено в архів",
    program_published: "Програму опубліковано",
    add_auditors: "Відповідальні за модуль",
    modules_auditors: "Редагування спостерігачів і термінів модулів",
    add_main_auditor_info: "Керівник програми - це спостерігач у завданні. Він буде призначатися на кожен модуль, якщо в модулях не обрано іншого спостерігача.",
    dates_schedule_info: "Дати є інформаційними і використовуються для встановлення термінів завдань",
    clear_schedule: "Видалення призначення. Видалити можна лише неопубліковані призначення.",
    program_updated: "Програму збережено",
    program_added: "Програму додано",
    program_scheds_done: "Призначення зроблено",
    such_module_exist: "Модуль вже обрано",
    such_user_exist: "Співробітника вже обрано",
    duration_hint: "Розраховується автоматично",
    degree_add_block_info: "Виберіть з переліку блок компетенцій і натисніть на +. Його компетенції будуть додані до Оцінки 360.",
    add_block_label: "Вибрати блок компетенцій",
    slaves_add: "Автоматичне додавання співробітників підрозділу",
    slaves_add_byhands: "Ручне додавання",
    chief_add: "Автоматичне додавання керівників підрозділів співробітника",
    chief_add_byhands: "Ручне додавання",
    del_subpassw_title: "Видалення пароля",
    del_subpassw_message: "Пароль буде очищено, користувач при вході повинен буде ввести його заново",
    degree_msg_sended: "Повідомлення надіслані",
    degree_checkall_btn: "Відзначити все",
    degree_clear_btn: "Видалити відповіді",
    degree_clear_msg: "Будуть видалені оцінки (відповіді) вибраних оцінювачів. Їм потрібно буде провести оцінки заново.",
    degree_status_student: {
     1: "Немає оцінок експертів",
     2: "Не призначені експерти"
    },
    degree_status_expert: {
     0: "Всі оцінки дані",
     2: "Немає оцінок за одну або більше компетенцій",
     3: "Не всі оцінки дані"
    },
    degree_status_btn: "Відправити повідомлення",
    degree_status_msg: "Ви берете участь в оцінці співробітників як оцінюваний. Сформуйте список оцінювачів, виберіть погоджувачів цього списку в особистому кабінеті додатку «Університет» у розділі Мої оцінки.",
    degree_fill_msg: "Ви берете участь в оцінці співробітників як оцінювач. Поставте оцінку співробітникам в особистому кабінеті додатку «Університет» у розділі Мої оцінки.",
    degree_uagree_msg: "Ви призначені погоджувачем в оцінці 360. Перейдіть в особистий кабінет додатку «Університет» у розділі Мої оцінки.",
    degree_status: {
     0: "Погоджено",
     1: "Немає призначених експертів",
     2: "Не призначено погоджувача",
     3: "Не погоджено"
    },
    Degree360StatusList: "Статуси формування",
    Degree360StatusFill: "Статуси заповнення",
    change_password: "Змінити пароль",
    change_password_m: "Зміна паролів співробітникам",
    change_password_hint: "Введіть новий пароль і підтвердження. Після зміни пароля увійдіть в систему.",
    change_password_hint_m: "Вкажіть співробітника, пароль буде згенеровано автоматично.",
    change_password_done: "Пароль змінено, використовуйте його при наступному вході.",
    change_password_fail: "Сталася помилка, пароль не змінено. Спробуйте ще раз або зверніться до адміністратора.",
    change_password_done_m: "Пароль змінено, скопіюйте і передайте його співробітнику.",
    clear_profile: "Видалити профіль",
    clear_profile_msg: "Профіль буде видалено, але email і пароль для входу будуть збережені. Для створення нового профілю потрібно буде увійти в систему. Ви впевнені?",
    clear_profile_done_m: "Профіль видалено",
    add_head: "Керівник",
    add_users: "Співробітник",
    struct_reset_msg: "Ви дійсно хочете скинути структуру?",
    struct_reset: "Скинути структуру",
    department_add: "Підрозділ",
    struct_company: "/Компанія",
    struct_department: "Відділ",
    company_structure: "Компанія",
    last_name_need: "Обов'язково заповніть ім'я та прізвище!",

    degree_checkall_btn: "Відзначити все",
    sign_up_for_an_account: "Увійдіть або зареєструйте свій аккаунт",
    auth_email: "Email",
    auth_password: "Пароль",
    auth_password2: "Повторіть пароль",
    sign_up: "Зареєструвати",
    sign_in: "Увійти",
    email_invalid: "Некоректний email",
    password_invalid: "Невірний пароль",
    email_exist: "Email знайдено",
    email_ok: "Email вільний",
    auth_password_match: "Підходящий пароль",
    auth_password_not_match: "Паролі не збігаються",
    invitations: "Запрошення",
    link_invite: "Посилання для самостійної реєстрації",
    reminders: "Нагадування",
    notifications: "Сповіщення",
    subac_validate_info: "Для імені субакаунта використовуйте тільки латинські букви та цифри.",
    promo_1: "Розширте можливості вашого додатка. Придбайте підписку на тему, диск або субакаунти в розділі Налаштування:Мій тариф.",
    prog_filter:"фільтр",
    prog_filter_all:"всі програми",
    prog_filter_tgrp: "шаблони для груп",
    prog_filter_tuser: "для нових співробітників",
    prog_is_template: "це шаблон для призначення",
    prog_is_template_newuser:"новим співробітникам",
    prog_is_template_grp:"для груп",
    prog_info_grp:"постійно діючі призначення. При додаванні в групу співробітника йому автоматично буде здійснено призначення.",
    prog_info_newusers:"при додаванні нового співробітника на портал Бітрікс24 йому автоматично буде здійснено призначення.",
    im_agreemer:"я-оцінювач",
    agree_degree360_mess: "узгодити список оцінювачів для оцінки 360?",
    decline_degree360_mess: "повернути на доопрацювання список оцінювачів для оцінки 360?",
    degree360_all_agreed: "списки оцінювачів узгоджені для оцінки 360",
    degree_valid_1: "не вказано назву оцінки 360",
    degree_valid_2: "не встановлені дати",
    degree_valid_3:"не задані оцінюють у одного або декількох оцінюваних",
    degree_valid_4: "не встановлені компетенції",
    degree_valid_5:" Не встановлено шкалу оцінки 360",
    degree_valid_6:" не узгоджені оцінюють при самостійному виборі",
    pdf_download: "завантажити файл",
    pdf_build: "побудувати PDF-файл",
    scale_value:"Значення",
    scale_name:"Назва",
    scale_desc:"опис",
    degree360_all_agreed: "списки оцінювачів узгоджені для оцінки 360",
    degree_valid_1: "не вказано назву оцінки 360",
    degree_valid_2: "не встановлені дати",
    degree_valid_3:"не задані оцінюють у одного або декількох оцінюваних",
    degree_valid_4: "не встановлені компетенції",
    degree_valid_5:" Не встановлено шкалу оцінки 360",
    degree_valid_6:" не узгоджені оцінюють при самостійному виборі",
    degree360_self_message:" вам призначено завдання на формування списку оцінювачів для оцінки 360",
    clear_agreed: "видалити узгоджувального",
    students_clear:" будуть видалені всі оцінювані разом зі своїми оцінювачами",
    add_degree360_info: "оцінка 360 повністю формується модератором",
    add_task_degree360_info: "Формування списку оцінювачів можливе двома способами: модератором; оцінюваними. При активації формування списку буде здійснюватися оцінюваними. Після публікації оцінки у оцінюваних в особистому кабінеті в розділі Мої оцінки з'явиться завдання зі створення списку та вибору погоджувачів списку. Оцінка буде запущена, коли всі списки будуть сформовані.",
    listDegree360: "оцінки 360",
    eve_and_bad_moder: "Заявка на участь у заході від співробітника",
    profiles_lb: "профілі",
    profiles_plc: "профіль містить кілька компетенцій",
    add_from_comps: "вибрати компетенцію",
    incomplete_data: "неповна компетенція, відсутні індикатори",
    experts_clear: "буде видалено всіх оцінюючих працівників",
    degree_tasks: [{value: 0, label:"ставити завдання індивідуально"},{value: 1, label:"ставити одну загальну задачу"},{value: 2, label: "не ставити завдання"}],
    old_assessments: "старий формат оцінок",
    degree_task_name: "введіть назву завдання",
    degree_task_description: "введіть опис завдання",
    the_task_360:"завдання",
    tasks_360:"завдання",
    add_task_degree360: "формування списку оцінюючих оцінюваними",
    add_degree360: "додати оцінку 360",
    stud_id: "ID оцінюваного",
    its_my_degree: "Я-оцінюваний",
    degree_readonly_info: "Призначення для Оцінки 360 вже зроблені, деякі дії з Оцінкою не можуть бути виконані. Наприклад, видалення оцінюваних, оцінювачів, видалення та додавання компетенцій, зміна дат, включення самооцінки, зміна способу постановки завдань.",
    degree360_alert: "Заявка вже узгоджена, змінити її не можна.",
    degree360_agreeID: "узгодження",
    degree360_agreed:"узгоджено",
    degree360_wait_agreed: "очікує узгодження",
    degree360_self_title:" заявки на оцінку 360",
    degree360_self:" Заявка на оцінку 360",
    degree_self: "формування списку оцінювачів",
    degree_self_info:"що б подати заявку, виберіть для себе оцінюючих і відповідального за узгодження оцінки 360",
    degree_start_finish_info: "дати використовуються Інформаційно і не впливають на відображення Оцінки360.",
    agree_not_valid:"не всі дані заповнені. Будь ласка, перевірте назву, дати, оцінюваних, оцінюючих.",
    degree_add_scale_info: "виберіть шкалу оцінки зі списку, що б додати її до оцінки 360",
    degree_add_comp_info: "виберіть зі списку компетенцію і натисніть кнопку<+>, що б додати компетенцію до оцінки 360",
    degree_adb_prof_info: "виберіть зі списку профіль і натисніть на +. Його компетенції будуть додані до оцінки 360",
    degree_add_experts_info: "додайте оцінюють використовуючи кнопки нижче",
    degree_add_students_info: "додайте оцінюваних співробітників використовуючи кнопки нижче",
    degree_agree_degree360_info: "у лівому положенні прапорця, можна зберігати робочу оцінку. Праве положення - буде зроблено призначення при збереженні.",
    degree_agree_user_info: "для призначення оцінки необхідно узгодження. Виберіть узгоджувального працівника.",
    degree_hide_fio_info: "за замовчуванням оцінюваний буде бачити дані оцінюють. При активації дані оцінюють будуть приховані.",
    degree_pub_lk_info: "публікація результатів оцінки в особистому кабінеті оцінюваного після того, як всі оцінювачі виставлять оцінки.",
    degree_task_info: "завдання в Бітріксі зробити оцінку. Ставиться оцінюючим.",
    show_options_block: "Налаштування оцінки 360",
    show_users_block: "списки оцінюваних і оцінюючих",
    show_comps_block: "компетенції, індикатори, шкала оцінки",
    degree360_aver_title:" результати оцінок (середні значення)",
    degree360_stat_title:" кількість респондентів, які брали участь в оцінці",
    degree360_aver_col_:"Практики",
    degree360_stat_ret0: "кількість людей",
    degree360_stat_col_: "групи респондентів",
    degree360_stat_col0:"Самооцінка",
    degree360_stat_col1: "Керівник",
    degree360_stat_col2:"колеги",
    degree360_stat_col3:"підлеглі",
    degree360_stat_col4:"всі респонденти<br>(без самооцінки)",
    degree360_stat_col5:"всі респонденти",
    degree360_description:" Вам потрібно виконати оцінку 360",
    degree360_message: "вам призначено оцінку 360. Перейдіть в додаток'університет/мої оцінки'",
    you_agree_360: "ви призначили узгоджувальним в оцінці 360.  Перейдіть в додаток університет для узгодження.",
    del_student:"ви хочете видалити оцінюваного",
    do_schedules: "оцінка 360 буде призначена оцінювачам",
    no_experts:"не задані оцінюють",
    agree_user:"узгоджувальний",
    agree_degree360:"узгодити",
    decline_degree360: "повернути",
    decline_degree360_info: "якщо ви змінили статус заявки, збережіть її",
    publish_degree360: "Публікація оцінки 360",
    del_degree360: "видалення оцінки 360",
    del_degree360_msg: "ви дійсно хочете видалити оцінку 360",
    degree_name: "введіть назву оцінки",
    add_prof_label: "вибрати профіль",
    scale_plc: "вибрати шкалу",
    add_prof: "додати до компетенцій",
    save_comp:"Зберегти",
    close_comp:"закрити",
    program_readonly:"за цією програмою зроблені призначення, тому змінити її не можна. Можна зробити копію програми в списку програм для її зміни і нових призначень.",
    program_info: "Модуль 'програми' призначений для формування комплексних програм розвитку. Наприклад, таких як адаптація новачків, навчання кадрового резерву та ін."+
      "<br>Програми можна формувати з наступних елементів: курси, тести, уроки (наприклад, додати в урок PDF-файл, регламент, презентацію, посилання, матеріали, лонгрід і т.д.), заходи (наприклад, створити захід Зустріч з керівником)."+
      "<br>Після створення Програми її можна призначати співробітникам, групам (при додаванні співробітника в групу, йому автоматично буде зроблено призначення програми). Список співробітників можна змінювати: при додаванні - співробітнику буде призначена програма, при видаленні - він буде продовжувати бути присутнім в звітах."+
      "<br>Призначену програму не можна змінювати.",
    copy_program: "копіювати програму",
    module_level:"рівень",
    eve_and_bad_moder: "Заявка на участь у заході від співробітника",
    is_answered: "є відповіді",
    check_all: "позначити всі оцінки",
    check_group: "позначити групу",
    check_one: "позначити рядок",
    rep_date:"Дата",
    rep_type: "За що",
    the_open: "відкрите питання",
    the_event:"подія",
    the_360:"оцінка 360",
    the_aos: "АОС",
    the_rate: "оцінка курсу / тесту",
    the_comment:"коментар",
    the_ipr: "ІПР",
    the_Rc: "оцінка курсу",
    the_Rt: "оцінка тесту",
    the_course:"Курс",
    write_off:"списання",
    not_in_sched: "відсутній у призначенні програми",
    sub_alert: "даний аккаунт містить субаккаунти, але їх ліміт вичерпаний. Зверніться до модератора програми",
    sub_limit_exceeded: "ліміт субаккаунтів вичерпаний. Перейдіть в Налаштування / мій тариф для збільшення ліміту",
    sub_login_already: "такий субаккаунт вже існує",
    sub_accounts: "Субаккаунти",
    native_add: "Додати обліковий запис. Доступно тільки для адміністратора порталу",
    sub_add: "додати субаккаунт",
    del_subac_title: "видалення субаккаунта",
    del_subac_message: "Субаккаунт буде видалено зі списку. Ви впевнені?",
    del_sub_title: "видалення облікового запису",
    del_sub_message: "обліковий запис буде видалено зі списку разом зі своїми субаккаунтамі. Ви впевнені?",
    self_sign_up:" самостійна реєстрація",
    sub_acc_title: "Авторизація",
    sub_acc_login:"Login",
    sub_acc_password:"Пароль",
    sub_acc_password2: "повторіть пароль",
    sub_acc_recv:"Ваші дані",
    sub_acc_first_name:"Ім'я",
    sub_acc_last_name:"прізвище",
    sub_acc_middle_name: "по батькові",
    sub_acc_position: "Посада",
    sub_acc_login_not_found: "Логін не знайдено, потрібна реєстрація",
    sub_acc_save:"Зберегти",
    sub_acc_ok:"Увійти",
    sub_acc_passwords_not_match: "паролі не збігаються",
    sub_acc_password_fail: "неправильний пароль",
    sub_acc_valid_recv: "заповніть реквізити",
    sub_acc_recv: "змінити Дані",
    sub_important_info: "Важлива інформація",
    sub_info: "<ol>"+
     "<li><b>Додати акаунт.</b><br>"+
     "Важливі умови:</li>"+
     "<ul><li>Це акаунт Бітрікс24, до якого будуть прив'язані субакаунти Університету.</li>"+
     "<li>Створювати акаунт може тільки адміністратор порталу Бітрікс24. Не модератор додатку.</li>"+
     "<li>Акаунт не може бути Адміністратором Бітрікс24 або модератором додатку Університет.</li></ul>"+
     "<li><b>Вибрати спосіб реєстрації субакаунтів у додатку:</b></li>"+
     "<ul><li><b>Самостійно.</b> У цьому випадку адміністратор порталу повинен ввести тільки логіни і повідомити ці логіни субакаунтам. При вході субакаунти введуть свій логін, потім додаток запропонує заповнити дані (ПІБ та посаду) і придумати пароль.</li>"+
     "<li><b>Адміністратором.</b> У цьому випадку адміністратор вводить логін і заповнює всі дані (ПІБ та посаду), передає субакаунтам логіни. При вході субакаунту буде запропоновано створити пароль. Інші дані (ПІБ та посада) вже будуть заповнені Адміністратором.</li></ul>"+
     "<li><b>При кожному вході в додаток субакаунт повинен буде ввести свій логін і пароль.</b></li>"+
     "</ol>",
     cert_format:" формат сторінки",
    cert_name: "назва сертифіката",
    cert_save: "Зберегти сертифікат",
    cert_new: "додати сертифікат",
    cert_list:" список сертифікатів",
    clear_form: "новий сертифікат",
    cert_remove: "видалити зі списку",
    cert_default:"за замовчуванням",
    cert_scale: "Масштаб",
    cert_opts_fio:"Ім'я користувача",
    cert_opts_pos: "Посада",
    cert_opts_name: "назва тесту / курсу",
    cert_opts_score: "прохідний бал",
    cert_opts_date:"Дата проходження",
    cert_opts_text: "додатковий текст",
    cert_dark_mode: "темний фон",
    cert_orient_a:"Альбомна",
    cert_orient_p:"Портретна",
    certs:"сертифікати",
    cer_tificate:"Сертифікат",
    cert_img:"зображення",
    save_png:"завантажити",
    cert_del_title: "увага!",
    cert_del_message: "сертифікат буде видалено зі списку. Також буде видалено файл з диска.",
    cert_reload_title: "увага!",
    cert_reload_message: "для застосування змін потрібно вийти і зайти знову в Налаштування сертифіката.",
    cert_font_size:"розмір шрифту",
    cert_font:"Шрифт",
    cert_font_color: "колір шрифту",
    is_program_tasks: [{value: 0, label:"ставити завдання для кожного модуля"},{value: 1, label:"ставити одну загальну задачу"}, {value: 2, label: "не ставити завдання"}],
    program_tasks:"завдання",
    program_task_description:"вам потрібно виконати програму",
    course_passed:"курс пройдено",
    module_complete: "завершити модуль",
    done_success: "успішно!",
    save_program:"Зберегти",
    add_module_s: "додати послідовний модуль",
    add_module_p: "додати паралельний модуль",
    program_message: "вам призначена програма навчання. Перейдіть в додаток'університет/Мої програми'",
    my_programs:"Мої програми",
    publish_schedule_tooltip: "не обрана програма або не задані дати призначення, Керівник, співробітники",
    mod_description:"у плані виконання програми, вам необхідно виконати модуль",
    list_schedules:"призначення",
    add_schedule:"додати призначення",
    one_schedule:"призначення",
    publish_schedule: "зробити призначення",
    dates_schedule:" дати призначення",
    add_students:"співробітники",
    dep_rem_title: "видалити з призначення?",
    dep_rem_mess: "видалити всіх з цією групою (підрозділом)",
    dep_rem_mess1: "видалити цього учасника",
    start_finish: "початок / закінчення",
    selectusers_label:"співробітники",
    selectgroups_label: "групи",
    selectdeps_label: "відділи",
    selecttests_label: "курси / тести",
    selectgroup_title: "Додати групу",
    selectgroup_message: "Виберіть групу",
    courses_tests: "курси-тести",
    students:"навчаються",
    del_schedule_title:"видалення",
    del_schedule_message: "підтвердити видалення призначення",
    del_schedules_message: "підтвердити видалення призначень",
    date_to_short:"закінчення",
    students_comment:"якщо співробітник був доданий через відділ або групу, то видалення такого співробітника спричинить за собою видалення і інших співробітників цього відділу / групи",
    add_department: "Відділ",
    add_group:"група",
    add_main_auditor: "Керівник",
    mod_duration: "тривалість, дн.",
    mod_action: {'hand':"ручне", 'auto':"авто"},
    add_program:"додати програму",
    list_programs: "Список програм",
    one_program:"програма",
    progrm_modules:"модулі",
    requaried_field: "Обов'язкове поле",
    program_name: "назва програми",
    program_description:"Опис програми",
    module_type:"Тип модуля",
    module_type_opt: [{value: 1, label:"Курс"},{value: 2, label:"Тест"}, {value: 3, label:"Урок"}],
    select_module: "виберіть модуль",
    not_found: "нічого не знайдено",
    type_open:" спосіб відкриття модулів",
    type_open_opt: [{value:'P', label:"відкрити все відразу"},{value:'S', label:"відкрити послідовно. Якщо модуль не пройдено, все одно відкривати наступний"}, {value:'H', label:"відкривати послідовно. Якщо модуль не пройдений, не відкривати наступний"}],
    publish_program: "опублікувати програму",
    del_program:"видалити",
    del_program_msg: "ви впевнені, що хочете видалити",
    mod_passed: [{value: '0', label:" немає статусу"}, {value: 'Y', label:"пройдено"}, {value: 'N', label:"не пройдено"}],

    points:"Бали",
    points_plc: "поставте 0 якщо бали нараховуються як задано в налаштуваннях програми",
    for_course_active:"пройдений курс",
    for_course_mod_lb: "спосіб підрахунку",
    for_course_mode:[
      {value: 'sum', label:"розраховувати підсумовуванням балів за модулі"},
      {value: 'hand', label:"Ввести вручну"},
    ],
    for_course_dop_lb: "Бали за уроки/тести в межах курсу",
    for_course_dop:[
      {value: 'top', label: "нараховувати"},
      {value: 'node', label:"не нараховувати"},
    ],
    separ_add: "додати роздільник",
    sep:"роздільник",
    bid_del_message:"Видалити заявку",
    em_count_limit:"Перевищено максимальну кількість учасників",
    em_count:"Кількість учасників. 0-не обмежена",
    eve_attende:"Учасник",
    eve_bid:"Заявка",
    not_published:"Не опубліковано",
    my_events:"Мої події",
    ipr_comp:"компетенція",
    ipr_ind:"індикатори",
    eve_bid_done:"ваша заявка додана. Після розгляду захід буде додано в список і ви станете учасником.",
    eve_new_big_add:"потрібної події немає у списку? Запропонуйте заявку. Після перевірки захід буде додано до списку і ви станете учасником.",
    eve_new_bid_title: "Заявка на нову подію",
    eve_new_bid_done: "ваша заявка додана. Після розгляду захід буде додано в список і ви станете учасником.",
    alert_understood:"зрозуміло",
    eve_new_bid_moder: "надійшла заявка на захід від співробітника",
    em_details:"деталі",
    bid_restore_title: "відновити заявку",
    bid_restore_message: "Вкажіть причину відновлення заявки",
    bid_accepted: "Заявка прийнята",
    bid_declined: "Заявка відхилена, відновити.",
    bind_accept: "прийняти заявку",
    bid_decline_title: "відхилити заявку",
    bid_decline_message: "Вкажіть причину відхилення заявки",
    eve_bids_all: "Додати всіх",
    eve_calend:"у календарі",
    eve_detail: "Детальніше про подію",
    eve_edit: "змінити",
    em_bids:"Заявки",
    eve_bid_lbl: "Підати заявку на подію",
    eve_title: "подати заявку на подію",
    eve_bin_add: "Буду учасником",
    eve_nts:"події",
    text_block: "текстовий блок",
    text_block_name: "назва поля",
    text_block_value:"вміст поля",
    r_o:"лише для читання",
    set_files:"Файли",
    integral_chart:"Інтегральна діаграма",
    view_cards:"Вигляд: картки",
    view_list:"Вигляд: список",
    node_disabled:"Зробити неактивним",
    node_enabled:"Зробити активним",
    download: "Завантажити",
    no_valid_publish: "не задані важливі дані, наприклад, співробітник, Керівник, наставник, компетенції, індикатори чи ні прав для редагування",
    hide_fio: "приховувати дані оцінювачів",
    such_element_exist: "такий елемент вже існує",
    program_course: "/ програма курсу",
    lessons:"Уроки",
    tests:"тести",
    no_lessons: "немає уроків для додавання до курсу",
    no_tests:" немає тестів для додавання до курсу",
    drop_here: "перетягніть сюди уроки та тести",
    reset_tree: "Скинути навігацію",
    reset_true_msg: "видалити всі уроки та тести з курсу?",
    export_all:"Експортувати все",
    imgFiles:"Файли до відповіді",
    upload_title:"Завантажити",
    upload_err:"Помилка завантаження",
    upload_hint:"Виберіть файл на диску або перетягніть його сюди",
    max_file_size:"Розмір файлу не повинен перевищувати {mb} Мб",
    delete_image:"Видалити файл",

    ipr_note:"Вам призначено 'Індивідуальний план розвитку'. Перейдіть в особистий кабінет програми Корпоративний університет.",
    add_evt_title: "подія додана",
    add_evt_msg: "закрити форму додавання заходів і перейти до списку?",
    add_evt_ok:"закрити",
    add_evt_canc: "додати більше",
    res360_published: "результати оцінки 360 опубліковані в Особистому кабінеті оцінюваного",
    rm_rm: "розвиваюча подія",
    rm_dln:"прострочено",
    rm_deadline: "кінцевий термін",
    go_course:"Перейти",
    no_results: "немає даних",
    enter_course_name: "наберіть частину назви для пошуку",
    course_name: "додати курс",
    course_statuses: "статус курсів",
    ipr_courses:"РМ курси",
    ipr_export: "експорт",
    iprset_create_label: "хто може створити",
    iprset_edit_label: "хто може редагувати",
    iprset_read_label: "хто може бачити",
    iprset_status_label: "хто може змінювати статус",
    iprset_role_opt: [{value: 'M', label:"Модератор"}, {value: 'U', label:"співробітник"}, {value: 'N', label:"Наставник"}],

    add_rm: "РМ",
    add_ind:"Індикатор",
    ipr_title: "індивідуальний план розвитку",
    ipr_name:" назва ІПР",
    ipr_close:"закрити",
    ipr_open:"відкрити",
    select_person: "співробітник",
    select_manager: "Керівник",
    select_mentor: "Наставник",
    rm_label: "РМ",
    rm_options:[
      "Ассесмент",
      "Аудіо контент",
      "Вебінар",
      "Воркшоп",
      "Відео контент",
      "Вікторина",
      "Гра",
      "Дистанційний курс",
      "Доповідь",
      "Дослідження",
      "Зустріч",
      "Кейс",
      "Командоутворення",
      "Конкурс",
      "Конференція",
      "Курс",
      "Лекція",
      "Майстер клас",
      "Митап",
      "Наставництво",
      "Оцінка",
      "Подкаст",
      "Проект",
      "Семінар",
      "Сесія",
      "Симуляція",
      "Стажування",
      "Тестування",
      "Тренінг",
      "Універсіада",
      "Фасилітація",
      "Хакатон",
      "-свій варіант -"
    ],
    rm_compleeted:"виконано",
    rm_started:" у процесі",
    rm_new: "не розпочато",
    rm_status:"Статус",
    hand_made: "Ввести вручну",
    comp_name: "назва компетенції",
    ind_name:" назва індикатора",
    del_ind: "Видалити індикатор",

    selectcomps_label: "компетенції",
    selectcomps_title: "додати компетенції",
    selectcomp_message: "виберіть компетенцію",
    selectcomps_message: "виберіть одну або кілька компетенцій",
    selectcomps_add: "додати",
    selectcomps_finish: "закрити",
    selectscale: "Шкала оцінок",
    target_sc: "цільовий рівень",
    current_sc: "поточний рівень",

    del_certs: "сертифікат був видалений в розділі Освіта, видаліть його тут",
    no_certs:"у вас немає жодного завантаженого сертифіката. Додайте їх у розділі 'Освіта'",
    aos_filled: "заповнена",
    anketa_add: "додати анкету",
    for_test:"для тестів",
    for_course: "для курсів",
    for_event:"для заходів",
    aos_copy:"(копія)",
    new_aos_name: "змініть ім'я що б зберегти",
    first_aos_name: "Анкета за замовчуванням",
    aos_edit: "редагування анкети",
    aos_look: "переглянути анкету",
    aos_refresh_title: "завантажити за замовчуванням",
    aos_refresh_message: "ваша анкета буде замінена на анкету за замовчуванням",
    answ_aos: "відповіді",
    answer: "Текст",
    score: "Вага",
    quest_aos: "питання",
    add_answ: "додати відповідь",
    other_answ: "Інше",
    a_o_s: "АОС",
    add_aos: "Додати питання",
    aos: "анкета зворотного зв'язку",
    aos_del: "видалити анкету",

    cert_del: "видалити сертифікат з події?",
    cert_add: "додати сертифікат",
    event_certs: "виберіть сертифікати",
    em_del:"Видалити захід (з календаря теж)",
    em_save: "записати",
    em_clear: "Очистити",
    em_meeting_notify: "надіслати запрошення",
    em_description: "опис події",
    em_meeting_text:" текст запрошення",
    em_color: "колір події",
    em_text_color: "колір текста",
    em_attendees: "учасники",
    em_host: 'відповідальний',
    em_org: "Організатор",
    EMS: "зовнішні та очні заходи",
    em_eventName: "назва події",
    em_common_list: "загальний список",
    em_personas: 'оцінювані',
    em_close: "вибрати",
    em_location:"Тип",
    em_location_opt: ['внутрішнє','зовнішнє'],
    em_vid: "Вид",
    em_vid_opt:[
      "Тренінг",
      'Ассесмент',
      'Аудіо контент',
      'Вебінар',
      'Воркшоп',
      'Відео контент',
      'Вікторина',
      'Гра',
      'Дистанційний курс',
      'Доповідь',
      'Дослідження',
      'Кейс',
      'Командоутворення',
      'Конкурс',
      'Конференція',
      'Курс',
      'Лекція',
      'Майстер клас',
      'Митап',
      'Наставництво',
      'Оцінка',
      'Подкаст',
      'Проект',
      'Семінар',
      'Сесія',
      'Симуляція',
      'Стажування',
      'Тестування',
      'Універсіада',
      'Фасилітація',
      'Хакатон',
    ],
    common_list: "загальний список",
    personas: 'оцінювані',
    exp_comments: "Коментарі",
    no_events: "немає майбутніх заходів",
    em_from_to: "початок і кінець події",

    acc1: "Показувати результати у ЛКК",
    add_section: "додати секцію",
    comps_added:" додані компетенції",
    comps_add: "додати компетенцію",
    attention: "увага",
    del_competence: "видалити компетенцію",
    competencies: 'компетенції',
    all: "Все",
    add_coworkers: 'додати колег',
    coworkers: 'колеги',
    add_slaves: 'додати підлеглих',
    slaves: 'підлеглі',
    add_chief: 'додати керівника',
    chief: 'Керівник',
    new: 'співробітник',
    average_: 'Середнє без участі оцінюваного',
    departments: 'підрозділи',
    add_departments: 'додати підрозділи',
    selectdepartments_title: 'додати підрозділ',
    selectdepartments_message: 'виберіть підрозділ',
    selectdepartments_add: 'додати',
    selectdepartments_finish: 'закрити',

    rep_competence: "компетенція / індикатори",
    rep_indicators: "експерти",
    rep_scores: "оцінки",
    comment: "коментар оцінюваному",
    acc:"опублікувати в особистому кабінеті оцінюваного",
    you_ass_360:"вам призначено оцінку 360. Список всіх оцінок дивіться в особистому кабінеті.",
    del_many_title: "видалення декількох оцінок",
    del_many_mess: "ви хочете видалити кілька позначених оцінок",
    rows_per_page: 'записів на сторінці',
    all_report: 'загальний звіт',
    start_finish: 'початок / закінчення',
    competence: 'компетенція',
    selectusers_title: 'додати співробітника',
    selectuser_message: 'Виберіть користувача',
    selectusers_message: 'виберіть одного або декілька користувачів',
    selectusers_add: 'додати',
    selectusers_finish: 'закрити',
    title: 'Оцінка 360/180',
    goback: 'Повернутися',
    loading: 'Завантаження',
    remove: 'Видалити',
    assessment: 'Оцінка',
    competences: 'Компетенції',
    name: 'Назва',
    date_start: 'Дата початку',
    date_finish: 'Дата закінчення',
    description: 'Опис',
    persona: 'Оцінюваний',
    experts: 'Оцінюючий',
    clear: 'Очистити',
    del: 'Видалити',
    add: 'Додати',
    add_assessment: 'Створити оцінку',
    add_persona: 'Додати оцінюваного',
    add_experts: 'Додати оцінюючих',
    ok: 'Записати',
    reset: 'Скасувати',
    scores: 'Оцінки',
    publish: 'Опублікувати',
    alert_message: 'Не заповнені дані',
    alert_persona: 'Оцінюваний',
    alert_experts: 'Оцінюючі',
    alert_dates: 'Дати',
    alert_competences: 'Компетенції',
    alert_scores: 'Оцінки',
    del_ass_title: 'Видалення оцінки',
    del_ass_mess: 'Видалити оцінку',
    deleteSome: 'Видалити',
    export: 'Експортувати',
    report: 'Звіт',
    del_some_title: 'Видалення',
    del_some_mess: 'Ви хочете видалити оці',
    more: 'Ще',
    chart: 'Результати',
    chart_title: 'Діаграма оцінки 180°/360° для сотрудника',
    refresh: 'Оновити список',
    user: 'Сотрудник',
    clear_scores: 'Видалити відповіді',
    clrscores_title: 'Очищення',
    clrscores_message: 'Всі відповіді будуть видалені з цієї Оцінки. Продовжити?',
    legend: 'Повні назви компетенцій',
    results: 'Результати',
    average: 'Середнє',
    average1: 'Середнє*',
    close_report: 'Закрити звіт',
    assessment_name: 'Назва оцінки',
    action: 'Дія',
    list: 'Повернутися до списку оцінок',
    status: 'Статус',
    published: 'Опубліковано',
    draft: 'Чернетка',
  },
  fr: {
    sign_up_btn_ok: "Créer",
    sign_up_confirm: "Un nouveau portail sera créé. Vous deviendrez l'administrateur principal et pourrez inviter d'autres utilisateurs.",
    is_reg_info: "Cet e-mail est introuvable. Vous pouvez enregistrer un nouveau portail avec et y inviter des utilisateurs.",
    for_prog_dop_lb: "Points pour les cours/leçons/tests à l'intérieur",
    passed_prog: "Programme terminé",
    the_collapsed: "Total de plus de 3 mois",
    the_collapsed_info: "Les entrées dans les détails de plus de trois mois sont regroupées en une seule. Les points sont additionnés dans le processus",
    the_test: "Test",
    the_lesson: "Leçon",
    passed_course: "Cours terminé",
    points_reset: "Réinitialiser tous les points",
    duration_info: "Durée du programme en jours. Calculée en additionnant la durée de tous les modules. Utilisée pour déterminer les délais des affectations et des tâches.",
    the_programs: "Programmes",
    program_view_btn: "Responsable du programme non sélectionné",
    the_program_info: "En cliquant sur le bouton 'Aller à', vous vous assignerez le programme pour le compléter. Vous pouvez trouver l'affectation dans la section 'Mes programmes'.",
    go_to_program: "Aller à",
    the_program: "Programme",
    clear_program: "Suppression du programme. Seuls les programmes non publiés peuvent être supprimés.",
    uncheck_all_uscheds: "Décocher tout",
    check_all_uscheds: "Cocher tout",
    is_archive: "Archive",
    from_archive: "Restaurer de l'archive",
    to_archive: "Déplacer vers l'archive",
    program_archived: "Déplacé vers l'archive",
    program_published: "Programme publié",
    add_auditors: "Responsables du module",
    modules_auditors: "Édition des observateurs et des délais des modules",
    add_main_auditor_info: "Le responsable du programme est un observateur dans la tâche. Il sera assigné à chaque module, sauf si un autre observateur est sélectionné dans les modules.",
    dates_schedule_info: "Les dates sont informatives et utilisées pour définir les délais des tâches",
    clear_schedule: "Suppression de l'affectation. Seules les affectations non publiées peuvent être supprimées.",
    program_updated: "Programme enregistré",
    program_added: "Programme ajouté",
    program_scheds_done: "Affectations effectuées",
    such_module_exist: "Module déjà sélectionné",
    such_user_exist: "Employé déjà sélectionné",
    duration_hint: "Calculé automatiquement",
    degree_add_block_info: "Sélectionnez un bloc de compétences dans la liste et cliquez sur +. Ses compétences seront ajoutées à l'évaluation 360.",
    add_block_label: "Sélectionner un bloc de compétences",
    slaves_add: "Ajout automatique des employés du département",
    slaves_add_byhands: "Ajout manuel",
    chief_add: "Ajout automatique des chefs de département de l'employé",
    chief_add_byhands: "Ajout manuel",
    del_subpassw_title: "Suppression du mot de passe",
    del_subpassw_message: "Le mot de passe sera effacé, l'utilisateur devra le saisir à nouveau lors de la connexion",
    degree_msg_sended: "Notifications envoyées",
    degree_checkall_btn: "Tout cocher",
    degree_clear_btn: "Supprimer les réponses",
    degree_clear_msg: "Les évaluations (réponses) des évaluateurs sélectionnés seront supprimées. Ils devront effectuer les évaluations à nouveau.",
    degree_status_student: {
     1: "Aucune évaluation d'expert",
     2: "Aucun expert assigné"
    },
    degree_status_expert: {
     0: "Toutes les évaluations données",
     2: "Aucune évaluation pour une ou plusieurs compétences",
     3: "Toutes les évaluations ne sont pas données"
    },
    degree_status_btn: "Envoyer une notification",
    degree_status_msg: "Vous participez à l'évaluation des employés en tant qu'évalué. Créez une liste d'évaluateurs et sélectionnez les approbateurs pour cette liste dans la section 'Mes évaluations' du compte personnel de l'application 'Université'.",
    degree_fill_msg: "Vous participez à l'évaluation des employés en tant qu'évaluateur. Évaluez les employés dans la section 'Mes évaluations' du compte personnel de l'application 'Université'.",
    degree_uagree_msg: "Vous avez été nommé approbateur dans l'évaluation 360. Allez dans la section 'Mes évaluations' du compte personnel de l'application 'Université'.",
    degree_status: {
     0: "Approuvé",
     1: "Aucun expert assigné",
     2: "Aucun approbateur assigné",
     3: "Non approuvé"
    },
    Degree360StatusList: "Statuts de formation",
    Degree360StatusFill: "Statuts de remplissage",
    change_password: "Changer le mot de passe",
    change_password_m: "Changer les mots de passe des employés",
    change_password_hint: "Entrez le nouveau mot de passe et la confirmation. Après avoir changé le mot de passe, connectez-vous au système.",
    change_password_hint_m: "Indiquez l'employé, le mot de passe sera généré automatiquement.",
    change_password_done: "Mot de passe changé, utilisez-le pour la prochaine connexion.",
    change_password_fail: "Une erreur s'est produite, le mot de passe n'a pas été changé. Réessayez ou contactez l'administrateur.",
    change_password_done_m: "Mot de passe changé, copiez-le et transmettez-le à l'employé.",
    clear_profile: "Supprimer le profil",
    clear_profile_msg: "Le profil sera supprimé, mais l'email et le mot de passe pour la connexion seront conservés. Pour créer un nouveau profil, vous devrez vous connecter au système. Êtes-vous sûr?",
    clear_profile_done_m: "Profil supprimé",
    add_head: "Manager",
    add_users: "Employé",
    struct_reset_msg: "Voulez-vous vraiment réinitialiser la structure?",
    struct_reset: "Réinitialiser la structure",
    department_add: "Département",
    struct_company: "/Entreprise",
    struct_department: "Département",
    company_structure: "Entreprise",
    last_name_need: "Le prénom et le nom doivent être remplis!",

    degree_checkall_btn: "Tout cocher",
    sign_up_for_an_account: "Connectez-vous ou inscrivez-vous pour un compte",
    auth_email: "Email",
    auth_password: "Mot de passe",
    auth_password2: "Répétez le mot de passe",
    sign_up: "S'inscrire",
    sign_in: "Se connecter",
    email_invalid: "Email invalide",
    password_invalid: "Mot de passe incorrect",
    email_exist: "Email trouvé",
    email_ok: "Email disponible",
    auth_password_match: "Mot de passe correspondant",
    auth_password_not_match: "Les mots de passe ne correspondent pas",
    invitations: "Invitations",
    link_invite: "Lien d'auto-inscription",
    reminders: "Rappels",
    notifications: "Notifications",
    subac_validate_info: "Utilisez uniquement des lettres latines et des chiffres pour le nom du sous-compte.",
    promo_1: "Étendez les capacités de votre application. Achetez un abonnement pour un thème, un disque ou des sous-comptes dans la section Paramètres:Mon Plan.",
    prog_filter: "Filtre",
    prog_filter_all: "tous les programmes",
    prog_filter_tgrp: "Modèles pour les groupes",
    prog_filter_tuser: "Pour les nouveaux employés",
    prog_is_template:"ceci est un modèle à attribuer",
    prog_is_template_newuser:" nouveaux employés",
    prog_is_template_grp:"pour les groupes",
    prog_info_grp: "affectation Permanente. Lorsqu'un employé est Ajouté à un groupe, il est automatiquement affecté.",
    prog_info_newusers:"lorsqu'un nouvel employé est Ajouté au portail Bitrix24, il sera automatiquement affecté.",
    im_agreemer:"je suis un évaluateur",
    agree_degree360_mess:"Négocier une liste d'évaluateurs pour Évaluer 360?",
    decline_degree360_mess: "retourner La liste des évaluateurs pour l'Évaluation 360?",
    degree360_all_agreed:"Les listes d'évaluateurs sont cohérentes pour l'évaluation 360",
    degree_valid_1:"nom d'Évaluation 360 Non spécifié",
    degree_valid_2:"aucune date spécifiée",
    degree_valid_3:"aucune évaluation N'est spécifiée pour un ou plusieurs évaluateurs",
    degree_valid_4:"aucune compétence définie",
    degree_valid_5: "pas d'échelle d'Évaluation 360",
    degree_valid_6:"les Évaluateurs indépendants Ne sont pas cohérents",
    pdf_download: "Télécharger le fichier",
    pdf_build: "Générer un fichier PDF",
    scale_value: "Valeur",
    scale_name: "Nom",
    scale_desc: "Description",
    degree360_all_agreed:"Les listes d'évaluateurs sont cohérentes pour l'évaluation 360",
    degree_valid_1:"nom d'Évaluation 360 Non spécifié",
    degree_valid_2:"aucune date spécifiée",
    degree_valid_3:"aucune évaluation N'est spécifiée pour un ou plusieurs évaluateurs",
    degree_valid_4:"aucune compétence définie",
    degree_valid_5: "pas d'échelle d'Évaluation 360",
    degree_valid_6:"les Évaluateurs indépendants Ne sont pas cohérents",
    degree360_self_message:"vous Avez une tâche pour créer une liste d'évaluateurs pour l'Évaluation 360",
    clear_agreed:"Supprimer le correspondant",
    students_clear:"toutes les évaluations Avec leurs Évaluateurs seront supprimées",
    add_degree360_info:"le Score 360 est entièrement généré par le modérateur",
    add_task_degree360_info: "La liste des évaluateurs peut être formée de deux manières : par le modérateur ; par les évalués. Lorsqu'elle est activée, la liste sera formée par les évalués. Après la publication de l'évaluation, les évalués auront une tâche dans leur compte personnel dans la section Mes évaluations pour créer une liste et sélectionner des approbateurs. L'évaluation commencera lorsque toutes les listes seront formées.",
    listDegree360: "Scores 360",
    eve_and_bad_moder:"Demande de participation d'un employé à un événement",
    profiles_lb: "Profils",
    profiles_plc: "le Profil contient plusieurs compétences",
    add_from_comps: "Sélectionner une compétence",
    incomplete_data:"compétence Incomplète, indicateurs manquants",
    experts_clear: "tous les employés évaluateurs seront supprimés",
    degree_tasks: [{value: 0, label:"définir des tâches individuellement"},{value: 1, label:"définir une tâche commune"},{value: 2, label:" ne pas définir de tâches"}],
    old_assessments: "ancien format d'évaluation",
    degree_task_name:"Entrez le nom du travail",
    degree_task_description:"Entrez la Description du travail",
    the_task_360: "Mission",
    tasks_360: "Tâches",
    add_task_degree360: "Création d'une liste d'évaluateurs",
    add_degree360: "Ajouter une évaluation 360",
    stud_id: "ID d'Évaluation",
    its_my_degree:"je suis Évalué",
    degree_readonly_info: "Des affectations ont déjà été faites pour l'évaluation 360, certaines actions avec l'évaluation ne peuvent pas être effectuées. Par exemple, la suppression des personnes évaluées, des évaluateurs, la suppression et l'ajout de compétences, le changement de dates, l'activation de l'auto-évaluation, le changement de la manière dont les tâches sont définies.",
    degree360_alert: "la Demande a déjà été acceptée, elle ne peut pas être modifiée.",
    degree360_agreeID: "Concordance",
    degree360_agreed:"Convenu",
    degree360_wait_agreed:"en Attente d'approbation",
    degree360_self_title:"Demandes d'Évaluation 360",
    degree360_self:"Demande d'Évaluation 360",
    degree_self:"Créer une liste d'évaluateurs",
    degree_self_info:"pour postuler, choisissez un Évaluateur et un responsable de l'évaluation 360",
    degree_start_finish_info: "les Dates sont utilisées et n'affectent pas l'affichage de l'évaluation.",
    agree_not_valid:"toutes les données ne sont pas remplies. S'il vous plaît vérifier le titre, les dates, les notes, les Notes.",
    degree_add_scale_info:"Sélectionnez une échelle d'évaluation dans la liste pour l'ajouter à l'Évaluation 360",
    degree_add_comp_info:"Sélectionnez une compétence dans la liste, puis cliquez sur < + > pour ajouter une compétence à l'évaluation 360",
    degree_adb_prof_info: "Sélectionnez un profil dans la liste et cliquez sur +. Ses compétences seront ajoutées à l'Évaluation 360",
    degree_add_experts_info:"Ajouter des Évaluateurs en utilisant les boutons ci-dessous",
    degree_add_students_info:"Ajoutez les employés Évalués en utilisant les boutons ci-dessous",
    degree_agree_degree360_info:"Dans la position gauche de la case à cocher, vous pouvez enregistrer la Note de travail. Position de droite - l'affectation sera faite lors de l'enregistrement.",
    degree_agree_user_info: "une négociation est nécessaire pour attribuer une Évaluation. Sélectionnez un collaborateur correspondant.",
    degree_hide_fio_info:"par défaut, l'évaluateur verra les données des évaluateurs. Lors de l'activation, les données des évaluateurs seront masquées.",
    degree_pub_lk_info: "Publier les résultats de l'évaluation dans le tableau de bord de l'évaluation après que tous les évaluateurs ont évalué.",
    degree_task_info: "la Tâche de Bitrix est d'évaluer. Il est placé par l'évaluateur.",
    show_options_block:" paramètres d'Évaluation 360",
    show_users_block:"Listes à évaluer et à évaluer",
    show_comps_block:"Compétences, indicateurs, échelle d'évaluation",
    degree360_aver_title:"résultats des évaluations (moyennes)",
    degree360_stat_title:"nombre de répondants ayant participé à l'évaluation",
    degree360_aver_col_: "Pratique",
    degree360_stat_ret0:"nombre de personnes",
    degree360_stat_col_:"groupes de répondants",
    degree360_stat_col0: "auto-Évaluation",
    degree360_stat_col1: "Superviseur",
    degree360_stat_col2: "Collègues",
    degree360_stat_col3: "Subordonnés",
    degree360_stat_col4: "tous les répondants<br>(sans auto-évaluation)",
    degree360_stat_col5: "tous les répondants",
    degree360_description:"vous devez effectuer une Évaluation 360",
    degree360_message: "vous avez reçu une Note de 360. Aller à l'application 'Université / mes notes'",
    you_agree_360: "vous assignez un accord dans l'Évaluation 360.  Accédez à l'application Université pour la négociation.",
    del_student:"voulez-vous supprimer l'évaluable",
    do_schedules:"l'Évaluation 360 sera attribuée à l'évaluateur",
    no_experts:"aucun évaluateur spécifié",
    agree_user: "correspondant",
    agree_degree360: "Négocier",
    decline_degree360: "Retourner",
    decline_degree360_info:"si vous avez modifié le statut de la demande, enregistrez-la",
    publish_degree360: "Publier une évaluation 360",
    del_degree360: "Suppression de l'évaluation 360",
    del_degree360_msg:"voulez-vous vraiment supprimer l'évaluation 360",
    degree_name:"Entrez le nom de l'évaluation",
    add_prof_label:"Sélectionner un profil",
    scale_plc:"Sélectionner l'échelle",
    add_prof: "Ajouter aux compétences",
    save_comp: "Enregistrer",
    close_comp: "Fermer",
    program_readonly:"Bu program için atamalar yapıldı, bu nedenle değiştirilemez. Programı değiştirmek ve yeni atamalar için programlar listesinde programın bir kopyasını oluşturabilirsiniz.",
    program_info: "le Module 'Programme' est conçu pour former des programmes de développement intégrés. Par exemple, comme l'Adaptation des nouveaux arrivants, la Formation de la réserve de personnel, etc."+
      "<br>Les programmes peuvent être formés à partir des éléments suivants: cours, tests, leçons (par exemple, ajouter un fichier PDF, un règlement, une présentation, des liens, du matériel, longrid, etc.), activités (par exemple, créer un événement réunion avec un Superviseur)."+
      "<br>Les programmes sont formés à partir d'éléments en parallèle et séquentiellement. Les éléments successifs forment les niveaux (cela peut être un Jour,Une étape d'apprentissage). Dans un niveau peut être n'importe quel nombre d'éléments, ce qui signifie qu'à ce niveau, ils doivent tous passer."+
      "<br>Après avoir créé un programme, vous pouvez l'affecter à des employés, des groupes (lorsque vous ajoutez un employé à un groupe, il sera automatiquement affecté au programme). Vous pouvez modifier la liste des employés: lors de l'ajout - l'employé sera affecté à un programme, si vous supprimez - il continuera à être présent dans les rapports."+
      "<br>Le programme désigné ne peut pas être changé.",
    copy_program:"Copier le programme",
    module_level: "Niveau",
    eve_and_bad_moder:"Demande de participation d'un employé à un événement",
    is_answered:"il y a des réponses",
    check_all: "Cocher toutes les notes",
    check_group:"Marquer le groupe",
    check_one:"Marquer la ligne",
    rep_date: "date",
    rep_type:"Pour quoi",
    the_open: "question Ouverte",
    the_event: "Événement",
    the_360: "Score 360",
    the_aos: "AOS",
    the_rate: "Évaluation du cours/test",
    the_comment: "Commentaire",
    the_ipr: "Ypres",
    the_Rc: "Évaluation du cours",
    the_Rt:"Évaluation du test",
    the_course: "Cours",
    write_off: "Annulation",
    not_in_sched:"Manquant dans l'affectation du programme",
    sub_alert:"ce compte contient des sous-comptes, mais leur limite est épuisée. Contactez le modérateur de l'application",
    sub_limit_exceeded: "Limite de sous-comptes épuisée. Allez dans paramètres / mon tarif pour augmenter la limite",
    sub_login_already:"un tel sous-compte existe déjà",
    sub_accounts: "Subaccounts",
    native_add: "Ajouter un compte. Disponible uniquement pour l'administrateur du portail",
    sub_add: "Ajouter un sous-compte",
    del_subac_title: "Suppression d'un sous-compte",
    del_subac_message: "le sous-Compte sera supprimé de la liste. Êtes-vous sûr?",
    del_sub_title:"Supprimer un compte",
    del_sub_message: "le Compte sera supprimé de la liste avec ses sous-comptes. Êtes-vous sûr?",
    self_sign_up: "auto-inscription",
    sub_acc_title: "Autorisation",
    sub_acc_login: "Login",
    sub_acc_password:"mot de Passe",
    sub_acc_password2: "Répéter le mot de passe",
    sub_acc_recv:"Vos données",
    sub_acc_first_name: "Nom",
    sub_acc_last_name: "Nom de famille",
    sub_acc_middle_name:"Patronyme",
    sub_acc_position:"Position",
    sub_acc_login_not_found:"Connexion introuvable, inscription requise",
    sub_acc_save: "Enregistrer",
    sub_acc_ok: "Connexion",
    sub_acc_passwords_not_match:"les mots de Passe ne correspondent pas",
    sub_acc_password_fail:"mot de passe Incorrect",
    sub_acc_valid_recv:"Remplissez les détails",
    sub_acc_recv: "Modifier les données",
    sub_important_info: "Informations importantes",
    sub_info: "<ol>"+
     "<li><b>Ajouter un compte.</b><br>"+
     "Conditions importantes :</li>"+
     "<ul><li>Ceci est un compte Bitrix24 auquel les sous-comptes de l'Université seront liés.</li>"+
     "<li>Seul l'administrateur du portail Bitrix24 peut créer un compte. Pas le modérateur de l'application.</li>"+
     "<li>Le compte ne peut pas être un Administrateur Bitrix24 ou un modérateur de l'application Université.</li></ul>"+
     "<li><b>Sélectionnez la méthode d'enregistrement des sous-comptes dans l'application :</b></li>"+
     "<ul><li><b>Indépendamment.</b> Dans ce cas, l'administrateur du portail doit uniquement saisir les identifiants et informer ces identifiants aux sous-comptes. Lors de la connexion, les sous-comptes saisiront leur identifiant, puis l'application leur demandera de remplir les données (nom complet et poste) et de créer un mot de passe.</li>"+
     "<li><b>Par l'administrateur.</b> Dans ce cas, l'administrateur saisit l'identifiant et remplit toutes les données (nom complet et poste), puis fournit les identifiants aux sous-comptes. Lors de la connexion, le sous-compte sera invité à créer un mot de passe. Les autres données (nom complet et poste) seront déjà remplies par l'Administrateur.</li></ul>"+
     "<li><b>À chaque connexion à l'application, le sous-compte devra saisir son identifiant et son mot de passe.</b></li>"+
     "</ol>",
     cert_format:"Format de page",
    cert_name: "nom du certificat",
    cert_save: "Enregistrer le certificat",
    cert_new: "Ajouter un certificat",
    cert_list: "liste des Certificats",
    clear_form: "nouveau certificat",
    cert_remove:"Supprimer de la liste",
    cert_default: "Par défaut",
    cert_scale: "Échelle",
    cert_opts_fio: "nom d'utilisateur",
    cert_opts_pos: "Poste",
    cert_opts_name:"nom du test/cours",
    cert_opts_score:"score de Passage",
    cert_opts_date:"date de passage",
    cert_opts_text:"texte Supplémentaire",
    cert_dark_mode:"fond Sombre",
    cert_orient_a:"Paysage",
    cert_orient_p: "Portrait",
    certs: "Certificats",
    cer_tificate: "Certificat",
    cert_img: "Image",
    save_png: "Télécharger",
    cert_del_title: "Attention!",
    cert_del_message: "le Certificat sera supprimé de la liste. Le fichier sera également supprimé du disque.",
    cert_reload_title: "Attention!",
    cert_reload_message: "pour appliquer les modifications, vous devez vous déconnecter et revenir aux paramètres du certificat.",
    cert_font_size:"taille de la police",
    cert_font: "Police",
    cert_font_color:"couleur de police",
    is_program_tasks: [{value: 0, label:"définir des tâches pour chaque module"}, {value:1, label:"définir une tâche commune"},{value:2, label:" ne pas définir de tâches"}],
    program_tasks: "Tâches",
    program_task_description:"vous devez exécuter le programme",
    course_passed:"Cours terminé",
    module_complete: "Terminer le module",
    done_success: "Réussi!",
    save_program: "Enregistrer",
    add_module_s: "Ajouter un module série",
    add_module_p: "Ajouter un module parallèle",
    program_message:"vous avez reçu un programme de formation. Accédez à l'application 'Université / mes programmes'",
    my_programs: "mes programmes",
    publish_schedule_tooltip:"aucun programme n'a été sélectionné ou aucune date d'affectation, superviseur, employés n'a été spécifiée",
    mod_description: "Dans le plan d'exécution du programme, vous devez exécuter le module",
    list_schedules: "Destination",
    add_schedule: "Ajouter une affectation",
    one_schedule: "Affectation",
    publish_schedule:"Faire une affectation",
    dates_schedule: "dates d'affectation",
    add_students: "Employés",
    dep_rem_title: "Supprimer de la destination?",
    dep_rem_mess:"Supprimer tout le monde avec ce groupe (subdivision)",
    dep_rem_mess1:"Supprimer ce membre",
    start_finish: "Début/fin",
    selectusers_label: "Employés",
    selectgroups_label: "Groupes",
    selectdeps_label: "Départements",
    selecttests_label: "cours / tests",
    selectgroup_title: "Ajouter un groupe",
    selectgroup_message: "Sélectionnez un groupe",
    courses_tests: "Cours-tests",
    students: "Apprenants",
    del_schedule_title: "Suppression",
    del_schedule_message:"Confirmer la suppression de la destination",
    del_schedules_message:"Confirmez la suppression des affectations",
    date_to_short: "fin",
    students_comment: "si un employé a été Ajouté par le biais d'un département ou d'un groupe, la suppression d'un tel employé entraînera la suppression d'autres employés de ce département / groupe.",
    add_department: "Département",
    add_group: "Groupe",
    add_main_auditor: "Superviseur",
    mod_duration: "Durée, jours.",
    mod_action: {'hand':" manuel", 'auto':"auto"},
    add_program: "Ajouter un programme",
    list_programs:"Liste des programmes",
    one_program: "Programme",
    progrm_modules: "Modules",
    requaried_field: "champ Obligatoire",
    program_name: "nom du programme",
    program_description: "Description du programme",
    module_type: "type de module",
    module_type_opt: [{value: 1, label: "Cours"}, {value:2, label:"Test"},{value:3, label:"Leçon"}],
    select_module: "Sélectionnez un module",
    not_found: "rien trouvé",
    type_open: "méthode d'ouverture des modules",
    type_open_opt: [{value:'P', label:"tout Ouvrir en même temps"},{value:'S', label:"Ouvrir séquentiellement. Si le module échoue, ouvrez toujours le suivant"}, {value:' H', label:"ouvrir séquentiellement. Si le module échoue, ne pas ouvrir le suivi"}],
    publish_program:"Publier un programme",
    del_program: "Supprimer",
    del_program_msg:"vous êtes sûr de vouloir supprimer",
    mod_passed: [{value: '0', label:"aucun statut"}, {value:'Y', label:"Réussi"},{value:'N', label:"Échoué"}],

    points:"Résultats",
    points_plc:"mettez 0 si les points sont attribués comme défini dans les paramètres de l'application",
    for_course_active:"cours Terminé",
    for_course_mod_lb:"méthode de comptage",
    for_course_mode:[
      {value:' sum', label:"Calculer la somme des points par modules"},
      {value: 'hand', label:"Entrer manuellement"},
    ],
    for_course_dop_lb: "Points pour les leçons/tests dans le cadre du cours",
    for_course_dop:[
      {value: 'top', label: "Cumul"},
      {value: 'node', label:"Non crédité"},
    ],
    separ_add: "Ajouter un séparateur",
    sep: "Séparateur",
    bid_del_message: "Supprimer l'enchère",
    em_count_limit: "Nombre maximum de participants dépassé",
    em_count:"Nombre de participants. 0-illimité",
    eve_attende:"Participant",
    eve_bid:"Enchérir",
    not_published: "Non publié",
    my_events: "Mes événements",
    ipr_comp: "Compétence",
    ipr_ind: "Indicateurs",
    eve_bid_done: "votre candidature a été ajoutée. Après examen, vous deviendrez un participant.",
    eve_new_big_add:"l'événement Souhaité n'est-il pas répertorié? Proposez une offre. Après vérification, l'événement sera Ajouté à la liste et vous deviendrez membre.",
    eve_new_bid_title:"Demande de nouvel événement",
    eve_new_bid_done: "votre candidature a été ajoutée. Après avoir examiné l'événement sera Ajouté à la liste et vous deviendrez un participant.",
    alert_understood: "Clair",
    eve_new_bid_moder:"demande d'événement Reçue d'un employé",
    em_details: "Détails",
    bid_restore_title:"Restaurer la demande",
    bid_restore_message:"Indiquez la raison de la restauration de la demande",
    bid_accepted:"Demande acceptée",
    bid_declined: "Demande rejetée, restaurer.",
    bind_accept:"Accepter la demande",
    bid_decline_title:"rejeter la demande",
    bid_decline_message:"Indiquez la raison du rejet de la demande",
    eve_bids_all:"Ajouter tout le monde",
    eve_calend: "dans le calendrier",
    eve_detail:"en Savoir plus sur l'événement",
    eve_edit: "Modifier",
    em_bids: "Candidatures",
    eve_bid_lbl: "postuler",
    eve_title: "postuler à un événement",
    eve_bin_add: "Je serai membre",
    eve_nts: "Événements",
    text_block: "bloc de Texte",
    text_block_name:"nom du champ",
    text_block_value:"Contenu du champ",
    r_o: "lecture seule",
    set_files:"Fichiers",
    integral_chart:"Graphique intégral",
    view_cards:"Vue : cartes",
    view_list:"Vue: liste",
    node_disabled:"Rendre inactif",
    node_enabled:"Rendre actif",
    download: "Télecharger",
    no_valid_publish:"Aucune donnée importante n'a été définie, par exemple un employé, un superviseur, un mentor, des compétences, des indicateurs ou il n'y a pas de droits d'édition",
    hide_fio:"Masquer les données des évaluateurs",
    such_element_exist:"un tel élément existe déjà",
    program_course: "/ Programme de cours",
    lessons: "Leçons",
    tests: "Tests",
    no_lessons:"pas de leçons à ajouter au cours",
    no_tests:"aucun test à ajouter au cours",
    drop_here: "Faites glisser les leçons et les tests ici",
    reset_tree: "Réinitialiser la navigation",
    reset_true_msg: "Supprimer toutes les leçons et tous les tests du cours?",
    export_all:"Tout exporter",
    imgFiles:"Fichiers auxquels répondre",
    upload_title:"Télécharger",
    upload_err:"Le téléchargement a échoué",
    upload_hint:"Sélectionnez un fichier sur le disque ou faites-le glisser et déposez-le ici",
    max_file_size:"La taille du fichier ne doit pas dépasser {mb} Mo",
    delete_image:"Supprimer le fichier",

    ipr_note:"On vous a attribué un 'Plan de Développement Individuel'. Accédez à votre application University.",
    add_evt_title: "Événement Ajouté",
    add_evt_msg: "Fermer le formulaire d'ajout d'événements et accéder à la liste?",
    add_evt_ok: "Fermer",
    add_evt_canc: "Ajouter plus",
    res360_published:"les Résultats de l'évaluation 360 sont publiés dans le Compte personnel de l'évaluation",
    rm_rm: "événement de Développement",
    rm_dln: "en Retard",
    rm_deadline:"date Limite",
    go_course: "Aller",
    no_results: "aucune donnée",
    enter_course_name:"Saisissez une partie du nom à rechercher",
    course_name: "Ajouter un cours",
    course_statuses: "Statut des cours",
    ipr_courses: "cours RM",
    ipr_export: "Exporter",
    iprset_create_label:"Qui peut créer",
    iprset_edit_label:"qui peut modifier",
    iprset_read_label:"Qui peut voir",
    iprset_status_label:"Qui peut changer le statut",
    iprset_role_opt: [{value:'M', label:"modérateur"},{value:'U', label:"Employé"},{value:'N', label:"Mentor"}],

    add_rm: "RM",
    add_ind: "Indicateur",
    ipr_title: "plan de développement Individuel",
    ipr_name: "Nom de l'IPR",
    ipr_close: "Fermer",
    ipr_open: "Ouvrir",
    select_person: "Employé",
    select_manager: "Superviseur",
    select_mentor: "Mentor",
    rm_label: "RM",
    rm_options:[
      "Assesment",
      "Attaché case",
      "Concours",
      "Conférence",
      "Conférence",
      "Contenu audio",
      "Contenu vidéo",
      "Cours à distance",
      "Facilitation",
      "Hackathon",
      "Jeu",
      "Jeux universitaires",
      "Master class",
      "Mitap",
      "Podcast",
      "Projet",
      "Quiz",
      "Rapport",
      "Rencontre",
      "Session",
      "Simulation",
      "Stage",
      "Séminaire",
      "Taux",
      "Team building",
      "Training",
      "Tutorat",
      "Webinaire",
      "Workshop",
      "Étalonnage",
      "Étude",
      "Évaluation",
      "-votre option-"
    ],
    rm_compleeted:"Terminé",
    rm_started:"en cours",
    rm_new: "non démarré",
    rm_status: "Statut",
    hand_made: "Entrer manuellement",
    comp_name: "nom de la compétence",
    ind_name: "nom de l'indicateur",
    del_ind: "Supprimer l'indicateur",

    selectcomps_label: "Compétences",
    selectcomps_title: "Ajouter des compétences",
    selectcomp_message: "Sélectionnez une compétence",
    selectcomps_message: "Sélectionnez une ou plusieurs compétences",
    selectcomps_add: "Ajouter",
    selectcomps_finish: "Fermer",
    selectscale: "Échelle d'évaluation",
    target_sc: "niveau Cible",
    current_sc: "niveau Actuel",

    del_certs:"le Certificat a été supprimé dans la section Éducation, supprimez-le ici",
    no_certs: "vous n'Avez aucun certificat Téléchargé. Ajoutez-les dans la section 'éducation'",
    aos_filled:"rempli",
    anketa_add:"Ajouter un questionnaire",
    for_test: "pour les tests",
    for_course: "pour les cours",
    for_event: "pour les événements",
    aos_copy: "(Copie)",
    new_aos_name:"Changez le nom pour enregistrer",
    first_aos_name:"Questionnaire par défaut",
    aos_edit:"Modifier le questionnaire",
    aos_look:"Voir le questionnaire",
    aos_refresh_title:"Charger par défaut",
    aos_refresh_message:"votre questionnaire sera remplacé par le questionnaire par défaut",
    answ_aos: "Réponses",
    answer: "Texte",
    score: "Poids",
    quest_aos: "Questions",
    add_answ: "Ajouter une réponse",
    other_answ: "Autre",
    a_o_s: "AOS",
    add_aos: "Ajouter une question",
    aos: "Questionnaire de rétroaction",
    aos_del: "Supprimer le questionnaire",

    cert_del: "Supprimer le certificat de l'événement?",
    cert_add: "Ajouter un certificat",
    event_certs: "Sélectionnez les Certificats",
    em_del: "Supprimer un événement (du calendrier également)",
    em_save: "Écrire",
    em_clear: "Effacer",
    em_meeting_notify:"Envoyer des invitations",
    em_description: "Description de l'événement",
    em_meeting_text: "texte d'invitation",
    em_color: "couleur de l'événement",
    em_text_color:"couleur de tects",
    em_attendees: "Membres",
    em_host: 'Responsable',
    em_org: "Organisateur",
    em_title:"activités Extérieures et extérieures",
    em_eventName:"nom de l'événement",
    em_common_list: "liste Partagée",
    em_personas: 'Évalué',
    em_close: "choisir",
    em_location: "Type",
    em_location_opt: ['Interne', 'Externe'],
  em_vid: "Vue",
  em_vid_opt:[
  'Assesment',
  'Atelier',
  'Case',
  'Concours',
  'Conférence',
  'Conférence',
  'Contenu audio',
  'Contenu vidéo',
  'Cours à distance',
  'Facilitation',
  'Hackathon',
  'Jeu',
  'Master class',
  'Mentorat',
  'Mitap',
  'Podcast',
  'Projet',
  'Quiz',
  'Rapport',
  'Session',
  'Simulation',
  'Stage',
  'Taux',
  'Team building',
  'Test',
  'Training',
  'Universiade',
    'Webinaire',
    'Workshop',
    'Étude',
    'Évaluation',
],
  common_list: "liste Commune",
  personas: 'Classé',
  exp_comments: "Commentaires",
  no_events:"pas d'événements à venir",
    em_from_to: "Début et fin de l'événement",
    acc1: "Afficher les résultats dans le compte personnelC",
    add_section: "Ajouter une section",
    comps_added: "compétences Ajoutées",
    comps_add: "Ajouter une compétence",
    attention: "Attention",
    del_competence:"Supprimer la compétence",
    competencies: 'Compétences',
    all: "All",
    add_coworkers: 'Ajouter des collègues',
    coworkers: 'Collègues',
    add_slaves: 'Ajouter des subordonnés',
    slaves: 'Esclaves',
    add_chief: 'Ajouter un superviseur',
    chief: 'Chef',
    new: 'Employé',
    average_: 'Moyenne non évaluée',
    departments: 'Subdivisions',
    add_departments: 'Ajouter des subdivisions',
    selectdepartments_title: 'Ajouter une subdivision',
    selectdepartments_message: "Sélectionnez l'unité d'organisation",
    selectdepartments_add: 'Ajouter',
    selectdepartments_finish: 'Fermer',

    rep_competence: "Compétence / Indicateurs",
    rep_indicators: "Experts",
    rep_scores: "Évaluations",
    comment: "Commentaire à évaluer",
    acc: "Publier dans votre compte personnel",
    you_ass_360: "vous avez reçu une Note de 360. Voir la liste de toutes les évaluations dans votre compte personnel.",
    del_many_title:"Suppression de plusieurs évaluations",
    del_many_mess:"vous voulez supprimer plusieurs notes marquées",
    rows_per_page: 'Entrées par page',
    all_report: 'rapport Général',
    start_finish: 'Début/fin',
    competence: 'Compétence',
    selectusers_title: 'Ajouter un utilisateur',
    selectuser_message: 'Sélectionner un utilisateur ',
    selectusers_message: 'Sélectionner un ou plusieurs utilisateurs',
    selectusers_add: 'Ajouter',
    selectusers_finish: 'Fermer',
    title: 'Évaluation 360/180',
    goback: 'Retour',
    loading: 'Chargement',
    remove: 'Supprimer',
    assessment: 'Évaluation',
    competences: 'Compétences',
    name: 'Nom',
    date_start: 'Date de début',
    date_finish: 'Date de fin',
    description: 'Description',
    persona: 'En cours d’évaluation',
    experts: 'Participants',
    clear: 'Effacer',
    del: 'Supprimer',
    add: 'Ajouter',
    add_assessment: 'Lancer une évaluation',
    add_persona: 'Ajouter une personne à évaluer',
    add_experts: 'Ajouter des participants',
    ok: 'OK',
    reset: 'Annuler',
    scores: 'Évaluations',
    publish: 'Publier',
    alert_message: 'Donnée à compléter',
    alert_persona: 'En cours d’évaluation',
    alert_experts: 'Participants',
    alert_dates: 'Dates',
    alert_competences: 'Compétences',
    alert_scores: 'Évaluations',
    del_ass_title: 'Supprimer la note',
    del_ass_mess: 'Supprimer la note',
    deleteSome: 'Supprimer',
    export: 'Exporter',
    report: 'Rapport',
    del_some_title: 'Suppression',
    del_some_mess: "Vous voulez supprimer l'évaluation",
    more: 'Plus',
    chart: 'Résultats',
    chart_title: 'Tableau d’évaluation 180°/360° par utilisateur',
    refresh: 'Mettre à jour la liste',
    user: 'Utilisateur',
    clear_scores: 'Effacer la note',
    clrscores_title: 'Effacer',
    clrscores_message: 'Tous les résultats seront effacés. Continuer ?',
    legend: 'Intitulés des compétences',
    results: 'Résultats',
    average: 'Moyenne',
    average1: 'Moyenne*',
    close_report: 'Quitter le rapport',
    assessment_name: 'Intitulé du résultat',
    action: 'Action',
    list: 'Retour aux résultats',
    status: 'Statut',
    published: 'Publié',
    draft: 'Brouillon',
  },
  it: {
    sign_up_btn_ok: "Crea",
    sign_up_confirm: "Verrà creato un nuovo portale. Diventerai l'amministratore principale e potrai invitare altri utenti.",
    is_reg_info: "Questa email non è trovata. Puoi registrare un nuovo portale con esso e invitare utenti.",
    for_prog_dop_lb: "Punti per corsi/lezioni/test all'interno",
    passed_prog: "Programma completato",
    the_collapsed: "Totale più vecchio di 3 mesi",
    the_collapsed_info: "Le voci nei dettagli più vecchie di tre mesi vengono raggruppate in una sola. I punti vengono sommati nel processo",
    the_test: "Test",
    the_lesson: "Lezione",
    passed_course: "Corso completato",
    points_reset: "Azzera tutti i punti",
    duration_info: "Durata del programma in giorni. Calcolata sommando la durata di tutti i moduli. Utilizzata per determinare le scadenze degli incarichi e delle attività.",
    the_programs: "Programmi",
    program_view_btn: "Responsabile del programma non selezionato",
    the_program_info: "Cliccando sul pulsante 'Vai a', ti assegnerai il programma per completarlo. Puoi trovare l'assegnazione nella sezione 'I miei programmi'.",
    go_to_program: "Vai a",
    the_program: "Programma",
    clear_program: "Eliminazione del programma. Solo i programmi non pubblicati possono essere eliminati.",
    uncheck_all_uscheds: "Deseleziona tutto",
    check_all_uscheds: "Seleziona tutto",
    is_archive: "Archivio",
    from_archive: "Ripristina dall'archivio",
    to_archive: "Sposta nell'archivio",
    program_archived: "Spostato nell'archivio",
    program_published: "Programma pubblicato",
    add_auditors: "Responsabili del modulo",
    modules_auditors: "Modifica degli osservatori e delle scadenze dei moduli",
    add_main_auditor_info: "Il responsabile del programma è un osservatore nel compito. Sarà assegnato a ciascun modulo a meno che non venga selezionato un altro osservatore nei moduli.",
    dates_schedule_info: "Le date sono informative e utilizzate per impostare le scadenze delle attività",
    clear_schedule: "Eliminazione dell'assegnazione. Solo le assegnazioni non pubblicate possono essere eliminate.",
    program_updated: "Programma salvato",
    program_added: "Programma aggiunto",
    program_scheds_done: "Assegnazioni effettuate",
    such_module_exist: "Modulo già selezionato",
    such_user_exist: "Dipendente già selezionato",
    duration_hint: "Calcolato automaticamente",
    degree_add_block_info: "Seleziona un blocco di competenze dall'elenco e fai clic su +. Le sue competenze verranno aggiunte alla valutazione 360.",
    add_block_label: "Seleziona blocco di competenze",
    slaves_add: "Aggiunta automatica dei dipendenti del dipartimento",
    slaves_add_byhands: "Aggiunta manuale",
    chief_add: "Aggiunta automatica dei capi dipartimento del dipendente",
    chief_add_byhands: "Aggiunta manuale",
    del_subpassw_title: "Eliminazione della password",
    del_subpassw_message: "La password verrà cancellata, l'utente dovrà inserirla nuovamente al momento del login",
    degree_msg_sended: "Notifiche inviate",
    degree_checkall_btn: "Seleziona tutto",
    degree_clear_btn: "Elimina risposte",
    degree_clear_msg: "Le valutazioni (risposte) dei valutatori selezionati saranno eliminate. Dovranno eseguire nuovamente le valutazioni.",
    degree_status_student: {
     1: "Nessuna valutazione degli esperti",
     2: "Nessun esperto assegnato"
    },
    degree_status_expert: {
     0: "Tutte le valutazioni date",
     2: "Nessuna valutazione per una o più competenze",
     3: "Non tutte le valutazioni date"
    },
    degree_status_btn: "Invia notifica",
    degree_status_msg: "Stai partecipando alla valutazione dei dipendenti come valutato. Crea un elenco di valutatori e seleziona gli approvatori per questo elenco nella sezione 'Le mie valutazioni' dell'account personale dell'app 'Università'.",
    degree_fill_msg: "Stai partecipando alla valutazione dei dipendenti come valutatore. Valuta i dipendenti nella sezione 'Le mie valutazioni' dell'account personale dell'app 'Università'.",
    degree_uagree_msg: "Sei stato nominato approvatore nella valutazione 360. Vai alla sezione 'Le mie valutazioni' dell'account personale dell'app 'Università'.",
    degree_status: {
     0: "Approvato",
     1: "Nessun esperto assegnato",
     2: "Nessun approvatore assegnato",
     3: "Non approvato"
    },
    Degree360StatusList: "Stati di formazione",
    Degree360StatusFill: "Stati di compilazione",
    change_password: "Cambia password",
    change_password_m: "Cambia le password dei dipendenti",
    change_password_hint: "Inserisci la nuova password e la conferma. Dopo aver cambiato la password, accedi al sistema.",
    change_password_hint_m: "Specifica il dipendente, la password verrà generata automaticamente.",
    change_password_done: "Password cambiata, usala per il prossimo accesso.",
    change_password_fail: "Si è verificato un errore, la password non è stata cambiata. Riprova o contatta l'amministratore.",
    change_password_done_m: "Password cambiata, copiala e trasmettila al dipendente.",
    clear_profile: "Elimina profilo",
    clear_profile_msg: "Il profilo verrà eliminato, ma l'email e la password per l'accesso verranno salvati. Per creare un nuovo profilo, sarà necessario accedere al sistema. Sei sicuro?",
    clear_profile_done_m: "Profilo eliminato",
    add_head: "Manager",
    add_users: "Dipendente",
    struct_reset_msg: "Vuoi davvero reimpostare la struttura?",
    struct_reset: "Reimposta struttura",
    department_add: "Dipartimento",
    struct_company: "/Azienda",
    struct_department: "Dipartimento",
    company_structure: "Azienda",
    last_name_need: "Nome e cognome devono essere compilati!",
    degree_checkall_btn: "Seleziona tutto",
    sign_up_for_an_account: "Accedi o registrati per un account",
    auth_email: "Email",
    auth_password: "Password",
    auth_password2: "Ripeti password",
    sign_up: "Registrati",
    sign_in: "Accedi",
    email_invalid: "Email non valida",
    password_invalid: "Password non valida",
    email_exist: "Email trovata",
    email_ok: "Email disponibile",
    auth_password_match: "Password corrispondente",
    auth_password_not_match: "Le password non corrispondono",
    invitations: "Inviti",
    link_invite: "Link per l'auto-registrazione",
    reminders: "Promemoria",
    notifications: "Notifiche",
    subac_validate_info: "Usa solo lettere latine e numeri per il nome del sottoconto.",
    promo_1: "Espandi le capacità della tua applicazione. Acquista un abbonamento per un tema, un disco o sottoconti nella sezione Impostazioni:Il mio piano.",
    prog_filter:"Filtro",
    prog_filter_all: "tutti i programmi",
    prog_filter_tgrp: "modelli per gruppi",
    prog_filter_tuser: "per i nuovi dipendenti",
    prog_is_template: "questo è il modello da assegnare",
    prog_is_template_newuser: "nuovi dipendenti",
    prog_is_template_grp: "per gruppi",
    prog_info_grp: "assegnazione permanente. Quando un dipendente viene aggiunto a un gruppo, verrà assegnato automaticamente.",
    prog_info_newusers: "quando un nuovo dipendente viene aggiunto al portale Bitrix24, verrà assegnato automaticamente.",
    im_agreemer: "sono un valutatore",
    agree_degree360_mess: "concordare un elenco di valutatori per un punteggio di 360?",
    decline_degree360_mess: "restituire un elenco di valutatori per la valutazione 360 per la revisione?",
    degree360_all_agreed: "gli elenchi dei valutatori sono coerenti per un punteggio di 360",
    degree_valid_1: "nessun nome di valutazione impostato su 360",
    degree_valid_2: "Nessuna data specificata",
    degree_valid_3: "non sono stati specificati valutatori per uno o più valutatori",
    degree_valid_4: "nessuna competenza specificata",
    degree_valid_5: "nessuna scala di valutazione impostata su 360",
    degree_valid_6: "i valutatori non sono coerenti nella selezione indipendente",
    pdf_download:"Scarica il file",
    pdf_build: "crea file PDF",
    scale_value:"valore",
    scale_name:"nome",
    scale_desc:"Descrizione",
    degree360_all_agreed: "gli elenchi dei valutatori sono coerenti per un punteggio di 360",
    degree_valid_1: "nessun nome di valutazione impostato su 360",
    degree_valid_2: "Nessuna data specificata",
    degree_valid_3: "non sono stati specificati valutatori per uno o più valutatori",
    degree_valid_4: "nessuna competenza specificata",
    degree_valid_5: "nessuna scala di valutazione impostata su 360",
    degree_valid_6: "i valutatori non sono coerenti nella selezione indipendente",
    degree360_self_message: "ti è stato assegnato un compito per generare un elenco di valutatori per un punteggio di 360",
    clear_agreed:"elimina il corrispondente",
    students_clear:"tutti i valutatori verranno rimossi insieme ai loro valutatori",
    add_degree360_info: "il punteggio 360 è completamente generato dal moderatore",
    add_task_degree360_info: "L'elenco dei valutatori può essere formato in due modi: dal moderatore; dai valutati. Quando attivato, l'elenco sarà formato dai valutati. Dopo la pubblicazione della valutazione, i valutati avranno un compito nel loro account personale nella sezione Le mie valutazioni per creare un elenco e selezionare gli approvatori. La valutazione inizierà quando tutti gli elenchi saranno formati.",
    listDegree360: "punteggi 360",
    eve_and_bad_moder: "richiesta di partecipazione a un evento da parte di un dipendente",
    profiles_lb:"profili",
    profiles_plc: "il profilo contiene più competenze",
    add_from_comps:"Seleziona competenza",
    incomplete_data: "competenza incompleta, indicatori mancanti",
    experts_clear: "verranno rimossi tutti i dipendenti di valutazione",
    degree_tasks:[{value:0, label:"imposta attività individualmente"},{value:1, label:"imposta un'attività comune"},{value:2, label:" non impostare attività"}],
    old_assessments: "vecchio formato di valutazione",
    degree_task_name: "inserisci il nome del lavoro",
    degree_task_description: "immettere una descrizione del lavoro",
    the_task_360:"compito",
    tasks_360:"attività",
    add_task_degree360: "Création d'une liste d'évaluateurs",
    add_degree360: "Aggiungi un punteggio di 360",
    stud_id: "ID valutato",
    its_my_degree: "sono valutato",
    degree_readonly_info: "Sono già stati assegnati incarichi per la valutazione 360, alcune azioni con la valutazione non possono essere eseguite. Ad esempio, eliminare le persone valutate, i valutatori, eliminare e aggiungere competenze, cambiare le date, abilitare l'autovalutazione, cambiare il modo in cui vengono impostati i compiti.",
    degree360_alert: "L'applicazione è già stata concordata, non è possibile modificarla.",
    degree360_agreeID: "corrispondenza",
    degree360_agreed: "concordato",
    degree360_wait_agreed: "in attesa di riconciliazione",
    degree360_self_title: "domande di valutazione 360",
    degree360_self: "domanda di valutazione 360",
    degree_self: "generazione di un elenco di valutatori",
    degree_self_info: "per fare domanda, scegli il tuo valutatore e il responsabile della riconciliazione del punteggio 360",
    degree_start_finish_info:"le date vengono utilizzate in modo informativo e non influiscono sulla visualizzazione del Valuto360.",
    agree_not_valid: "non tutti i dati sono pieni. Si prega di controllare il titolo, le date, le Valutazioni, la valutazione.",
    degree_add_scale_info: "seleziona una scala di valutazione dall'elenco per aggiungerla alla valutazione 360",
    degree_add_comp_info: "selezionare una competenza dall'elenco e fare clic su < + > per aggiungere una competenza al punteggio 360",
    degree_adb_prof_info: "seleziona un profilo dall'elenco e fai clic su +. Le sue competenze saranno aggiunte al punteggio 360",
    degree_add_experts_info: "Aggiungi valutatori usando i pulsanti sottostanti",
    degree_add_students_info: "Aggiungi dipendenti valutati utilizzando i pulsanti sottostanti",
    degree_agree_degree360_info: "nella posizione sinistra della casella di controllo, è possibile salvare una valutazione di lavoro. Posizione a destra: l'assegnazione verrà effettuata al momento del salvataggio.",
    degree_agree_user_info: "per assegnare una valutazione è necessario un accordo. Scegli un dipendente corrispondente.",
    degree_hide_fio_info:"per impostazione predefinita, il valutatore vedrà i dati del valutatore. Quando attivato, i dati dei valutatori saranno nascosti.",
    degree_pub_lk_info: "pubblicazione dei risultati della valutazione nell'area personale del valutatore dopo che tutti i valutatori hanno valutato.",
    degree_task_info: "L'attività in Bitrix genera una valutazione. È messo dal valutatore.",
    show_options_block: "impostazioni di valutazione 360",
    show_users_block: "elenchi di valutatori e valutatori",
    show_comps_block: "competenze, indicatori, scala di valutazione",
    degree360_aver_title: "risultati delle valutazioni (medie)",
    degree360_stat_title: "numero di intervistati che hanno partecipato alla valutazione",
    degree360_aver_col_:"pratiche",
    degree360_stat_ret0: "numero di persone",
    degree360_stat_col_: "gruppi di rispondenti",
    degree360_stat_col0:"autovalutazione",
    degree360_stat_col1:"Capo",
    degree360_stat_col2:"colleghi",
    degree360_stat_col3: "subordinati",
    degree360_stat_col4:"tutti gli intervistati<br>(senza autovalutazione)",
    degree360_stat_col5:"tutti gli intervistati",
    degree360_description: "è necessario eseguire una valutazione di 360",
    degree360_message: "ti viene assegnato un punteggio di 360. Vai all 'App Università/i miei voti'",
    you_agree_360: "sei un appuntamento coerente in una valutazione di 360.  Vai All'applicazione Università per l'approvazione.",
    del_student: "vuoi eliminare il valutabile",
    do_schedules: "il punteggio 360 sarà assegnato al valutatore",
    no_experts: "nessun valutatore specificato",
    agree_user: "corrispondente",
    agree_degree360: "concordare",
    decline_degree360:"ritorno",
    decline_degree360_info: "se hai modificato lo stato della richiesta, salvala",
    publish_degree360: "pubblicazione del punteggio 360",
    del_degree360: "eliminazione del punteggio 360",
    del_degree360_msg: "vuoi davvero rimuovere il punteggio 360",
    degree_name: "inserisci il nome della valutazione",
    add_prof_label: "seleziona Profilo",
    scale_plc: "Seleziona scala",
    add_prof:"Aggiungi alle competenze",
    save_comp:"Salva",
    close_comp:"Chiudi",
    program_readonly: "le assegnazioni sono fatte per questo programma, quindi non è possibile modificarlo. È possibile creare una copia del programma nell'elenco dei programmi per modificarlo e assegnarlo a nuovi scopi.",
    program_info: "il modulo 'programmi' è progettato per formare programmi di sviluppo complessi. Ad esempio, come l'adattamento dei principianti, La formazione del pool di talenti, ecc."+
      "<br>I programmi possono essere formati dai seguenti elementi: corsi, test, lezioni (ad esempio, aggiungere un PDF, un regolamento, una presentazione, riferimenti, materiali, longrid, ecc.), eventi (ad esempio, creare un evento incontro con il supervisore)."+
      "<br>I programmi sono formati da elementi in parallelo e in serie. Gli elementi successivi formano i livelli (può essere un giorno,una fase di apprendimento). In un livello può essere qualsiasi numero di elementi, il che significa che a questo livello tutti devono passare."+
      "<br>Dopo aver creato un programma, può essere assegnato a dipendenti, gruppi(quando si aggiunge un dipendente a un gruppo, verrà automaticamente assegnato un programma). L'elenco dei dipendenti può essere modificato: quando viene aggiunto-al dipendente verrà assegnato un programma, quando viene rimosso-continuerà a essere presente nei rapporti."+
      "<br>Il programma assegnato non può essere modificato.",
    copy_program: "Copia il programma",
    module_level:"livello",
    eve_and_bad_moder: "richiesta di partecipazione a un evento da parte di un dipendente",
    is_answered: "ci sono risposte",
    check_all: "Segna tutti i voti",
    check_group: "segna un gruppo",
    check_one: "segna una riga",
    rep_date:"Data",
    rep_type: "per cosa",
    the_open: "domanda aperta",
    the_event:"evento",
    the_360: "punteggio 360",
    the_aos: "aos",
    the_rate: "valutazione del corso / test",
    the_comment:"commento",
    the_ipr: "YPR",
    the_Rc: "valutazione del corso",
    the_Rt: "valutazione del test",
    the_course:"corso",
    write_off:"cancellazione",
    not_in_sched:"non presente nella destinazione del programma",
    sub_alert: "questo account contiene account secondari, ma il loro limite è stato esaurito. Contatta il moderatore dell'app",
    sub_limit_exceeded: "il limite dei subaccount è scaduto. Vai a Impostazioni / la mia tariffa per aumentare il limite",
    sub_login_already: "tale account secondario esiste già",
    sub_accounts:"Subaccounts",
    native_add:"Aggiungi un account. Disponibile solo per l'amministratore del portale",
    sub_add:"Aggiungi account secondario",
    del_subac_title: "eliminazione di un account secondario",
    del_subac_message: "il subaccount verrà rimosso dall'elenco. Sei sicuro?",
    del_sub_title: "eliminazione dell'account",
    del_sub_message: "L'account verrà rimosso dall'elenco insieme ai suoi account secondari. Sei sicuro?",
    self_sign_up: "registrazione automatica",
    sub_acc_title:"autorizzazione",
    sub_acc_login:"Login",
    sub_acc_password:"password",
    sub_acc_password2: "ripeti la password",
    sub_acc_recv: "i tuoi dati",
    sub_acc_first_name:"nome",
    sub_acc_last_name:"cognome",
    sub_acc_middle_name:"secondo nome",
    sub_acc_position:"posizione",
    sub_acc_login_not_found: "accesso non trovato, registrazione richiesta",
    sub_acc_save:"Salva",
    sub_acc_ok:"Accedi",
    sub_acc_passwords_not_match: "le password non corrispondono",
    sub_acc_password_fail:"password non valida",
    sub_acc_valid_recv: "compila i dettagli",
    sub_acc_recv:"Modifica dati",
    sub_important_info: "Informazioni importanti",
    sub_info: "<ol>"+
     "<li><b>Aggiungi un account.</b><br>"+
     "Condizioni importanti:</li>"+
     "<ul><li>Questo è un account Bitrix24 a cui saranno collegati i sottoconti dell'Università.</li>"+
     "<li>Solo l'amministratore del portale Bitrix24 può creare un account. Non il moderatore dell'applicazione.</li>"+
     "<li>L'account non può essere un Amministratore Bitrix24 o un moderatore dell'applicazione Università.</li></ul>"+
     "<li><b>Seleziona il metodo di registrazione dei sottoconti nell'applicazione:</b></li>"+
     "<ul><li><b>Indipendentemente.</b> In questo caso, l'amministratore del portale deve solo inserire i login e informare questi login ai sottoconti. Al momento dell'accesso, i sottoconti inseriscono il loro login, quindi l'applicazione chiederà loro di compilare i dati (nome completo e posizione) e creare una password.</li>"+
     "<li><b>Dal amministratore.</b> In questo caso, l'amministratore inserisce il login e compila tutti i dati (nome completo e posizione), quindi fornisce i login ai sottoconti. Al momento dell'accesso, il sottoconto sarà invitato a creare una password. Gli altri dati (nome completo e posizione) saranno già compilati dall'Amministratore.</li></ul>"+
     "<li><b>Ogni volta che accedono all'applicazione, il sottoconto dovrà inserire il proprio login e password.</b></li>"+
     "</ol>",
     cert_format:"Formato Pagina",
    cert_name: "nome del certificato",
    cert_save: "Salva certificato",
    cert_new: "Aggiungi certificato",
    cert_list:"elenco dei certificati",
    clear_form:"nuovo certificato",
    cert_remove:"Rimuovi dall'elenco",
    cert_default:"predefinito",
    cert_scale: "scala",
    cert_opts_fio: "nome utente",
    cert_opts_pos:"posizione",
    cert_opts_name: "nome del test / corso",
    cert_opts_score:"passaggio",
    cert_opts_date: "data di passaggio",
    cert_opts_text:"testo aggiuntivo",
    cert_dark_mode:"sfondo scuro",
    cert_orient_a:"orizzontale",
    cert_orient_p:"Ritratto",
    Karts:"certificati",
    cer_tificate:"Certificato",
    cert_img:"immagine",
    save_png:"Scarica",
    cert_del_title: "attenzione!",
    cert_del_message: "il certificato verrà rimosso dall'elenco. Verrà eliminato anche il file dal disco.",
    cert_reload_title: "attenzione!",
    cert_reload_message: "per applicare le modifiche, è necessario uscire e accedere nuovamente alle impostazioni del certificato.",
    cert_font_size: "dimensione carattere",
    cert_font:"carattere",
    cert_font_color: "Colore carattere",
    is_program_tasks:[{value:0, label:"imposta attività per ogni modulo"},{value:1, label:"imposta un'attività comune"},{value: 2, label:"non impostare attività"}],
    program_tasks:"attività",
    program_task_description: "è necessario eseguire il programma",
    course_passed: "corso completato",
    module_complete: "termina modulo",
    done_success: "successo!",
    save_program:"Salva",
    add_module_s:"Aggiungi modulo seriale",
    add_module_p:"Aggiungi modulo parallelo",
    program_message: "ti è stato assegnato un programma di formazione. Vai all'App Università/I miei programmi",
    my_programs:"i miei programmi",
    publish_schedule_tooltip: "nessun programma selezionato o date di appuntamento, supervisore, dipendenti",
    mod_description: "nel piano di esecuzione del programma, è necessario eseguire il modulo",
    list_schedules:"assegnazioni",
    add_schedule: "Aggiungi destinazione",
    one_schedule:"destinazione",
    publish_schedule: "fare un appuntamento",
    dates_schedule:"date di destinazione",
    add_students:"dipendenti",
    dep_rem_title: "Rimuovi dalla destinazione?",
    dep_rem_mess:"Elimina tutti con questo gruppo (unità organizzativa)",
    dep_rem_mess1:"elimina questo membro",
    start_finish: "Inizio / Fine",
    selectusers_label:"dipendenti",
    selectgroups_label:"gruppi",
    selectdeps_label: "reparti",
    selecttests_label: "corsi / test",
    selectgroup_title:"Aggiungi gruppo",
    selectgroup_message:"Seleziona Gruppo",
    courses_tests: "corsi-test",
    students:"studenti",
    del_schedule_title:"eliminazione",
    del_schedule_message: "conferma l'eliminazione della destinazione",
    del_schedules_message: "conferma l'eliminazione delle assegnazioni",
    date_to_short: "terminazioni",
    students_comment: "se un dipendente è stato aggiunto tramite un dipartimento o un gruppo, la rimozione di tale dipendente comporterà la rimozione anche di altri dipendenti di quel dipartimento / gruppo",
    add_department:"Dipartimento",
    add_group:"gruppo",
    add_main_auditor:"Capo",
    mod_duration:"durata, giorni.",
    mod_action: {'hand':" Manuale", 'auto':"Auto"},
    add_program: "Aggiungi programma",
    list_programs: "elenco dei programmi",
    one_program:"programma",
    progrm_modules:"moduli",
    requaried_field:"campo obbligatorio",
    program_name: "nome del programma",
    program_description: "descrizione del programma",
    module_type: "tipo di modulo",
    module_type_opt:[{value:1, label:"corso"},{value: 2, label: "test"}, {value: 3, label: "lezione"}],
    select_module:"Seleziona modulo",
    not_found:"non trovato",
    type_open:"metodo di apertura dei moduli",
    type_open_opt:[{value:'P', label:"Apri tutto in una volta"},{value:'S', label:"Apri in sequenza. Se il modulo fallisce, Apri comunque il seguente"}, {value:' H', label:"Apri in sequenza. Se il modulo non è passato, non aprire il successivo"}],
    publish_program: "pubblica il programma",
    del_program:"Elimina",
    del_program_msg: "sei sicuro di voler eliminare",
    mod_passed: [{value:' 0', label:"nessuno Stato"}, {value: 'Y', label: "passato"}, {value: 'N', label: "Non passato"}],

    points:"Punteggi",
    points_plc: "metti 0 se i punti vengono assegnati come specificato nelle impostazioni dell'applicazione",
    for_course_active: "corso completato",
    for_course_mod_lb: "metodo di conteggio",
    for_course_mode:[
      {value:' sum', label:"calcola sommando i punti per i moduli"},
      {value:' hand', label:"inserisci manualmente"},
    ],
    for_course_dop_lb: "Punti per lezioni/test all'interno del corso",
    for_course_dop:[
      {value:' top', label:"carica "},
      {value:' node', label:"non addebitare"},
    ],
    separ_add: "Aggiungi separatore",
    sep:"separatore",
    bid_del_message: "Elimina offerta",
    em_count_limit: "Numero massimo di partecipanti superato",
    em_count:"Numero di partecipanti. 0-illimitato",
    eve_attende:"Partecipante",
    eve_bid:"Offerta",
    not_published: "Non publie",
    my_events: "I miei eventi",
    ipr_comp:"competenza",
    ipr_ind:"indicatori",
    eve_bid_done: "l'applicazione è stata aggiunta. Dopo la revisione, diventerai un membro.",
    eve_new_big_add: "l'evento desiderato non è elencato? Offri un'applicazione. Una volta verificato, l'evento verrà aggiunto all'elenco e diventerai un partecipante.",
    eve_new_bid_title:"Richiedi un nuovo evento",
    eve_new_bid_done: "l'applicazione è stata aggiunta. Dopo la revisione, l'evento verrà aggiunto all'elenco e diventerai un partecipante.",
    alert_understood:"chiaro",
    eve_new_bid_moder: "è stata ricevuta una richiesta di evento da un dipendente",
    em_details:"dettagli",
    bid_restore_title: "Ripristina applicazione",
    bid_restore_message: "specificare il motivo del ripristino dell'applicazione",
    bid_accepted: "domanda accettata",
    bid_declined: "richiesta rifiutata, ripristinare.",
    bind_accept: "Accetta domanda",
    bid_decline_title: "rifiuta la domanda",
    bid_decline_message: "indica il motivo del rifiuto della richiesta",
    eve_bids_all: "Aggiungi tutti",
    eve_calend:"nel calendario",
    eve_detail:"maggiori informazioni sull'evento",
    eve_edit:"modifica",
    em_bids:"applicazioni",
    eve_bid_lbl:"bidisci",
    eve_title:"richiedi l'evento",
    eve_bin_add:"sarò un membro",
    eve_nts:"eventi",
    text_block:"blocco di testo",
    text_block_name: "nome campo",
    text_block_value:"contenuto del campo",
    r_o:"sola lettura",
    set_files:"File",
    integral_chart:"Grafico integrale",
    view_cards:"Visualizza: carte",
    view_list:"Visualizza: elenco",
    node_disabled:"Rendi inattivo",
    node_enabled:"Rendi attivo",
    download: "Scarica",
    no_valid_publish: "non sono stati impostati dati importanti, ad esempio dipendenti, supervisori, tutor, competenze, indicatori o nessun diritto di modifica",
    hide_fio: "Nascondi i dati dei valutatori",
    such_element_exist: "tale elemento esiste già",
    program_course: "/ programma del corso",
    lessons:"lezioni",
    tests:"test",
    no_lessons: "nessuna lezione da aggiungere al corso",
    no_tests: "nessun test da aggiungere al corso",
    drop_here: "Trascina qui lezioni e test",
    reset_tree: "Ripristina navigazione",
    reset_true_msg: "rimuovere tutte le lezioni e i test dal corso?",
    export_all:"Esporta tutto",
    imgFiles:"File a cui rispondere",
    upload_title:"Carica",
    upload_err:"Caricamento fallito",
    upload_hint:"Seleziona un file su disco o trascinalo qui",
    max_file_size:"La dimensione del file non deve superare {mb} Mb",
    delete_image:"Elimina file",

    ipr_note:"Ti è stato assegnato un 'Piano di sviluppo individuale'. Vai alla tua app University.",
    add_evt_title:"evento aggiunto",
    add_evt_msg: "chiudi il modulo Aggiungi attività e vai all'elenco?",
    add_evt_ok:"Chiudi",
    add_evt_canc: "aggiungi altro",
    res360_published: "i risultati della valutazione 360 sono pubblicati Nell'area personale della valutazione",
    rm_rm:"attività di sviluppo",
    rm_dln: "scaduto",
    rm_deadline: "scadenza",
    go_course:"vai",
    no_results: "Nessun dato",
    enter_course_name: "digita una parte del nome da cercare",
    course_name: "Aggiungi corso",
    course_statuses: "stato dei corsi",
    ipr_courses:"corsi RM",
    ipr_export:"esportazione",
    iprset_create_label:"chi può creare",
    iprset_edit_label:"chi può modificare",
    iprset_read_label:"chi può vedere",
    iprset_status_label:"chi può cambiare stato",
    iprset_role_opt:[{value: 'M', label: "moderatore"}, {value: 'U', label: "dipendente"}, {value: 'N', label:"mentore"}],

    add_rm:"RM",
    add_ind:"indicatore",
    ipr_title: "piano di sviluppo individuale",
    ipr_name: "nome IPR",
    ipr_close:"Chiudi",
    ipr_open:"Apri",
    select_person:"dipendente",
    select_manager:"Capo",
    select_mentor:"mentore",
    rm_label:"RM",
    rm_options:[
      "Assessment",
      "Cambio",
      "Caso",
      "Concorso",
      "Conferenza",
      "Contenuto audio",
      "Contenuto video",
      "Corso a distanza",
      "Facilitazione",
      "Gioco",
      "Giudizio",
      "Hackathon",
      "Incontro",
      "Lezione",
      "Master class",
      "Mentoring",
      "Mitap",
      "Podcast",
      "Progetto",
      "Quiz",
      "Relazione",
      "Ricerca",
      "Seminario",
      "Sessione",
      "Simulazione",
      "Stage",
      "Team building",
      "Testing",
      "Training",
      "Universiadi",
      "Webinar",
      "Workshop",
      "-la tua opzione-"
    ],
    rm_compleeted:"fatto",
    rm_started: "in corso",
    rm_new:"non avviato",
    rm_status:"stato",
    hand_made: "inserisci manualmente",
    comp_name:"nome della competenza",
    ind_name: "nome dell'indicatore",
    del_ind: "Elimina indicatore",

    selectcomps_label:"competenze",
    selectcomps_title:"Aggiungi competenze",
    selectcomp_message:"Seleziona competenza",
    selectcomps_message:"seleziona una o più competenze",
    selectcomps_add:"Aggiungi",
    selectcomps_finish:"Chiudi",
    selectscale: "scala di valutazione",
    target_sc: "livello target",
    current_sc:"livello attuale",

    del_certs: "il certificato è stato eliminato nella sezione Istruzione, eliminalo qui",
    no_certs: "non hai un singolo certificato caricato. Aggiungili nella sezione Istruzione ",
    aos_filled: "pieno",
    anketa_add: "Aggiungi profilo",
    for_test:"per i test",
    for_course:"per i corsi",
    for_event:"per eventi",
    aos_copy:"(copia)",
    new_aos_name: "cambia il nome per salvare",
    first_aos_name: "Profilo predefinito",
    aos_edit: "modifica del questionario",
    aos_look: "Visualizza Profilo",
    Aos_refresh_title:"carica predefinito",
    aos_refresh_message: "il tuo questionario verrà sostituito con quello predefinito",
    answ_aos:"risposte",
    answer:"testo",
    punteggio:"Peso",
    quest_aos:"domande",
    add_answ: "Aggiungi risposta",
    other_answ:"altro",
    a_o_s: "aos",
    add_aos: "Aggiungi domanda",
    aos: "questionario di feedback",
    aos_del: "Elimina profilo",

    cert_del: "rimuovere il certificato dall'evento?",
    cert_add: "Aggiungi certificato",
    event_certs: "Seleziona certificati",
    em_del: "Elimina un evento (anche dal calendario)",
    em_save:"Scrivi",
    em_clear:"Cancella",
    em_meeting_notify: "Invia inviti",
    em_description: "descrizione dell'evento",
    em_meeting_text: "testo dell'invito",
    em_color:"colore evento",
    em_text_color:"colore tects",
    em_attendees:"membri",
    em_host: 'responsabile',
    em_org:"organizzatore",
    em_title:"eventi esterni e faccia a faccia",
    em_eventName:"nome dell'evento",
    em_common_list:"elenco condiviso",
    em_personas: 'valutato',
    em_close:"selezionare",
    em_location:"Tipo",
    em_location_opt:['Interno','Esterno'],
    em_vid:"Vista",
    em_vid_opt:[
      'Assesment',
      'Cambio',
      'Caso',
      'Concorso',
      'Conferenza',
      'Contenuto Audio',
      'Contenuto Video',
      'Corso A Distanza',
      'Facilitazione',
      'Gioco',
      'Hackathon',
      'Lezione',
      'Master Class',
      'Mentoring',
      'Mitap',
      'Podcast',
      'Progetto',
      'Quiz',
      'Rapporto',
      'Ricerca',
      'Seminario',
      'Sessione',
      'Simulazione',
      'Stage',
      'Team Building',
      'Test',
      'Training',
      'Universiade',
      'Valutazione',
      'Webinar',
      'Workshop',
    ],
    common_list:"elenco comune",
    personas: 'valutato',
    exp_comments:"Commenti",
    no_events: "nessun evento imminente",
    em_from_to:"inizio e fine dell'evento",
    acc1: "Mostra i risultati nell'account personale",
    add_section: "Aggiungi sezione",
    comps_added: "competenze aggiunte",
    comps_add:"Aggiungi competenza",
    Attenzione:"Attenzione",
    del_competence: "Elimina competenza",
    competencies:'competenze',
    all:"Tutti",
    add_coworkers: 'Aggiungi colleghi',
    coworkers:'colleghi',
    add_slaves:'Aggiungi subalterni',
    schiavi:'schiavi',
    add_chief: 'Aggiungi supervisore',
    capo:'Capo',
    nuovo:'dipendente',
    average_: 'Media senza partecipazione valutabile',
    departments:'divisioni',
    add_departments: 'Aggiungi divisioni',
    selectdepartments_title: 'Aggiungi suddivisione',
    selectdepartments_message: 'Seleziona unità organizzativa',
    selectdepartments_add:'Aggiungi',
    selectdepartments_finish:'Chiudi',

    rep_competence: "competenza / indicatori",
    rep_indicators:"esperti",
    rep_scores:"valutazioni",
    comment: "Commento valutato",
    acc: "pubblica nel tuo account valutato",
    you_ass_360: "ti viene assegnato un punteggio di 360. Per un elenco di tutte le valutazioni, consulta il tuo account personale.",
    del_many_title: "eliminazione di più valutazioni",
    del_many_mess: "si desidera eliminare più valutazioni contrassegnate",
    rows_per_page: 'Record per pagina',
    all_report: 'Rapporto generale',
    start_finish: 'Inizio / Fine',
    competence: 'Competenza',
    selectusers_title: 'Aggiungi dipendente',
    selectuser_message: 'Seleziona utente',
    selectusers_message: 'Seleziona uno o più utenti',
    selectusers_add: 'Aggiungi',
    selectusers_finish: 'Chiudi',
    title: 'Feedback a 360/180 gradi',
    goback: 'Indietro',
    loading: 'Caricamento',
    remove: 'Rimuovi',
    assessment: 'feedback',
    competences: 'Competenze',
    name: 'Nome',
    date_start: 'Data inizio',
    date_finish: 'Data fine',
    description: 'Descrizione',
    persona: 'Sotto feedback',
    experts: 'Membri',
    clear: 'Cancella',
    del: 'Elimina',
    add: 'Aggiungi',
    add_assessment: 'Crea feedback',
    add_persona: 'Aggiungi una persona sotto feedback',
    add_experts: 'Aggiungi membri',
    ok: 'OK',
    reset: 'Cancella',
    scores: 'Feedback',
    publish: 'Pubblica',
    alert_message: 'Dati non inseriti',
    alert_persona: 'Sotto feedback',
    alert_experts: 'Membri',
    alert_dates: 'Date',
    alert_competences: 'Competenze',
    alert_scores: 'Feedback',
    del_ass_title: 'Elimina valutazione',
    del_ass_mess: 'Elimina valutazione',
    deleteSome: 'Elimina',
    export: 'Esporta',
    report: 'Report',
    del_some_title: 'Elimina',
    del_some_mess: 'Vuoi rimuovere la valutazione',
    more: 'Altro',
    chart: 'Risultati',
    chart_title: 'Grafico valutazione 180°/360° per il dipendente',
    refresh: 'Aggiorna lista',
    user: 'Dipendente',
    clear_scores: 'Cancella valutazione',
    clrscores_title: 'Cancella',
    clrscores_message: 'Tutti i punteggi saranno eliminati da questa valutazione. Continuare?',
    legend: 'Nomi completi competenze',
    results: 'Risultati',
    average: 'Media',
    average1: 'Media*',
    close_report: 'Chiudi report',
    assessment_name: 'Nome valutazione',
    action: 'Azione',
    list: 'Torna alla lista punteggi',
    status: 'Status',
    published: 'Pubblicato',
    draft: 'Bozza',
  },
  tr: {
    sign_up_btn_ok: "Oluştur",
    sign_up_confirm: "Yeni bir portal oluşturulacak. Ana yönetici olacaksınız ve diğer kullanıcıları davet edebilirsiniz.",
    is_reg_info: "Bu e-posta bulunamadı. Onunla yeni bir portal kaydedebilir ve kullanıcıları davet edebilirsiniz.",
    for_prog_dop_lb: "Kurslar/dersler/testler için puanlar",
    passed_prog: "Tamamlanan program",
    the_collapsed: "Toplam 3 aydan eski",
    the_collapsed_info: "Detaylardaki üç aydan daha eski kayıtlar birleştirilir. Bu süreçte puanlar toplanır",
    the_test: "Test",
    the_lesson: "Ders",
    passed_course: "Tamamlanan kurs",
    points_reset: "Tüm puanları sıfırla",
    duration_info: "Programın gün cinsinden süresi. Tüm modüllerin süresi toplanarak hesaplanır. Atamaların ve görevlerin son tarihlerini belirlemede kullanılır.",
    the_programs: "Programlar",
    program_view_btn: "Program yöneticisi seçilmedi",
    the_program_info: "'Git' düğmesine tıklayarak programı tamamlamak için kendinize atayacaksınız. Atamayı 'Programlarım' bölümünde bulabilirsiniz.",
    go_to_program: "Git",
    the_program: "Program",
    clear_program: "Program siliniyor. Yalnızca yayımlanmamış programlar silinebilir.",
    uncheck_all_uscheds: "Tüm işaretleri kaldır",
    check_all_uscheds: "Hepsini işaretle",
    is_archive: "Arşiv",
    from_archive: "Arşivden geri yükle",
    to_archive: "Arşive taşı",
    program_archived: "Arşive taşındı",
    program_published: "Program yayınlandı",
    add_auditors: "Modül sorumluları",
    modules_auditors: "Gözlemcileri ve modül sürelerini düzenleme",
    add_main_auditor_info: "Program yöneticisi, görevde bir gözlemcidir. Modüllerde başka bir gözlemci seçilmedikçe her modüle atanacaktır.",
    dates_schedule_info: "Tarihler bilgilendiricidir ve görev sürelerini belirlemek için kullanılır",
    clear_schedule: "Atama siliniyor. Yalnızca yayımlanmamış atamalar silinebilir.",
    program_updated: "Program kaydedildi",
    program_added: "Program eklendi",
    program_scheds_done: "Atamalar yapıldı",
    such_module_exist: "Modül zaten seçildi",
    such_user_exist: "Çalışan zaten seçildi",
    duration_hint: "Otomatik olarak hesaplanır",
    degree_add_block_info: "Listeden bir yetkinlik bloğu seçin ve + tuşuna basın. Yetkinlikleri 360 Değerlendirmesine eklenecektir.",
    add_block_label: "Yetkinlik bloğu seçin",
    slaves_add: "Bölüm çalışanlarının otomatik eklenmesi",
    slaves_add_byhands: "Manuel ekleme",
    chief_add: "Çalışanın bölüm başkanlarının otomatik eklenmesi",
    chief_add_byhands: "Manuel ekleme",
    del_subpassw_title: "Şifre Silme",
    del_subpassw_message: "Şifre temizlenecek, kullanıcı giriş yaparken tekrar girmesi gerekecek",
    degree_msg_sended: "Bildirimler gönderildi",
    degree_checkall_btn: "Hepsini işaretle",
    degree_clear_btn: "Yanıtları sil",
    degree_clear_msg: "Seçilen değerlendiricilerin değerlendirmeleri (yanıtları) silinecektir. Değerlendirmeleri tekrar yapmaları gerekecek.",
    degree_status_student: {
     1: "Uzman değerlendirmesi yok",
     2: "Uzman atanmadı"
    },
    degree_status_expert: {
     0: "Tüm değerlendirmeler verildi",
     2: "Bir veya daha fazla yetkinlik için değerlendirme yok",
     3: "Tüm değerlendirmeler verilmedi"
    },
    degree_status_btn: "Bildirim gönder",
    degree_status_msg: "Değerlendirilen olarak çalışan değerlendirmesine katılıyorsunuz. 'Üniversite' uygulamasının kişisel hesap bölümünde 'Değerlendirmelerim' bölümünde bir değerlendirici listesi oluşturun ve bu liste için onaylayıcıları seçin.",
    degree_fill_msg: "Değerlendirici olarak çalışan değerlendirmesine katılıyorsunuz. 'Üniversite' uygulamasının kişisel hesap bölümünde 'Değerlendirmelerim' bölümünde çalışanları değerlendirin.",
    degree_uagree_msg: "360 değerlendirmesinde onaylayıcı olarak atandınız. 'Üniversite' uygulamasının kişisel hesap bölümünde 'Değerlendirmelerim' bölümüne gidin.",
    degree_status: {
     0: "Onaylandı",
     1: "Atanan uzman yok",
     2: "Onaylayıcı atanmadı",
     3: "Onaylanmadı"
    },
    Degree360StatusList: "Oluşturma durumları",
    Degree360StatusFill: "Doldurma durumları",
    change_password: "Şifre değiştir",
    change_password_m: "Çalışanların şifrelerini değiştir",
    change_password_hint: "Yeni şifreyi ve onayı girin. Şifreyi değiştirdikten sonra sisteme giriş yapın.",
    change_password_hint_m: "Çalışanı belirtin, şifre otomatik olarak oluşturulacaktır.",
    change_password_done: "Şifre değiştirildi, bir sonraki girişte kullanın.",
    change_password_fail: "Bir hata oluştu, şifre değiştirilemedi. Tekrar deneyin veya yöneticiye başvurun.",
    change_password_done_m: "Şifre değiştirildi, kopyalayın ve çalışana iletin.",
    clear_profile: "Profili sil",
    clear_profile_msg: "Profil silinecek, ancak giriş için e-posta ve şifre kaydedilecektir. Yeni bir profil oluşturmak için sisteme giriş yapmanız gerekecek. Emin misiniz?",
    clear_profile_done_m: "Profil silindi",
    add_head: "Yönetici",
    add_users: "Çalışan",
    struct_reset_msg: "Yapıyı gerçekten sıfırlamak istiyor musunuz?",
    struct_reset: "Yapıyı sıfırla",
    department_add: "Bölüm",
    struct_company: "/Şirket",
    struct_department: "Departman",
    company_structure: "Şirket",
    last_name_need: "Ad ve soyadı doldurulmalıdır!",
    degree_checkall_btn: "Hepsini işaretle",
    sign_up_for_an_account: "Hesabınıza giriş yapın veya kaydolun",
    auth_email: "Email",
    auth_password: "Şifre",
    auth_password2: "Şifreyi tekrar girin",
    sign_up: "Kaydol",
    sign_in: "Giriş yap",
    email_invalid: "Geçersiz email",
    password_invalid: "Geçersiz şifre",
    email_exist: "Email bulundu",
    email_ok: "Email uygun",
    auth_password_match: "Şifre eşleşiyor",
    auth_password_not_match: "Şifreler eşleşmiyor",
    invitations: "Davetiyeler",
    link_invite: "Kendi kendine kayıt bağlantısı",
    reminders: "Hatırlatmalar",
    notifications: "Bildirimler",
    subac_validate_info: "Alt hesap adı için yalnızca Latin harfleri ve rakamları kullanın.",
    promo_1: "Uygulamanızın yeteneklerini genişletin. Ayarlar:Planım bölümünde bir tema, disk veya alt hesaplar için abonelik satın alın.",
    prog_filter:"Filtre",
    prog_filter_all:"Tüm programlar",
    prog_filter_tgrp:"Gruplar için şablonlar",
    prog_filter_tuser:"Yeni çalışanlar için",
    prog_ıs_template:"Bu, atama için şablondur",
    prog_is_template_newuser:"yeni çalışanlara",
    prog_is_template_grp:"gruplar için",
    prog_info_grp:"Kalıcı görev süresi. Bir çalışan grubuna eklendiğinde, kendisine otomatik olarak atanacaktır.",
    prog_info_newusers:"Bitrix24 portalına yeni bir çalışan eklendiğinde, otomatik olarak atanacaktır.",
    im_agreemer:"Ben değerlendiriciyim",
    agree_degree360_mess:"360 notu için değerlendiricilerin listesini koordine etmek mi?",
    decline_degree360_mess:"360 Notu için değerlendiricilerin listesini revize etmek üzere iade mi ediyorsunuz?",
    degree360_all_agreed:"Değerlendiricilerin listeleri 360 puan için tutarlı",
    degree_valid_1:"360 Puan adı verilmedi",
    degree_valid_2:"Tarih verilmedi",
    degree_valid_3:"Değerlendirilenlerden bir veya daha fazlasında Değerlendiriciler belirtilmedi",
    degree_valid_4:"Yetkinlik verilmedi",
    degree_valid_5:"360 puan ölçeği ayarlanmadı",
    degree_valid_6:"Bağımsız seçimde değerlendiriciler üzerinde anlaşmaya varılmadı",
    pdf_download:"Dosyayı indir",
    pdf_build:"PDF dosyası oluştur",
    scale_value:"Değer",
    scale_name:"İsim",
    scale_desc:"Açıklama",
    degree360_all_agreed:"Değerlendiricilerin listeleri 360 puan için tutarlı",
    degree_valid_1:"360 Puan adı verilmedi",
    degree_valid_2:"Tarih verilmedi",
    degree_valid_3:"Değerlendirilenlerden bir veya daha fazlasında Değerlendiriciler belirtilmedi",
    degree_valid_4:"Yetkinlik verilmedi",
    degree_valid_5:"360 puan ölçeği ayarlanmadı",
    degree_valid_6:"Bağımsız seçimde değerlendiriciler üzerinde anlaşmaya varılmadı",
    degree360_self_message:"360 Puan için değerlendiricilerin listesini oluşturmanız için size bir görev atanmış",
    clear_agreed:"Eşleştiriciyi kaldır",
    students_clear:"Değerlendirilenlerle birlikte Değerlendirilenler silinecektir",
    add_degree360_info:"360 puanı tamamen moderatör tarafından oluşturulur",
    add_task_degree360_info: "Değerlendiricilerin listesi iki şekilde oluşturulabilir: moderatör tarafından; değerlendirilenler tarafından. Etkinleştirildiğinde, liste değerlendirilenler tarafından oluşturulacaktır. Değerlendirme yayınlandıktan sonra, değerlendirilenlerin kişisel hesaplarında, Değerlendirmelerim bölümünde bir liste oluşturma ve onaylayıcıları seçme görevi olacaktır. Tüm listeler oluşturulduğunda değerlendirme başlayacaktır.",
    listDegree360:"Puanlar 360",
    eve_and_bad_moder:"Bir çalışandan etkinliğe katılım başvurusu",
    profiles_lb:"Profiller",
    profiles_plc:"Profil birkaç yetkinlik içeriyor",
    add_from_comps:"Yetkinlik seçin",
    incomplete_data:"Eksik yetkinlik, gösterge yok",
    experts_clear:"Değerlendiren tüm çalışanlar kaldırılacak",
    degree_tasks:[{value:0, label:"görevleri ayrı ayrı ayarla"},{value:1, label:"ortak bir görev koy"},{value:2, label:" görevleri ayrı ayrı koymayın"}],
    old_assessments:"Eski not formatı",
    degree_task_name:"İş adını girin",
    degree_task_description:"İş tanımını girin",
    the_task_360:"Görev",
    tasks_360:"Görevler",
    add_task_degree360:"Değerlendirilenlerin değerlendirilenlerin listesini oluşturma",
    add_degree360:"360 puan ekle",
    stud_id:"Değerlendirilenin Kimliği",
    its_my_degree:"Ben Değerlendirilenim",
    degree_readonly_info: "360 Değerlendirmesi için atamalar zaten yapılmış, Değerlendirme ile ilgili bazı işlemler gerçekleştirilemez. Örneğin, değerlendirilen kişilerin, değerlendiricilerin silinmesi, yetkinliklerin silinmesi ve eklenmesi, tarihlerin değiştirilmesi, öz değerlendirme etkinleştirilmesi, görevlerin belirlenme şeklinin değiştirilmesi.",
    degree360_alert:"Başvuru zaten kabul edildi, değiştirilemez.",
    degree360_agreeID:"Anlaşma",
    degree360_agreed:"Kabul edildi",
    degree360_wait_agreed:"Onay bekliyor",
    degree360_self_title:"Değerlendirme başvuruları 360",
    degree360_self:"Değerlendirme başvurusu 360",
    degree_self:"Değerlendiricilerin listesini oluşturma",
    degree_self_info:"Başvurmak için kendiniz için Değerlendiricileri ve 360 Değerlendirmesini kabul etmekten Sorumlu Olanı seçin",
    degree_start_finish_info:"Tarihler bilgi açısından kullanılır ve Derecelendirmenin görüntülenmesini etkilemez 360.",
    agree_not_valid:"Tüm veriler doldurulmadı. Lütfen adı, tarihleri, Değerlendirilenleri, Değerlendirenleri kontrol edin.",
    degree_add_scale_info:"360 puanına eklemek için listeden bir puan ölçeği seçin",
    degree_add_comp_info:"Listeden bir yetkinlik seçin ve 360 puanına yetkinlik eklemek için <+> düğmesine tıklayın",
    degree_adb_prof_info:"Listeden bir profil seçin ve üzerine tıklayın +. Yetkinlikleri 360 Puanına eklenecek",
    degree_add_experts_info:"Aşağıdaki düğmeleri kullanarak Değerlendiriciler ekleyin",
    degree_add_students_info:"Aşağıdaki düğmeleri kullanarak Değerlendirilen çalışanları ekleyin",
    degree_agree_degree360_info:"Bayrağın sol konumunda, çalışma değerlendirmesini kaydedebilirsiniz. Doğru pozisyon - kaydedildiğinde atama yapılacaktır.",
    degree_agree_user_info:"Değerlendirmeyi atamak için onay gereklidir. Eşleşen bir çalışan seçin.",
    degree_hide_fio_info:"Değerlendirilen kişi varsayılan olarak değerlendirenlerin verilerini görecektir. Etkinleştirildiğinde, değerlendiricilerin verileri gizlenecektir.",
    degree_pub_lk_info:"Tüm değerlendirenler not verdikten sonra değerlendirme sonuçlarının değerlendirilenin kişisel hesabına yayınlanması.",
    degree_task_info:"Bitrix'teki görev bir değerlendirme yapmaktır. Değerlendirene yerleştirilir.",
    show_options_block:"Derecelendirme Ayarları 360",
    show_users_block:"Değerlendirilenlerin ve değerlendirenlerin listeleri",
    show_comps_block:"Yetkinlikler, göstergeler, değerlendirme ölçeği",
    degree360_aver_title:"Tahminlerin sonuçları (ortalamalar)",
    degree360_stat_title:"Değerlendirmeye katılan katılımcıların sayısı",
    degree360_aver_col_:"Uygulamalar",
    degree360_stat_ret0:"Kişi sayısı",
    degree360_stat_col_:"Yanıtlayan grupları",
    degree360_stat_col0:"Benlik saygısı",
    degree360_stat_col1:"Yönetici",
    degree360_stat_col2:"İş arkadaşları",
    degree360_stat_col3:"Astlar",
    degree360_stat_col4:"Tüm yanıtlayanlar<br>(öz değerlendirme yok)",
    degree360_stat_col5:"Tüm yanıtlayanlar",
    degree360_description:"360 puanını tamamlamanız gerekiyor",
    degree360_message:"Size 360 puan verildi. Üniversite/Notlarım uygulamasına gidin",
    you_agree_360:"360 değerlendirmesinde onaylayanlara atanıyorsunuz.  Onay için Üniversite uygulamasına gidin.",
    del_student:"Değerlendirileni silmek istiyorsunuz",
    do_schedules:"Değerlendiriciye 360 puan atanacak",
    no_experts:"Değerlendiriciler belirtilmedi",
    agree_user:"Uzlaştırıcı",
    agree_degree360:"Müzakere etmek",
    decline_degree360:"Geri dön",
    decline_degree360_info:"Başvurunun durumunu değiştirdiyseniz kaydedin",
    publish_degree360:"360 puanının yayınlanması",
    del_degree360:"360 puanının silinmesi",
    del_degree360_msg:"360 puanını gerçekten silmek istiyorsunuz",
    degree_name:"Değerlendirmenin adını girin",
    add_prof_label:"Profili seç",
    scale_plc:"Ölçek seç",
    add_prof:"Yetkinliklere ekle",
    save_comp:"Kaydet",
    close_comp:"Kapat",
    program_readonly:"Bu program için atamalar yapıldı, bu nedenle değiştirilemez. Programı değiştirmek ve yeni atamalar için programlar listesinde programın bir kopyasını oluşturabilirsiniz.",
    program_info:"'Programlar' modülü, kapsamlı geliştirme programları oluşturmak için tasarlanmıştır. Örneğin, yeni başlayanların uyarlanması, Yetenek havuzunun eğitimi vb."+
      "<br>Programlar aşağıdaki unsurlardan oluşturulabilir: kurslar, testler, dersler (örneğin, derse PDF dosyası, yönetmelikler, sunum, referanslar, materyaller, uzun yazılar vb. Ekleyin), etkinlikler (örneğin, bir etkinlik oluşturun Liderle toplantı)."+
      "<br>Programlar paralel ve sırayla unsurlardan oluşturulur. Ardışık unsurlar seviyeleri oluşturur (bu bir Gün, Öğrenme Aşaması olabilir). Herhangi bir sayıda öğe bir seviyede olabilir, bu da hepsinin bu seviyede geçmesi gerektiği anlamına gelir."+
      "<br>Programı oluşturduktan sonra çalışanlara, gruplara atanabilir (gruba bir çalışan eklendiğinde, programa otomatik olarak atanacaktır). Çalışanların listesi değiştirilebilir: eklendiğinde, çalışana bir program atanacak, kaldırıldığında raporlarda bulunmaya devam edecektir."+
      "<br>Atanan program değiştirilemez.",
    copy_program:"Programı kopyala",
    module_level:"Seviye",
    eve_and_bad_moder:"Bir çalışandan etkinliğe katılım başvurusu",
    is_answered:"Cevaplar var",
    check_all:"Tüm notları işaretle",
    check_group:"Grubu işaretle",
    check_one:"Satırı işaretle",
    rep_date:"Tarih",
    rep_type:"Ne için",
    the_open:"Açık soru",
    the_event:"Etkinlik",
    the_360:"Puan 360",
    the_aos:"AOS",
    the_rate:"Kurs/test değerlendirmesi",
    the_comment:"Yorum",
    the_ipr:"YPRES",
    the_Rc:"Kurs Değerlendirmesi",
    the_Rt:"Test Değerlendirmesi",
    the_course:"Kurs",
    write_off:"Yazma",
    not_in_sched:"Programın amacında eksik",
    sub_alert:"Bu hesap alt hesaplar içeriyor, ancak limitleri tükendi. Uygulamanın moderatörüne başvurun",
    sub_limit_exceeded:"Alt hesapların sınırı tükendi. Limiti artırmak için Ayarlar/Tarifem'e gidin",
    sub_login_already:"Böyle bir alt hesap zaten var",
    sub_accounts:"Alt Hesaplar",
    native_add:"Hesap ekle. Yalnızca portal yöneticisi tarafından kullanılabilir",
    sub_add:"Alt hesap ekle",
    del_subac_title:"Alt hesabı silme",
    del_subac_message:"Alt hesap listeden kaldırılacaktır. Emin misiniz?",
    del_sub_title:"Hesabın silinmesi",
    del_sub_message:"Hesap, alt hesaplarıyla birlikte listeden silinecektir. Emin misiniz?",
    self_sign_up:"Kendi kendine kayıt ol",
    sub_acc_title:"Yetkilendirme",
    sub_acc_login:"Login",
    sub_acc_password:"Şifre",
    sub_acc_password2:"Şifreyi tekrarla",
    sub_acc_recv:"Verileriniz",
    sub_acc_first_name:"İsim",
    sub_acc_last_name:"Soyadı",
    sub_acc_middle_name:"Göbek adı",
    sub_acc_position:"Pozisyon",
    sub_acc_login_not_found:"Giriş bulunamadı, kayıt gerekli",
    sub_acc_save:"Kaydet",
    sub_acc_ok:"Giriş yap",
    sub_acc_passwords_not_match:"Şifreler eşleşmiyor",
    sub_acc_password_fail:"Geçersiz şifre",
    sub_acc_valid_recv:"Ayrıntıları doldurun",
    sub_acc_recv:"Verileri değiştir",
    sub_important_info: "Önemli Bilgi",
    sub_info: "<ol>"+
     "<li><b>Hesap ekle.</b><br>"+
     "Önemli koşullar:</li>"+
     "<ul><li>Bu, Üniversite alt hesaplarının bağlanacağı bir Bitrix24 hesabıdır.</li>"+
     "<li>Hesabı yalnızca Bitrix24 portal yöneticisi oluşturabilir. Uygulama moderatörü değil.</li>"+
     "<li>Hesap, Bitrix24 Yöneticisi veya Üniversite uygulaması moderatörü olamaz.</li></ul>"+
     "<li><b>Uygulamada alt hesap kaydı yöntemini seçin:</b></li>"+
     "<ul><li><b>Bağımsız olarak.</b> Bu durumda, portal yöneticisi yalnızca giriş bilgilerini girmeli ve bu giriş bilgilerini alt hesaplara bildirmelidir. Giriş yaparken, alt hesaplar giriş bilgilerini girecek, ardından uygulama onlardan verileri (tam ad ve pozisyon) doldurmalarını ve bir şifre oluşturmalarını isteyecektir.</li>"+
     "<li><b>Yönetici tarafından.</b> Bu durumda, yönetici giriş bilgilerini girer ve tüm verileri (tam ad ve pozisyon) doldurur, ardından giriş bilgilerini alt hesaplara sağlar. Giriş yaparken, alt hesaptan bir şifre oluşturması istenecektir. Diğer veriler (tam ad ve pozisyon) zaten Yönetici tarafından doldurulmuş olacaktır.</li></ul>"+
     "<li><b>Uygulamaya her giriş yaptıklarında, alt hesap giriş bilgilerini ve şifrelerini girmelidir.</b></li>"+
     "</ol>",
     cert_format:"Sayfa biçimi",
    cert_name:"Sertifika adı",
    cert_save:"Sertifikayı kaydet",
    cert_new:"Sertifika ekle",
    cert_list:"Sertifikaların listesi",
    clear_form:"Yeni sertifika",
    cert_remove:"Listeden kaldır",
    cert_default:"Varsayılan olarak",
    cert_scale:"Ölçek",
    cert_opts_fio:"Kullanıcı adı",
    cert_opts_pos:"iş unvanı",
    cert_opts_name:"Testin/kursun adı",
    cert_opts_score:"Geçme notu",
    cert_opts_date:"Geçiş tarihi",
    cert_opts_text:"Ek metin",
    cert_dark_mode:"Karanlık arka plan",
    cert_orient_a:"Yatay",
    cert_orient_p:"Portre",
    certs:"Sertifikalar",
    cer_tificate:"Sertifika",
    cert_img:"Resim",
    save_png:"İndir",
    cert_del_title:"Dikkat!",
    cert_del_message:"Sertifika listeden kaldırılacaktır. Diskteki dosya da silinecektir.",
    cert_reload_title:"Dikkat!",
    cert_reload_message:"Değişiklikleri uygulamak için çıkış yapmanız ve sertifika ayarlarına tekrar girmeniz gerekecektir.",
    cert_font_size:"Yazı tipi boyutu",
    cert_font:"Yazı tipi",
    cert_font_color:"Yazı tipi rengi",
    is_program_tasks:[{value:0, label:"her modül için görev belirleme"},{value:1, label:"ortak bir görev belirleme"},{value:2, label:" görev belirleme"}],
    program_tasks:"Görevler",
    program_task_description:"Programı yürütmeniz gerekiyor",
    course_passed:"Kurs tamamlandı",
    module_complete:"Modülü bitir",
    done_success:"Başarılı!",
    save_program:"Kaydet",
    add_module_s:"Seri modül ekle",
    add_module_p:"Paralel modül ekle",
    program_message:"Size bir eğitim programı atanmıştır. Üniversite/Programlarım uygulamasına gidin",
    my_programs:"Programlarım",
    publish_schedule_tooltip:"Program seçilmedi veya randevu tarihleri belirlenmedi, yönetici, çalışanlar",
    mod_description:"Programın yürütülmesi açısından, modülü yürütmeniz gerekir",
    list_schedules:"Hedefler",
    add_schedule:"Atama ekle",
    one_schedule:"Atama",
    publish_schedule:"Randevu al",
    dates_schedule:"Randevu tarihleri",
    add_students:"Çalışanlar",
    dep_rem_title:"Hedeften kaldırılıyor musunuz?",
    dep_rem_mess:"Bu gruba (birime) sahip herkesi silin",
    dep_rem_mess1:"Bu katılımcıyı sil",
    start_finish:"Başlangıç/bitiş",
    selectusers_label:"Çalışanlar",
    selectgroups_label:"Gruplar",
    selectdeps_label:"Bölümler",
    selecttests_label:"kurslar/testler",
    selectgroup_title:"Grup ekle",
    selectgroup_message:"Bir grup seçin",
    kurslar_tests:"Kurslar-testler",
    students:"Öğrenciler",
    del_schedule_title:"Silme",
    del_schedule_message:"Atamanın kaldırılmasını onaylayın",
    del_schedules_message:"Atamaların kaldırılmasını onaylayın",
    date_to_short:"sonlar",
    students_comment:"Bir departman veya grup aracılığıyla bir çalışan eklenmişse, söz konusu çalışanın kaldırılması, o departmanın/grubun diğer çalışanlarının da kaldırılmasını gerektirecektir",
    add_department:"Departman",
    add_group:"Grup",
    add_main_auditor:"Yönetici",
    mod_duration:"Süre, gün.",
    mod_action:{'hand':"manuel", 'auto':"otomatik"},
    add_program:"Program ekle",
    list_programs:"Programların listesi",
    one_program:"Program",
    progrm_modules:"Modüller",
    requaried_field:"Zorunlu alan",
    program_name:"Programın adı",
    program_description:"Program açıklaması",
    module_type:"Modül türü",
    module_type_opt:[{value:1, label:"Kurs"},{value:2, label:"Test"},{value:3, label:"Ders"}],
    select_module:"Modülü seçin",
    not_found:"Hiçbir şey bulunamadı",
    type_open:"Modülleri açma yöntemi",
    type_open_opt:[{value:'P', label:"Hepsini bir kerede aç"},{value:'S', label:"Sırayla aç. Modül geçilmezse, yine de bir sonrakini açın"},{value:'H', label:"Sırayla açın. Modül geçilmezse, sonraki modülü açmayın"}],
    publish_program:"Programı yayınla",
    del_program:"Sil",
    del_program_msg:"Silmek istediğinizden emin misiniz",
    mod_passed:[{value:'0', label:"durum yok"}, {value:'Y', label:"Geçti"},{value:'n', label:"Başarısız oldu"}],

    points:"Skorlar",
    points_plc:"puanlar uygulama ayarlarında belirtildiği gibi verilirse 0 koyun",
    for_course_active:"Tamamlanan kurs",
    for_course_mod_lb:"Sayma yöntemi",
    for_course_mode:[
      {value:'sum', label:"Modüller için puanları toplayarak hesaplayın"},
      {value:'hand', label:"Manuel olarak girin"},
    ],
    for_course_dop_lb: "Kurs içindeki dersler/testler için puanlar",
    for_course_dop:[
      {value:'top', label:"Tahakkuk etmek "},
      {value: 'düğüm', label:"Tahakkuk etme"},
    ],
    separ_add:"Ayırıcı ekle",
    sep:"Ayırıcı",
    bid_del_message: "Teklifi sil",
    em_count_limit: "Maksimum katılımcı sayısı aşıldı",
    em_count:"Katılımcı sayısı. 0-sınırsız",
    eve_attende:"Katılımcı",
    eve_bid:"Teklif",
    not_published: "Yayınlanmamıs",
    my_events: "Benim etkinliklerim",
    ipr_comp:"Yetkinlik",
    ipr_ind:"Göstergeler",
    eve_bid_done:"Başvurunuz eklendi. Değerlendirildikten sonra üye olacaksınız.",
    eve_new_big_add:"İstenen etkinlik listede yok mu? Başvurunuzu sunun. Doğrulandıktan sonra etkinlik listeye eklenecek ve siz üye olacaksınız.",
    eve_new_bid_title:"Yeni etkinlik başvurusu",
    eve_new_bid_done:"Başvurunuz eklendi. Değerlendirildikten sonra etkinlik listeye eklenecek ve siz üye olacaksınız.",
    alert_understood:"Anlıyorum",
    eve_new_bid_moder:"Bir çalışandan etkinlik için başvuru alındı",
    em_details:"Ayrıntılar",
    bid_restore_title:"Talebi geri yükle",
    bid_restore_message:"Başvurunun geri yüklenmesinin nedenini belirtin",
    bid_accepted:"Başvuru kabul edildi",
    bid_declined:"Başvuru reddedildi, geri yükle.",
    bind_accept:"Başvuruyu kabul et",
    bid_decline_title:"Başvuruyu reddet",
    bid_decline_message:"Başvurunun reddedilme nedenini belirtin",
    eve_bids_all:"Herkesi ekle",
    eve_calend:"Takvimde",
    eve_detail:"Etkinlik hakkında daha fazla bilgi edinin",
    eve_edit:"Değiştir",
    em_bıds:"Başvurular",
    eve_bid_lbl:"Etkinlik",
    eve_title:"Etkinliğe katılmak için başvurun",
    eve_bin_add:"Üye olacağım",
    eve_nts:"Etkinlikler",
    text_block:"Metin bloğu",
    text_block_name:"Alan adı",
    text_block_value:"Alanın içeriği",
    r_o:"Salt okunur",
    set_files:"Dosyalar",
    integral_chart:"İntegral grafiği",
    view_cards:"Görünüm: kartlar",
    view_list:"Görünüm: liste",
    node_disabled:"Etkin olmayan hale getir",
    node_enabled:"Etkinleştir",
    download: "indir",
    no_valid_publish:"Çalışan, yönetici, akıl hocası, yetkinlikler, göstergeler veya düzenleme hakları gibi önemli veriler belirtilmemiş",
    hide_fio:"Değerlendiricilerin verilerini gizle",
    such_element_exist:"Böyle bir öğe zaten var",
    program_course:"/Kurs programı",
    lessons:"Dersler",
    tests:"Testler",
    no_lessons:"Kursa eklenecek ders yok",
    no_tests:"Kursa eklenecek test yok",
    drop_here:"Dersleri ve testleri buraya sürükleyin",
    reset_tree:"Navigasyonu sıfırla",
    reset_true_msg:"Tüm dersleri ve testleri dersten silmek mi istiyorsunuz?",
    export_all:"Tümünü Dışa Aktar",
    imgFiles:"Yanıtlanacak dosyalar",
    upload_title:"Yükle",
    upload_err:"Yükleme başarısız oldu",
    upload_hint:"Diskte bir dosya seçin veya buraya sürükleyip bırakın",
    max_file_size:"Dosya boyutu {mb} Mb'yi geçmemelidir",
    delete_image:"Dosyayı sil",

    ipr_note:"Size bir 'Bireysel Gelişim Planı' atandı. Kurumsal Üniversite uygulamanıza gidin.",
    add_evt_title:"Etkinlik eklendi",
    add_evt_msg:"Etkinlik ekleme formunu kapatın ve listeye gidin mi?",
    add_evt_ok:"Kapat",
    add_evt_canc:"Daha fazla ekle",
    res360_published:"360 derecesinin sonuçları değerlendiricinin Kişisel Hesabında yayınlanmaktadır",
    rm_rm:"Gelişim etkinliği",
    rm_dln:"Süresi doldu",
    rm_deadline:"Son tarih",
    go_course:"Git",
    no_results:"Veri yok",
    enter_course_name:"Aranacak başlığın bir kısmını yazın",
    course_name:"Kurs ekle",
    course_statuses:"Kurs durumu",
    ipr_courses:"RM kursları",
    ipr_export:"Dışa aktar",
    iprset_create_label:"Kim oluşturabilir",
    iprset_edit_label:"Kim düzenleyebilir",
    iprset_read_label:"Kim görebilir",
    iprset_status_label:"Durumu kim değiştirebilir",
    iprset_role_opt:[{value:'M', label:"Moderatör"},{value:'U', label:"Çalışan"},{value:'N', label:"Akıl Hocası"}],

    add_rm:"RM",
    add_ind:"Gösterge",
    ipr_title:"Bireysel gelişim planı",
    ipr_name:"Ipr'nin adı",
    ipr_close:"Kapat",
    ipr_open:"Aç",
    select_person:"Çalışan",
    select_manager:"Yönetici",
    select_mentor:"Akıl Hocası",
    rm_label:"RM",
    rm_options:[
      "Ana sınıf",
      "Araştırma",
      "Assessment",
      "Atölye",
      "Ders",
      "Dönem",
      "Hackathon",
      "Kolaylaştırma",
      "Konferans",
      "Kurs",
      "Mentorluk",
      "Mesafe kursu",
      "Mitap",
      "Oyun",
      "Podcast",
      "Proje",
      "Randevu",
      "Rapor",
      "Seminer",
      "Ses içeriği",
      "Simülasyon",
      "Staj",
      "Sınav",
      "Takdir",
      "Takım oluşturma",
      "Test etme",
      "Toplantı",
      "Universiade",
      "Video içeriği",
      "Web semineri",
      "Yarışma",
      "Çanta",
      "-kendi seçeneğim-"
    ],
    rm_compleeted:"Tamamlandı",
    rm_started:"Devam ediyor",
    rm_new:"Başlamadı",
    rm_status:"Durum",
    hand_made:"Elle gir",
    comp_name:"Yetkinlik adı",
    ind_name:"Göstergenin adı",
    del_ind:"Göstergeyi sil",

    selectcomps_label:"Yetkinlikler",
    selectcomps_title:"Yetkiler ekle",
    selectcomp_message:"Yetkinlik seç",
    selectcomps_message:"Bir veya daha fazla yetkiyi seçin",
    selectcomps_add:"Ekle",
    selectcomps_finish:"Kapat",
    selectscale:"Derecelendirme Ölçeği",
    target_sc:"Hedef seviye",
    current_sc:"Geçerli seviye",

    del_certs:"Sertifika Eğitim bölümünde silindi, buradan silin",
    no_certs:"İndirdiğiniz tek bir sertifikanız yok. Bunları Eğitim bölümüne Ekleyin ",
    aos_filled:"dolu",
    anketa_add:"Anket ekle",
    for_test:"Testler için",
    for_course:"Kurslar için",
    for_event:"Etkinlikler için",
    aos_copy:"(Kopya)",
    new_aos_name:"Kaydetmek için adı değiştirin",
    first_aos_name:"Varsayılan profil",
    aos_edit:"Anketi düzenleme",
    aos_look:"Anketi görüntüle",
    aos_refresh_title:"Varsayılan olarak yükle",
    aos_refresh_message:"Anketiniz varsayılan anketle değiştirilecektir",
    answ_aos:"Cevaplar",
    answer:"Metin",
    score:"Ağırlık",
    quest_aos:"Sorular",
    add_answ:"Cevap ekle",
    other_answ:"Diğer",
    a_o_s:"AOC",
    add_aos:"Soru ekle",
    aos:"Geri bildirim anketi",
    aos_del:"Anketi sil",

    cert_del:"Sertifikayı etkinlikten kaldırmalı mıyım?",
    cert_add:"Sertifika ekle",
    event_certs:"Sertifikaları seç",
    em_del: "Bir etkinliği sil (takvimden de)",
    em_save:"Yaz",
    em_clear:"Temizle",
    em_meeting_notify:"Davetiye gönder",
    em_description:"Olayın açıklaması",
    em_meeting_text:"Davetiye metni",
    em_color:"Olayın rengi",
    em_text_color:"Tekts'in rengi",
    em_attendees:"Üyeler",
    em_host:'Sorumlu',
    em_org:"Organizatör",
    em_title:"Dış ve yüz yüze etkinlikler",
    em_eventName:"Etkinliğin adı",
    em_common_list:"Genel liste",
    em_personas: 'Değerlendirilenler',
    em_close:"seçmek",
    em_location:"Tip",
    em_location_opt:['İç','Dış'],
    em_vid:"Görünüm",
    em_vid_opt:[
      'Ana sinif',
      'Araştırma',
      'Assessment',
      'Atölye',
      'Ders',
      'Değerlendirme',
      'Hackathon',
      'Kolaylaştırma',
      'Konferans',
      'Kurs',
      'Mentorluk',
      'Mesafe rotasi',
      'Mitap',
      'Oturum',
      'Oyun',
      'Podcast',
      'Proje',
      'Rapor',
      'Seminer',
      'Ses içeriği',
      'Simülasyon',
      'Staj',
      'Sınav',
      'Takim oluşturma',
      'Test etme',
      'Toplantı',
      'Universiade',
      'Video içeriği',
      'Web semineri',
      'Yarışma',
      'Çanta',
    ],
    common_list:"Genel liste",
    personas: 'Değerlendirilenler',
    exp_comments:"Yorumlar",
    no_events:"Yaklaşan etkinlik yok",
    em_from_to:"Etkinliğin başlangıcı ve sonu",
    acc1:"Sonuçları kişisel hesapta göster",
    add_section:"Bölüm ekle",
    comps_added:"Eklenen yetkinlikler",
    comps_add:"Yetkinlik ekle",
    attention:"Dikkat",
    del_competence:"Yeterliliği kaldır",
    competencies: 'Yetkinlikler',
    all:"Herkes",
    add_coworkers: 'Meslektaşları ekle',
    coworkers: 'İş arkadaşları',
    add_slaves: 'Köle ekle',
    slaves: 'Köleler',
    add_chief: 'Yönetici ekle',
    chief: 'Yönetici',
    new: 'Çalışan',
    average_: 'Değerlendiricinin katılımı olmayan ortalama',
    start_finish: 'Başlangıç/bitiş',
    departments: 'Bölümler',
    add_departments: 'Birim ekle',
    selectdepartments_title: 'Birim ekle',
    selectdepartments_message: 'Kuruluş birimini seç',
    selectdepartments_add: 'Ekle',
    selectdepartments_finish: 'Kapat',

    rep_competence:"Yetkinlik/Göstergeler",
    rep_indicators:"Uzmanlar",
    rep_scores:"Değerlendirmeler",
    comment:"Değerlendirilen kişiye yorum",
    acc:"Değerlendirilen kişinin kişisel hesabına yayınla",
    you_ass_360:"Size 360 puan verildi. Tüm değerlendirmelerin bir listesini kişisel hesabınıza bakın.",
    del_many_title:"Birden fazla derecelendirmeyi silme",
    del_many_mess:"İşaretli birkaç notu silmek istiyorsunuz",
    rows_per_page: 'Sayfa başına kayıt',
    all_report: 'Genel rapor',
    start_finish: 'Başlangıç/Bitiş',
    competence: 'Yeterlilik',
    selectusers_title: 'Çalışan ekle',
    selectuser_message: 'Kullanıcı seç',
    selectusers_message: 'Bir veya daha fazla kullanıcı seçin',
    selectusers_add: 'Ekle',
    selectusers_finish: 'Kapat',
    title: '360/180 geri bildirim',
    goback: 'Geri',
    loading: 'Yükleniyor',
    remove: 'Kaldırmak',
    assessment: 'geri bildirim',
    competences: 'Yeterlilikleri',
    name: 'İsim',
    date_start: 'Başlangıç tarihi',
    date_finish: 'Bitiş tarihi',
    description: 'Açıklama',
    persona: 'Geri bildirim altında',
    experts: 'Üyeler',
    clear: 'Açık',
    del: 'Silmek',
    add: 'Ekle',
    add_assessment: 'Geri bildirim oluştur',
    add_persona: 'Geri bildirim altında bir kişi ekleyin',
    add_experts: 'Üye ekle',
    ok: 'Tamam',
    reset: 'İptal',
    scores: 'Geri bildirimler',
    publish: 'Yayınla',
    alert_message: 'Data not filled',
    alert_persona: 'Geri bildirim altında',
    alert_experts: 'Üyeler',
    alert_dates: 'Tarih',
    alert_competences: 'Yeterlilikleri',
    alert_scores: 'Geri bildirimler',
    del_ass_title: 'Derecelendirmeyi sil',
    del_ass_mess: 'Derecelendirmeyi sil',
    deleteSome: 'Silmek',
    export: 'İhracat',
    report: 'Rapor',
    del_some_title: 'Silmek',
    del_some_mess: 'Derecelendirmeyi silmek istiyorsunuz',
    more: 'Daha',
    chart: 'Sonuçlar',
    chart_title: '180°/360° Çalışan için derecelendirme tablosu',
    refresh: 'Güncelleme listesi',
    user: 'Çalışan',
    clear_scores: 'Derecelendirmeyi temizle',
    clrscores_title: 'Açık',
    clrscores_message: 'Tüm puanlar bu Derecelendirmeden silinecektir. Devam et?',
    legend: 'Tam yetkinlik adları',
    results: 'Sonuçlar',
    average: 'Ortalama',
    average1: 'Ortalama*',
    close_report: 'Raporu kapat',
    assessment_name: 'Derecelendirme adı',
    action: 'Eylem',
    list: 'Skor listesine geri dön',
    status: 'Durum',
    published: 'Yayınlanan',
    draft: 'Taslak',
  },
  de: {
    sign_up_btn_ok: "Erstellen",
    sign_up_confirm: "Ein neues Portal wird erstellt. Sie werden der Hauptadministrator und können andere Benutzer einladen.",
    is_reg_info: "Diese E-Mail ist nicht vorhanden. Sie können damit ein neues Portal registrieren und Benutzer einladen.",
    for_prog_dop_lb: "Punkte für Kurse/Lektionen/Tests innerhalb",
    passed_prog: "Abgeschlossenes Programm",
    the_collapsed: "Insgesamt älter als 3 Monate",
    the_collapsed_info: "Einträge in den Details, die älter als drei Monate sind, werden zu einem zusammengefasst. Punkte werden dabei summiert",
    the_test: "Test",
    the_lesson: "Lektion",
    passed_course: "Abgeschlossener Kurs",
    points_reset: "Alle Punkte zurücksetzen",
    duration_info: "Programmdauer in Tagen. Berechnet durch Summierung der Dauer aller Module. Wird zur Bestimmung von Fristen für Zuweisungen und Aufgaben verwendet.",
    the_programs: "Programme",
    program_view_btn: "Programmmanager nicht ausgewählt",
    the_program_info: "Durch Klicken auf die Schaltfläche 'Gehe zu' weisen Sie sich das Programm zur Durchführung zu. Sie finden die Zuweisung im Abschnitt 'Meine Programme'.",
    go_to_program: "Gehe zu",
    the_program: "Programm",
    clear_program: "Löschen des Programms. Nur unveröffentlichte Programme können gelöscht werden.",
    uncheck_all_uscheds: "Alle deaktivieren",
    check_all_uscheds: "Alle aktivieren",
    is_archive: "Archiv",
    from_archive: "Aus dem Archiv wiederherstellen",
    to_archive: "In das Archiv verschieben",
    program_archived: "In das Archiv verschoben",
    program_published: "Programm veröffentlicht",
    add_auditors: "Modulverantwortliche",
    modules_auditors: "Bearbeitung von Beobachtern und Modulfristen",
    add_main_auditor_info: "Der Programmleiter ist ein Beobachter in der Aufgabe. Er wird jedem Modul zugewiesen, es sei denn, in den Modulen wird ein anderer Beobachter ausgewählt.",
    dates_schedule_info: "Die Daten sind informativ und werden zur Festlegung von Aufgabenfristen verwendet",
    clear_schedule: "Löschen der Zuweisung. Nur unveröffentlichte Zuweisungen können gelöscht werden.",
    program_updated: "Programm gespeichert",
    program_added: "Programm hinzugefügt",
    program_scheds_done: "Zuweisungen gemacht",
    such_module_exist: "Modul bereits ausgewählt",
    such_user_exist: "Mitarbeiter bereits ausgewählt",
    duration_hint: "Automatisch berechnet",
    degree_add_block_info: "Wählen Sie einen Kompetenzblock aus der Liste und klicken Sie auf +. Seine Kompetenzen werden der 360-Grad-Bewertung hinzugefügt.",
    add_block_label: "Kompetenzblock auswählen",
    slaves_add: "Automatische Hinzufügung von Abteilungsmitarbeitern",
    slaves_add_byhands: "Manuelle Hinzufügung",
    chief_add: "Automatische Hinzufügung der Abteilungsleiter des Mitarbeiters",
    chief_add_byhands: "Manuelle Hinzufügung",
    del_subpassw_title: "Passwort löschen",
    del_subpassw_message: "Das Passwort wird gelöscht, der Benutzer muss es beim nächsten Login erneut eingeben",
    degree_msg_sended: "Benachrichtigungen gesendet",
    degree_checkall_btn: "Alles auswählen",
    degree_clear_btn: "Antworten löschen",
    degree_clear_msg: "Die Bewertungen (Antworten) der ausgewählten Bewerter werden gelöscht. Sie müssen die Bewertungen erneut durchführen.",
    degree_status_student: {
     1: "Keine Expertenbewertungen",
     2: "Keine Experten zugewiesen"
    },
    degree_status_expert: {
     0: "Alle Bewertungen abgegeben",
     2: "Keine Bewertungen für eine oder mehrere Kompetenzen",
     3: "Nicht alle Bewertungen abgegeben"
    },
    degree_status_btn: "Benachrichtigung senden",
    degree_status_msg: "Sie nehmen als Bewerteter an der Mitarbeiterbewertung teil. Erstellen Sie eine Liste der Bewerter und wählen Sie die Genehmiger für diese Liste im persönlichen Kontoabschnitt 'Meine Bewertungen' der App 'Universität' aus.",
    degree_fill_msg: "Sie nehmen als Bewerter an der Mitarbeiterbewertung teil. Bewerten Sie die Mitarbeiter im persönlichen Kontoabschnitt 'Meine Bewertungen' der App 'Universität'.",
    degree_uagree_msg: "Sie wurden als Genehmiger in der 360-Grad-Bewertung ernannt. Gehen Sie zum persönlichen Kontoabschnitt 'Meine Bewertungen' der App 'Universität'.",
    degree_status: {
     0: "Genehmigt",
     1: "Keine zugewiesenen Experten",
     2: "Kein Genehmiger zugewiesen",
     3: "Nicht genehmigt"
    },
    Degree360StatusList: "Bildungsstatus",
    Degree360StatusFill: "Ausfüllstatus",
    change_password: "Passwort ändern",
    change_password_m: "Passwörter der Mitarbeiter ändern",
    change_password_hint: "Geben Sie das neue Passwort und die Bestätigung ein. Melden Sie sich nach dem Ändern des Passworts im System an.",
    change_password_hint_m: "Geben Sie den Mitarbeiter an, das Passwort wird automatisch generiert.",
    change_password_done: "Passwort geändert, verwenden Sie es beim nächsten Login.",
    change_password_fail: "Ein Fehler ist aufgetreten, das Passwort wurde nicht geändert. Versuchen Sie es erneut oder wenden Sie sich an den Administrator.",
    change_password_done_m: "Passwort geändert, kopieren Sie es und geben Sie es dem Mitarbeiter weiter.",
    clear_profile: "Profil löschen",
    clear_profile_msg: "Das Profil wird gelöscht, aber die E-Mail und das Passwort für den Login werden gespeichert. Um ein neues Profil zu erstellen, müssen Sie sich im System anmelden. Sind Sie sicher?",
    clear_profile_done_m: "Profil gelöscht",
    add_head: "Manager",
    add_users: "Mitarbeiter",
    struct_reset_msg: "Möchten Sie die Struktur wirklich zurücksetzen?",
    struct_reset: "Struktur zurücksetzen",
    department_add: "Abteilung",
    struct_company: "/Unternehmen",
    struct_department: "Abteilung",
    company_structure: "Unternehmen",
    last_name_need: "Vor- und Nachname müssen ausgefüllt werden!",

    degree_checkall_btn: "Alles auswählen",
    sign_up_for_an_account: "Melden Sie sich an oder registrieren Sie ein Konto",
    auth_email: "Email",
    auth_password: "Passwort",
    auth_password2: "Passwort wiederholen",
    sign_up: "Registrieren",
    sign_in: "Anmelden",
    email_invalid: "Ungültige Email",
    password_invalid: "Ungültiges Passwort",
    email_exist: "Email gefunden",
    email_ok: "Email verfügbar",
    auth_password_match: "Passwort stimmt überein",
    auth_password_not_match: "Passwörter stimmen nicht überein",
    invitations: "Einladungen",
    link_invite: "Selbstregistrierungslink",
    reminders: "Erinnerungen",
    notifications: "Benachrichtigungen",
    subac_validate_info: "Verwenden Sie nur lateinische Buchstaben und Zahlen für den Subaccount-Namen.",
    promo_1: "Erweitern Sie die Möglichkeiten Ihrer Anwendung. Kaufen Sie ein Abonnement für ein Thema, eine Festplatte oder Unterkonten im Abschnitt Einstellungen:Mein Tarif.",
    prog_filter:"Filter",
    prog_filter_all:"Alle Programme",
    prog_filter_tgrp:"Vorlagen für Gruppen",
    prog_filter_tuser:"Für neue Mitarbeiter",
    prog_is_template:"Dies ist eine Zuweisungsvorlage",
    prog_is_template_newuser:"neue Mitarbeiter",
    prog_is_template_grp:"Für Gruppen",
    prog_info_grp:"Permanente Zuweisung. Wenn ein Mitarbeiter zu einer Gruppe hinzugefügt wird, wird er automatisch zugewiesen.",
    prog_info_newusers:"Wenn Sie dem Bitrix24-Portal einen neuen Mitarbeiter hinzufügen, wird dieser automatisch zugewiesen.",
    im_agreemer:"Ich bin ein Bewertender",
    agree_degree360_mess:"Soll ich die Bewertungsliste für die Bewertung 360 aushandeln?",
    decline_degree360_mess:"Möchten Sie die Liste der Bewertungsberechtigten für die Bewertung 360 zur Vervollständigung zurückgeben?",
    degree360_all_agreed:"Die Bewertungslisten sind für die Bewertung von 360 konsistent",
    degree_valid_1:"Es wurde kein Name für die Bewertung 360 angegeben",
    degree_valid_2:"Es wurden keine Daten angegeben",
    degree_valid_3:"Es wurden keine Auswertungswerte für einen oder mehrere Auswertungswerte angegeben",
    degree_valid_4:"Keine Kompetenzen angegeben",
    degree_valid_5:"Es wurde keine Bewertungsskala 360 angegeben",
    degree_valid_6:"Evaluatoren bei der Selbstauswahl sind nicht konsistent",
    pdf_download:"Datei herunterladen",
    pdf_build:"PDF-Datei erstellen",
    scale_value:"Wert",
    scale_name:"Name",
    scale_desc:"Beschreibung",
    degree360_all_agreed:"Die Bewertungslisten sind für die Bewertung von 360 konsistent",
    degree_valid_1:"Es wurde kein Name für die Bewertung 360 angegeben",
    degree_valid_2:"Es wurden keine Daten angegeben",
    degree_valid_3:"Es wurden keine Auswertungswerte für einen oder mehrere Auswertungswerte angegeben",
    degree_valid_4:"Keine Kompetenzen angegeben",
    degree_valid_5:"Es wurde keine Bewertungsskala 360 angegeben",
    degree_valid_6:"Evaluatoren bei der Selbstauswahl sind nicht konsistent",
    degree360_self_message:"Ihnen wurde eine Aufgabe zugewiesen, um eine Liste von Bewertungsberechtigten für die Bewertung 360 zu erstellen",
    clear_agreed:"Den Übereinstimmenden löschen",
    students_clear:"Alle Bewerteten werden zusammen mit ihren Bewertenden gelöscht",
    add_degree360_info:"Die Bewertung 360 wird vollständig vom Moderator generiert",
    add_task_degree360_info: "Die Liste der Bewerter kann auf zwei Arten erstellt werden: vom Moderator; von den Bewerteten. Bei Aktivierung wird die Liste von den Bewerteten erstellt. Nach der Veröffentlichung der Bewertung haben die Bewerteten in ihrem persönlichen Konto im Abschnitt Meine Bewertungen die Aufgabe, eine Liste zu erstellen und Genehmiger auszuwählen. Die Bewertung beginnt, wenn alle Listen erstellt sind.",
    listDegree360:"360 Bewertungen",
    eve_and_bad_moder:"Bewerbung für eine Veranstaltung von einem Mitarbeiter",
    profiles_lb:"Profile",
    profiles_plc:"Ein Profil enthält mehrere Kompetenzen",
    add_from_comps:"Kompetenz auswählen",
    incomplete_data:"Unvollständige Kompetenz, keine Indikatoren vorhanden",
    experts_clear:"Alle bewertenden Mitarbeiter werden entfernt",
    degree_tasks:[{value:0, label:"Aufgaben einzeln anordnen"},{value:1, label:"eine gemeinsame Aufgabe anordnen"},{value:2, label:" Aufgaben nicht anordnen"}],
    old_assessments:"Altes Bewertungsformat",
    degree_task_name:"Geben Sie einen Auftragsnamen ein",
    degree_task_description:"Geben Sie eine Beschreibung für den Job ein",
    the_task_360:"Aufgabe",
    tasks_360:"Aufgaben",
    add_task_degree360:"Eine Liste der Bewertungsberechtigten erstellen",
    add_degree360:"Bewertung 360 hinzufügen",
    stud_id:"ID des ausgewerteten",
    its_my_degree:"Ich bin ein Bewerteter",
    degree_readonly_info: "Für die 360-Grad-Bewertung wurden bereits Zuweisungen vorgenommen, einige Aktionen mit der Bewertung können nicht durchgeführt werden. Zum Beispiel das Löschen von bewerteten Personen, Bewertern, das Löschen und Hinzufügen von Kompetenzen, das Ändern von Terminen, das Aktivieren der Selbsteinschätzung, das Ändern der Art und Weise, wie Aufgaben festgelegt werden.",
    degree360_alert:"Der Antrag wurde bereits vereinbart und kann nicht geändert werden.",
    degree360_agreeID:"Abgleichen",
    degree360_agreed:"Abgestimmt",
    degree360_wait_agreed:"Ausstehende Aushandlung",
    degree360_self_title:"Bewerbungen für die Bewertung 360",
    degree360_self:"Bewerbung für die Bewertung 360",
    degree_self:"Benotungsliste erstellen",
    degree_self_info:"Um sich zu bewerben, wählen Sie die Bewertenden und Verantwortlichen aus, die für die Abgleichung der Bewertung 360 verantwortlich sind",
    degree_start_finish_info:"Daten werden informativ verwendet und haben keinen Einfluss auf die Anzeige der Schätzung360.",
    agree_not_valid:"Nicht alle Daten sind voll. Bitte überprüfen Sie den Titel, die Daten, die geschätzten, die Bewertenden.",
    degree_add_scale_info:"Wählen Sie eine Bewertungsskala aus der Liste aus, um sie der Bewertung 360 hinzuzufügen",
    degree_add_comp_info:"Wählen Sie eine Kompetenz aus der Liste aus und klicken Sie auf die Schaltfläche <+>, um die Kompetenz der Bewertung 360 hinzuzufügen",
    degree_adb_prof_info:"Wählen Sie ein Profil aus der Liste aus und klicken Sie auf +. Seine Kompetenzen werden der 360-Bewertung hinzugefügt",
    degree_add_experts_info:"Fügen Sie mit den unten stehenden Schaltflächen Bewertende hinzu",
    degree_add_students_info:"Fügen Sie die bewerteten Mitarbeiter mit den unten stehenden Schaltflächen hinzu",
    degree_agree_degree360_info:"In der linken Position des Kontrollkästchens können Sie die Arbeitsbewertung speichern. Rechte Position - Die Zuweisung wird beim Speichern vorgenommen.",
    degree_agree_user_info:"Es ist ein Abgleich erforderlich, um eine Bewertung zuzuweisen. Wählen Sie den zustimmenden Mitarbeiter aus.",
    degree_hide_fio_info:"Standardmäßig wird der Auswertende die Daten der Auswertenden anzeigen. Bei der Aktivierung werden die Daten der Auswertenden ausgeblendet.",
    degree_pub_lk_info:"Veröffentlichen Sie die Bewertungsergebnisse im persönlichen Bereich des Bewerteten, nachdem alle Bewertungsberechtigten eine Bewertung abgegeben haben.",
    degree_task_info:"Aufgabe in der Bitrix, eine Auswertung durchzuführen. Es wird vom Bewertenden gestellt.",
    show_options_block:"Bewertungseinstellungen für 360",
    show_users_block:"Bewertungs- und Bewertungslisten",
    show_comps_block:"Kompetenzen, Indikatoren, Bewertungsskala",
    degree360_aver_title:"Bewertungsergebnisse (Durchschnittswerte)",
    degree360_stat_title:"Anzahl der Befragten, die an der Bewertung teilgenommen haben",
    degree360_aver_col_:"Praxis",
    degree360_stat_ret0:"Anzahl der Personen",
    degree360_stat_col_:"Gruppen von Befragten",
    degree360_stat_col0:"Selbstwertgefühl",
    degree360_stat_col1:"Manager",
    degree360_stat_col2:"Kollegen",
    degree360_stat_col3:"Untergeordnete",
    degree360_stat_col4:"Alle Befragten<br>(ohne Selbsteinschätzung)",
    degree360_stat_col5:"Alle Befragten",
    degree360_description:"Sie müssen eine 360-Bewertung durchführen",
    degree360_message:"Ihnen wurde eine 360-Bewertung zugewiesen. Gehe zur App 'Universität/Meine Noten'",
    you_agree_360:"Sie sind in der Bewertung 360 dem Passenden zugewiesen.  Gehen Sie zur Uni-App zur Abstimmung.",
    del_student:"Sie möchten den ausgewerteten löschen",
    do_schedules:"Die Bewertung 360 wird dem Bewertungsberechtigten zugewiesen",
    no_experts:"Es wurden keine Auswertenden angegeben",
    agree_user:"Übereinstimmend",
    agree_degree360:"Zustimmen",
    decline_degree360:"Zurückgeben",
    decline_degree360_info:"Wenn Sie den Status der Anwendung geändert haben, speichern Sie sie",
    publish_degree360:"Bewertung 360 veröffentlichen",
    del_degree360:"Bewertung 360 löschen",
    del_degree360_msg:"Möchten Sie die Bewertung 360 wirklich löschen?",
    degree_name:"Geben Sie einen Namen für die Bewertung ein",
    add_prof_label:"Profil auswählen",
    scale_plc:"Skala auswählen",
    add_prof:"Zu Kompetenzen hinzufügen",
    save_comp:"Speichern",
    close_comp:"Schließen",
    program_readonly:"Für dieses Programm wurden Zuweisungen vorgenommen, sodass es nicht geändert werden kann. Sie können ein Programm in der Programmliste kopieren, um es zu ändern und neue Zuweisungen zu erstellen.",
    program_info:"Das Modul 'Programme' dient zur Erstellung komplexer Entwicklungsprogramme. Zum Beispiel, wie die Anpassung von Anfängern, die Ausbildung der Personalreserve usw."+
      "<br>Programme können aus den folgenden Elementen gebildet werden: Kurse, Tests, Unterricht (z. B. PDF-Datei, Geschäftsordnung, Präsentation, Links, Materialien, Longride usw.), Aktivitäten (z. B. Erstellen einer Veranstaltung Treffen mit einem Vorgesetzten)."+
      "<br>Programme werden parallel und nacheinander aus Elementen gebildet. Aufeinanderfolgende Elemente bilden die Ebenen (dies kann ein Tag sein, eine Lernphase). Es kann eine beliebige Anzahl von Elementen in einer Ebene geben, was bedeutet, dass sie alle auf dieser Ebene passieren müssen."+
      "<br>Nachdem Sie ein Programm erstellt haben, können Sie es Mitarbeitern und Gruppen zuweisen (wenn Sie einen Mitarbeiter zu einer Gruppe hinzufügen, wird ihm automatisch eine Programmzuweisung zugewiesen). Sie können die Liste der Mitarbeiter ändern: Beim Hinzufügen wird dem Mitarbeiter ein Programm zugewiesen, beim Löschen wird er weiterhin in den Berichten angezeigt."+
      "<br>Das zugewiesene Programm kann nicht geändert werden.",
    copy_program:"Programm kopieren",
    module_level:"Ebene",
    eve_and_bad_moder:"Bewerbung für eine Veranstaltung von einem Mitarbeiter",
    is_answered:"Es gibt Antworten",
    check_all:"Alle Noten markieren",
    check_group:"Gruppe markieren",
    check_one:"Zeile markieren",
    rep_date:"Datum",
    rep_type:"Wofür",
    the_open:"Offene Frage",
    the_event:"Veranstaltung",
    the_360:"Bewertung 360",
    the_aos:"AOS",
    the_rate:"Kurs-/Testbewertung",
    the_comment:"Kommentar",
    the_ipr:"YPR",
    the_Rc:"Bewertung des Kurses",
    the_Rt:"Testbewertung",
    the_course:"Kurs",
    write_off:"Abschreiben",
    not_in_sched:"Fehlt in der Programmzuweisung",
    sub_alert:"Dieses Konto enthält Unteraccounts, aber das Limit ist ausgeschöpft. Wenden Sie sich an den Moderator der App",
    sub_limit_exceeded:"Das Subaccount-Limit ist erschöpft. Gehen Sie zu Einstellungen/Mein Tarif, um das Limit zu erhöhen",
    sub_login_already:"Eine solche Unterakkuunt existiert bereits",
    sub_accounts:"Subaccounts",
    native_add:"Konto hinzufügen. Nur für den Portal-Administrator verfügbar",
    sub_add:"Subaccount hinzufügen",
    del_subac_title:"Unteraccount löschen",
    del_subac_message:"Der Subaccount wird aus der Liste entfernt. Sind Sie sicher?",
    del_sub_title:"Konto löschen",
    del_sub_message:"Das Konto wird zusammen mit seinen Unteraccounts aus der Liste entfernt. Sind Sie sicher?",
    self_sign_up:"Selbstregistrierung",
    sub_acc_title:"Autorisieren",
    sub_acc_login:"Login",
    sub_acc_password:"Passwort",
    sub_acc_password2:"Passwort wiederholen",
    sub_acc_recv:"Ihre Daten",
    sub_acc_first_name:"Name",
    sub_acc_last_name:"Nachname",
    sub_acc_middle_name:"Vorname",
    sub_acc_position:"Position",
    sub_acc_login_not_found:"Kein Login gefunden, Registrierung erforderlich",
    sub_acc_save:"Speichern",
    sub_acc_ok:"Anmelden",
    sub_acc_passwords_not_match:"Passwörter stimmen nicht überein",
    sub_acc_password_fail:"Falsches Passwort",
    sub_acc_valid_recv:"Füllen Sie die Details aus",
    sub_acc_recv:"Daten ändern",
    sub_important_info: "Wichtige Informationen",
    sub_info: "<ol>"+
     "<li><b>Ein Konto hinzufügen.</b><br>"+
     "Wichtige Bedingungen:</li>"+
     "<ul><li>Dies ist ein Bitrix24-Konto, mit dem Universitätsunterkonten verknüpft werden.</li>"+
     "<li>Nur der Bitrix24-Portaladministrator kann ein Konto erstellen. Nicht der Anwendungsmoderator.</li>"+
     "<li>Das Konto kann kein Bitrix24-Administrator oder ein Moderator der Universitätsanwendung sein.</li></ul>"+
     "<li><b>Wählen Sie die Methode zur Registrierung von Unterkonten in der Anwendung:</b></li>"+
     "<ul><li><b>Unabhängig.</b> In diesem Fall muss der Portaladministrator nur die Anmeldedaten eingeben und diese Anmeldedaten den Unterkonten mitteilen. Beim Einloggen geben die Unterkonten ihre Anmeldedaten ein, dann fordert die Anwendung sie auf, die Daten (vollständiger Name und Position) auszufüllen und ein Passwort zu erstellen.</li>"+
     "<li><b>Durch den Administrator.</b> In diesem Fall gibt der Administrator die Anmeldedaten ein und füllt alle Daten (vollständiger Name und Position) aus und stellt dann die Anmeldedaten den Unterkonten zur Verfügung. Beim Einloggen wird das Unterkonto aufgefordert, ein Passwort zu erstellen. Die anderen Daten (vollständiger Name und Position) werden bereits vom Administrator ausgefüllt.</li></ul>"+
     "<li><b>Jedes Mal, wenn sie sich in die Anwendung einloggen, müssen die Unterkonten ihre Anmeldedaten und ihr Passwort eingeben.</b></li>"+
     "</ol>",
     cert_format:"Seitenformat",
    cert_name:"Name des Zertifikats",
    cert_save:"Zertifikat speichern",
    cert_new:"Zertifikat hinzufügen",
    cert_list:"Liste der Zertifikate",
    clear_form:"Neues Zertifikat",
    cert_remove:"Aus der Liste entfernen",
    cert_default:"Standard",
    cert_scale:"Maßstab",
    cert_opts_fio:"Benutzername",
    cert_opts_pos:"Position",
    cert_opts_name:"Name des Tests/Kurses",
    cert_opts_score:"Durchlaufende Punktzahl",
    cert_opts_date:"Durchlaufdatum",
    cert_opts_text:"Zusätzlicher Text",
    cert_dark_mode:"Dunkler Hintergrund",
    cert_orient_a:"Querformat",
    cert_orient_p:"Hochformat",
    certs:"Zertifikate",
    cer_tificate:"Zertifikat",
    cert_img:"Bild",
    save_png:"Herunterladen",
    cert_del_title:"Achtung!",
    cert_del_message:"Das Zertifikat wird aus der Liste entfernt. Die Datei wird ebenfalls von der Festplatte gelöscht.",
    cert_reload_title:"Achtung!",
    cert_reload_message:"Um die Änderungen zu übernehmen, müssen Sie die Zertifikateinstellungen beenden und erneut aufrufen.",
    cert_font_size:"Schriftgröße",
    cert_font:"Schriftart",
    cert_font_color:"Schriftfarbe",
    is_program_tasks:[{value:0, label:"Aufgaben für jedes Modul setzen"},{value:1, label:"eine gemeinsame Aufgabe setzen"},{value:2, label:" keine Aufgaben setzen"}],
    program_tasks:"Aufgaben",
    program_task_description:"Sie müssen das Programm ausführen",
    course_passed:"Kurs bestanden",
    module_complete:"Modul beenden",
    done_success:"Erfolgreich!",
    save_program:"Speichern",
    add_module_s:"Serielles Modul hinzufügen",
    add_module_p:"Paralleles Modul hinzufügen",
    program_message:"Ihnen wurde ein Trainingsprogramm zugewiesen. Gehe zur App 'Universität/Meine Programme'",
    my_programs:"Meine Programme",
    publish_schedule_tooltip:"Es wurde kein Programm ausgewählt oder es wurden keine Termine festgelegt, Manager, Mitarbeiter",
    mod_description:"Im Ausführungsplan des Programms müssen Sie das Modul ausführen",
    list_schedules:"Zuweisungen",
    add_schedule:"Ziel hinzufügen",
    one_schedule:"Ziel",
    publish_schedule:"Zuweisung vornehmen",
    dates_schedule:"Zieldaten",
    add_students:"Mitarbeiter",
    dep_rem_title:"Aus dem Ziel entfernen?",
    dep_rem_mess:"Alle mit dieser Gruppe (Organisationseinheit) löschen",
    dep_rem_mess1:"Diesen Teilnehmer entfernen",
    start_finish:"Anfang/Ende",
    selectusers_label:"Mitarbeiter",
    selectgroups_label:"Gruppen",
    selectdeps_label:"Abteilungen",
    selecttests_label:"Kurse/Tests",
    selectgroup_title:"Gruppe hinzufügen",
    selectgroup_message:"Gruppe auswählen",
    courses_tests:"Kurse-Tests",
    students:"Lernende",
    del_schedule_title:"Löschen",
    del_schedule_message:"Bestätigen Sie, dass das Ziel gelöscht wird",
    del_schedules_message:"Bestätigen Sie das Löschen von Zuweisungen",
    date_to_short:"Endungen",
    students_comment:"Wenn ein Mitarbeiter über eine Abteilung oder Gruppe hinzugefügt wurde, führt das Entfernen eines solchen Mitarbeiters dazu, dass auch andere Mitarbeiter dieser Abteilung/Gruppe entfernt werden",
    add_department:"Abteilung",
    add_group:"Gruppe",
    add_main_auditor:"Manager",
    mod_duration:"Dauer, Tag.",
    mod_action:{'hand':"manuell", 'auto':"automatisch"},
    add_program:"Programm hinzufügen",
    list_programs:"Liste der Programme",
    one_program:"Programm",
    progrm_modules:"Module",
    requaried_field:"Erforderliches Feld",
    program_name:"Name des Programms",
    program_description:"Programmbeschreibung",
    module_type:"Modultyp",
    module_type_opt:[{value:1, label:"Kurs"},{value:2, label:"Test"},{value:3, label:"Lektion"}],
    select_module:"Modul auswählen",
    not_found:"Es wurde nichts gefunden",
    type_open:"Methode zum Öffnen von Modulen",
    type_open_opt:[{value:'P', label:"Alles auf einmal öffnen"},{value:'S', label:"Nacheinander öffnen. Wenn das Modul fehlschlägt, wird immer noch folgendes geöffnet"},{value:'H', label:"Nacheinander öffnen. Wenn das Modul fehlschlägt, das nachfolgende Modul nicht öffnen"}],
    publish_program:"Programm veröffentlichen",
    del_program:"Löschen",
    del_program_msg:"Sie sind sicher, dass Sie es löschen möchten",
    mod_passed:[{value:'0', label:"Kein Status"}, {value:'Y', label:"Bestanden"},{value:'N', label:"Fehlgeschlagen"}],

    points:"Spielstände",
    points_plc:"Setze 0, wenn die Punkte wie in den App-Einstellungen festgelegt vergeben werden",
    for_course_active:"Absolvierter Kurs",
    for_course_mod_lb:"Zählmethode",
    for_course_mode:[
      {value:'sum', label:"Durch Summieren der Punkte für Module berechnen"},
      {value:'hand', label:"Manuell eingeben"},
    ],
    for_course_dop_lb: "Punkte für Lektionen/Tests innerhalb des Kurses",
    for_course_dop:[
      {value:'top', label:"Berechnen "},
      {value:'node', label:"Nicht belasten"},
    ],
    separ_add:"Trennzeichen hinzufügen",
    sep:"Trennzeichen",
    bid_del_message: "Gebot löschen",
    em_count_limit: "Maximale Teilnehmerzahl überschritten",
    em_count: "Anzahl der Teilnehmer. 0-unbegrenzt",
    eve_attende:"Teilnehmer",
    eve_bid:"Gebot",
    not_published:"Yayınlanmamıs",
    my_events:"Meine Veranstaltungen",
    ipr_comp:"Kompetenz",
    ipr_ind:"Indikatoren",
    eve_bid_done:"Ihr Antrag wurde hinzugefügt. Nach der Überprüfung werden Sie Mitglied.",
    eve_new_big_add:"Ist der gewünschte Termin nicht aufgeführt? Bieten Sie eine Bewerbung an. Nach der Überprüfung wird die Veranstaltung zur Liste hinzugefügt und Sie werden Mitglied.",
    eve_new_bid_title:"Anmeldung für eine neue Veranstaltung",
    eve_new_bid_done:"Ihr Antrag wurde hinzugefügt. Nach der Überprüfung wird die Veranstaltung zur Liste hinzugefügt und Sie werden Mitglied.",
    alert_understood:"Klar",
    eve_new_bid_moder:"Eine Anfrage für eine Veranstaltung von einem Mitarbeiter ist eingegangen",
    em_details:"Details",
    bid_restore_title:"Anforderung wiederherstellen",
    bid_restore_message:"Geben Sie an, warum die Anforderung wiederhergestellt wurde",
    bid_accepted:"Antrag wurde angenommen",
    bid_declined:"Antrag abgelehnt, wiederherstellen.",
    bind_accept:"Bewerbung annehmen",
    bid_decline_title:"Angebot ablehnen",
    bid_decline_message:"Geben Sie den Grund für die Ablehnung des Angebots an",
    eve_bids_all:"Alle hinzufügen",
    eve_calend:"Im Kalender",
    eve_detail:"Weitere Informationen zur Veranstaltung",
    eve_edit:"Ändern",
    em_bids:"Bewerbungen",
    eve_bid_lbl:"Ich bin berechtigt",
    eve_title:"Bewerbe dich für eine Veranstaltung",
    eve_bin_add:"Ich werde Mitglied sein",
    eve_nts:"Veranstaltungen",
    text_block:"Textblock",
    text_block_name:"Feldname",
    text_block_value:"Feldinhalt",
    r_o:"Schreibgeschützt",
    set_files:"Dateien",
    integral_chart:"Integraldiagramm",
    view_cards:"Ansicht: Karten",
    view_list:"Ansicht: Liste",
    node_disabled: "Inaktiv machen",
    node_enabled: "Aktiv machen",
    download: "Herunterladen",
    no_valid_publish:"Es wurden keine wichtigen Daten angegeben, z. B. Mitarbeiter, Vorgesetzter, Mentor, Kompetenzen, Indikatoren oder keine Berechtigung zum Bearbeiten",
    hide_fio:"Daten der Auswertenden ausblenden",
    such_element_exist:"Ein solches Element existiert bereits",
    program_course:"/Kursprogramm",
    lessons:"Lektionen",
    tests:"Tests",
    no_lessons:"Es gibt keine Lektionen, die dem Kurs hinzugefügt werden können",
    no_tests:"Es gibt keine Tests, die dem Kurs hinzugefügt werden können",
    drop_here:"Lektionen und Tests hierher ziehen",
    reset_tree:"Navigation zurücksetzen",
    reset_true_msg:"Möchten Sie alle Lektionen und Tests aus dem Kurs entfernen?",
    export_all:"Alle exportieren",
    imgFiles:"Antwortende Dateien",
    upload_title:"Hochladen",
    upload_err:"Hochladen fehlgeschlagen",
    upload_hint:"Wählen Sie eine Datei auf der Festplatte oder ziehen Sie sie hierher",
    max_file_size:"Dateigröße darf {mb} Mb nicht überschreiten",
    delete_image:"Datei löschen",

    ipr_note:"Ihnen wurde ein 'Individueller Entwicklungsplan' zugewiesen. Gehen Sie zu Ihrer University App.",
    add_evt_title:"Ereignis hinzugefügt",
    add_evt_msg:"Schließen Sie das Formular zum Hinzufügen von Veranstaltungen und gehen Sie zur Liste?",
    add_evt_ok:"Schließen",
    add_evt_canc:"Mehr hinzufügen",
    res360_published:"Die Ergebnisse der Bewertung 360 werden im persönlichen Bereich des Bewerteten veröffentlicht",
    rm_rm:"Eine Entwicklungsveranstaltung",
    rm_dln:"Abgelaufen",
    rm_deadline:"Frist",
    go_course:"Gehe zu",
    no_results:"Keine Daten vorhanden",
    enter_course_name:"Geben Sie einen Teil des Namens ein, nach dem gesucht werden soll",
    course_name:"Kurs hinzufügen",
    course_statuses:"Kursstatus",
    ipr_courses:"RM Kurse",
    ipr_export:"Exportieren",
    iprset_create_label:"Wer kann das erstellen?",
    iprset_edit_label:"Wer kann das bearbeiten?",
    iprset_read_label:"Wer kann sehen",
    iprset_status_label:"Wer kann den Status ändern",
    iprset_role_opt:[{value:'M', label:"Moderator"},{value:'U', label:"Mitarbeiter"},{value:'N', label:"Mentor"}],

    add_rm:"RM",
    add_ind:"Indikator",
    ipr_title:"Individueller Entwicklungsplan",
    ipr_name:"IPR-Name",
    ipr_close:"Schließen",
    ipr_open:"Öffnen",
    select_person:"Mitarbeiter",
    select_manager:"Manager",
    select_mentor:"Mentor",
    rm_label:"rm",
    rm_options:[
      "Aktenkoffer",
      "Assesment",
      "Audioinhalte",
      "Bericht",
      "Bewertung",
      "Fernkurs",
      "Forschung",
      "Hackathon",
      "Konferenz",
      "Kurs",
      "Meisterklasse",
      "Mentoring",
      "Mitap",
      "Moderation",
      "Podcast",
      "Praktikum",
      "Projekt",
      "Quiz",
      "Seminar",
      "Simulierung",
      "Sitzung",
      "Spiel",
      "Teambildung",
      "Testen",
      "Training",
      "Treff",
      "Universiade",
      "Videoinhalte",
      "Vortrag",
      "Webinar",
      "Wettbewerb",
      "Workshop",
      "-eine eigene Option-"
    ],
    rm_compleeted:"Erledigt",
    rm_started:"In Bearbeitung",
    rm_new:"Nicht gestartet",
    rm_status:"Status",
    hand_made:"Manuell eingeben",
    comp_name:"Kompetenzname",
    ind_name:"Name des Indikators",
    del_ind:"Indikator löschen",

    selectcomps_label:"Kompetenzen",
    selectcomps_title:"Kompetenzen hinzufügen",
    selectcomp_message:"Kompetenz auswählen",
    selectcomps_message:"Wählen Sie eine oder mehrere Kompetenzen aus",
    selectcomps_add:"Hinzufügen",
    selectcomps_finish:"Schließen",
    selectscale:"Bewertungsskala",
    target_sc:"Zielebene",
    current_sc:"Aktuelles Niveau",

    del_certs:"Das Zertifikat wurde im Abschnitt Bildung gelöscht, löschen Sie es hier",
    no_certs:"Sie haben kein einziges Zertifikat heruntergeladen. Fügen Sie sie im Abschnitt Bildung hinzu ",
    aos_filled:"voll",
    anketa_add:"Profil hinzufügen",
    for_test:"Für Tests",
    for_course:"Für Kurse",
    for_event:"Für Veranstaltungen",
    aos_copy:"(Kopie)",
    new_aos_name:"Ändern Sie den Namen, um ihn zu speichern",
    first_aos_name:"Standardprofil",
    aos_edit:"Profil bearbeiten",
    aos_look:"Profil ansehen",
    aos_refresh_title:"Standard laden",
    aos_refresh_message:"Ihr Fragebogen wird durch den Standardfragebogen ersetzt",
    answ_aos:"Antworten",
    answer:"Text",
    score:"Gewicht",
    quest_aos:"Fragen",
    add_answ:"Antwort hinzufügen",
    other_answ:"Andere",
    a_o_s:"AOS",
    add_aos:"Frage hinzufügen",
    aos:"Feedback-Fragebogen",
    aos_del:"Profil löschen",

    cert_del:"Möchten Sie das Zertifikat aus der Veranstaltung entfernen?",
    cert_add:"Zertifikat hinzufügen",
    event_certs:"Zertifikate auswählen",
    em_del: "Ein Ereignis löschen (auch aus dem Kalender)",
    em_save:"Schreiben",
    em_clear:"Löschen",
    em_meeting_notify:"Einladungen senden",
    em_description:"Beschreibung des Ereignisses",
    em_meeting_text:"Einladungstext",
    em_color:"Ereignisfarbe",
    em_text_color:"Tex-Farbe",
    em_attendees:"Mitglieder",
    em_host:'Verantwortlich',
    em_org:"Veranstalter",
    em_title:"Externe und private Aktivitäten",
    em_eventName:"Name der Veranstaltung",
    em_common_list:"Allgemeine Liste",
    em_personas: 'Bewertet',
    em_close:"wählen",
    em_location:"Typ",
    em_location_opt:['Intern','Extern'],
    em_vid:"Ansicht",
    em_vid_opt:[
      'Assesment',
      'Audioinhalte',
      'Bericht',
      'Bewertung',
      'Fernkurs',
      'Forschung',
      'Hackathon',
      'Koffer',
      'Konferenz',
      'Kurs',
      'Meisterklasse',
      'Mentoring',
      'Mitap',
      'Moderation',
      'Podcast',
      'Praktikum',
      'Projekt',
      'Quiz',
      'Seminar',
      'Simulation',
      'Sitzung',
      'Spiel',
      'Teambildung',
      'Testen',
      'Training',
      'Universiade',
      'Videoinhalte',
      'Vortrag',
      'Webinar',
      'Wettbewerb',
      'Workshop',
    ],
    common_list:"Allgemeine Liste",
    personas: 'Bewertet',
    exp_comments:"Kommentare",
    no_events:"Keine bevorstehenden Veranstaltungen",
    em_from_to:"Beginn und Ende der Veranstaltung",
    acc1:"Ergebnisse im persönlichen Konto anzeigen",
    add_section:"Abschnitt hinzufügen",
    comps_added:"Hinzugefügte Kompetenzen",
    comps_add:"Kompetenz hinzufügen",
    attention:"Achtung",
    del_competence:"Kompetenz löschen",
    competencies: 'Kompetenzen',
    all:"Alles",
    add_coworkers: 'Kollegen hinzufügen',
    coworkers: 'Kollegen',
    add_slaves: 'Untergebene hinzufügen',
    slaves: 'Untergebene',
    add_chief: 'Manager hinzufügen',
    chief: 'Vorgesetzter',
    neu: 'Mitarbeiter',
    average_: 'Durchschnitt ohne Bewertung',
    departments: 'Abteilungen',
    add_departments: 'Organisationseinheiten hinzufügen',
    selectdepartments_title: 'Organisationseinheit hinzufügen',
    selectdepartments_message: 'Organisationseinheit auswählen',
    selectdepartments_add: 'Hinzufügen',
    selectdepartments_finish: 'Schließen',

    rep_competence:"Kompetenz/Indikatoren",
    rep_indicators:"Experten",
    rep_scores:"Schätzungen",
    comment:"Ein Kommentar, der ausgewertet wird",
    acc:"Im persönlichen Bereich des bewerteten veröffentlichen",
    you_ass_360:"Ihnen wurde eine 360-Punktzahl zugewiesen. Eine Liste aller Bewertungen finden Sie in Ihrem persönlichen Konto.",
    del_many_title:"Mehrere Bewertungen löschen",
    del_many_mess:"Sie möchten mehrere markierte Bewertungen löschen",
    rows_per_page: 'Einträge pro Seite',
    all_report: 'Gesamtbericht',
    start_finish: 'Start/ende',
    competence: 'Kompetenz',
    selectusers_title: 'Mitarbeiter hinzufügen',
    selectuser_message: 'Nutzer wählen',
    selectusers_message: 'Wählen Sie einen oder mehrere Benutzer aus',
    selectusers_add: 'Hinzufügen',
    selectusers_finish: 'Nah dran',
    title: '360/180-Feedback',
    goback: 'Zurück',
    loading: 'Wird geladen',
    remove: 'Entfernen',
    assessment: 'Rückmeldung',
    competences: 'Kompetenzen',
    name: 'Name',
    date_start: 'Startdatum',
    date_finish: 'Enddatum',
    description: 'Beschreibung',
    persona: 'Unter Feedback',
    experts: 'Mitglieder',
    clear: 'Klar',
    del: 'Löschen',
    add: 'Hinzufügen',
    add_assessment: 'Feedback erstellen',
    add_persona: 'Fügen Sie eine Person unter Feedback hinzu',
    add_experts: 'Mitglieder hinzufügen',
    ok: 'OK',
    reset: 'Abbrechen',
    scores: 'Rückmeldungen',
    publish: 'Veröffentlichen',
    alert_message: 'Daten nicht ausgefüllt',
    alert_persona: 'Unter Feedback',
    alert_experts: 'Mitglieder',
    alert_dates: 'Termine',
    alert_competences: 'Kompetenzen',
    alert_scores: 'Rückmeldungen',
    del_ass_title: 'Bewertung löschen',
    del_ass_mess: 'Bewertung löschen',
    deleteSome: 'Löschen',
    export: 'Export',
    report: 'Prüfbericht',
    del_some_title: 'Löschen',
    del_some_mess: 'Sie möchten die Bewertung löschen',
    more: 'Mehr',
    chart: 'Ergebnisse',
    chart_title: '180°/360° Bewertungstabelle für Mitarbeiter',
    refresh: 'Aktualisierungsliste',
    user: 'Angestellter',
    clear_scores: 'Bewertung löschen',
    clrscores_title: 'Klar',
    clrscores_message: 'Alle Ergebnisse werden aus dieser Bewertung gelöscht. Weitermachen?',
    legend: 'Vollständige Kompetenznamen',
    results: 'Ergebnisse',
    average: 'Durchschnitt',
    average1: 'Durchschnitt*',
    close_report: 'Bericht schließen',
    assessment_name: 'Bewertungsname',
    action: 'Handlung',
    list: 'Zurück zur Punkteliste',
    status: 'Status',
    published: 'Veröffentlicht',
    draft: 'Luftzug',
  },
  pl: {
    sign_up_btn_ok: "Utwórz",
    sign_up_confirm: "Zostanie utworzony nowy portal. Zostaniesz głównym administratorem i będziesz mógł zaprosić innych użytkowników.",
    is_reg_info: "Taki email nie istnieje. Możesz zarejestrować nowy portal i zaprosić do niego użytkowników.",
    for_prog_dop_lb: "Punkty za kursy/lekcje/testy wewnątrz",
    passed_prog: "Ukończony program",
    the_collapsed: "Łącznie starsze niż 3 miesiące",
    the_collapsed_info: "Wpisy w szczegółach starsze niż trzy miesiące są łączone w jeden. Punkty są sumowane w tym procesie",
    the_test: "Test",
    the_lesson: "Lekcja",
    passed_course: "Ukończony kurs",
    points_reset: "Zresetuj wszystkie punkty",
    duration_info: "Czas trwania programu w dniach. Obliczany przez zsumowanie czasu trwania wszystkich modułów. Używany przy określaniu terminów przydziałów i zadań.",
    the_programs: "Programy",
    program_view_btn: "Nie wybrano kierownika programu",
    the_program_info: "Klikając przycisk 'Przejdź', przypiszesz sobie program do ukończenia. Możesz znaleźć przypisanie w sekcji 'Moje programy'.",
    go_to_program: "Przejdź",
    the_program: "Program",
    clear_program: "Usuwanie programu. Można usunąć tylko nieopublikowane programy.",
    uncheck_all_uscheds: "Odznacz wszystko",
    check_all_uscheds: "Zaznacz wszystko",
    is_archive: "Archiwum",
    from_archive: "Przywróć z archiwum",
    to_archive: "Przenieś do archiwum",
    program_archived: "Przeniesiono do archiwum",
    program_published: "Program opublikowany",
    add_auditors: "Odpowiedzialni za moduł",
    modules_auditors: "Edycja obserwatorów i terminów modułów",
    add_main_auditor_info: "Kierownik programu jest obserwatorem w zadaniu. Będzie przypisany do każdego modułu, chyba że w modułach zostanie wybrany inny obserwator.",
    dates_schedule_info: "Daty są informacyjne i używane do ustalania terminów zadań",
    clear_schedule: "Usuwanie przydziału. Można usunąć tylko nieopublikowane przydziały.",
    program_updated: "Program zapisany",
    program_added: "Program dodany",
    program_scheds_done: "Przydziały wykonane",
    such_module_exist: "Moduł już wybrany",
    such_user_exist: "Pracownik już wybrany",
    duration_hint: "Obliczane automatycznie",
    degree_add_block_info: "Wybierz z listy blok kompetencji i kliknij +. Jego kompetencje zostaną dodane do Oceny 360.",
    add_block_label: "Wybierz blok kompetencji",
    slaves_add: "Automatyczne dodawanie pracowników działu",
    slaves_add_byhands: "Ręczne dodawanie",
    chief_add: "Automatyczne dodawanie kierowników działu pracownika",
    chief_add_byhands: "Ręczne dodawanie",
    del_subpassw_title: "Usuwanie hasła",
    del_subpassw_message: "Hasło zostanie wyczyszczone, użytkownik będzie musiał je ponownie wprowadzić przy logowaniu",
    degree_msg_sended: "Powiadomienia wysłane",
    degree_checkall_btn: "Zaznacz wszystko",
    degree_clear_btn: "Usuń odpowiedzi",
    degree_clear_msg: "Oceny (odpowiedzi) wybranych oceniających zostaną usunięte. Będą musieli ponownie przeprowadzić oceny.",
    degree_status_student: {
     1: "Brak ocen ekspertów",
     2: "Brak przypisanych ekspertów"
    },
    degree_status_expert: {
     0: "Wszystkie oceny zostały podane",
     2: "Brak ocen dla jednej lub więcej kompetencji",
     3: "Nie wszystkie oceny zostały podane"
    },
    degree_status_btn: "Wyślij powiadomienie",
    degree_status_msg: "Bierzesz udział w ocenie pracowników jako oceniany. Utwórz listę oceniających i wybierz zatwierdzających tę listę w sekcji 'Moje oceny' w osobistym koncie aplikacji 'Uniwersytet'.",
    degree_fill_msg: "Bierzesz udział w ocenie pracowników jako oceniający. Oceń pracowników w sekcji 'Moje oceny' w osobistym koncie aplikacji 'Uniwersytet'.",
    degree_uagree_msg: "Zostałeś wyznaczony jako zatwierdzający w ocenie 360. Przejdź do sekcji 'Moje oceny' w osobistym koncie aplikacji 'Uniwersytet'.",
    degree_status: {
     0: "Zatwierdzono",
     1: "Brak przypisanych ekspertów",
     2: "Brak przypisanego zatwierdzającego",
     3: "Nie zatwierdzono"
    },
    Degree360StatusList: "Statusy tworzenia",
    Degree360StatusFill: "Statusy wypełniania",
    change_password: "Zmień hasło",
    change_password_m: "Zmień hasła pracowników",
    change_password_hint: "Wprowadź nowe hasło i potwierdzenie. Po zmianie hasła zaloguj się do systemu.",
    change_password_hint_m: "Wskaż pracownika, hasło zostanie wygenerowane automatycznie.",
    change_password_done: "Hasło zmienione, użyj go przy następnym logowaniu.",
    change_password_fail: "Wystąpił błąd, hasło nie zostało zmienione. Spróbuj ponownie lub skontaktuj się z administratorem.",
    change_password_done_m: "Hasło zmienione, skopiuj je i przekaż pracownikowi.",
    clear_profile: "Usuń profil",
    clear_profile_msg: "Profil zostanie usunięty, ale email i hasło do logowania zostaną zachowane. Aby utworzyć nowy profil, musisz zalogować się do systemu. Czy jesteś pewien?",
    clear_profile_done_m: "Profil usunięty",
    add_head: "Kierownik",
    add_users: "Pracownik",
    struct_reset_msg: "Czy na pewno chcesz zresetować strukturę?",
    struct_reset: "Zresetuj strukturę",
    department_add: "Dział",
    struct_company: "/Firma",
    struct_department: "Dział",
    company_structure: "Firma",
    last_name_need: "Imię i nazwisko muszą być wypełnione!",
    degree_checkall_btn: "Zaznacz wszystko",
    sign_up_for_an_account: "Zaloguj się lub zarejestruj konto",
    auth_email: "Email",
    auth_password: "Hasło",
    auth_password2: "Powtórz hasło",
    sign_up: "Zarejestruj się",
    sign_in: "Zaloguj się",
    email_invalid: "Nieprawidłowy email",
    password_invalid: "Nieprawidłowe hasło",
    email_exist: "Email znaleziony",
    email_ok: "Email dostępny",
    auth_password_match: "Hasło pasuje",
    auth_password_not_match: "Hasła nie pasują",
    invitations: "Zaproszenia",
    link_invite: "Link do samodzielnej rejestracji",
    reminders: "Przypomnienia",
    notifications: "Powiadomienia",
    subac_validate_info: "Używaj tylko liter łacińskich i cyfr w nazwie subkonta.",
    promo_1: "Rozszerz możliwości swojej aplikacji. Kup subskrypcję na temat, dysk lub subkonta w sekcji Ustawienia:Mój Plan.",
    prog_filter:"Filtr",
    prog_filter_all: "Wszystkie programy",
    prog_filter_tgrp: "Szablony dla grup",
    prog_filter_tuser:" dla nowych pracowników",
    prog_is_template:" to jest szablon do przypisania",
    prog_is_template_newuser: "nowi pracownicy",
    prog_is_template_grp:" dla grup",
    prog_info_grp: "stałe zadanie. Po dodaniu pracownika do grupy zostanie on automatycznie przydzielony.",
    prog_info_newusers: "po dodaniu nowego pracownika do portalu Bitrix24 zostanie on automatycznie przydzielony.",
    im_agreemer: "jestem oceniającym",
    agree_degree360_mess: "uzgodnić listę oceniających dla oceny 360?",
    decline_degree360_mess: "zwrócić listę oceniających do oceny 360 do rewizji?",
    degree360_all_agreed:" listy oceniających są spójne dla oceny 360",
    degree_valid_1:" Nie podano tytułu oceny 360",
    degree_valid_2:"Nie podano dat",
    degree_valid_3:" nie ustawiono oceniających dla jednego lub więcej oceniających",
    degree_valid_4:"Nie podano kompetencji",
    degree_valid_5:"nie ustawiono skali oceny 360",
    degree_valid_6: "nie uzgodniono oceniających przy samodzielnym wyborze",
    pdf_download: "Pobierz plik",
    pdf_build: "Zbuduj plik PDF",
    scale_value:"wartość",
    scale_name:"Tytuł",
    scale_desc:"opis",
    degree360_all_agreed:" listy oceniających są spójne dla oceny 360",
    degree_valid_1:" Nie podano tytułu oceny 360",
    degree_valid_2:"Nie podano dat",
    degree_valid_3:" nie ustawiono oceniających dla jednego lub więcej oceniających",
    degree_valid_4:"Nie podano kompetencji",
    degree_valid_5:"nie ustawiono skali oceny 360",
    degree_valid_6: "nie uzgodniono oceniających przy samodzielnym wyborze",
    degree360_self_message: "masz zadanie tworzenia listy oceniających do oceny 360",
    clear_agreed: "Usuń dopasowanie",
    students_clear: "wszyscy oceniający zostaną usunięci wraz ze swoimi oceniającymi",
    add_degree360_info: "wynik 360 jest w pełni tworzony przez moderatora",
    add_task_degree360_info: "Lista oceniających może być tworzona na dwa sposoby: przez moderatora; przez ocenianych. Po aktywacji lista będzie tworzona przez ocenianych. Po opublikowaniu oceny oceniani będą mieli zadanie w swoim koncie osobistym w sekcji Moje oceny, aby stworzyć listę i wybrać zatwierdzających. Ocena rozpocznie się, gdy wszystkie listy zostaną utworzone.",
    listDegree360:"oceny 360",
    eve_and_bad_moder: "zgłoszenie do udziału w wydarzeniu od pracownika",
    profiles_lb:"profile",
    profiles_plc: "profil zawiera wiele kompetencji",
    add_from_comps:"Wybierz kompetencje",
    incomplete_data:" niekompletna kompetencja, brak wskaźników",
    experts_clear:"wszyscy oceniający pracownicy zostaną usunięci",
    degree_tasks: [{value: 0, label:"ustaw zadania indywidualnie"},{value: 1, label:"Ustaw jedno wspólne zadanie"},{value: 2, label:"nie ustawiaj zadań"}],
    old_assessments: "stary format ocen",
    degree_task_name: "wpisz nazwę zadania",
    degree_task_description:"wprowadź opis zadania",
    the_task_360:"zadanie",
    tasks_360:"zadania",
    add_task_degree360: "Tworzenie listy oceniających ocenianych",
    add_degree360: "Dodaj wynik 360",
    stud_id:" ID ocenianego",
    its_my_degree: "Jestem oceniany",
    degree_readonly_info: "Przydziały do oceny 360 zostały już dokonane, niektóre działania związane z oceną nie mogą być wykonane. Na przykład usuwanie ocenianych osób, oceniających, usuwanie i dodawanie kompetencji, zmiana dat, włączanie samooceny, zmiana sposobu ustalania zadań.",
    degree360_alert: "wniosek został już uzgodniony, nie można go zmienić.",
    degree360_agreeID:"dopasowanie",
    degree360_agreed:"uzgodnione",
    degree360_wait_agreed:"oczekuje na uzgodnienie",
    degree360_self_title: "wnioski o ocenę 360",
    degree360_self: "wniosek o ocenę 360",
    degree_self: "Tworzenie listy oceniających",
    degree_self_info: "aby złożyć wniosek, wybierz osoby oceniające i odpowiedzialne za uzgodnienie oceny 360",
    degree_start_finish_info: "daty są używane informacyjnie i nie mają wpływu na wyświetlanie Wyniku360.",
    agree_not_valid: "nie wszystkie dane są zapełnione. Proszę sprawdzić tytuł, daty, ocenianych, oceniających.",
    degree_add_scale_info: "wybierz skalę oceny z listy, aby dodać ją do oceny 360",
    degree_add_comp_info: "Wybierz kompetencje z listy i kliknij<+>, aby dodać kompetencje do oceny 360",
    degree_adb_prof_info: "Wybierz profil z listy i kliknij +. Jego kompetencje zostaną dodane do oceny 360",
    degree_add_experts_info: "Dodaj oceniających za pomocą przycisków poniżej",
    degree_add_students_info: "Dodaj ocenianych pracowników za pomocą przycisków poniżej",
    degree_agree_degree360_info: "w lewej pozycji pola wyboru można zapisać ocenę roboczą. Prawa pozycja - zostanie wykonane przypisanie podczas zapisywania.",
    degree_agree_user_info: "aby przypisać ocenę, konieczne jest uzgodnienie. Wybierz koordynującego pracownika.",
    degree_hide_fio_info: "domyślnie oceniany zobaczy dane oceniających. Po aktywacji dane oceniających zostaną ukryte.",
    degree_pub_lk_info: "Publikowanie wyników oceny na koncie osobistym ocenianego po wystawieniu oceny przez wszystkich oceniających.",
    degree_task_info: "zadanie w Bitrixie do oceny. Wystawiane przez oceniającego.",
    show_options_block:" Ustawienia oceny 360",
    show_users_block: "listy ocenianych i ocenianych",
    show_comps_block: "kompetencje, wskaźniki, skala oceny",
    degree360_aver_title:"wyniki ocen (średnie)",
    degree360_stat_title: "liczba respondentów biorących udział w ocenie",
    degree360_aver_col_:"praktyki",
    degree360_stat_ret0:"liczba osób",
    degree360_stat_col_: "grupy respondentów",
    degree360_stat_col0:"samoocena",
    degree360_stat_col1:"szef",
    degree360_stat_col2:"koledzy",
    degree360_stat_col3:"podwładni",
    degree360_stat_col4: "wszyscy respondenci<br>(bez samooceny)",
    degree360_stat_col5: "wszyscy respondenci",
    degree360_description:" musisz wykonać ocenę 360",
    degree360_message: "masz przypisany wynik 360. Przejdź do aplikacji 'Uniwersytet / moje oceny'",
    you_agree_360: "jesteś przypisany do dopasowania w wyniku 360.  Przejdź do aplikacji Uniwersytet do uzgodnienia.",
    del_student:" chcesz usunąć ocenianego",
    do_schedules: "wynik 360 zostanie przypisany do oceniającego",
    no_experts:" nie ustawiono oceniających",
    agree_user:"zgoda",
    agree_degree360:"uzgodnij",
    decline_degree360:"powrót",
    decline_degree360_info:"jeśli zmieniłeś status wniosku, zapisz go",
    publish_degree360: "Publikacja oceny 360",
    del_degree360: "usuwanie wyniku 360",
    del_degree360_msg: "naprawdę chcesz usunąć wynik 360",
    degree_name: "wpisz tytuł oceny",
    add_prof_label: "Wybierz profil",
    scale_plc:"wybierz skalę",
    add_prof: "Dodaj do kompetencji",
    save_comp:"Zapisz",
    close_comp:"Zamknij",
    program_readonly: "przypisania są dokonywane w tym programie, więc nie można go zmienić. Można wykonać kopię programu na liście programów do jego modyfikacji i nowych zadań.",
    program_info: "moduł' Programy ' jest przeznaczony do tworzenia kompleksowych programów rozwojowych. Na przykład, takich jak adaptacja początkujących, szkolenie w zakresie puli talentów itp."+
      "<br>Programy mogą być tworzone z następujących elementów: kursy, testy, lekcje (np. Dodaj plik PDF, Regulamin, prezentację, linki, materiały, longrid itp.), wydarzenia (np. Utwórz wydarzenie spotkanie z przełożonym)."+
      "<br>Programy są tworzone z elementów równolegle i sekwencyjnie. Kolejne elementy tworzą poziomy (może to być dzień,Faza uczenia się). Na jednym poziomie może znajdować się dowolna liczba elementów, co oznacza, że na tym poziomie wszystkie muszą przejść."+
      "<br>Po utworzeniu programu można go przypisać pracownikom, grupom (po dodaniu pracownika do grupy automatycznie zostanie mu przypisany program). Listę pracowników można zmienić: po dodaniu-pracownikowi zostanie przypisany program, po usunięciu-będzie on nadal obecny w raportach."+
      "<br>Przypisanego programu nie można zmienić.",
    copy_program: "Kopiuj program",
    module_level:"poziom",
    eve_and_bad_moder: "zgłoszenie do udziału w wydarzeniu od pracownika",
    is_answered: "są odpowiedzi",
    check_all:"Zaznacz wszystkie oceny",
    check_group: "Oznacz grupę",
    check_one: "zaznacz wiersz",
    rep_date:"Data",
    rep_type: "za co",
    the_open: "otwarte pytanie",
    The_event:"wydarzenie",
    the_360:"wynik 360",
    the_aos: "aos",
    the_rate: "ocena kursu / testu",
    the_comment:"komentarz",
    the_ipr: "Ypres",
    the_Rc: "ocena kursu",
    the_Rt: "wynik testu",
    The_course:"kurs",
    write_off:"odpis",
    not_in_sched: "Brak w przypisaniu programu",
    sub_alert: "To konto zawiera konta podrzędne, ale ich limit został wyczerpany. Skontaktuj się z moderatorem aplikacji",
    sub_limit_exceeded: "limit kont podrzędnych został wyczerpany. Przejdź do Ustawienia / moja taryfa, aby zwiększyć limit",
    sub_login_already: "takie subkonto już istnieje",
    sub_accounts:"Subaccounts",
    native_add:"Dodaj konto. Dostępne tylko dla administratora portalu",
    sub_add: "Dodaj subkonto",
    del_subac_title:"Usuwanie konta podrzędnego",
    del_subac_message: "konto podrzędne zostanie usunięte z listy. Na pewno?",
    del_sub_title:"Usuwanie konta",
    del_sub_message: "konto zostanie usunięte z listy wraz ze swoimi kontami podrzędnymi. Na pewno?",
    self_sign_up:"samodzielna Rejestracja",
    sub_acc_title:"autoryzacja",
    sub_acc_login:"Login",
    sub_acc_password:"hasło",
    sub_acc_password2: "Powtórz hasło",
    sub_acc_recv: "Twoje dane",
    sub_acc_first_name:"Nazwa",
    sub_acc_last_name:"nazwisko",
    sub_acc_middle_name:"drugie imię",
    sub_acc_position:"stanowisko",
    sub_acc_login_not_found: "nie znaleziono loginu, wymagana rejestracja",
    sub_acc_save:"Zapisz",
    sub_acc_ok:"Zaloguj się",
    sub_acc_passwords_not_match:"hasła nie pasują",
    sub_acc_password_fail:"nieprawidłowe hasło",
    sub_acc_valid_recv: "wypełnij dane",
    sub_acc_recv:"Zmień dane",
    sub_important_info: "Ważne informacje",
    sub_info: "<ol>"+
     "<li><b>Dodaj konto.</b><br>"+
     "Ważne warunki:</li>"+
     "<ul><li>To jest konto Bitrix24, do którego będą powiązane subkonta Uniwersytetu.</li>"+
     "<li>Konto może utworzyć tylko administrator portalu Bitrix24. Nie moderator aplikacji.</li>"+
     "<li>Konto nie może być Administratorem Bitrix24 ani moderatorem aplikacji Uniwersytet.</li></ul>"+
     "<li><b>Wybierz metodę rejestracji subkont w aplikacji:</b></li>"+
     "<ul><li><b>Niezależnie.</b> W tym przypadku administrator portalu musi tylko wprowadzić loginy i poinformować te loginy subkontom. Podczas logowania subkonta wprowadzą swoje loginy, a następnie aplikacja poprosi ich o wypełnienie danych (pełne imię i nazwisko oraz stanowisko) i utworzenie hasła.</li>"+
     "<li><b>Przez administratora.</b> W tym przypadku administrator wprowadza login i wypełnia wszystkie dane (pełne imię i nazwisko oraz stanowisko), a następnie przekazuje loginy subkontom. Podczas logowania subkonto zostanie poproszone o utworzenie hasła. Pozostałe dane (pełne imię i nazwisko oraz stanowisko) będą już wypełnione przez Administratora.</li></ul>"+
     "<li><b>Za każdym razem, gdy logują się do aplikacji, subkonto będzie musiało wprowadzić swój login i hasło.</b></li>"+
     "</ol>",
     cert_format:"format strony",
    cert_name:" nazwa certyfikatu",
    cert_save: "Zapisz certyfikat",
    cert_new: "Dodaj certyfikat",
    cert_list:" lista certyfikatów",
    clear_form: "nowy certyfikat",
    cert_remove: "Usuń z listy",
    cert_default:" default",
    cert_scale:"Skala",
    cert_opts_fio:"nazwa użytkownika",
    cert_opts_pos:"stanowisko",
    cert_opts_name:" nazwa testu / kursu",
    cert_opts_score:"pozytywny wynik",
    cert_opts_date:" data przejścia",
    cert_opts_text: "dodatkowy tekst",
    cert_dark_mode: "ciemne tło",
    cert_orient_a:"album",
    cert_orient_p:"Portret",
    certs:"Certyfikaty",
    cer_tificate:"certyfikat",
    cert_img:"Obraz",
    save_png:"Pobierz",
    cert_del_title: "Uwaga!",
    cert_del_message: "certyfikat zostanie usunięty z listy. Plik zostanie również usunięty z dysku.",
    cert_reload_title: "Uwaga!",
    cert_reload_message: "aby zastosować zmiany, musisz wyjść i ponownie przejść do ustawień certyfikatu.",
    cert_font_size:"rozmiar czcionki",
    cert_font:"czcionka",
    cert_font_color:" kolor czcionki",
    is_program_tasks: [{value: 0, label:"ustaw zadania dla każdego modułu"},{value: 1, label:"Ustaw jedno wspólne zadanie"},{value: 2, label:"nie ustawiaj zadań"}],
    program_tasks:"zadania",
    program_task_description: "musisz wykonać program",
    course_passed: "kurs zaliczony",
    module_complete: "Zakończ moduł",
    done_success: "sukces!",
    save_program:"Zapisz",
    add_module_s: "Dodaj moduł szeregowy",
    add_module_p: "Dodaj moduł równoległy",
    program_message: "masz przydzielony program szkoleniowy. Przejdź do aplikacji 'Uniwersytet/moje programy'",
    my_programs: "Moje programy",
    publish_schedule_tooltip:" nie wybrano programu ani nie ustawiono dat spotkań, przełożonego, pracowników",
    mod_description: "w planie wykonania programu, trzeba wykonać moduł",
    list_schedules:"zadania",
    add_schedule: "Dodaj zadanie",
    one_schedule:"przypisanie",
    publish_schedule: "dokonaj przypisania",
    dates_schedule:" daty docelowe",
    add_students:"pracownicy",
    dep_rem_title: "usunąć z miejsca docelowego?",
    dep_rem_mess:"Usuń wszystkich z tej grupy (jednostki organizacyjnej)",
    dep_rem_mess1: "Usuń tego członka",
    start_finish: "początek / koniec",
    selectusers_label:"pracownicy",
    selectgroups_label:"grupy",
    selectdeps_label: "działy",
    selecttests_label: "kursy / testy",
    selectgroup_title: "Dodaj grupę",
    selectgroup_message: "Wybierz grupę",
    courses_tests: "kursy-testy",
    students:"uczący się",
    del_schedule_title:"usuwanie",
    del_schedule_message:"Potwierdź usunięcie przypisania",
    del_schedules_message:"Potwierdź usunięcie przypisań",
    date_to_short:"zakończenia",
    students_comment:" jeśli pracownik został dodany za pośrednictwem działu lub grupy, usunięcie takiego pracownika spowoduje usunięcie innych pracowników tego działu / grupy",
    add_department:"Dział",
    add_group:"Grupa",
    add_main_auditor:"Kierownik",
    mod_duration: "czas trwania, dni.",
    mod_action: {'hand':"Instrukcja", 'auto': "Auto"},
    add_program: "Dodaj program",
    list_programs:"lista programów",
    one_program:"program",
    progrm_modules:"Moduły",
    requaried_field: "Pole wymagane",
    program_name:"Nazwa programu",
    program_description: "opis programu",
    module_type:"typ modułu",
    module_type_opt: [{value: 1, label:"kurs"}, {value: 2, label: "Test"}, {value: 3, label: "Lekcja"}],
    select_module: "Wybierz moduł",
    not_found:"nic nie znaleziono",
    type_open:" sposób otwierania modułów",
    type_open_opt: [{value: 'P', label:"Otwórz wszystko na raz"}, {value:'S', label:"otwórz kolejno. Jeśli moduł się nie powiedzie, nadal Otwórz Następny"}, {value:'H', label:"otwórz kolejno. Jeśli moduł nie powiedzie się, nie otwierać kolejny"}],
    publish_program: "Opublikuj program",
    del_program:"Usuń",
    del_program_msg:"czy na pewno chcesz usunąć",
    mod_passed: [{value:'0', label:"brak statusu"}, {value:'Y', label:"passed"},{value:'N', label: "failed"}],

    points:"Wyniki",
    points_plc: "Ustaw 0 jeśli punkty są przyznawane zgodnie z ustawieniami aplikacji",
    for_course_active: "ukończony kurs",
    for_course_mod_lb:"sposób liczenia",
    for_course_mode:[
      {value: 'sum', label: "Oblicz sumując punkty za Moduły"},
      {value: 'hand', label: "wprowadź ręcznie"},
    ],
    for_course_dop_lb: "Punkty za lekcje/testy w ramach kursu",
    for_course_dop:[
      {value: 'top', label: "naliczać"},
      {value: 'node', label:" nie naliczaj"},
    ],
    separ_add: "Dodaj separator",
    sep:"Separator",
    bid_del_message: "Usuń ofertę",
    em_count_limit: "Przekroczono maksymalną liczbę uczestników",
    em_count:"Liczba uczestników. 0-nieograniczona",
    eve_attende:"Uczestnik",
    eve_bid:"Licytacja",
    not_published:"nie opublikowano",
    my_events:"moje wydarzenia",
    ipr_comp:"kompetencje",
    ipr_ind:"wskaźniki",
    eve_bid_done: "Twoja aplikacja została dodana. Po rozważeniu zostaniesz członkiem.",
    eve_new_big_add: "żądanego wydarzenia nie ma na liście? Zaproponuj aplikację. Po sprawdzeniu wydarzenie zostanie dodane do listy i zostaniesz członkiem.",
    eve_new_bid_title: "zgłoszenie na nowe wydarzenie",
    eve_new_bid_done: "Twoja aplikacja została dodana. Po przejrzeniu wydarzenie zostanie dodane do listy i zostaniesz członkiem.",
    alert_understood:"Rozumiem",
    eve_new_bid_moder:" otrzymano zgłoszenie na wydarzenie od pracownika",
    em_details:"szczegóły",
    bid_restore_title: "Przywróć aplikację",
    bid_restore_message: "podaj powód przywrócenia wniosku",
    bid_accepted: "wniosek zaakceptowany",
    bid_declined: "wniosek odrzucony, Przywróć.",
    bind_accept: "Zaakceptuj aplikację",
    bid_decline_title: "Odrzuć wniosek",
    bid_decline_message: "podaj powód odrzucenia wniosku",
    eve_bids_all: "Dodaj wszystkich",
    eve_calend:"w kalendarzu",
    eve_detail: "więcej o wydarzeniu",
    eve_edit:"Edytuj",
    em_bids:"Aplikacje",
    eve_bid_lbl: "Zgłaszanie wniosku o udział w wydarzeniu",
    eve_title: "Złóż wniosek o udział w wydarzeniu",
    eve_bin_add: "będę członkiem",
    eve_nts:"wydarzenia",
    text_block:"blok tekstowy",
    text_block_name:" Nazwa pola",
    text_block_value: "zawartość pola",
    r_o:" tylko do odczytu",
    set_files:"Pliki",
    integral_chart:"Wykres całkowy",
    view_cards:"Widok: karty",
    view_list:"Widok: lista",
    node_disabled:"Ustaw jako nieaktywny",
    node_enabled:"Uaktywnij",
    download: "Pobierz",
    no_valid_publish:" Nie podano ważnych danych, takich jak pracownik, przełożony, mentor, kompetencje, wskaźniki lub brak uprawnień do edycji",
    hide_fio: "Ukryj dane oceniających",
    such_element_exist: "taki element już istnieje",
    program_course: "/ program kursu",
    lessons:"lekcje",
    tests:"testy",
    no_lessons: "brak lekcji do dodania do kursu",
    no_tests: "brak testów do dodania do kursu",
    drop_here: "przeciągnij tutaj lekcje i testy",
    reset_tree: "Zresetuj nawigację",
    reset_true_msg: "usunąć wszystkie lekcje i testy z kursu?",
    export_all:"Eksportuj wszystko",
    imgFiles:"Pliki do odpowiedzi",
    upload_title:"Prześlij",
    upload_err:"Przesyłanie nie powiodło się",
    upload_hint:"Wybierz plik na dysku lub przeciągnij i upuść go tutaj",
    max_file_size:"Rozmiar pliku nie może przekraczać {mb} MB",
    delete_image:"Usuń plik",

    ipr_note:"Otrzymałeś 'Indywidualny plan rozwoju'. Przejdź do swojej aplikacji University.",
    add_evt_title:"wydarzenie dodane",
    add_evt_msg: "zamknij formularz dodawania działań i przejdź do listy?",
    add_evt_ok:"Zamknij",
    add_evt_canc: "Dodaj więcej",
    res360_published: "Wyniki oceny 360 opublikowane na koncie osobistym ocenianego",
    rm_rm: "wydarzenie rozwojowe",
    rm_dln: "przeterminowane",
    rm_deadline:"termin",
    go_course:"Idź",
    no_results: "brak danych",
    enter_course_name: "wpisz część nazwy do wyszukania",
    course_name: "Dodaj kurs",
    course_statuses: "Status kursów",
    ipr_courses:"kursy RM",
    ipr_export:"eksport",
    iprset_create_label: "kto może tworzyć",
    iprset_edit_label: "kto może edytować",
    iprset_read_label: "kto może zobaczyć",
    iprset_status_label: "kto może zmienić status",
    iprset_role_opt:[{value:'M', label:"Moderator"},{value:'U', label:"pracownik"},{value: 'N', label:"Mentor"}],

    add_rm: "RM",
    add_ind:"wskaźnik",
    ipr_title: "indywidualny plan rozwoju",
    ipr_name: "Nazwa IPR",
    ipr_close:"Zamknij",
    ipr_open:"Otwórz",
    select_person:"pracownik",
    select_manager:"Kierownik",
    select_mentor: "Mentor",
    rm_label: "RM",
    rm_options:[
      "Aktówka",
      "Assesment",
      "Badanie",
      "Budowanie zespołu",
      "Facylitacja",
      "Grom",
      "Hackaton",
      "Klasa mistrzowska",
      "Konkurs",
      "Kurs na odległość",
      "Mentoring",
      "Mitap",
      "Narada",
      "Opinia",
      "Podcast",
      "Projekt",
      "Quiz",
      "Raport",
      "Rok",
      "Seminarium",
      "Sesja",
      "Spotkanie",
      "Staż",
      "Symulacja",
      "Szkolenia",
      "Testowanie",
      "Treści wideo",
      "Treść audio",
      "Uniwersjada",
      "Webinarium",
      "Workshop",
      "Wykład",
      "-swoją opcję -"
    ],
    rm_compleeted:"gotowe",
    rm_started: "w toku",
    rm_new: "nie rozpoczęte",
    rm_status:"Status",
    hand_made: "wprowadź ręcznie",
    comp_name: "nazwa kompetencji",
    ind_name: "nazwa wskaźnika",
    del_ind: "Usuń wskaźnik",

    selectcomps_label:"kompetencje",
    selectcomps_title: "Dodaj kompetencje",
    selectcomp_message: "Wybierz kompetencje",
    selectcomps_message: "Wybierz jedną lub więcej kompetencji",
    selectcomps_add:"Dodaj",
    selectcomps_finish:"Zamknij",
    selectscale: "Skala ocen",
    target_sc: "poziom docelowy",
    current_sc: "aktualny poziom",

    del_certs: "certyfikat został usunięty w sekcji Edukacja, usuń go tutaj",
    no_certs: "nie masz przesłanego certyfikatu. Dodaj je w dziale Edukacja ",
    aos_filled: "wypełniony",
    anketa_add: "Dodaj profil",
    for_test: "do testów",
    for_course: "dla kursów",
    for_event:"na imprezy",
    aos_copy:"(Kopia)",
    new_aos_name: "Zmień nazwę, aby zapisać",
    first_aos_name: "profil domyślny",
    aos_edit: "Edycja kwestionariusza",
    aos_look: "Zobacz profil",
    aos_refresh_title: "załaduj domyślnie",
    aos_refresh_message: "Twój kwestionariusz zostanie zastąpiony domyślnym kwestionariuszem",
    answ_aos:"odpowiedzi",
    answer:"tekst",
    score: "Waga",
    quest_aos:"pytania",
    add_answ: "Dodaj odpowiedź",
    other_answ: "Inne",
    a_o_s: "aos",
    add_aos: "Dodaj pytanie",
    aos: "kwestionariusz opinii",
    aos_del: "Usuń profil",

    cert_del: "usunąć certyfikat z wydarzenia?",
    cert_add: "Dodaj certyfikat",
    event_certs: "Wybierz certyfikaty",
    em_del: "Usuń wydarzenie (również z kalendarza)",
    em_save:"Nagraj",
    em_clear:"Wyczyść",
    em_meeting_notify: "Wyślij zaproszenia",
    em_description:" opis zdarzenia",
    em_meeting_text:" tekst zaproszenia",
    em_color: "kolor zdarzenia",
    em_text_color: "Kolor tects",
    em_attendees:"członkowie",
    em_host: 'odpowiedzialny',
    em_org:"Organizator",
    em_title: "działania zewnętrzne i osobiste",
    em_eventName: "nazwa wydarzenia",
    em_common_list: "lista Ogólna",
    em_personas: 'oceniany',
    em_close:"wybrać",
    em_location:"Typ",
    em_location_opt:['wewnętrzny','zewnętrzny'],
  em_vid: "Widok",
  em_vid_opt:[
  'Assesment',
  'Badania',
  'Budowanie zespołu',
  'Case',
  'Facylitacja',
  'Gra',
  'Hackaton',
  'Klasa mistrzowska',
  'Konferencja',
  'Konkurs',
  'Kurs na odległość',
  'Mentoring',
  'Mitap',
  'Ocena',
  'Podcast',
  'Projekt',
  'Quiz',
  'Raport',
  'Rok',
  'Sesja',
  'Staż',
  'Symulacja',
  'Szkolenia',
  'Testowanie',
  'Treści wideo',
  'Treść audio',
  'Uniwersjada',
  'Warsztaty',
  'Webinarium',
  'Workshop',
  'Wykład',
],
  common_list: "Wspólna Lista",
  personas: 'oceniani',
  exp_comments:"Komentarze",
  no_events: "brak nadchodzących wydarzeń",
    em_from_to: "początek i koniec wydarzenia",
    acc1: "Pokaż wyniki na koncie osobistym",
    add_section: "Dodaj sekcję",
    comps_added: "dodane kompetencje",
    comps_add: "Dodaj kompetencje",
    attention:"Uwaga",
    del_competence: "Usuń kompetencje",
    competencies: 'kompetencje',
    all: "wszystko",
    add_coworkers: 'Dodaj współpracowników',
    coworkers: 'koledzy',
    add_slaves: 'Dodaj podwładnych',
    slaves: 'podwładni',
    add_chief: 'Dodaj przełożonego',
    chief:'szef',
    nowość: 'pracownik',
    average_: 'Średnia bez udziału ocenianego',
    departments: 'podziały',
    add_departments: 'Dodaj podziały',
    selectdepartments_title: 'Dodaj podział',
    selectdepartments_message: 'wybierz dział',
    selectdepartments_add: 'Dodaj',
    selectdepartments_finish: 'Zamknij',

    rep_competence: "kompetencje / wskaźniki",
    rep_indicators: "eksperci",
    rep_scores:"oceny",
    comment: "Komentarz oceniany",
    acc: "Opublikuj na koncie osobistym ocenianego",
    you_ass_360: "masz przypisany wynik 360. Zobacz listę wszystkich ocen na swoim koncie osobistym.",
    del_many_title: "Usuwanie wielu ocen",
    del_many_mess: "chcesz usunąć kilka oznaczonych ocen",
    rows_per_page: 'Rekordy na stronę',
    all_report: 'Raport ogólny',
    start_finish: ' Początek/ Koniec ',
    competence: 'Kompetencja',
    selectusers_title: 'Dodaj pracownika',
    selectuser_message: 'Wybierz użytkownika',
    selectusers_message: 'Wybierz jednego lub więcej użytkowników',
    selectusers_add: 'Dodać',
    selectusers_finish: 'Blisko',
    title: 'Opinie 360/180',
    goback: 'Plecy',
    loading: 'Ładowanie',
    remove: 'Usunąć',
    assessment: 'sprzężenie zwrotne',
    competences: 'Kompetencje',
    name: 'Nazwa',
    date_start: 'Data rozpoczęcia',
    date_finish: 'Data zakończenia',
    description: 'Opis',
    persona: 'W ramach opinii',
    experts: 'Członkowie',
    clear: 'Jasne',
    del: 'Kasować',
    add: 'Dodać',
    add_assessment: 'Utwórz opinię',
    add_persona: 'Dodaj osobę w ramach opinii',
    add_experts: 'Dodaj członków',
    ok: 'OK',
    reset: 'Anulować',
    scores: 'Informacje zwrotne',
    publish: 'Publikować',
    alert_message: 'Dane nie są wypełnione',
    alert_persona: 'W ramach opinii',
    alert_experts: 'Członkowie',
    alert_dates: 'Daktyle',
    alert_competences: 'Kompetencje',
    alert_scores: 'Informacje zwrotne',
    del_ass_title: 'Usuń ocenę',
    del_ass_mess: 'Usuń ocenę',
    deleteSome: 'Kasować',
    export: 'Eksport',
    report: 'Raport',
    del_some_title: 'Kasować',
    del_some_mess: 'Chcesz usunąć ocenę',
    more: 'Więcej',
    chart: 'Wyniki',
    chart_title: 'Tabela ocen 180°/360° dla pracownika',
    refresh: 'Aktualizuj listę',
    user: 'Pracownik',
    clear_scores: 'Wyczyść ocenę',
    clrscores_title: 'Jasne',
    clrscores_message: 'Wszystkie wyniki zostaną usunięte z tej oceny. Kontyntynuj?',
    legend: 'Pełne nazwy kompetencji',
    results: 'Wyniki',
    average: 'Przeciętny',
    average1: 'Przeciętny*',
    close_report: 'Zamknij raport',
    assessment_name: 'Nazwa oceny',
    action: 'Akcja',
    list: 'Powrót do listy wyników',
    status: 'Status',
    published: 'Opublikowany',
    draft: 'Projekt',
  },
  pt: {
    sign_up_btn_ok: "Criar",
    sign_up_confirm: "Um novo portal será criado. Você se tornará o administrador principal e poderá convidar outros usuários.",
    is_reg_info: "Este e-mail não foi encontrado. Você pode registrar um novo portal com ele e convidar usuários.",
    for_prog_dop_lb: "Pontos para cursos/lições/testes dentro",
    passed_prog: "Programa concluído",
    the_collapsed: "Total superior a 3 meses",
    the_collapsed_info: "As entradas nos detalhes com mais de três meses são agrupadas numa só. Os pontos são somados no processo",
    the_test: "Teste",
    the_lesson: "Lição",
    passed_course: "Curso concluído",
    points_reset: "Redefinir todos os pontos",
    duration_info: "Duração do programa em dias. Calculada somando a duração de todos os módulos. Usada para determinar prazos para atribuições e tarefas.",
    the_programs: "Programas",
    program_view_btn: "Gerente do programa não selecionado",
    the_program_info: "Ao clicar no botão 'Ir para', você atribuirá o programa a si mesmo para conclusão. Você pode encontrar a atribuição na seção 'Meus Programas'.",
    go_to_program: "Ir para",
    the_program: "Programa",
    clear_program: "Excluindo programa. Apenas programas não publicados podem ser excluídos.",
    uncheck_all_uscheds: "Desmarcar tudo",
    check_all_uscheds: "Marcar tudo",
    is_archive: "Arquivo",
    from_archive: "Restaurar do arquivo",
    to_archive: "Mover para o arquivo",
    program_archived: "Movido para o arquivo",
    program_published: "Programa publicado",
    add_auditors: "Supervisores do módulo",
    modules_auditors: "Edição de observadores e prazos dos módulos",
    add_main_auditor_info: "O gerente do programa é um observador na tarefa. Ele será atribuído a cada módulo, a menos que outro observador seja selecionado nos módulos.",
    dates_schedule_info: "As datas são informativas e usadas para definir prazos de tarefas",
    clear_schedule: "Excluindo atribuição. Apenas atribuições não publicadas podem ser excluídas.",
    program_updated: "Programa salvo",
    program_added: "Programa adicionado",
    program_scheds_done: "Atribuições feitas",
    such_module_exist: "Módulo já selecionado",
    such_user_exist: "Funcionário já selecionado",
    duration_hint: "Calculado automaticamente",
    degree_add_block_info: "Selecione um bloco de competências da lista e clique em +. Suas competências serão adicionadas à Avaliação 360.",
    add_block_label: "Selecionar bloco de competências",
    slaves_add: "Adição automática de funcionários do departamento",
    slaves_add_byhands: "Adição manual",
    chief_add: "Adição automática dos chefes de departamento do funcionário",
    chief_add_byhands: "Adição manual",
    del_subpassw_title: "Exclusão de senha",
    del_subpassw_message: "A senha será apagada, o usuário precisará inseri-la novamente ao fazer login",
    degree_msg_sended: "Notificações enviadas",
    degree_checkall_btn: "Marcar tudo",
    degree_clear_btn: "Excluir respostas",
    degree_clear_msg: "As avaliações (respostas) dos avaliadores selecionados serão excluídas. Eles precisarão realizar as avaliações novamente.",
    degree_status_student: {
     1: "Sem avaliações de especialistas",
     2: "Nenhum especialista designado"
    },
    degree_status_expert: {
     0: "Todas as avaliações dadas",
     2: "Sem avaliações para uma ou mais competências",
     3: "Nem todas as avaliações dadas"
    },
    degree_status_btn: "Enviar notificação",
    degree_status_msg: "Você está participando da avaliação de funcionários como avaliado. Crie uma lista de avaliadores e selecione os aprovadores para essa lista na seção 'Minhas Avaliações' da conta pessoal do aplicativo 'Universidade'.",
    degree_fill_msg: "Você está participando da avaliação de funcionários como avaliador. Avalie os funcionários na seção 'Minhas Avaliações' da conta pessoal do aplicativo 'Universidade'.",
    degree_uagree_msg: "Você foi nomeado como aprovador na avaliação 360. Vá para a seção 'Minhas Avaliações' da conta pessoal do aplicativo 'Universidade'.",
    degree_status: {
     0: "Aprovado",
     1: "Nenhum especialista designado",
     2: "Nenhum aprovador designado",
     3: "Não aprovado"
    },
    Degree360StatusList: "Status de formação",
    Degree360StatusFill: "Status de preenchimento",
    change_password: "Alterar senha",
    change_password_m: "Alterar senhas dos funcionários",
    change_password_hint: "Digite a nova senha e a confirmação. Após alterar a senha, faça login no sistema.",
    change_password_hint_m: "Especifique o funcionário, a senha será gerada automaticamente.",
    change_password_done: "Senha alterada, use-a no próximo login.",
    change_password_fail: "Ocorreu um erro, a senha não foi alterada. Tente novamente ou entre em contato com o administrador.",
    change_password_done_m: "Senha alterada, copie e passe para o funcionário.",
    clear_profile: "Excluir perfil",
    clear_profile_msg: "O perfil será excluído, mas o email e a senha para login serão salvos. Para criar um novo perfil, você precisará fazer login no sistema. Tem certeza?",
    clear_profile_done_m: "Perfil excluído",
    add_head: "Gerente",
    add_users: "Funcionário",
    struct_reset_msg: "Você realmente deseja redefinir a estrutura?",
    struct_reset: "Redefinir estrutura",
    department_add: "Departamento",
    struct_company: "/Empresa",
    struct_department: "Departamento",
    company_structure: "Empresa",
    last_name_need: "Nome e sobrenome devem ser preenchidos!",

    degree_checkall_btn: "Marcar tudo",
    sign_up_for_an_account: "Faça login ou cadastre-se para uma conta",
    auth_email: "Email",
    auth_password: "Senha",
    auth_password2: "Repita a senha",
    sign_up: "Cadastrar",
    sign_in: "Entrar",
    email_invalid: "Email inválido",
    password_invalid: "Senha inválida",
    email_exist: "Email encontrado",
    email_ok: "Email disponível",
    auth_password_match: "Senha correspondente",
    auth_password_not_match: "As senhas não coincidem",
    invitations: "Convites",
    link_invite: "Link de auto-registro",
    reminders: "Lembretes",
    notifications: "Notificações",
    subac_validate_info: "Use apenas letras latinas e números para o nome da subconta.",
    promo_1: "Expanda as capacidades do seu aplicativo. Compre uma assinatura para um tema, disco ou subcontas na seção Configurações:Meu Plano.",
    prog_filter: "Filtro",
    prog_filter_all:"todos os programas",
    prog_filter_tgrp: "modelos para grupos",
    prog_filter_tuser: "para novos funcionários",
    prog_is_template: "este é um modelo para o destino",
    prog_is_template_newuser:"novos funcionários",
    prog_is_template_grp: "para grupos",
    prog_info_grp: "nomeação permanente. Ao adicionar um funcionário ao grupo, ele será automaticamente atribuído.",
    prog_info_newusers: "ao adicionar um novo funcionário ao Portal Bitrix24, ele será automaticamente designado.",
    im_agreemer: "eu sou um avaliador",
    agree_degree360_mess: "concordar com a lista de avaliadores para a avaliação 360?",
    decline_degree360_mess: "retornar para revisão a lista de avaliadores para a avaliação 360?",
    degree360_all_agreed: "listas de avaliadores acordadas para uma avaliação 360",
    degree_valid_1: "nenhum título de Avaliação 360 definido",
    degree_valid_2: "nenhuma data definida",
    degree_valid_3: "nenhum Avaliador foi especificado para um ou mais Avaliadores",
    degree_valid_4: "nenhuma competência definida",
    degree_valid_5: "nenhuma escala de pontuação 360 definida",
    degree_valid_6:"Avaliadores de auto-seleção não concordam",
    pdf_download: "descarregar ficheiro",
    pdf_build: "construir um arquivo PDF",
    scale_value: "valor",
    scale_name:"título",
    scale_desc: "Descrição",
    degree360_all_agreed: "listas de avaliadores acordadas para uma avaliação 360",
    degree_valid_1: "nenhum título de Avaliação 360 definido",
    degree_valid_2: "nenhuma data definida",
    degree_valid_3: "nenhum Avaliador foi especificado para um ou mais Avaliadores",
    degree_valid_4: "nenhuma competência definida",
    degree_valid_5: "nenhuma escala de pontuação 360 definida",
    degree_valid_6:"Avaliadores de auto-seleção não concordam",
    degree360_self_message: "você tem uma tarefa agendada para construir uma lista de avaliadores para uma avaliação 360",
    clear_agreed: "excluir o correspondente",
    students_clear:"todos os avaliados juntamente com seus avaliadores serão excluídos",
    add_degree360_info: "a pontuação 360 é totalmente formada pelo moderador",
    add_task_degree360_info: "A lista de avaliadores pode ser formada de duas maneiras: pelo moderador; pelos avaliados. Quando ativada, a lista será formada pelos avaliados. Após a publicação da avaliação, os avaliados terão uma tarefa em sua conta pessoal na seção Minhas Avaliações para criar uma lista e selecionar aprovadores. A avaliação começará quando todas as listas forem formadas.",
    listDegree360: "avaliações 360",
    eve_and_bad_moder: "pedido de participação no evento de um funcionário",
    profiles_lb: "perfis",
    profiles_plc: "o perfil contém várias competências",
    add_from_comps: "selecionar competência",
    incomplete_data: "competência incompleta, indicadores ausentes",
    experts_clear: "todos os funcionários avaliados serão excluídos",
    degree_tasks: [{value: 0, label: "definir tarefas individualmente"}, {value:1, label:"definir uma tarefa comum"}, {value:2, label: "não definir tarefas"}],
    old_assessments: "formato de avaliação Antigo",
    degree_task_name: "digite o nome da tarefa",
    degree_task_description: "Digite a descrição da tarefa",
    the_task_360: "missão",
    tasks_360: "Tarefas",
    add_task_degree360: "formando uma lista de avaliadores avaliados",
    add_degree360: "adicionar uma pontuação de 360",
    stud_id: "ID DO avaliado",
    its_my_degree: "Eu sou avaliado",
    degree_readonly_info: "As atribuições já foram feitas para a Avaliação 360, algumas ações com a Avaliação não podem ser realizadas. Por exemplo, excluir pessoas avaliadas, avaliadores, excluir e adicionar competências, alterar datas, habilitar autoavaliação, alterar a forma como as tarefas são definidas.",
    degree360_alert: "o pedido já foi acordado, não pode ser alterado.",
    degree360_agreeID: "correspondência",
    degree360_agreed: "acordado",
    degree360_wait_agreed: "aguardando aprovação",
    degree360_self_title: "pedidos de Avaliação 360",
    degree360_self: "pedido de Avaliação 360",
    degree_self: "formando uma lista de avaliadores",
    degree_self_info: "para se candidatar, escolha um avaliador e um responsável pela aprovação da nota 360",
    degree_start_finish_info: "as datas são usadas informativamente e não afetam a exibição da Avaliação360.",
    agree_not_valid: "nem todos os dados foram preenchidos. Por favor, verifique o título, datas, avaliado, avaliado.",
    degree_add_scale_info: "selecione uma escala de avaliação da lista para adicioná-la à pontuação 360",
    degree_add_comp_info: "selecione a competência da lista e clique no botão < + > para adicionar a competência à pontuação 360",
    degree_adb_prof_info: "selecione um perfil na lista e clique em +. Suas competências serão adicionadas à avaliação 360",
    degree_add_experts_info: "adicione Avaliadores usando os botões abaixo",
    degree_add_students_info: "adicione funcionários avaliados usando os botões abaixo",
    degree_agree_degree360_info: "na posição esquerda da bandeira, você pode salvar a nota de trabalho. Posição direita - será feita atribuição ao salvar.",
    degree_agree_user_info: "é necessária uma coordenação para atribuir uma avaliação. Escolha um funcionário consensual.",
    degree_hide_fio_info: "por padrão, o avaliador verá os dados dos Avaliadores. Quando ativado, os dados dos avaliadores serão ocultados.",
    degree_pub_lk_info: "publicar os resultados da avaliação na conta do avaliador depois que todos os avaliadores emitirem notas.",
    degree_task_info: "a tarefa no Bitrix é fazer uma avaliação. É feita uma avaliação.",
    show_options_block:" configurações de pontuação 360",
    show_users_block: "listas de avaliadores e avaliadores",
    show_comps_block: "competências, indicadores, escala de avaliação",
    degree360_aver_title: "resultados das estimativas (médias)",
    degree360_stat_title: "número de respondentes que participaram da avaliação",
    degree360_aver_col_:"práticas",
    degree360_stat_ret0: "número de pessoas",
    degree360_stat_col_:" grupos de respondentes",
    degree360_stat_col0: "auto-avaliação",
    degree360_stat_col1:"cabeça",
    degree360_stat_col2:"colegas",
    degree360_stat_col3:"subordinados",
    degree360_stat_col4: "todos os respondentes<br> (sem auto-avaliação)",
    degree360_stat_col5: "todos os respondentes",
    degree360_description:" você precisa fazer uma pontuação de 360",
    degree360_message: "você tem uma pontuação de 360. Vá para o aplicativo 'Universidade / minhas notas'",
    you_agree_360: "você é nomeado concordante na avaliação 360.  Vá para o aplicativo Universidade para correspondência.",
    del_student: "você deseja excluir um avaliado",
    do_schedules:"uma pontuação de 360 será atribuída ao avaliador",
    no_experts: "nenhum avaliador definido",
    agree_user:"correspondente",
    agree_degree360: "concordar",
    decline_degree360: "retornar",
    decline_degree360_info:"se você alterou o status do aplicativo, salve-o",
    publish_degree360: "publicar uma pontuação de 360",
    del_degree360: "excluir uma pontuação 360",
    del_degree360_msg: "você realmente deseja remover a nota 360",
    degree_name: "digite o nome da nota",
    add_prof_label: "selecionar perfil",
    scale_plc: "selecionar escala",
    add_prof: "adicionar às competências",
    save_comp: "salvar",
    close_comp: "Fechar",
    program_readonly: "este programa foi atribuído e não pode ser alterado. Você pode fazer uma cópia do programa na lista de programas para alterá-lo e novas atribuições.",
    program_info: "o módulo' programas ' é projetado para formar programas de desenvolvimento integrados. Por exemplo, como a adaptação de recém-chegados, treinamento de reserva de pessoal, etc."+
      "<br>Os programas podem ser formados a partir dos seguintes elementos: cursos, testes, lições (por exemplo, adicionar um arquivo PDF, regras, apresentação, links, materiais, longrid, etc.), atividades (por exemplo, criar um evento reunião com o supervisor)."+
      "<br>Os programas são formados a partir de elementos paralelos e sucessivos. Os elementos sucessivos formam os níveis (pode ser um dia,um estágio de treinamento). Em um nível pode ser qualquer número de elementos, o que significa que, neste nível, todos eles precisam passar."+
      "<br>Depois de criar um programa, ele pode ser atribuído a funcionários, grupos (quando você adiciona um funcionário a um grupo, ele será automaticamente atribuído a um programa). A lista de funcionários pode ser alterada: ao adicionar - um programa será atribuído ao funcionário, quando excluído-ele continuará presente nos relatórios."+
      "<br>O programa designado não pode ser alterado.",
    copy_program: "copiar o programa",
    module_level: "nível",
    eve_and_bad_moder: "pedido de participação no evento de um funcionário",
    is_answered:"há respostas",
    check_all: "marcar todas as notas",
    check_group: "marcar grupo",
    check_one: "marcar linha",
    rep_date:"Data",
    rep_type: "por que",
    the_open: "uma questão aberta",
    the_event:"Evento",
    the_360: "avaliação 360",
    the_aos: "aos",
    the_rate: "avaliação do curso / teste",
    the_comment: "comentário",
    the_ipr: "Ypres",
    the_Rc: "avaliação do curso",
    the_Rt: "avaliação do teste",
    the_course: "curso",
    write_off: "Revista",
    not_in_sched: "ausente na atribuição do programa",
    sub_alert: "esta conta contém sub-contas, mas seu limite está esgotado. Entre em contato com o moderador do aplicativo",
    sub_limit_exceeded: "o limite de sub-contas está esgotado. Vá para Configurações / Meu plano para aumentar o limite",
    sub_login_already: "essa sub-conta já existe",
    sub_accounts: "sub-contas",
    native_add: "Adicionar conta. Disponível apenas para o administrador do portal",
    sub_add: "adicionar uma sub-conta",
    del_subac_title: "remoção de sub-conta",
    del_subac_message: "a sub-conta será removida da lista. Tem a certeza?",
    del_sub_title: "exclusão de conta",
    del_sub_message: "a conta será removida da lista juntamente com suas sub-contas. Tem a certeza?",
    self_sign_up: "auto-registro",
    sub_acc_title: "autorização",
    sub_acc_login: "Login",
    sub_acc_password: "senha",
    sub_acc_password2: "repita a senha",
    sub_acc_recv: "seus dados",
    sub_acc_first_name: "nome",
    sub_acc_last_name: "sobrenome",
    sub_acc_middle_name:"nome do meio",
    sub_acc_position:"posição",
    sub_acc_login_not_found: "login não encontrado, registro necessário",
    sub_acc_save: "salvar",
    sub_acc_ok: "entrar",
    sub_acc_passwords_not_match: "as Senhas não correspondem",
    sub_acc_password_fail: "senha inválida",
    sub_acc_valid_recv: "preencha os detalhes",
    sub_acc_recv: "editar dados",
    sub_important_info: "Informações importantes",
    sub_info: "<ol>"+
     "<li><b>Adicionar uma conta.</b><br>"+
     "Condições importantes:</li>"+
     "<ul><li>Esta é uma conta Bitrix24 à qual as subcontas da Universidade serão vinculadas.</li>"+
     "<li>Apenas o administrador do portal Bitrix24 pode criar uma conta. Não o moderador da aplicação.</li>"+
     "<li>A conta não pode ser um Administrador Bitrix24 ou um moderador da aplicação Universidade.</li></ul>"+
     "<li><b>Selecione o método de registro de subcontas na aplicação:</b></li>"+
     "<ul><li><b>Independentemente.</b> Neste caso, o administrador do portal só precisa inserir os logins e informar esses logins às subcontas. Ao fazer login, as subcontas inserirão seu login, então a aplicação solicitará que preencham os dados (nome completo e cargo) e criem uma senha.</li>"+
     "<li><b>Pelo administrador.</b> Neste caso, o administrador insere o login e preenche todos os dados (nome completo e cargo), e depois fornece os logins às subcontas. Ao fazer login, a subconta será solicitada a criar uma senha. Os outros dados (nome completo e cargo) já estarão preenchidos pelo Administrador.</li></ul>"+
     "<li><b>Cada vez que fizerem login na aplicação, a subconta precisará inserir seu login e senha.</b></li>"+
     "</ol>",
     cert_format: "formato da página",
    cert_name: "nome do certificado",
    cert_save: "salvar certificado",
    cert_new: "adicionar certificado",
    cert_list:" lista de certificados",
    clear_form:"novo certificado",
    cert_remove: "Remover da lista",
    cert_default:"padrão",
    cert_scale: "escala",
    cert_opts_fio: "nome de usuário",
    cert_opts_pos:"posição",
    cert_opts_name: "nome do teste / curso",
    cert_opts_score: "pontuação de aprovação",
    cert_opts_date: "data de passagem",
    cert_opts_text:"texto adicional",
    cert_dark_mode: "fundo escuro",
    cert_orient_a:"Paisagem",
    cert_orient_p:"retrato",
    certs:"certificados",
    cer_tificate:"certificado",
    cert_img:"imagem",
    save_png: "baixar",
    cert_del_title: "atenção!",
    cert_del_message: "o certificado será removido da lista. O arquivo também será excluído do disco.",
    cert_reload_title: "atenção!",
    cert_reload_message: "para aplicar as alterações, você precisará sair e entrar novamente nas configurações do certificado.",
    cert_font_size: "tamanho da fonte",
    cert_font: "fonte",
    cert_font_color: "cor da fonte",
    is_program_tasks: [{value: 0, label:"definir tarefas para cada módulo"}, {value: 1, label:"definir uma tarefa comum"}, {value:2, label:"não definir tarefas"}],
    program_tasks:"Tarefas",
    program_task_description:" você precisa executar o programa",
    course_passed: "curso concluído",
    module_complete: "terminar módulo",
    done_success: "sucesso!",
    save_program: "salvar",
    add_module_s: "Adicionar módulo serial",
    add_module_p: "Adicionar módulo paralelo",
    program_message: "você tem um programa de treinamento designado. Vá para o aplicativo 'Universidade / meus programas'",
    my_programs: "meus programas",
    publish_schedule_tooltip: "nenhum programa selecionado ou datas de nomeação, gerente, funcionários não definidas",
    mod_description: "em termos de execução do programa, você precisa executar um módulo",
    list_schedules: "atribuições",
    add_schedule: "Adicionar destino",
    one_schedule: "atribuição",
    publish_schedule: "faça um compromisso",
    dates_schedule: "datas de destino",
    add_students:"funcionários",
    dep_rem_title: "remover do Destino?",
    dep_rem_mess: "excluir todos com este grupo (unidade)",
    dep_rem_mess1: "excluir este membro",
    start_finish: "início / fim",
    selectusers_label:"funcionários",
    selectgroups_label: "grupos",
    selectdeps_label: "departamentos",
    selecttests_label: "cursos / testes",
    selectgroup_title: "Adicionar grupo",
    selectgroup_message: "selecione um grupo",
    courses_tests: "cursos de teste",
    students:"alunos",
    del_schedule_title:"apagar",
    del_schedule_message: "confirme a exclusão do Destino",
    del_schedules_message: "confirme a exclusão de compromissos",
    date_to_short:"terminações",
    students_comment: "se um funcionário foi adicionado por meio de um departamento ou grupo, a remoção desse funcionário resultará na remoção de outros funcionários desse departamento / grupo",
    add_department: "Divisão",
    add_group: "Grupo",
    add_main_auditor:"cabeça",
    mod_duration: "duração, dia.",
    mod_action: {'hand': "manual",'auto':"auto"},
    add_program: "adicionar programa",
    list_programs: "lista de programas",
    one_program: "programa",
    program_modules:"Módulos",
    requaried_field: "campo obrigatório",
    program_name: "nome do programa",
    program_description:"Descrição do programa",
    module_type: "tipo de Módulo",
    module_type_opt: [{value: 1, label: "curso"}, {value: 2, label:"teste"}, {value: 3, label:"lição"}],
    select_module: "selecione um módulo",
    not_found: "Nada encontrado",
    type_open: "método para abrir módulos",
    type_open_opt: [{value:'P', label:"abrir tudo de uma vez"},{value:'S', label:"abrir sequencialmente. Se o módulo não for passado, abra o seguinte"},{value:'H', label:"abra sequencialmente. Se o módulo não for passado, não abra o seguinte"}],
    publish_program: "publicar programa",
    del_program: "excluir",
    del_program_msg: "você tem certeza de que deseja excluir",
    mod_passed: [{value: '0', label: "sem status"}, {value: 'Y', label:"passado"}, {value: 'N', label:"Não passado"}],

    points:"Pontuações",
    points_plc: "coloque 0 se os pontos forem creditados conforme definido nas configurações do aplicativo",
    for_course_active: "curso concluído",
    for_course_mod_lb: "método de contagem",
    for_course_mode:[
      {value: 'sum', label:"calcular pela soma de pontos por módulos"},
      {value: 'hand', label:"inserir manualmente"},
    ],
    for_course_dop_lb: "Pontos para lições/testes dentro do curso",
    for_course_dop:[
      {value: 'top', label: "adicionar"},
      {value:' node', label:"não creditar"},
    ],
    separ_add: "adicionar separador",
    sep:"separador",
    bid_del_message: "Excluir lance",
    em_count_limit: "Número máximo de participantes excedido",
    em_count:"Número de participantes. 0-ilimitado",
    eve_attende:"Participante",
    eve_bid:"Lance",
    not_published: "não publicado",
    my_events: "meus eventos",
    ipr_comp:"competência",
    ipr_ind:"indicadores",
    eve_bid_done: "seu pedido foi adicionado. Após a revisão, você se tornará um membro.",
    eve_new_big_add: "o evento desejado não está na lista? Faça uma proposta. Após a verificação, o evento será adicionado à lista e você se tornará um membro.",
    eve_new_bid_title: "pedido de novo evento",
    eve_new_bid_done: "seu pedido foi adicionado. Após a revisão, o evento será adicionado à lista e você se tornará um membro.",
    alert_understood: "entendido",
    eve_new_bid_moder: "pedido de Evento recebido de um funcionário",
    Em_details:"detalhes",
    bid_restore_title: "recuperar pedido",
    bid_restore_message: "indique o motivo da recuperação do pedido",
    bid_accepted: "pedido aceito",
    bid_declined: "pedido negado, restaurar.",
    bind_accept: "Aceitar pedido",
    bid_decline_title: "rejeitar pedido",
    bid_decline_message: "indique o motivo da rejeição do pedido",
    eve_bids_all: "adicionar todos",
    eve_calend: "no calendário",
    eve_detail: "mais sobre o evento",
    eve_edit: "editar",
    em_bids: "aplicações",
    eve_bid_lbl: "Oferta",
    eve_title: "Inscreva-se para participar do evento",
    eve_bin_add: "serei um participante",
    eve_nts:"eventos",
    text_block: "Bloco de texto",
    text_block_name: "nome do campo",
    text_block_value: "conteúdo do campo",
    r_o:"somente leitura",
    set_files:"Arquivos",
    integral_chart:"Gráfico integral",
    view_cards:"Visualizar: cartões",
    view_list:"Ver: lista",
    node_disabled:"Tornar inativo",
    node_enabled:"Tornar ativo",
    download: "Baixar",
    no_valid_publish: "dados importantes, como funcionário, supervisor, mentor, competências, indicadores ou sem permissões de edição, não são fornecidos",
    hide_fio: "ocultar dados dos Avaliadores",
    such_element_exist: "tal elemento já existe",
    program_course: "/programa do curso",
    lessons: "lições",
    tests: "Testes",
    no_lessons: "não há lições para adicionar ao curso",
    no_tests: "nenhum teste para adicionar ao curso",
    drop_here: "arraste e solte lições e testes aqui",
    reset_tree: "Redefinir navegação",
    reset_true_msg: "excluir todas as lições e testes do curso?",
    export_all:"Exportar tudo",
    imgFiles:"Arquivos para responder",
    upload_title:"Carregar",
    upload_err:"Falha no envio",
    upload_hint:"Selecione um arquivo no disco ou arraste e solte aqui",
    max_file_size:"O tamanho do arquivo não deve exceder {mb} Mb",
    delete_image:"Excluir arquivo",

    ipr_note:"Você recebeu um 'Plano de Desenvolvimento Individual'. Acesse o aplicativo da sua Universidade Corporativa.",
    add_evt_title: "Evento adicionado",
    add_evt_msg: "fechar o formulário de adição de eventos e ir para a lista?",
    add_evt_ok: "Fechar",
    add_evt_canc: "adicionar mais",
    res360_published: "os resultados da avaliação 360 são publicados na conta pessoal da avaliação",
    rm_rm: "Evento de desenvolvimento",
    rm_dln: "expirado",
    rm_deadline: "prazo final",
    go_course: "ir",
    no_results: "sem dados",
    enter_course_name: "Digite parte do nome para pesquisar",
    course_name: "adicionar curso",
    course_statuses: "status do curso",
    ipr_courses:"cursos RM",
    ipr_export:"exportar",
    iprset_create_label: "quem pode criar",
    iprset_edit_label: "quem pode editar",
    iprset_read_label: "quem pode ver",
    iprset_status_label: "quem pode alterar o status",
    iprset_role_opt: [{value: 'M', label:"moderador"}, {value: 'U', label:"empregado"}, {value: 'N', label:"mentor"}],

    add_rm: "RM",
    add_ind:"indicador",
    ipr_title: "plano de desenvolvimento individual",
    ipr_name: "nome IPR",
    ipr_close: "Fechar",
    ipr_open: "abrir",
    select_person:"empregado",
    select_manager:"cabeça",
    select_mentor: "mentor",
    rm_label: "RM",
    rm_options:[
      "Aula",
      "Avaliação",
      "Avaliação",
      "Case",
      "Concurso",
      "Concurso",
      "Conferência",
      "Conteúdo vídeo",
      "Conteúdo áudio",
      "Curso à distância",
      "Curso",
      "Estágio",
      "Facilitação",
      "Formação de equipes",
      "Hackathon",
      "Jogo",
      "Master class",
      "Mitap",
      "Orientação",
      "Pesquisa",
      "Podcast",
      "Projeto",
      "Relatório",
      "Reunião",
      "Seminário",
      "Sessão",
      "Simulação",
      "Teste",
      "Treinamento",
      "Universíada",
      "Webinar",
      "Workshop",
      "-sua opção -"
    ],
    rm_compleeted: "concluído",
    rm_started: "em andamento",
    rm_new: "não iniciado",
    rm_status: "Status",
    hand_made: "inserir manualmente",
    comp_name:"nome da competência",
    ind_name:"nome do indicador",
    del_ind: "remover indicador",

    selectcomps_label:"competências",
    selectcomps_title: "adicionar competências",
    selectcomp_message: "Escolha uma competência",
    selectcomps_message: "selecione uma ou mais competências",
    selectcomps_add: "adicionar",
    selectcomps_finish:"Fechar",
    selectscale: "escala de avaliação",
    target_sc: "nível alvo",
    current_sc: "nível atual",

    del_certs: "o certificado foi excluído na seção Educação, exclua-o aqui",
    no_certs: "você não tem nenhum certificado baixado. Adicione - os na seção Educação ",
    aos_filled: "preenchido",
    anketa_add: "Adicionar perfil",
    for_test:" para testes",
    for_course: "para cursos",
    for_event: "para eventos",
    aos_copy: "(cópia)",
    new_aos_name: "Alterar nome para salvar",
    first_aos_name: "Perfil padrão",
    aos_edit: "Editar Perfil",
    aos_look: "Ver perfil",
    aos_refresh_title: "baixar padrão",
    aos_refresh_message: "seu perfil será substituído pelo perfil padrão",
    answ_aos: "Respostas",
    resposta:"texto",
    pontuação:"Peso",
    quest_aos: "perguntas",
    add_answ: "Adicionar resposta",
    other_answ: "outro",
    a_o_s: "aos",
    add_aos: "adicionar pergunta",
    aos: "questionário de feedback",
    aos_del: "Apagar Perfil",

    cert_del: "deseja remover o certificado do evento?",
    cert_add: "adicionar certificado",
    event_certs: "selecione Certificados",
    em_del: "Excluir um evento (do calendário também)",
    em_save:"gravar",
    em_clear:"limpar",
    em_meeting_notify: "enviar convites",
    em_description: "descrição do evento",
    em_meeting_text: "texto do convite",
    em_color: "cor do evento",
    em_text_color: "cor de Tex",
    em_attendees:"membros",
    em_host:'responsável',
    em_org: "Organizador",
    em_title: "eventos externos e presenciais",
    em_eventName:" nome do evento",
    em_common_list: "lista geral",
    em_personas: 'avaliado',
    em_close:"selecionar",
    em_location: "Tipo",
    em_location_opt: ['Interno', 'Externo'],
    em_vid:"ver",
    em_vid_opt:[
      'Avaliação',
      'Case',
      'Concurso',
      'Conferência',
      'Conteúdo de vídeo',
      'Conteúdo de áudio',
      'Curso à distância',
      'Curso',
      'Estágio',
      'Facilitação',
      'Formação de comando',
      'Hackathon',
      'Jogo',
      'Julgamento',
      'Master class',
      'Mentoria',
      'Mitap',
      'Palestra',
      'Pesquisa',
      'Podcast',
      'Projeto',
      'Quiz',
      'Relatório',
      'Seminário',
      'Sessão',
      'Simulação',
      'Teste',
      'Treinamento',
      'Universíada',
      'Webinar',
      'Workshop',
    ],
    common_list:"Lista comum",
    personas: 'Estimativa',
    exp_comments:"Comentários",
    no_events:"Sem eventos futuros",
    em_from_to:"Início e fim do evento",
    acc1:"Mostrar resultados na conta pessoal",
    add_section:"Adicionar seção " ,
    comps_added:"Competências adicionadas",
    comps_add:"Adicionar competência",
    attention:"Atenção",
    del_competence:"Remover competência",
    competencies: ' Competências ',
    all:"Todos",
    add_coworkers: 'Adicionar colegas',
    coworkers: 'Colegas',
    add_slaves: 'Adicionar subordinados',
    slaves: 'Subordinados',
    add_chief : 'Adicionar chefe',
    chief: 'Supervisor',
    new: 'Empregado',
    average_: 'Médio sem o value',

    departments:'unidades',
    add_departments: 'adicionar unidades',
    selectdepartments_title: 'adicionar divisão',
    selectdepartments_message: 'selecione uma unidade',
    selectdepartments_add: 'adicionar',
    selectdepartments_finish: 'Fechar',

    rep_competence: "competência / indicadores",
    rep_indicators:"especialistas",
    rep_scores: "avaliações",
    comment: "Comentário avaliado",
    acc: "publicar no gabinete do avaliador",
    you_ass_360: "você recebeu uma pontuação de 360. Veja a lista de todas as avaliações em sua conta pessoal.",
    del_many_title: "excluir várias avaliações",
    del_many_mess: "você deseja excluir várias notas marcadas",
    rows_per_page: 'Registros por página',
    all_report: 'Relatório geral',
    start_finish: 'Inicio / Fim',
    competence: 'Competência',
    selectusers_title: 'Adicionar funcionário',
    selectuser_message: 'Selecione o usuário',
    selectusers_message: 'Selecione um ou mais usuários',
    selectusers_add: 'Adicionar',
    selectusers_finish: 'Fechar',
    title: 'Feedback 360/180',
    goback: 'Voltar',
    loading: 'Carregando',
    remove: 'Retirar',
    assessment: 'comentários',
    competences: 'Competências',
    name: 'Nome',
    date_start: 'Data de início',
    date_finish: 'Data de término',
    description: 'Descrição',
    persona: 'Sob feedback',
    experts: 'Membros',
    clear: 'Claro',
    del: 'Excluir',
    add: 'Adicionar',
    add_assessment: 'Crie um feedback',
    add_persona: 'Adicionar uma pessoa ao feedback',
    add_experts: 'Adicionar membros',
    ok: 'OK',
    reset: 'Cancelar',
    scores: 'Feedbacks',
    publish: 'Publicar',
    alert_message: 'Dados não preenchidos',
    alert_persona: 'Sob feedback',
    alert_experts: 'Membros',
    alert_dates: 'Datas',
    alert_competences: 'Competências',
    alert_scores: 'Feedbacks',
    del_ass_title: 'Excluir avaliação',
    del_ass_mess: 'Excluir avaliação',
    deleteSome: 'Excluir',
    export: 'Exportar',
    report: 'Relatório',
    del_some_title: 'Excluir',
    del_some_mess: 'Você quer deletar a avaliação',
    more: 'Mais',
    chart: 'Resultados',
    chart_title: 'Gráfico de classificação 180 ° / 360 ° para funcionário',
    refresh: 'Lista de atualização',
    user: 'Empregado',
    clear_scores: 'Classificação clara',
    clrscores_title: 'Claro',
    clrscores_message: 'Todas as pontuações serão excluídas desta classificação. Prosseguir?',
    legend: 'Nomes completos de competência',
    results: 'Resultados',
    average: 'Média',
    average1: 'Média*',
    close_report: 'Fechar relatório',
    assessment_name: 'Nome de classificação',
    action: 'Açao',
    list: 'Voltar para a lista de pontuação',
    status: 'Status',
    published: 'Publicados',
    draft: 'Projeto',
  },
  es: {
    sign_up_btn_ok: "Crear",
    sign_up_confirm: "Se creará un nuevo portal. Te convertirás en el administrador principal y podrás invitar a otros usuarios.",
    is_reg_info: "Este correo electrónico no se encuentra. Puede registrar un nuevo portal con él e invitar a usuarios.",
    for_prog_dop_lb: "Puntos por cursos/lecciones/pruebas dentro",
    passed_prog: "Programa completado",
    the_collapsed: "Total superior a 3 meses",
    the_collapsed_info: "Las entradas en los detalles de más de tres meses se agrupan en una sola. Los puntos se suman en el proceso",
    the_test: "Prueba",
    the_lesson: "Lección",
    passed_course: "Curso completado",
    points_reset: "Restablecer todos los puntos",
    duration_info: "Duración del programa en días. Calculada sumando la duración de todos los módulos. Se utiliza para determinar los plazos de asignaciones y tareas.",
    the_programs: "Programas",
    program_view_btn: "Gerente del programa no seleccionado",
    the_program_info: "Al hacer clic en el botón 'Ir a', te asignarás el programa para completarlo. Puedes encontrar la asignación en la sección 'Mis Programas'.",
    go_to_program: "Ir a",
    the_program: "Programa",
    clear_program: "Eliminando programa. Solo se pueden eliminar programas no publicados.",
    uncheck_all_uscheds: "Desmarcar todo",
    check_all_uscheds: "Marcar todo",
    is_archive: "Archivo",
    from_archive: "Restaurar del archivo",
    to_archive: "Mover al archivo",
    program_archived: "Movido al archivo",
    program_published: "Programa publicado",
    add_auditors: "Responsables del módulo",
    modules_auditors: "Edición de observadores y plazos de los módulos",
    add_main_auditor_info: "El gerente del programa es un observador en la tarea. Se asignará a cada módulo a menos que se seleccione otro observador en los módulos.",
    dates_schedule_info: "Las fechas son informativas y se utilizan para establecer los plazos de las tareas",
    clear_schedule: "Eliminando asignación. Solo se pueden eliminar asignaciones no publicadas.",
    program_updated: "Programa guardado",
    program_added: "Programa añadido",
    program_scheds_done: "Asignaciones realizadas",
    such_module_exist: "Módulo ya seleccionado",
    such_user_exist: "Empleado ya seleccionado",
    duration_hint: "Calculado automáticamente",
    degree_add_block_info: "Seleccione un bloque de competencias de la lista y haga clic en +. Sus competencias se añadirán a la Evaluación 360.",
    add_block_label: "Seleccionar bloque de competencias",
    slaves_add: "Adición automática de empleados del departamento",
    slaves_add_byhands: "Adición manual",
    chief_add: "Adición automática de los jefes de departamento del empleado",
    chief_add_byhands: "Adición manual",
    del_subpassw_title: "Eliminación de contraseña",
    del_subpassw_message: "La contraseña será borrada, el usuario deberá ingresarla nuevamente al iniciar sesión",
    degree_msg_sended: "Notificaciones enviadas",
    degree_checkall_btn: "Marcar todo",
    degree_clear_btn: "Eliminar respuestas",
    degree_clear_msg: "Se eliminarán las evaluaciones (respuestas) de los evaluadores seleccionados. Tendrán que realizar las evaluaciones nuevamente.",
    degree_status_student: {
     1: "Sin evaluaciones de expertos",
     2: "No hay expertos asignados"
    },
    degree_status_expert: {
     0: "Todas las evaluaciones dadas",
     2: "Sin evaluaciones para una o más competencias",
     3: "No todas las evaluaciones dadas"
    },
    degree_status_btn: "Enviar notificación",
    degree_status_msg: "Está participando en la evaluación de empleados como evaluado. Cree una lista de evaluadores y seleccione los aprobadores para esta lista en la sección 'Mis Evaluaciones' de la cuenta personal de la aplicación 'Universidad'.",
    degree_fill_msg: "Está participando en la evaluación de empleados como evaluador. Evalúe a los empleados en la sección 'Mis Evaluaciones' de la cuenta personal de la aplicación 'Universidad'.",
    degree_uagree_msg: "Ha sido nombrado aprobador en la evaluación 360. Vaya a la sección 'Mis Evaluaciones' de la cuenta personal de la aplicación 'Universidad'.",
    degree_status: {
     0: "Aprobado",
     1: "No hay expertos asignados",
     2: "No hay aprobador asignado",
     3: "No aprobado"
    },
    Degree360StatusList: "Estados de formación",
    Degree360StatusFill: "Estados de llenado",
    change_password: "Cambiar contraseña",
    change_password_m: "Cambiar contraseñas de los empleados",
    change_password_hint: "Ingrese la nueva contraseña y la confirmación. Después de cambiar la contraseña, inicie sesión en el sistema.",
    change_password_hint_m: "Especifique el empleado, la contraseña se generará automáticamente.",
    change_password_done: "Contraseña cambiada, úsela en el próximo inicio de sesión.",
    change_password_fail: "Ocurrió un error, la contraseña no se cambió. Inténtelo de nuevo o contacte al administrador.",
    change_password_done_m: "Contraseña cambiada, cópiela y pásela al empleado.",
    clear_profile: "Eliminar perfil",
    clear_profile_msg: "El perfil será eliminado, pero el correo electrónico y la contraseña para iniciar sesión se guardarán. Para crear un nuevo perfil, deberá iniciar sesión en el sistema. ¿Está seguro?",
    clear_profile_done_m: "Perfil eliminado",
    add_head: "Gerente",
    add_users: "Empleado",
    struct_reset_msg: "¿Realmente desea restablecer la estructura?",
    struct_reset: "Restablecer estructura",
    department_add: "Departamento",
    struct_company: "/Empresa",
    struct_department: "Departamento",
    company_structure: "Empresa",
    last_name_need: "¡El nombre y el apellido deben estar completos!",
    degree_checkall_btn: "Marcar todo",
    sign_up_for_an_account: "Inicie sesión o regístrese para una cuenta",
    auth_email: "Email",
    auth_password: "Contraseña",
    auth_password2: "Repetir contraseña",
    sign_up: "Registrarse",
    sign_in: "Iniciar sesión",
    email_invalid: "Email inválido",
    password_invalid: "Contraseña inválida",
    email_exist: "Email encontrado",
    email_ok: "Email disponible",
    auth_password_match: "Contraseña coincidente",
    auth_password_not_match: "Las contraseñas no coinciden",
    invitations: "Invitaciones",
    link_invite: "Enlace de auto-registro",
    reminders: "Recordatorios",
    notifications: "Notificaciones",
    subac_validate_info: "Utilice solo letras latinas y números para el nombre de la subcuenta.",
    promo_1: "Expanda las capacidades de su aplicación. Compre una suscripción para un tema, disco o subcuentas en la sección Configuración:Mi Plan.",
    prog_filter:"Filtro",
    prog_filter_all: "Todos los programas",
    prog_filter_tgrp: "Plantillas para grupos",
    prog_filter_tuser:" para nuevos empleados",
    prog_is_template: "esta es una plantilla para asignar",
    prog_is_template_newuser: "nuevos empleados",
    prog_is_template_grp: "para grupos",
    prog_info_grp: "asignación Permanente. Cuando se agrega un funcionario al grupo, se le asignará automáticamente.",
    prog_info_newusers:"cuando se agrega un nuevo empleado al portal Bitrix24, se le asignará automáticamente.",
    im_agreemer: "soy un evaluador",
    agree_degree360_mess:"¿Acordar una lista de evaluadores para un puntaje de 360?",
    decline_degree360_mess: "¿Devolver la lista de evaluadores para la evaluación 360?",
    degree360_all_agreed: "las Listas de evaluadores son consistentes para una calificación de 360",
    degree_valid_1: "no se ha especificado el nombre de la Evaluación 360",
    degree_valid_2: "sin fecha establecida",
    degree_valid_3: "no se especifican Evaluadores para uno o más Evaluados",
    degree_valid_4: "no se especifican competencias",
    degree_valid_5: "no se ha establecido una escala de Calificación de 360",
    degree_valid_6: "Evaluadores no coincidentes en la auto-selección",
    pdf_download: "Descargar archivo",
    pdf_build: "Construir archivo PDF",
    scale_value:"Valor",
    scale_name: "Nombre",
    scale_desc: "Descripción",
    degree360_all_agreed: "las Listas de evaluadores son consistentes para una calificación de 360",
    degree_valid_1: "no se ha especificado el nombre de la Evaluación 360",
    degree_valid_2: "sin fecha establecida",
    degree_valid_3: "no se especifican Evaluadores para uno o más Evaluados",
    degree_valid_4: "no se especifican competencias",
    degree_valid_5: "no se ha establecido una escala de Calificación de 360",
    degree_valid_6: "Evaluadores no coincidentes en la auto-selección",
    degree360_self_message: "se le ha asignado una tarea para crear una lista de evaluadores para una calificación de 360",
    clear_agreed: "Eliminar coincidente",
    students_clear: "se eliminarán todos los Evaluados junto con sus Evaluadores",
    add_degree360_info: "la Puntuación 360 está totalmente formada por el moderador",
    add_task_degree360_info: "La lista de evaluadores se puede formar de dos maneras: por el moderador; por los evaluados. Cuando se active, la lista será formada por los evaluados. Después de que se publique la evaluación, los evaluados tendrán una tarea en su cuenta personal en la sección Mis Evaluaciones para crear una lista y seleccionar aprobadores. La evaluación comenzará cuando se formen todas las listas.",
    listDegree360: "Calificaciones 360",
    eve_and_bad_moder: "Solicitud de evento de un empleado",
    profiles_lb:"Perfiles",
    profiles_plc: "el Perfil contiene varias competencias",
    add_from_comps: "Seleccionar competencia",
    incomplete_data: "competencia Incompleta, falta de indicadores",
    experts_clear: "se eliminarán todos los evaluadores",
    degree_tasks: [{value: 0, label: "poner tareas individualmente"}, {value: 1, label:"poner una tarea común"}, {value: 2, label:"no poner tareas"}],
    old_assessments: "formato de evaluación Antiguo",
    degree_task_name: "Introduzca el nombre del trabajo",
    degree_task_description: "Introduzca una descripción del trabajo",
    the_task_360:"Tarea",
    tasks_360:"Tareas",
    add_task_degree360: "Crear una lista de evaluadores evaluables",
    add_degree360: "Añadir Puntuación 360",
    stud_id: "ID Evaluado",
    its_my_degree: "soy Evaluable",
    degree_readonly_info: "Ya se han realizado asignaciones para la Evaluación 360, no se pueden realizar algunas acciones con la Evaluación. Por ejemplo, eliminar personas evaluadas, evaluadores, eliminar y agregar competencias, cambiar fechas, habilitar la autoevaluación, cambiar la forma en que se establecen las tareas.",
    degree360_alert: "la Solicitud ya está acordada, no se puede modificar.",
    degree360_agreeID: "Coincidencia",
    degree360_agreed: "Acordado",
    degree360_wait_agreed: "pendiente de aprobación",
    degree360_self_title: "Solicitudes de Evaluación 360",
    degree360_self: "Solicitud de calificación 360",
    degree_self: "Crear una lista de evaluadores",
    degree_self_info: "para presentar una solicitud, seleccione un Evaluador y El responsable de acordar la Evaluación 360",
    degree_start_finish_info: "las Fechas se utilizan de forma informativa y no afectan a la visualización de la Puntuación.",
    agree_not_valid: "No todos los datos están completos. Por favor, compruebe el título, las fechas Estimadas, Evaluando.",
    degree_add_scale_info: "Seleccione una escala de calificación de la lista para agregarla a la Calificación 360",
    degree_add_comp_info: "Seleccione competencia de la lista y haga clic en el botón < + > para agregar competencia a la Evaluación 360",
    degree_adb_prof_info: "Seleccione un perfil de la lista y haga clic en +. Sus competencias se agregarán a la evaluación de 360",
    degree_add_experts_info: "Añadir Evaluadores usando los botones de abajo",
    degree_add_students_info: "Agregue los empleados Evaluados usando los botones de abajo",
    degree_agree_degree360_info: "en la posición izquierda de la casilla de verificación, puede guardar la evaluación de trabajo. Posición derecha: se realizará la asignación al guardar.",
    degree_agree_user_info: "para asignar una Evaluación, se necesita una coincidencia. Seleccione un empleado a juego.",
    degree_hide_fio_info: "de forma predeterminada, el evaluador verá los datos de los evaluadores. Cuando se activa, los datos de los evaluadores se ocultarán.",
    degree_pub_lk_info: "Publique los resultados de la evaluación en el área personal del evaluador después de que todos los evaluadores hayan realizado la evaluación.",
    degree_task_info: "Tarea en Bitrix para hacer una evaluación. Puesto por el evaluador.",
    show_options_block: "configuración de Puntuación 360",
    show_users_block: "Listas de evaluados y evaluados",
    show_comps_block: "Competencias, indicadores, escala de evaluación",
    degree360_aver_title: "Resultados de las evaluaciones (promedios)",
    degree360_stat_title:" número de encuestados que participaron en la evaluación",
    degree360_aver_col_:"Prácticas",
    degree360_stat_ret0:"número de personas",
    degree360_stat_col_: "grupos de encuestados",
    degree360_stat_col0: "Autoevaluación",
    degree360_stat_col1:"Jefe",
    degree360_stat_col2:"Colegas",
    degree360_stat_col3: "Subordinados",
    degree360_stat_col4: "todos los encuestados<br> (sin autoevaluación)",
    degree360_stat_col5: "todos los encuestados",
    degree360_description: "debe realizar una evaluación de 360",
    degree360_message:" tiene una Puntuación de 360. Vaya a la aplicación 'Universidad / mis calificaciones'",
    you_agree_360: "usted está asignado a un concordante en la Evaluación 360.  Vaya a la aplicación Universidad para la conciliación.",
    del_student: "quieres eliminar el evaluado",
    do_schedules: "se asignará una Puntuación de 360 al evaluador",
    no_experts: "evaluadores No especificados",
    agree_user: "concordante",
    agree_degree360: "Acordar",
    decline_degree360: "Devolver",
    decline_degree360_info: "si ha cambiado el estado de su solicitud, guárdela",
    publish_degree360: "publicación de evaluación 360",
    del_degree360: "Eliminar Puntuación 360",
    del_degree360_msg: "Realmente quieres eliminar la Puntuación 360",
    degree_name: "Introduzca el nombre de la evaluación",
    add_prof_label: "Seleccionar perfil",
    scale_plc: "Seleccionar escala",
    add_prof: "Añadir a competencias",
    save_comp: "Guardar",
    close_comp:"Cerrar",
    program_readonly: "se han realizado asignaciones Para este programa, por lo que no se puede cambiar. Puede hacer una copia del programa en la lista de programas para modificarlo y nuevas asignaciones.",
    program_info: "el Módulo' Programas ' está diseñado para formar programas integrales de desarrollo. Por ejemplo, como la Adaptación de los principiantes, la Formación de la reserva de personal, etc."+
      "<br>Los programas se pueden formar a partir de los siguientes elementos: cursos, pruebas, lecciones (por ejemplo, agregar un archivo PDF a una lección, reglas, presentación, enlaces, materiales, longrid, etc.), actividades (por ejemplo, crear un evento Reunión con un Supervisor)."+
      "<br>Los programas se forman a partir de elementos en paralelo y secuencialmente. Los elementos sucesivos forman niveles (esto puede ser un Día,una Etapa de aprendizaje). En un nivel puede ser cualquier número de elementos, lo que significa que en este nivel todos ellos tienen que pasar."+
      "<br>Después de crear un programa, puede asignarlo a empleados, grupos (cuando agrega a un empleado a un grupo, se le asignará automáticamente el programa). Puede cambiar la lista de empleados: al agregar, se asignará un programa al empleado, y al eliminar, continuará presente en los informes."+
      "<br>El programa asignado no se puede cambiar.",
    copy_program: "Copiar programa",
    module_level:"Nivel",
    eve_and_bad_moder: "Solicitud de evento de un empleado",
    is_answered: "Hay respuestas",
    check_all: "Marcar todas las puntuaciones",
    check_group: "Marcar un grupo",
    check_one: "Marcar línea",
    rep_date: "fecha",
    rep_type: "Por qué",
    the_open: "pregunta Abierta",
    the_event:"Evento",
    the_360: "Puntuación 360",
    the_aos: "AOS",
    the_rate: "Evaluación del curso / prueba",
    the_comment: "Comentario",
    the_ipr: "ypr",
    the_Rc: "Evaluación del curso",
    the_Rt: "Evaluación de la prueba",
    the_course:"Curso",
    write_off: "Cancelación",
    not_in_sched: "Falta en la asignación del programa",
    sub_alert: "esta cuenta contiene sub-cuentas, pero su límite se ha agotado. Póngase en contacto con el moderador de la aplicación",
    sub_limit_exceeded: "se ha agotado el límite de sub-cuentas. Vaya a configuración / mi tarifa para aumentar el límite",
    sub_login_already: "esta sub cuenta ya existe",
    sub_accounts:"Subaccounts",
    native_add: "Añadir cuenta. Solo disponible para el administrador del portal",
    sub_add: "Añadir sub-cuenta",
    del_subac_title: "Eliminar sub-cuenta",
    del_subac_message: "la sub-Cuenta se eliminará de la lista. ¿Está seguro?",
    del_sub_title: "Eliminar cuenta",
    del_sub_message: "la Cuenta se eliminará de la lista junto con sus sub-cuentas. ¿Está seguro?",
    self_sign_up: "auto-registro",
    sub_acc_title: "Autorización",
    sub_acc_login:"Login",
    sub_acc_password:"Contraseña",
    sub_acc_password2: "Repita la contraseña",
    sub_acc_recv: "Sus datos",
    sub_acc_first_name: "Nombre",
    sub_acc_last_name:"Apellido",
    sub_acc_middle_name: "segundo Nombre",
    sub_acc_position:"Posición",
    sub_acc_login_not_found: "Inicio de Sesión no encontrado, se requiere registro",
    sub_acc_save: "Guardar",
    sub_acc_ok: "Iniciar sesión",
    sub_acc_passwords_not_match: "las Contraseñas no coinciden",
    sub_acc_password_fail: "contraseña Incorrecta",
    sub_acc_valid_recv: "Rellene los detalles",
    sub_acc_recv: "Modificar datos",
    sub_important_info: "Información importante",
    sub_info: "<ol>"+
     "<li><b>Agregar una cuenta.</b><br>"+
     "Condiciones importantes:</li>"+
     "<ul><li>Esta es una cuenta de Bitrix24 a la que se vincularán las subcuentas de la Universidad.</li>"+
     "<li>Solo el administrador del portal de Bitrix24 puede crear una cuenta. No el moderador de la aplicación.</li>"+
     "<li>La cuenta no puede ser un Administrador de Bitrix24 o un moderador de la aplicación Universidad.</li></ul>"+
     "<li><b>Seleccione el método de registro de subcuentas en la aplicación:</b></li>"+
     "<ul><li><b>Independientemente.</b> En este caso, el administrador del portal solo necesita ingresar los inicios de sesión e informar estos inicios de sesión a las subcuentas. Al iniciar sesión, las subcuentas ingresarán su inicio de sesión, luego la aplicación les pedirá que completen los datos (nombre completo y puesto) y creen una contraseña.</li>"+
     "<li><b>Por el administrador.</b> En este caso, el administrador ingresa el inicio de sesión y completa todos los datos (nombre completo y puesto), y luego proporciona los inicios de sesión a las subcuentas. Al iniciar sesión, se le pedirá a la subcuenta que cree una contraseña. Los otros datos (nombre completo y puesto) ya estarán completados por el Administrador.</li></ul>"+
     "<li><b>Cada vez que inicien sesión en la aplicación, la subcuenta deberá ingresar su inicio de sesión y contraseña.</b></li>"+
     "</ol>",
     cert_format: "Formato de página",
    cert_name:" nombre del certificado",
    cert_save: "Guardar certificado",
    cert_new: "Añadir certificado",
    cert_list:" lista de certificados",
    clear_form: "nuevo certificado",
    cert_remove: "Eliminar de la lista",
    cert_default: "por defecto",
    cert_scale:"Escala",
    cert_opts_fio:" nombre de usuario",
    cert_opts_pos:"Posición",
    cert_opts_name:" nombre de la prueba / curso",
    cert_opts_score: "Puntuación de Aprobación",
    cert_opts_date: "fecha de aprobación",
    cert_opts_text: "texto Adicional",
    cert_dark_mode: "fondo Oscuro",
    cert_orient_a:"Horizontal",
    cert_orient_p:"Retrato",
    certs:"Certificados",
    cer_tificate:"Certificado",
    cert_img:"Imagen",
    save_png: "Descargar",
    cert_del_title: "¡Atención!",
    cert_del_message: "el Certificado se eliminará de la lista. También se eliminará el archivo del disco.",
    cert_reload_title: "¡Atención!",
    cert_reload_message:"para aplicar los cambios, deberá salir y volver a la configuración del certificado.",
    cert_font_size:" Tamaño de fuente",
    cert_font:"Fuente",
    cert_font_color:" color de fuente",
    separ_add: "Añadir separador",
    sep: "Separador",
    is_program_tasks: [{value: 0, label: "poner tareas para cada módulo"}, {value: 1, label:"poner una tarea común"}, {value: 2, label:"no poner tareas"}],
    program_tasks:"Tareas",
    program_task_description: "necesita ejecutar el programa",
    course_passed: "Curso aprobado",
    module_complete: "Finalizar módulo",
    done_success: "¡con Éxito!",
    save_program: "Guardar",
    add_module_s: "Añadir módulo serie",
    add_module_p: "Añadir módulo paralelo",
    program_message: "se le ha asignado un programa de capacitación. Vaya a la aplicación 'Universidad / mis programas'",
    my_programs: "Mis programas",
    publish_schedule_tooltip: "no se ha seleccionado ningún programa o fecha de asignación, director, empleados",
    mod_description: "En el plan de ejecución del programa, es necesario ejecutar el módulo",
    list_schedules: "Asignaciones",
    add_schedule: "Añadir destino",
    one_schedule: "Asignación",
    publish_schedule: "hacer una cita",
    dates_schedule: "fechas de destino",
    add_students: "Empleados",
    dep_rem_title: "¿Eliminar de destino?",
    dep_rem_mess: "Eliminar a todos con este grupo (unidad organizativa)",
    dep_rem_mess1: "Eliminar a este miembro",
    start_finish: "Inicio / fin",
    selectusers_label: "Empleados",
    selectgroups_label: "Grupos",
    selectdeps_label: "Departamentos",
    selecttests_label: "cursos/pruebas",
    selectgroup_title: "Añadir grupo",
    selectgroup_message: "Seleccione un grupo",
    courses_tests: "cursos-pruebas",
    students:"Estudiantes",
    del_schedule_title:"Eliminar",
    del_schedule_message: "Confirmar eliminación de destino",
    del_schedules_message: "Confirmar eliminación de asignaciones",
    date_to_short: "terminaciones",
    students_comment: "si un empleado se ha agregado a través de un Departamento o grupo, la eliminación de dicho empleado implicará la eliminación de otros empleados de ese Departamento/grupo",
    add_department:"Departamento",
    add_group: "Grupo",
    add_main_auditor:"Jefe",
    mod_duration:"Duración, día.",
    mod_action: {'hand':" manual", 'auto':"auto"},
    add_program: "Añadir programa",
    list_programs: "Lista de programas",
    one_program: "Programa",
    progrm_modules:"Módulos",
    requaried_field: "campo Requerido",
    program_name: "nombre del programa",
    program_description: "descripción del programa",
    module_type:"tipo de módulo",
    module_type_opt:[{value: 1, label:"Curso"}, {value:2, label:"Prueba"}, {value:3, label:"Lección"}],
    select_module: "Seleccione un módulo",
    not_found: "no se encontró nada",
    type_open:" cómo abrir módulos",
    type_open_opt: [{value:'P', label:"Abrir todo a la vez"},{value:'S', label:"Abrir secuencialmente. Si el módulo falla, todavía abrir el siguiente"}, {value:'H', label:"Abrir secuencialmente. Si el módulo falla, no abra el siguiente"}],
    publish_program: "Publicar un programa",
    del_program:"Eliminar",
    del_program_msg: "estás seguro de que quieres eliminar",
    mod_passed: [{value: '0', label: 'no status'}, {value: 'Y', label: 'Pasado'}, {value: 'N', label: 'No pasado'}],

    points:"Puntuaciones",
    points_plc: "ponga 0 si los puntos se otorgan como se establece en la configuración de la aplicación",
    for_course_active: "curso Completado",
    for_course_mod_lb: "Método de conteo",
    for_course_mode:[
      {value: 'sum', label:"Calcular sumando puntos por módulos"},
      {value: 'hand', label:"Introducir manualmente"},
    ],
    for_course_dop_lb: "Puntos por lecciones/pruebas dentro del curso",
    for_course_dop:[
      {value: 'top', label: 'Cargar'},
      {value: 'node', label:"No cargar"},
    ],
    bid_del_message: "Eliminar oferta",
    em_count_limit: "Se excedió el número máximo de participantes",
    em_count:"Número de participantes. 0-ilimitado",
    eve_attende:"Participante",
    eve_bid:"Oferta",
    not_published: "No publicado",
    my_events: "Mis eventos",
    ipr_comp: "Competencia",
    ipr_ind:"Indicadores",
    eve_bid_done: "su solicitud ha sido agregada. Después de la revisión, se convertirá en miembro.",
    eve_new_big_add: "¿el evento No está en la lista? Ofrezca una oferta. Después de la verificación, el evento se agregará a la lista y usted se convertirá en miembro.",
    eve_new_bid_title: "Solicitud de nuevo evento",
    eve_new_bid_done: "su solicitud ha sido agregada. Una vez revisado, el evento se agregará a la lista y usted se convertirá en miembro.",
    alert_understood: "Entendido",
    eve_new_bid_moder: "un empleado ha recibido una solicitud para el evento",
    em_details: "Detalles",
    bid_restore_title: "Restaurar ticket",
    bid_restore_message: "Especifique el motivo de la restauración de la solicitud",
    bid_accepted: "Solicitud aceptada",
    bid_declined: "Solicitud rechazada, restaurar.",
    bind_accept: "Aceptar solicitud",
    bid_decline_title: "Rechazar solicitud",
    bid_decline_message: "Especifique el motivo de la solicitud rechazada",
    eve_bids_all: "Añadir todos",
    eve_calend: "En el calendario",
    eve_detail: "Más información sobre el evento",
    eve_edit:"Editar",
    em_bids:"Aplicaciones",
    eve_bid_lbl: "Aplicar para el evento",
    eve_title: "Aplicar para el evento",
    eve_bin_add: "Seré miembro",
    eve_nts:"Eventos",
    text_block: "bloque de Texto",
    text_block_name: "Nombre del campo",
    text_block_value: "Contenido del campo",
    r_o:" sólo lectura",
    set_files:"Archivos",
    integral_chart:"Gráfico integral",
    view_cards:"Ver: tarjetas",
    view_list:"Ver: lista",
    node_disabled:"Hacer inactivo",
    node_enabled: "Activar",
    download: "Descargar",
    no_valid_publish:"no se han establecido datos importantes, como empleado, supervisor, mentor, competencias, indicadores o no hay permisos de edición",
    hide_fio: "Ocultar datos de evaluadores",
    such_element_exist: "Tal elemento ya existe",
    program_course:"/Programa del curso",
    lessons:"Lecciones",
    tests:"Pruebas",
    no_lessons:" no hay lecciones para agregar al curso",
    no_tests:" no hay pruebas para agregar al curso",
    drop_here: "Arrastrar y soltar lecciones y pruebas aquí",
    reset_tree: "Restablecer navegación",
    reset_true_msg: "¿Eliminar todas las lecciones y pruebas del curso?",
    export_all:"Exportar todo",
    imgFiles:"Archivos para responder",
    upload_title:"Subir",
    upload_err:"Carga fallida",
    upload_hint:"Seleccione un archivo en el disco o arrástrelo y suéltelo aquí",
    max_file_size:"El tamaño del archivo no debe exceder {mb} Mb",
    delete_image:"Borrar archivo",

    ipr_note:"Se le asignó un 'Plan de Desarrollo Individual'. Vaya a la aplicación de su Universidad Corporativa.",
    add_evt_title: "Evento agregado",
    add_evt_msg: "¿Cerrar el formulario agregar eventos e ir a la lista?",
    add_evt_ok: "Cerrar",
    add_evt_canc: "Añadir más",
    res360_published: "los Resultados de la evaluación 360 se publican en el área Personal del evaluador",
    rm_rm: "evento de Desarrollo",
    rm_dln: "Vencido",
    rm_deadline: "Fecha límite",
    go_course: "Ir",
    no_results:" sin datos",
    enter_course_name: "Escriba una parte del nombre para buscar",
    course_name: "Añadir curso",
    course_statuses: "Estado de los cursos",
    ipr_courses: "cursos de RM",
    ipr_export:"Exportar",
    iprset_create_label: "Quién puede crear",
    iprset_edit_label: "quién puede editar",
    iprset_read_label: "quién puede ver",
    iprset_status_label: "quién puede cambiar el estado",
    iprset_role_opt: [{value: 'M', label: "moderador"}, {value: 'U', label:"Empleado"}, {value: 'N', label:"Mentor"}],

    add_rm:"RM",
    add_ind:"Indicador",
    ipr_title: "plan de desarrollo Individual",
    ipr_name: "nombre IPR",
    ipr_close:"Cerrar",
    ipr_open: "Abrir",
    select_person: "Empleado",
    select_manager:"Jefe",
    select_mentor: "Mentor",
    rm_label:"RM",
    rm_options:[
      "Assesment",
      "Clase magistral",
      "Concurso",
      "Concurso",
      "Conferencia",
      "Conferencia",
      "Contenido de audio",
      "Contenido de video",
      "Curso a distancia",
      "Cursos",
      "Entrenamiento",
      "Evaluación",
      "Facilitación",
      "Formación de equipos",
      "Hackathon",
      "Informe",
      "Investigación",
      "Juego",
      "Maletín",
      "Mitap",
      "Pasantía",
      "Período de sesiones",
      "Podcast",
      "Proyecto",
      "Pruebas",
      "Reunión",
      "Seminario",
      "Simulación",
      "Tutela",
      "Universiada",
      "Webinar",
      "Workshop",
      "-su opción-"
    ],
    rm_compleeted: "Completado",
    rm_started: "en proceso",
    rm_new: "No iniciado",
    rm_status:"Estado",
    hand_made: "Introducir manualmente",
    comp_name: "nombre de la competencia",
    ind_name: "Nombre del indicador",
    del_ind: "Eliminar indicador",

    selectcomps_label:"Competencias",
    selectcomps_title: "Añadir competencias",
    selectcomp_message:"Seleccionar competencia",
    selectcomps_message: "Seleccione una o más competencias",
    selectcomps_add:"Agregar",
    selectcomps_finish:"Cerrar",
    selectscale:"escala de calificación",
    target_sc:"nivel objetivo",
    current_sc: "nivel Actual",

    del_certs:"el Certificado se eliminó en Educación, elimínelo aquí",
    no_certs: "no tiene ningún certificado descargado. Añádalos a la sección Educación ",
    aos_filled: "lleno",
    anketa_add: "Añadir perfil",
    for_test: "para pruebas",
    for_course: "para cursos",
    for_event: "para eventos",
    aos_copy: "(Copia)",
    new_aos_name: "Cambia el nombre para guardarlo",
    first_aos_name: "Perfil predeterminado",
    aos_edit: "Edición del cuestionario",
    aos_look: "Ver perfil",
    aos_refresh_title: "Cargar por defecto",
    aos_refresh_message: "Su perfil será reemplazado por el perfil predeterminado",
    answ_aos: "Respuestas",
    answer:"Texto",
    score:"Peso",
    quest_aos: "Preguntas",
    add_answ: "Añadir respuesta",
    other_answ: "Otro",
    a_o_s: "AOS",
    add_aos: "Añadir pregunta",
    aos: "Cuestionario de comentarios",
    aos_del: "Eliminar perfil",

    cert_del: "¿Eliminar el certificado del evento?",
    cert_add: "Añadir certificado",
    event_certs: "Seleccione certificados",
    em_del: "Eliminar un evento (también del calendario)",
    em_save: "Grabar",
    em_clear:"Borrar",
    em_meeting_notify: "Enviar invitaciones",
    em_description: "descripción del evento",
    em_meeting_text: "texto de invitación",
    em_color:"color del evento",
    em_text_color: "color de TeX",
    em_attendees:"Miembros",
    em_host:'Responsable',
    em_org:"Organizador",
    em_title: "eventos Externos y presenciales",
    em_eventName: "nombre del evento",
    em_common_list: "lista General",
    em_personas: 'Evaluables',
    em_close:"elegir",
    em_location:"Tipo",
    em_location_opt: ['Interno', 'Externo'],
  em_vid:"Ver",
  em_vid_opt:[
  'Assessment',
  'Caso',
  'Concurso',
  'Conferencia',
  'Conferencia',
  'Contenido de audio',
  'Contenido de video',
  'Curso a distancia',
  'Cursos',
  'Entrenamiento',
  'Evaluación',
  'Facilitación',
  'Formación de equipos',
  'Hackathon',
  'Informe',
  'Investigación',
  'Juego',
  'Master class',
  'Mitap',
  'Pasantía',
  'Podcast',
  'Proyecto',
  'Quiz',
  'Seminario',
  'Sesión',
  'Simulación',
  'Testing',
    'Tutoría',
    'Universiada',
    'Webinar',
    'Workshop',
],
  common_list: "lista Común",
  personas: 'Evaluables',
  exp_comments: "Comentarios",
  no_events: "No hay próximos eventos",
    em_from_to: "Inicio y fin del evento",
    acc1: "Mostrar resultados en cuenta personal",
    add_section: "Añadir sección",
    comps_added: "competencias Añadidas",
    comps_add: "Añadir competencia",
    attention:"Atención",
    del_competence: "Eliminar competencia",
    competencies:'Competencias',
    all: "Todos",
    add_coworkers: 'Añadir colegas',
    coworkers:'Colegas',
    add_slaves: 'Añadir subordinados',
    slaves:'Esclavos',
    add_chief: 'Añadir supervisor',
    chief:'Jefe',
    nuevo:'Empleado',
    average_: 'Promedio sin evaluación',
    departments:'Divisiones',
    add_departments: 'Añadir unidades',
    selectdepartments_title: 'Añadir Subdivisión',
    selectdepartments_message :' seleccione una unidad organizativa',
    selectdepartments_add: 'Añadir',
    selectdepartments_finish: 'Cerrar',

    rep_competence:"Competencia/Indicadores",
    rep_indicators:"Expertos",
    rep_scores:"Puntuaciones",
    comment:"Comentario evaluado",
    acc: "Publicar en la cuenta personal del evaluado",
    you_ass_360:" se le asigna una Puntuación de 360. Para obtener una lista de todas las evaluaciones, consulte su cuenta personal.",
    del_many_title: "Eliminar múltiples evaluaciones",
    del_many_mess:" desea eliminar varias calificaciones marcadas",
    rows_per_page: 'Registros por página',
    all_report: 'Informe General',
    start_finish: 'Inicio / fin',
    competence: 'Competencia',
    selectusers_title: 'Añadir empleado',
    selectuser_message: 'Seleccionar usuario',
    selectusers_message: 'Seleccione uno o más usuarios',
    selectusers_add: 'Añadir',
    selectusers_finish: 'Cerca',
    title: '360/180 comentarios',
    goback: 'Volver',
    loading: 'Carga',
    remove: 'Quitar',
    assessment: 'comentario',
    competences: 'Competencias',
    name: 'Nombre',
    date_start: 'Fecha de inicio',
    date_finish: 'Fecha de finalización',
    description: 'Descripci',
    persona: 'Bajo feedback',
    experts: 'Miembro',
    clear: 'Claro',
    del: 'Eliminar',
    add: 'Añadir',
    add_assessment: 'Crear un comentario',
    add_persona: 'Añadir a una persona en feedback',
    add_experts: 'Añadir miembros',
    ok: 'OK',
    reset: 'Cancelar',
    scores: 'Comentario',
    publish: 'Publicar',
    alert_message: 'Datos no cumplimentados',
    alert_persona: 'Bajo feedback',
    alert_experts: 'Miembro',
    alert_dates: 'Fechas',
    alert_competences: 'Competencias',
    alert_scores: 'Comentario',
    del_ass_title: 'Eliminar calificación',
    del_ass_mess: 'Eliminar calificación',
    deleteSome: 'Eliminar',
    export: 'Exportación',
    report: 'Informe',
    del_some_title: 'Eliminar',
    del_some_mess: 'Desea eliminar la calificación',
    more: 'Mas',
    chart: 'Resultado',
    chart_title: 'Gráfico de calificación de 180°/360° para empleados',
    refresh: 'Lista de actualización',
    user: 'Empleado',
    clear_scores: 'Clasificación clara',
    clrscores_title: 'Claro',
    clrscores_message: 'Todas las puntuaciones se eliminarán de esta Calificación. Continuar?',
    legend: 'Nombres completos de competencias',
    results: 'Resultado',
    average: 'Promedio',
    average1: 'Promedio*',
    close_report: 'Cerrar informe',
    assessment_name: 'Nombre de la calificación',
    action: 'Acción',
    list: 'Volver a la lista de puntuaciones',
    status: 'Estado',
    published: 'Publicado',
    draft: 'Borrador',
  },
  vn: {
    sign_up_btn_ok: "Tạo",
    sign_up_confirm: "Một cổng thông tin mới sẽ được tạo. Bạn sẽ trở thành quản trị viên chính và có thể mời người dùng khác.",
    is_reg_info: "Email này không được tìm thấy. Bạn có thể đăng ký một cổng thông tin mới với nó và mời người dùng.",
    for_prog_dop_lb: "Điểm cho khóa học/bài học/kiểm tra bên trong",
    passed_prog: "Chương trình đã hoàn thành",
    the_collapsed: "Tổng cộng hơn 3 tháng",
    the_collapsed_info: "Các mục nhập trong chi tiết cũ hơn ba tháng được gộp lại thành một. Điểm được cộng dồn trong quá trình này",
    the_test: "Bài kiểm tra",
    the_lesson: "Bài học",
    passed_course: "Khóa học đã hoàn thành",
    points_reset: "Đặt lại tất cả điểm",
    duration_info: "Thời gian chương trình tính bằng ngày. Được tính bằng cách cộng tổng thời gian của tất cả các mô-đun. Được sử dụng để xác định thời hạn cho các nhiệm vụ và bài tập.",
    the_programs: "Chương trình",
    program_view_btn: "Chưa chọn người quản lý chương trình",
    the_program_info: "Bằng cách nhấp vào nút 'Đi tới', bạn sẽ tự chỉ định chương trình để hoàn thành. Bạn có thể tìm thấy nhiệm vụ trong phần 'Chương trình của tôi'.",
    go_to_program: "Đi tới",
    the_program: "Chương trình",
    clear_program: "Xóa chương trình. Chỉ có thể xóa các chương trình chưa được công bố.",
    uncheck_all_uscheds: "Bỏ chọn tất cả",
    check_all_uscheds: "Chọn tất cả",
    is_archive: "Lưu trữ",
    from_archive: "Khôi phục từ lưu trữ",
    to_archive: "Chuyển vào lưu trữ",
    program_archived: "Đã chuyển vào lưu trữ",
    program_published: "Chương trình đã được công bố",
    add_auditors: "Người chịu trách nhiệm cho mô-đun",
    modules_auditors: "Chỉnh sửa người quan sát và thời hạn của mô-đun",
    add_main_auditor_info: "Người quản lý chương trình là người quan sát trong nhiệm vụ. Họ sẽ được chỉ định cho từng mô-đun trừ khi một người quan sát khác được chọn trong các mô-đun.",
    dates_schedule_info: "Các ngày là thông tin và được sử dụng để thiết lập thời hạn nhiệm vụ",
    clear_schedule: "Xóa phân công. Chỉ có thể xóa các phân công chưa được công bố.",
    program_updated: "Chương trình đã lưu",
    program_added: "Chương trình đã thêm",
    program_scheds_done: "Đã thực hiện các phân công",
    such_module_exist: "Mô-đun đã được chọn",
    such_user_exist: "Nhân viên đã được chọn",
    duration_hint: "Được tính tự động",
    degree_add_block_info: "Chọn một khối năng lực từ danh sách và nhấp vào +. Các năng lực của nó sẽ được thêm vào Đánh giá 360.",
    add_block_label: "Chọn khối năng lực",
    slaves_add: "Thêm tự động nhân viên của bộ phận",
    slaves_add_byhands: "Thêm thủ công",
    chief_add: "Thêm tự động các trưởng bộ phận của nhân viên",
    chief_add_byhands: "Thêm thủ công",
    del_subpassw_title: "Xóa mật khẩu",
    del_subpassw_message: "Mật khẩu sẽ bị xóa, người dùng sẽ cần nhập lại khi đăng nhập",
    degree_msg_sended: "Thông báo đã được gửi",
    degree_checkall_btn: "Chọn tất cả",
    degree_clear_btn: "Xóa phản hồi",
    degree_clear_msg: "Các đánh giá (phản hồi) của những người đánh giá được chọn sẽ bị xóa. Họ sẽ cần thực hiện lại các đánh giá.",
    degree_status_student: {
     1: "Không có đánh giá của chuyên gia",
     2: "Không có chuyên gia được chỉ định"
    },
    degree_status_expert: {
     0: "Tất cả các đánh giá đã được đưa ra",
     2: "Không có đánh giá cho một hoặc nhiều năng lực",
     3: "Không phải tất cả các đánh giá đều được đưa ra"
    },
    degree_status_btn: "Gửi thông báo",
    degree_status_msg: "Bạn đang tham gia đánh giá nhân viên với tư cách là người được đánh giá. Tạo danh sách người đánh giá và chọn người phê duyệt cho danh sách này trong phần tài khoản cá nhân 'Đánh giá của tôi' của ứng dụng 'Đại học'.",
    degree_fill_msg: "Bạn đang tham gia đánh giá nhân viên với tư cách là người đánh giá. Đánh giá nhân viên trong phần tài khoản cá nhân 'Đánh giá của tôi' của ứng dụng 'Đại học'.",
    degree_uagree_msg: "Bạn đã được chỉ định làm người phê duyệt trong đánh giá 360. Đi tới phần tài khoản cá nhân 'Đánh giá của tôi' của ứng dụng 'Đại học'.",
    degree_status: {
     0: "Đã phê duyệt",
     1: "Không có chuyên gia được chỉ định",
     2: "Không có người phê duyệt được chỉ định",
     3: "Chưa được phê duyệt"
    },
    Degree360StatusList: "Trạng thái hình thành",
    Degree360StatusFill: "Trạng thái điền",
    change_password: "Đổi mật khẩu",
    change_password_m: "Đổi mật khẩu của nhân viên",
    change_password_hint: "Nhập mật khẩu mới và xác nhận. Sau khi đổi mật khẩu, hãy đăng nhập vào hệ thống.",
    change_password_hint_m: "Chỉ định nhân viên, mật khẩu sẽ được tạo tự động.",
    change_password_done: "Mật khẩu đã được đổi, sử dụng nó cho lần đăng nhập tiếp theo.",
    change_password_fail: "Đã xảy ra lỗi, mật khẩu không được đổi. Thử lại hoặc liên hệ với quản trị viên.",
    change_password_done_m: "Mật khẩu đã được đổi, sao chép và chuyển cho nhân viên.",
    clear_profile: "Xóa hồ sơ",
    clear_profile_msg: "Hồ sơ sẽ bị xóa, nhưng email và mật khẩu để đăng nhập sẽ được lưu lại. Để tạo hồ sơ mới, bạn sẽ cần đăng nhập vào hệ thống. Bạn có chắc không?",
    clear_profile_done_m: "Hồ sơ đã bị xóa",
    add_head: "Quản lý",
    add_users: "Nhân viên",
    struct_reset_msg: "Bạn có thực sự muốn đặt lại cấu trúc không?",
    struct_reset: "Đặt lại cấu trúc",
    department_add: "Phòng ban",
    struct_company: "/Công ty",
    struct_department: "Bộ phận",
    company_structure: "Công ty",
    last_name_need: "Tên và họ phải được điền!",
    degree_checkall_btn: "Chọn tất cả",
    sign_up_for_an_account: "Đăng nhập hoặc đăng ký tài khoản",
    auth_email: "Email",
    auth_password: "Mật khẩu",
    auth_password2: "Nhập lại mật khẩu",
    sign_up: "Đăng ký",
    sign_in: "Đăng nhập",
    email_invalid: "Email không hợp lệ",
    password_invalid: "Mật khẩu không hợp lệ",
    email_exist: "Email đã tồn tại",
    email_ok: "Email có sẵn",
    auth_password_match: "Mật khẩu khớp",
    auth_password_not_match: "Mật khẩu không khớp",
    invitations: "Lời mời",
    link_invite: "Liên kết tự đăng ký",
    reminders: "Nhắc nhở",
    notifications: "Thông báo",
    subac_validate_info: "Chỉ sử dụng chữ cái Latinh và số cho tên tài khoản phụ.",
    promo_1: "Mở rộng khả năng của ứng dụng của bạn. Mua đăng ký cho chủ đề, đĩa hoặc tài khoản phụ trong phần Cài đặt:Kế hoạch của tôi.",
    prog_filter:"Bộ Lọc",
    prog_filter_all: "Tất cả chương trình",
    prog_filter_tgrp: "Mẫu cho nhóm",
    prog_filter_tuser:" dành cho nhân viên mới",
    prog_is_template: "đây là mẫu cho bài tập",
    prog_is_template_newuser:"nhân viên mới",
    prog_is_template_grp: "dành cho nhóm",
    prog_info_grp: "Chuyển Nhượng Vĩnh viễn. Khi một nhân viên được thêm vào nhóm, anh ta sẽ tự động được chỉ định.",
    prog_info_newusers:" khi một nhân viên mới được thêm vào Cổng Bitrix24, anh ta sẽ tự động được chỉ định.",
    im_agreemer: "tôi là người đánh giá",
    agree_degree360_mess: "Đồng ý về một danh sách các đánh giá cho một Lớp 360?",
    decline_degree360_mess: "Tôi có nên trả lại danh sách người đánh giá để đánh giá 360 để sửa đổi không?",
    degree360_all_agreed: "Danh sách các nhà đánh giá đã được thống nhất cho lớp 360",
    degree_valid_1: "Tên Của Lớp 360 chưa được đặt",
    degree_valid_2: "Ngày chưa được đặt",
    degree_valid_3: "Người Đánh giá không được đặt cho một hoặc nhiều Người Đánh giá",
    degree_valid_4: "Năng lực không được thiết lập",
    degree_valid_5: "thang Điểm 360 không được đặt",
    degree_valid_6: "Người Đánh giá không được thỏa thuận khi đưa ra lựa chọn của riêng họ",
    pdf_download: "Tải về tập tin",
    pdf_build: "Xây dựng TỆP PDF",
    scale_value: "Giá trị",
    scale_name: "Tên",
    scale_desc: "Mô tả",
    degree360_all_agreed: "Danh sách các nhà đánh giá được đồng ý cho lớp 360",
    degree_valid_1: "Tên Của Lớp 360 không được đặt",
    degree_valid_2: "Ngày không được đặt",
    degree_valid_3: "Người Đánh giá không được đặt cho một hoặc nhiều Người Đánh giá",
    degree_valid_4: "Năng lực không được thiết lập",
    degree_valid_5: "thang Điểm 360 không được đặt",
    degree_valid_6: "Người Đánh giá không nhất quán khi đưa ra lựa chọn của riêng họ",
    degree360_self_message: "Bạn Đã được chỉ định một nhiệm vụ để tạo danh sách các trình đánh giá cho Lớp 360",
    clear_agreed: "Loại bỏ sự phù hợp",
    students_clear: "Tất cả Học Sinh Sẽ bị xóa cùng Với Học sinh Của họ",
    add_degree360_info: "xếp hạng 360 được tạo hoàn toàn bởi người điều hành",
    add_task_degree360_info: "Danh sách người đánh giá có thể được hình thành theo hai cách: bởi người điều hành; bởi người được đánh giá. Khi được kích hoạt, danh sách sẽ được hình thành bởi người được đánh giá. Sau khi đánh giá được công bố, người được đánh giá sẽ có một nhiệm vụ trong tài khoản cá nhân của họ trong phần Đánh giá của tôi để tạo danh sách và chọn người phê duyệt. Đánh giá sẽ bắt đầu khi tất cả các danh sách được hình thành.",
    listDegree360:"360 xếp hạng",
    eve_and_bad_moder: "Đơn xin tham gia sự kiện từ một nhân viên",
    profiles_lb:"Hồ sơ",
    profiles_plc: "hồ sơ chứa một số năng lực",
    add_from_comps: "Chọn một thẩm quyền",
    incomplete_data:"năng lực Không Đầy đủ, thiếu chỉ số",
    experts_clear: "Tất cả các nhân viên đánh giá sẽ bị xóa",
    degree_tasks: [{value:0, label:"đặt nhiệm vụ riêng lẻ"}, {value: 1, label:"đặt một nhiệm vụ chung"}, {value: 2, label: "không đặt nhiệm vụ"}],
    old_assessments:"định dạng xếp hạng Cũ",
    degree_task_name: "Nhập tên của nhiệm vụ",
    degree_task_description: "Nhập mô tả về tác vụ",
    the_task_360: "Nhiệm vụ",
    tasks_360: "Nhiệm vụ",
    add_task_degree360: "Tạo danh sách các trình đánh giá sẽ được đánh giá",
    add_degree360: "Thêm điểm 360",
    stud_id: "ID CỦA Người được đánh giá",
    its_my_degree: "tôi Đang Được Đánh giá",
    degree_readonly_info: "Các nhiệm vụ đã được thực hiện cho Đánh giá 360, một số hành động với Đánh giá không thể thực hiện được. Ví dụ: xóa người được đánh giá, người đánh giá, xóa và thêm năng lực, thay đổi ngày, bật tự đánh giá, thay đổi cách đặt nhiệm vụ.",
    degree360_alert:"ứng dụng đã được phê duyệt, nó không thể thay đổi.",
    degree360_agreeID: "Phê duyệt",
    degree360_agreed: "Đồng ý",
    degree360_wait_agreed: "Đang chờ phê duyệt",
    degree360_self_title:" 360 Lớp Ứng dụng",
    degree360_self:"Ứng dụng Lớp 360",
    degree_self: "Hình thành một danh sách các nhà đánh giá",
    degree_self_info: "để đăng ký, hãy chọn Cho mình Những Người Đánh giá và Người chịu trách nhiệm phê duyệt Điểm 360",
    degree_start_finish_info: "Ngày Được sử dụng một cách thông tin và không ảnh hưởng đến việc hiển thị Lớp 360.",
    agree_not_valid: "không phải tất cả dữ liệu được điền vào. Vui lòng kiểm tra tiêu đề, ngày Tháng, Phân loại, Phân loại.",
    degree_add_scale_info: "Chọn thang điểm xếp hạng từ danh sách để thêm thang điểm đó vào Xếp hạng 360",
    degree_add_comp_info: "Chọn một thẩm quyền từ danh sách và nhấp vào nút < + > để thêm thẩm quyền vào Đánh giá 360",
    degree_adb_prof_info: "Chọn một hồ sơ từ danh sách và nhấp vào +. Năng lực của anh ấy sẽ được thêm vào Đánh giá 360",
    degree_add_experts_info: "Thêm Người Đánh giá bằng các nút bên dưới",
    degree_add_students_info: "Thêm nhân viên Được Đánh giá bằng các nút bên dưới",
    degree_agree_degree360_info: "ở vị trí bên trái của hộp kiểm, bạn có thể lưu ước tính làm việc. Đúng vị trí - bài tập sẽ được thực hiện khi tiết kiệm.",
    degree_agree_user_info: "Phê duyệt là cần thiết để chỉ định Một Đánh giá. Chọn một nhân viên phù hợp.",
    degree_hide_fio_info: "theo mặc định, người đánh giá sẽ thấy dữ liệu của người đánh giá. Khi được kích hoạt, dữ liệu của người đánh giá sẽ bị ẩn.",
    degree_pub_lk_info: "Công bố kết quả đánh giá trong tài khoản cá nhân của người đánh giá sau khi tất cả người đánh giá đã cho điểm.",
    degree_task_info: "nhiệm vụ Trong Bitrix là đưa ra đánh giá. Đặt bởi người đánh giá.",
    show_options_block: "Cài đặt Xếp hạng 360",
    show_users_block:"Danh sách người đánh giá và người đánh giá",
    show_comps_block: "Năng lực, chỉ số, thang đánh giá",
    degree360_aver_title:"Kết quả Đánh giá (giá trị trung bình)",
    degree360_stat_title: "số người được hỏi tham gia đánh giá",
    degree360_aver_col_: "Thực hành",
    degree360_stat_ret0: "Số người",
    degree360_stat_col_: "nhóm Người Trả lời",
    degree360_stat_col0: "Tự đánh giá",
    degree360_stat_col1: "Người Giám sát",
    degree360_stat_col2: "Đồng nghiệp",
    degree360_stat_col3: "Cấp dưới",
    degree360_stat_col4: "Tất cả người trả lời<br> (không tự đánh giá)",
    degree360_stat_col5: "Tất cả người trả lời",
    degree360_description: "Bạn cần phải thực hiện Một Lớp 360",
degree360_message: "Bạn Đã được chỉ định Điểm 360. Chuyển đến Ứng dụng 'Đại học' / Điểm Của Tôi'",
    you_agree_360:"Bạn được bổ nhiệm làm hòa giải Viên Trong Đánh giá 360. Đi đến ứng dụng Đại học để phê duyệt.",
    del_student:" bạn muốn xóa cái được đánh giá",
    do_schedules: "lớp 360 sẽ được chỉ định cho người đánh giá",
    no_experts: "Người Đánh giá không được chỉ định",
    agree_user: "sự phù hợp",
    agree_degree360: "Đồng ý",
decline_degree360: "Trả lại",
decline_degree360_info: "Nếu bạn đã thay đổi trạng thái của ứng dụng, hãy lưu nó",
publish_degree360:"Xuất bản điểm 360",
del_degree360: "Xóa lớp 360",
del_degree360_msg:" bạn có thực sự muốn xóa lớp 360",
    degree_name: "Nhập tên của đánh giá",
    add_prof_label: "Chọn hồ sơ",
    scale_plc: "Chọn một quy mô",
    add_prof: "Thêm vào năng lực",
    save_comp: "Lưu",
close_comp: "Đóng",
    program_readonly: "Các Cuộc Hẹn đã được thực hiện cho chương trình này, vì vậy nó không thể thay đổi. Bạn có thể tạo một bản sao của chương trình trong danh sách các chương trình để sửa đổi và bài tập mới.",
    program_info: "mô-đun 'Chương trình' được thiết kế để tạo thành các chương trình phát triển toàn diện. Ví dụ, chẳng hạn như Sự Thích nghi của người mới bắt đầu, Đào tạo dự trữ nhân sự, v. v. "+
      "<br>Các chương trình có thể được hình thành từ các yếu tố sau: các khóa học, bài kiểm tra, bài học (ví dụ: thêm tệp PDF vào bài học, quy định, bản trình bày, liên kết, tài liệu, longrid, v. v.), sự kiện (ví dụ: tạo Một Cuộc Họp sự kiện với Người Đứng đầu)."+
      "<br>Các chương trình được hình thành từ các phần tử song song và tuần tự. Các yếu tố tuần tự hình thành các cấp độ (nó có thể Là Một Ngày, một giai đoạn học tập). Bất kỳ số lượng phần tử nào cũng có thể ở một cấp độ, có nghĩa là tất cả chúng phải được hoàn thành ở cấp độ này. "+
      "<br>Sau khi tạo một chương trình, nó có thể được chỉ định cho nhân viên, nhóm (khi thêm nhân viên vào một nhóm, chương trình sẽ tự động được chỉ định cho anh ta). Danh sách nhân viên có thể được thay đổi: khi được thêm vào, nhân viên sẽ được chỉ định một chương trình, khi bị xóa, anh ta sẽ tiếp tục có mặt trong các báo cáo."+
      "<br>Chương trình được chỉ định không thể thay đổi.",
    copy_program: "Sao chép chương trình",
    module_level:"Cấp độ",
    eve_and_bad_moder: "Đơn xin tham gia sự kiện từ một nhân viên",
    is_answered: "Có câu trả lời",
    check_all: "Đánh dấu tất cả các lớp",
    check_group: "Đánh Dấu nhóm",
    check_one: "Đánh dấu dòng",
    rep_date: "Ngày",
    rep_type: "để làm gì",
    the_open: "mở câu hỏi",
    the_event: "Sự kiện",
    the_360: "Điểm 360",
    the_aos: "AOS",
    the_rate: "Khóa Học / Điểm Kiểm tra",
    the_comment: "Bình luận",
    the_ipr:"IPR",
    the_Rc:"Đánh giá Khóa học",
    the_Rt:"Điểm Kiểm tra",
    the_course: "Khóa học",
    write_off: "Xóa Sổ",
    not_in_sched: "Thiếu trong bài tập chương trình",
    sub_alert: "tài khoản này chứa các tài khoản phụ, nhưng giới hạn của chúng đã đạt được. Liên hệ với người điều hành ứng dụng",
    sub_limit_exceeded: "giới hạn tài khoản phụ đã đạt được. Đi Tới Cài đặt / biểu Giá của Tôi để tăng giới hạn",
    sub_login_already: "một tài khoản phụ như vậy đã tồn tại",
    sub_accounts:"Tài khoản Phụ",
    native_add: "Thêm một tài khoản. Chỉ khả dụng cho quản trị viên cổng thông tin",
    sub_add: "Thêm tài khoản phụ",
    del_subac_title: "Xóa tài khoản phụ",
    del_subac_message: "tài khoản phụ sẽ bị xóa khỏi danh sách. Bạn có chắc không?",
    del_sub_title: "Xóa Tài khoản",
    del_sub_message: "tài khoản sẽ bị xóa khỏi danh sách cùng với các tài khoản phụ của nó. Bạn có chắc không?",
    self_sign_up: "Tự đăng ký",
    sub_acc_title: "Ủy quyền",
    sub_acc_login: "Login",
    sub_acc_password:"Mật khẩu",
    sub_acc_password2: "lặp lại mật khẩu",
    sub_acc_recv:"dữ liệu của Bạn",
    sub_acc_first_name: "Tên",
    sub_acc_last_name: "Họ",
    sub_acc_middle_name: "Bảo trợ",
    sub_acc_position: "Vị trí",
    sub_acc_login_not_found: "Không Tìm Thấy Đăng nhập, yêu cầu đăng ký",
    sub_acc_save: "Lưu",
    sub_acc_ok: "Đăng nhập",
    sub_acc_passwords_not_match: "Mật khẩu không khớp",
    sub_acc_password_fail:"mật khẩu không hợp lệ",
    sub_acc_valid_recv: "Điền chi tiết",
    sub_acc_recv: "Thay đổi dữ liệu",
    sub_important_info: "Thông tin quan trọng",
    sub_info: "<ol>"+
     "<li><b>Thêm tài khoản.</b><br>"+
     "Điều kiện quan trọng:</li>"+
     "<ul><li>Đây là tài khoản Bitrix24 mà các tài khoản phụ của Đại học sẽ được liên kết.</li>"+
     "<li>Chỉ quản trị viên cổng Bitrix24 mới có thể tạo tài khoản. Không phải người điều hành ứng dụng.</li>"+
     "<li>Tài khoản không thể là Quản trị viên Bitrix24 hoặc người điều hành ứng dụng Đại học.</li></ul>"+
     "<li><b>Chọn phương thức đăng ký tài khoản phụ trong ứng dụng:</b></li>"+
     "<ul><li><b>Độc lập.</b> Trong trường hợp này, quản trị viên cổng chỉ cần nhập tên đăng nhập và thông báo các tên đăng nhập này cho các tài khoản phụ. Khi đăng nhập, các tài khoản phụ sẽ nhập tên đăng nhập của họ, sau đó ứng dụng sẽ yêu cầu họ điền thông tin (họ tên và chức vụ) và tạo mật khẩu.</li>"+
     "<li><b>Bởi quản trị viên.</b> Trong trường hợp này, quản trị viên nhập tên đăng nhập và điền tất cả thông tin (họ tên và chức vụ), sau đó cung cấp tên đăng nhập cho các tài khoản phụ. Khi đăng nhập, tài khoản phụ sẽ được yêu cầu tạo mật khẩu. Các thông tin khác (họ tên và chức vụ) sẽ được quản trị viên điền sẵn.</li></ul>"+
     "<li><b>Mỗi lần đăng nhập vào ứng dụng, tài khoản phụ sẽ cần nhập tên đăng nhập và mật khẩu của họ.</b></li>"+
     "</ol>",
     cert_format:"Định dạng Trang",
    cert_name: "Tên Chứng Chỉ",
    cert_save: "Lưu chứng chỉ",
    cert_new: "Thêm chứng chỉ",
    cert_list: "Danh sách các chứng chỉ",
    clear_form:"chứng chỉ Mới",
    cert_remove: "Xóa khỏi danh sách",
    cert_default: "theo mặc định",
    cert_scale:"Quy mô",
    cert_opts_fio: "Tên Người Dùng",
    cert_opts_pos: "Vị trí",
cert_opts_name: "Tên của bài kiểm tra / khóa học",
    cert_opts_score: "Điểm Vượt qua",
    cert_opts_date: "Ngày hoàn thành",
    cert_opts_text: "văn Bản Bổ sung",
    cert_dark_mode:"Nền Tối",
    cert_orient_a:"Phong cảnh",
cert_orient_p: "Chân dung",
certs: "Giấy chứng nhận",
    cer_tificate: "Giấy chứng nhận",
    cert_img: "Hình ảnh",
    save_png: "Tải về",
cert_del_title: "Chú ý!",
cert_del_message: "chứng chỉ sẽ bị xóa khỏi danh sách. Tệp cũng sẽ bị xóa khỏi đĩa.",
cert_reload_title: "Chú ý!",
cert_reload_message: "để áp dụng các thay đổi, bạn sẽ cần đăng xuất và quay lại cài đặt chứng chỉ.",
cert_font_size:"cỡ chữ",
    cert_font: "Phông chữ",
    cert_font_color: "màu Chữ",
    is_program_tasks: [{value:0, label: "đặt nhiệm vụ cho mỗi mô-đun"}, {value:1, label: "đặt một nhiệm vụ chung"}, {value:2, label:"không đặt nhiệm vụ"}],
    program_tasks: "Nhiệm vụ",
    program_task_description: "Bạn cần chạy chương trình",
    course_passed: "khóa học đã được hoàn thành",
    module_complete: "Hoàn thành mô-đun",
    done_success: "Thành công!",
    save_program: "Lưu",
    add_module_s: "Thêm mô-đun nối tiếp",
    add_module_p: "Thêm một mô-đun song song",
    program_message: "Bạn đã được chỉ định một chương trình đào tạo. Chuyển đến Ứng dụng 'Đại học' / Chương trình Của Tôi'",
    my_programs:"chương trình Của Tôi",
    publish_schedule_tooltip:" không có chương trình nào được chọn hoặc đặt ngày hẹn, người giám sát, nhân viên",
    mod_description:"trong kế hoạch thực hiện chương trình, bạn cần thực hiện mô-đun",
    list_schedules: "Bài tập",
    add_schedule: "Thêm một cuộc hẹn",
    one_schedule: "Chuyển nhượng",
    publish_schedule:" đặt lịch hẹn",
    dates_schedule: "Ngày Hẹn",
    add_students:"Nhân viên",
    dep_rem_title: "Xóa khỏi bài tập?",
    dep_rem_mess: "Xóa tất cả mọi người với nhóm này (phân chia)",
    dep_rem_mess1: "Xóa thành viên này",
    start_finish: "Bắt đầu / Kết thúc",
    selectusers_label:"Nhân viên",
    selectgroups_label: "Nhóm",
    selectdeps_label: "Phòng ban",
    selecttests_label: "các khóa học / bài kiểm tra",
    selectgroup_title: "Thêm một nhóm",
    selectgroup_message: "Chọn nhóm",
    courses_tests: "các khóa Học Kiểm tra",
    students:"Sinh viên",
    del_schedule_title: "Xóa",
    del_schedule_message: "Xác nhận việc xóa điểm đến",
    del_schedules_message: "Xác nhận xóa bài tập",
    date_to_short: "kết thúc",
    students_comment: "Nếu một nhân viên được thêm vào thông qua một bộ phận hoặc nhóm, thì việc xóa một nhân viên như vậy sẽ đòi hỏi phải xóa các nhân viên khác của bộ phận/nhóm này",
    add_department: "Bộ phận",
    add_group: "Nhóm",
    add_main_auditor: "Người Giám sát",
    mod_duration: "Thời lượng, ngày",
    mod_action: {'tay': "thủ công", 'tự động':"tự động"},
    add_program: "Thêm một chương trình",
    list_programs: "Danh sách các chương trình",
    one_program: "Chương trình",
    progrm_modules:"Mô-đun",
    requaried_field:"Trường Bắt buộc",
    program_name: "Tên Chương trình",
    program_description:"Mô tả Chương trình",
    module_type: "Loại Mô-Đun",
    module_type_opt: [{value:1, nhãn: "Khóa học"}, {value: 2, nhãn: "Kiểm tra"}, {value: 3, nhãn: "Bài học"}],
    select_module: "Chọn một mô-đun",
    not_found: "Không tìm thấy gì",
    type_open: "Cách mở mô-đun",
    type_open_opt: [{value: 'P', label:"Mở tất cả cùng Một lúc"}, {value:'S', label:"mở tuần tự. Nếu mô-đun không được thông qua, hãy mở mô-đun tiếp theo"},{value:'H', label:"Mở tuần tự. Nếu mô-đun không được thông qua, không mở mô-đun tiếp theo"}],
    publish_program: "Xuất bản chương trình",
    del_program: "Xóa",
    del_program_msg: "Bạn có chắc chắn muốn xóa",
    mod_passed: [{value: '0', nhãn: "không có trạng thái"}, {value: 'Y', nhãn: "Đã Qua"}, {value:'N', nhãn: "Không được thông qua"}],

    points:"Điểm",
    points_plc: "Đặt 0 nếu điểm được trao như được chỉ định trong cài đặt ứng dụng",
    for_course_active: "khóa Học Đã Hoàn thành",
    for_course_mod_lb: "phương Pháp Đếm",
    for_course_mode: [
      {value:'tổng', label: "Tính bằng tổng điểm cho các mô-đun"},
      {value: 'tay', label: "Nhập thủ công"},
    ],
    for_course_dop_lb: "Điểm cho bài học/kiểm tra trong khóa học",
    for_course_dop: [
      {value: 'top', label: "Tích lũy"},
      {value:'nút', label: "không tính phí"},
    ],
    separ_add: "Thêm dấu phân cách",
    sep: "Dấu Phân cách",
    bid_del_message: "Xóa giá thầu",
    em_count_limit: "Đã vượt quá số lượng người tham gia tối đa",
    em_count:"Số lượng người tham gia. 0-không giới hạn",
    eve_attende:"Người tham gia",
    eve_bid:"Giá thầu",
    not_published: "Chưa được phát hành",
    my_events: "Các sự kiện của tôi",
    ipr_comp:"Năng lực",
    ipr_ind:"Chỉ số",
    eve_bid_done: "ứng dụng của Bạn đã được thêm vào. Sau khi xem xét, bạn sẽ trở thành người tham gia.",
    eve_new_big_add: "sự kiện mong muốn không có trong danh sách? Đưa ra yêu cầu. Sau khi xác minh, sự kiện sẽ được thêm vào danh sách và bạn sẽ trở thành người tham gia.",
    eve_new_bid_title: "Ứng dụng cho một sự kiện mới",
    eve_new_bid_done: "ứng dụng của Bạn đã được thêm vào. Sau khi xem xét, sự kiện sẽ được thêm vào danh sách và bạn sẽ trở thành người tham gia.",
    alert_understood: "Hiểu",
    eve_new_bid_moder: "một ứng dụng cho sự kiện đã được nhận từ một nhân viên",
    em_details: "Chi tiết",
    bid_restore_title: "Khôi phục ứng dụng",
    bid_restore_message: "Chỉ định lý do khôi phục ứng dụng",
    bid_accepted: "ứng dụng đã được chấp nhận",
    bid_declined: "Ứng dụng bị từ chối, khôi phục.",
    bind_accept: "Chấp nhận ứng dụng",
    bid_decline_title: "Từ chối ứng dụng",
    bid_decline_message: "Chỉ định lý do từ chối ứng dụng",
    eve_bids_all: "Thêm tất cả",
    eve_calend: "trong lịch",
    eve_detail:"Thông tin Thêm về sự kiện",
    eve_edit: "Chỉnh sửa",
    em_bids: "Ứng dụng",
    eve_bid_lbl: "Cho tham gia",
    eve_title: "Đăng ký tham gia sự kiện",
    eve_bin_add: "tôi sẽ là người tham gia",
    eve_nts: "Sự kiện",
    text_block:"khối văn bản",
    text_block_name: "Tên Trường",
    text_block_value:"Nội dung Trường",
    r_o: "Chỉ Đọc",
    set_files:"Tệp",
    integra_chart:"Biểu đồ tích phân",
    view_cards:"Xem: thẻ",
    view_list:"Xem: danh sách",
    node_disabled:"Đặt không hoạt động",
    node_enabled:"Kích hoạt",
    download: "Taót",
    no_valid_publish:"Dữ liệu Quan trọng không được đặt, ví dụ: nhân viên, người giám sát, người cố vấn, năng lực, chỉ số hoặc không có quyền chỉnh sửa",
    hide_fio: "Ẩn dữ liệu của người đánh giá",
    such_element_exist: "một phần Tử như vậy đã tồn tại",
    program_course:" / chương trình khóa học",
    lessons: "Bài học",
    tests: "Kiểm tra",
    no_lessons: "Không có bài học để thêm vào khóa học",
    no_tests:" không có bài kiểm tra nào để thêm vào khóa học",
    drop_here: "Kéo Bài Học và bài kiểm tra ở đây",
    reset_tree: "đặt lại điều hướng",
    reset_true_msg: "Xóa Tất cả các bài học và bài kiểm tra khỏi khóa học?",
    export_all:"Xuất tất cả",
    imgFiles:"Các tập tin để trả lời",
    upload_title:"Tải lên",
    upload_err:"Tải lên không thành công",
    upload_hint:"Chọn tệp trên đĩa hoặc kéo và thả tệp vào đây",
    max_file_size:"Kích thước tệp không được vượt quá {mb} Mb",
    delete_image:"Xóa tập tin",

    ipr_note:"Bạn đã được chỉ định 'Kế hoạch phát triển cá nhân'. Hãy truy cập ứng dụng Đại học doanh nghiệp của bạn.",
    add_evt_title: "Sự kiện được thêm vào",
    add_evt_msg: "Đóng biểu mẫu để thêm sự kiện và đi đến danh sách?",
    add_evt_ok: "Đóng",
    add_evt_canc: "Thêm nhiều hơn",
    res360_published:"kết quả đánh giá 360 được công bố Trong Tài khoản Cá nhân của người được đánh giá",
    rm_rm: "sự Kiện Phát triển",
    rm_dln: "Hết hạn",
    rm_deadline: "Thời hạn",
    go_course: "Đi",
    no_results: "Không có dữ liệu",
    enter_course_name: "Nhập một phần của tên để tìm kiếm",
    course_name: "Thêm khóa học",
    course_statuses: "trạng Thái Khóa học",
    ipr_courses: "KHÓA HỌC RM",
    ipr_export: "Xuất khẩu",
    iprset_create_label:" ai có thể tạo ra",
    iprset_edit_label: "ai có thể chỉnh sửa",
    iprset_read_label: "Ai có thể nhìn thấy",
    iprset_status_label: "Ai có thể thay đổi trạng thái",
    iprset_role_opt: [{value: 'M', label: "Người Điều hành"}, {value:'U', label:"Nhân viên"},{value:'N', label:"Người Cố vấn"}],

    add_rm: "RM",
    add_ind: "Chỉ số",
    ipr_title:"Kế hoạch phát triển Cá nhân",
    ipr_name: "TÊN IPR",
    ipr_close: "Đóng",
    ipr_open: "Mở",
    select_person: "Nhân viên",
    select_manager: "Người Giám sát",
    select_mentor: "Cố vấn",
    rm_label: "RM",
    rm_options: [
      "Bài giảng",
      "Bài kiểm tra",
      "Báo cáo",
      "Cạnh tranh",
      "Cuộc họp",
      "Dự án",
      "Gặp gỡ",
      "Hackathon",
      "Hội nghị",
      "Hội thảo",
      "Hội thảo",
      "Hội thảo",
      "Khoảng cách",
      "Khóa học",
      "Lớp thạc sĩ",
      "Mô phỏng",
      "Nghiên cứu tình huống",
      "Nghiên cứu",
      "Nội dung video",
      "Nội dung âm thanh",
      "Phiên",
      "Podcast",
      "Thử nghiệm",
      "Thực tập",
      "Trò chơi",
      "Tư vấn",
      "Tạo thuận lợi",
      "Xây dựng đội ngũ",
      "Đào tạo",
      "Đánh giá",
      "Đánh giá",
      "Đại học",
      "-phiên bản của riêng bạn -"
    ],
    rm_compleeted: "Đã Hoàn thành",
    rm_started: "đang tiến hành",
    rm_new: "Chưa bắt đầu",
    rm_status: "Trạng thái",
    hand_made: "Nhập bằng tay",
    comp_name: "Tên Thẩm quyền",
    ind_name: "Tên Chỉ báo",
    del_ind: "Xóa chỉ báo",

    selectcomps_label: "Năng lực",
    selectcomps_title: "Thêm năng lực",
    selectcomp_message: "Chọn một thẩm quyền",
    selectcomps_message: "Chọn một hoặc nhiều năng lực",
    selectcomps_add: "Thêm",
    selectcomps_finish: "Đóng",
    selectscale: "thang Đánh giá",
    target_sc: "Mức Độ Mục tiêu",
    current_sc: "Mức Độ Hiện tại",

    del_certs: "giấy chứng nhận đã bị xóa trong Phần Giáo dục, xóa nó ở đây",
    no_certs: "Bạn không có bất kỳ chứng chỉ tải về. Thêm chúng vào Phần Giáo dục ",
    aos_filled: "điền vào",
    anketa_add: "Thêm hồ sơ",
    for_test: "đối với các bài kiểm tra",
    for_course: "đối với các khóa học",
    for_event:" cho sự kiện",
    aos_copy: "(Bản Sao)",
    new_aos_name: "Thay đổi tên để lưu",
    first_aos_name: "Bảng Câu Hỏi Mặc định",
    aos_edit: "Chỉnh sửa bảng câu hỏi",
    aos_look: "Xem bảng câu hỏi",
    aos_refresh_title: "Tải theo mặc định",
    aos_refresh_message:"hồ sơ của bạn sẽ được thay thế bằng hồ sơ mặc định",
    answ_aos: "Các Câu Trả lời",
    answer: "Văn bản",
    score:"Trọng lượng",
    quest_aos: "Câu hỏi",
    add_answ: "Thêm phản hồi",
    other_answ: "Khác",
    a_o_s: "AOS",
    add_aos: "Thêm một câu hỏi",
    aos: "Bảng Câu Hỏi Phản Hồi",
    aos_del: "Xóa hồ sơ",

    cert_del: "Xóa chứng chỉ khỏi sự kiện?",
    cert_add: "Thêm chứng chỉ",
    event_certs: "Chọn chứng chỉ",
    em_del: "Xóa một sự kiện (khỏi cả lịch)",
    em_save: "Viết",
    em_clear: "Rõ ràng",
    em_meeting_notify: "Gửi lời mời",
    em_description: "Mô Tả Sự Kiện",
    em_meeting_text: "văn Bản Mời",
    em_color: "Màu Sự kiện",
    em_text_color: "Màu Văn bản",
    em_attendees: "Thành viên",
    em_host: 'Có Trách nhiệm',
    em_org: "Ban Tổ chức",
    em_title:"Sự Kiện Bên ngoài và mặt đối mặt",
    em_eventName: "Tên Sự kiện",
    em_common_list: "Danh Sách Chung",
    em_personas: 'Đánh giá',
    em_close: "chọn",
    em_location: "Loại",
    em_location_opt: ['Nội Bộ','Bên Ngoài'],
    em_vid: "Xem",
    em_vid_opt: [
    'Bài giảng',
    'Bài kiểm tra',
    'Báo cáo',
    'Cạnh tranh',
    'Dự án',
    'Gặp gỡ',
    'Hackathon',
    'Hội nghị',
    'Hội thảo',
    'Hội thảo',
    'Khóa học từ xa',
    'Khóa học',
    'Kiểm tra',
    'Lớp thạc sĩ',
    'Mô phỏng',
    'Nghiên cứu',
    'Nội dung video',
    'Nội dung âm thanh',
    'Phiên',
    'Podcast',
    'Thực tập',
    'Trò chơi',
    'Trường hợp',
    'Tư vấn',
    'Tạo thuận lợi',
    'Webinar',
    'Xây dựng đội ngũ',
    'Đào tạo',
    'Đánh giá',
    'Đánh giá',
    'Đại học',
  ],
    common_list: "Danh Sách Chung",
    personas: 'Đánh giá',
    exp_comments: "Bình luận",
    no_events: "Không có sự kiện sắp tới",
    em_from_to: "Bắt đầu và kết thúc sự kiện",
    acc1: "Hiển thị kết quả trong tài khoản cá nhân",
    add_section: "Thêm một phần",
    comps_added: "Thêm năng lực",
    comps_add: "Thêm năng lực",
    attention: "Chú ý",
    del_competence:"Xóa thẩm quyền",
    competencies: "Năng lực",
    all: "Tất cả",
    add_coworkers: 'Thêm đồng nghiệp',
    coworkers: "Đồng nghiệp",
    add_slaves: 'Thêm cấp dưới',
    slaves: 'Cấp dưới',
    add_chief: 'Thêm người giám sát',
    chief:'Chief',
    new: 'Nhân viên',
    Average_: 'Trung bình mà không có sự tham gia của các đánh giá',
    departments: 'Divisions',
    add_departments: 'Thêm bộ phận',
    selectdepartments_title: 'Thêm một bộ phận',
    selectdepartments_message: 'Chọn một bộ phận',
    selectdepartments_add: 'Thêm',
    selectdepartments_finish: 'Đóng',

    rep_competence: "Năng Lực/Chỉ số",
    rep_indicators: "Chuyên gia",
    rep_scores: "Xếp hạng",
    comment: "Bình luận để đánh giá",
    acc: "Xuất bản trong tài khoản cá nhân của đánh giá",
    you_ass_360:"Anh đã được giao một điểm của 360. Xem danh sách của tất cả các xếp hạng trong tài khoản cá nhân.",
    del_many_title:"Xóa nhiều đánh giá của",
    del_many_mess:"bạn có muốn loại bỏ được đánh giá của",
    rows_per_page: 'Tài liệu từng trang',
    all_report: 'Báo cáo tổng hợp',
    start_finish: 'Bắt đầu / kết thúc',
    competence: 'Năng lực',
    selectusers_title: 'Thêm nhân viên',
    selectuser_message: 'Chọn người dùng',
    selectusers_message: 'Chọn một hoặc nhiều người dùng',
    selectusers_add: 'Thêm vào',
    selectusers_finish: 'Gần',
    title: '360/180 phản hồi',
    goback: 'Mặt sau',
    loading: 'Đang tải',
    remove: 'Di dời',
    assessment: 'phản hồi',
    competences: 'Năng lực',
    name: 'Tên',
    date_start: 'Ngày bắt đầu',
    date_finish: 'Ngày kết thúc',
    description: 'Sự miêu tả',
    persona: 'Dưới phản hồi',
    experts: 'Các thành viên',
    clear: 'Sạch',
    del: 'Xóa bỏ',
    add: 'Thêm vào',
    add_assessment: 'Tạo phản hồi',
    add_persona: 'Thêm một người dưới phản hồi',
    add_experts: 'Thêm thành viên',
    ok: 'OK',
    reset: 'Hủy bỏ',
    scores: 'Phản hồi',
    publish: 'Công bố',
    alert_message: 'Dữ liệu không được lấp đầy',
    alert_persona: 'Dưới phản hồi',
    alert_experts: 'Các thành viên',
    alert_dates: 'Ngày',
    alert_competences: 'Năng lực',
    alert_scores: 'Phản hồi',
    del_ass_title: 'Xóa xếp hạng',
    del_ass_mess: 'Xóa xếp hạng',
    deleteSome: 'Xóa bỏ',
    export: 'Xuất khẩu',
    report: 'Bản báo cáo',
    del_some_title: 'Xóa bỏ',
    del_some_mess: 'Bạn muốn xóa xếp hạng',
    more: 'Hơn',
    chart: 'Kết quả',
    chart_title: '180 ° / 360 ° Biểu đồ xếp hạng cho nhân viên',
    refresh: 'Cập nhật danh sách',
    user: 'Nhân viên',
    clear_scores: 'Đánh giá rõ ràng',
    clrscores_title: 'Sạch',
    clrscores_message: 'Tất cả điểm số sẽ bị xóa khỏi Xếp hạng này. Tiếp tục?',
    legend: 'Tên năng lực đầy đủ',
    results: 'Kết quả',
    average: 'Trung bình',
    average1: 'Trung bình*',
    close_report: 'Đóng báo cáo',
    assessment_name: 'Tên xếp hạng',
    action: 'Hoạt động',
    list: 'Quay lại danh sách điểm số',
    status: 'Trạng thái',
    published: 'Được phát hành',
    draft: 'Bản nháp',
  },
};
